<div class="wrapper">
    <div class="mb-3">
        <app-menu [mode]="'teacher_reflections.title'" [details]="''" [school]="school"></app-menu> 
        <app-filters [showWeek]="true" [showAge]="true" (weekChanged)="weekChanged($event)" (_ageChanged)="_ageChanged($event)"></app-filters>
    </div>

    <div class="container-lg">
        <div *ngIf="!!reflections; else loading">
            <table class="_table" *ngIf="reflections.length > 0; else noClasses" class="table">
                <tr class="text-center">
                    <th class="class">{{'shared.class' | translate}}</th>
                    <th class="day">{{'shared.day' | translate}} 1</th>
                    <th class="day">{{'shared.day' | translate}} 2</th>
                    <th class="day">{{'shared.day' | translate}} 3</th>
                    <th class="day">{{'shared.day' | translate}} 4</th>
                    <th class="day">{{'shared.day' | translate}} 5</th>
                </tr>
                <tr class="_item" *ngFor="let item of reflections">
                    <td class="_title">{{item.title}}</td>
                    <td  *ngFor="let day of ['1','2','3','4','5']" (click)="select(item.id, day)" class="_content text-center cursor-pointer">
                        <span class="_icon" *ngIf="item.days.includes(day)">
                            <div class="_svg _checkmark"></div>
                        </span>
                        <span class="_icon" *ngIf="!item.days.includes(day)">
                            <div class="_svg _clipboard"></div>
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    
        <ng-template #noClasses>{{'shared.no_class' | translate}}</ng-template>
        <ng-template #loading>
            <div class="loader">
                <loader></loader>
            </div>
        </ng-template>
    </div>
    
</div>
