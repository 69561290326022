
<div class="wrapper">
    <div class="header">
        <div class="container-lg"> 
          <a routerLink="/login">
            <div class="logo-icon">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background:#fff" viewBox="0 0 792 344.24">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #97999a;
                      }
                
                      .cls-2 {
                        fill: #fff;
                      }
                
                      .cls-3 {
                        fill: #4eb748;
                      }
                
                      .cls-4 {
                        fill: #2150a3;
                      }
                    </style>
                    <symbol id="LB_Book_Open_2" data-name="LB Book Open 2" viewBox="0 0 750 750">
                      <path class="cls-1" d="M254.76,386.22c0-21,.06-42-.06-63,0-3.86,1.14-6.57,4.39-8.83a78.74,78.74,0,0,1,55.62-14.21,73.81,73.81,0,0,1,31.92,10.95c2.67,1.69,4.38.55,6.41-.67q31.63-19,63.28-38c6.95-4.16,12.46-1,12.49,7,0,6.88.12,13.76-.06,20.63-.07,2.64.73,3.76,3.31,4.78,6.82,2.7,13.43,5.94,20.13,9,4.3,1.94,5.8,5.39,5.78,10-.07,23,0,46,0,69,0,18.87,0,37.75-.06,56.62a13.61,13.61,0,0,1-1.44,6.43c-2.23,3.9-6.24,4.35-10.76,1.6a86.31,86.31,0,0,0-29.21-11.18c-21.83-4-42.47-.65-61.62,10.67-4.32,2.55-7.76,2.54-12-.4-22.89-15.93-51.69-15.82-74.84-.23a13.7,13.7,0,0,1-7.8,2.44c-3.76-.21-5.46-3.34-5.47-8.07Q254.75,418.47,254.76,386.22Zm86.86,52.4c.09-1.37.16-2,.16-2.6,0-36,0-72,.1-107.94,0-2.91-1.54-4-3.5-5.08q-33-18-66,.05c-2.47,1.34-3.38,3-3.37,5.92.12,35.11.08,70.21.09,105.32,0,1.29.12,2.58.21,4.35C293.56,427.23,317.42,427.32,341.62,438.62Zm72.62-147.35a7.52,7.52,0,0,0-1.77.45c-18.19,10.9-36.39,21.79-54.5,32.83-1,.64-1.77,2.62-1.77,4q-.15,53.4-.06,106.8a31.23,31.23,0,0,0,.32,3.17A8.51,8.51,0,0,0,358,438c17.76-10.65,35.48-21.38,53.31-31.93,2.56-1.51,3.16-3.32,3.16-6.07q-.12-52.28-.06-104.55C414.42,294.17,414.31,292.85,414.24,291.27Zm28.55,148.11a12.3,12.3,0,0,0,.37-1.93q0-54.93,0-109.84a3.55,3.55,0,0,0-1.3-2.74c-4.05-2.1-8.24-3.95-13-6.17v4.78c0,27.49-.08,55,.08,82.47,0,4.56-1.6,7.57-5.45,9.78-5.85,3.36-11.6,6.89-17.37,10.37-1.81,1.09-3.56,2.27-6.11,3.9A112.53,112.53,0,0,1,442.79,439.38Z"/>
                      <path class="cls-2" d="M341.62,438.62c-24.2-11.3-48.06-11.39-72.33,0-.09-1.77-.21-3.06-.21-4.35,0-35.11,0-70.21-.09-105.32,0-2.92.9-4.58,3.37-5.92q33-18,66-.05c2,1.06,3.51,2.17,3.5,5.08-.14,36-.09,72-.1,107.94C341.78,436.64,341.71,437.25,341.62,438.62Z"/>
                      <path class="cls-2" d="M414.24,291.27c.07,1.58.18,2.9.18,4.22q0,52.27.06,104.55c0,2.75-.6,4.56-3.16,6.07C393.49,416.66,375.77,427.39,358,438a8.51,8.51,0,0,1-1.55.45,31.23,31.23,0,0,1-.32-3.17q0-53.4.06-106.8c0-1.35.74-3.33,1.77-4,18.11-11,36.31-21.93,54.5-32.83A7.52,7.52,0,0,1,414.24,291.27Z"/>
                      <path class="cls-2" d="M442.79,439.38A112.53,112.53,0,0,0,400,430c2.55-1.63,4.3-2.81,6.11-3.9,5.77-3.48,11.52-7,17.37-10.37,3.85-2.21,5.47-5.22,5.45-9.78-.16-27.49-.08-55-.08-82.47V318.7c4.78,2.22,9,4.07,13,6.17a3.55,3.55,0,0,1,1.3,2.74q.11,54.92,0,109.84A12.3,12.3,0,0,1,442.79,439.38Z"/>
                    </symbol>
                  </defs>
                  <use width="750" height="750" transform="translate(321.01 -164.52) scale(0.89 0.91)" xlink:href="#LB_Book_Open_2"/>
                  <text/>  <g>
                    <path class="cls-3" d="M317.74,171.48c24.78,0,44.91,18.08,44.91,41.51s-19.26,41.61-44.91,41.61c-26.4,0-45-18.18-45-41.61S293.18,171.48,317.74,171.48Zm0,65.43c16.23,0,22.94-12,22.94-23.92s-7.14-23.91-22.94-23.91S294.69,201,294.69,213,301.08,236.91,317.74,236.91Z"/>
                    <path class="cls-3" d="M392.52,254H372.18V172h20.34L428,222.91h.21V172h20.35v82H428.23l-35.49-50.89h-.22Z"/>
                    <path class="cls-3" d="M488,172c24.46,0,45,17.09,45,40.95S512.54,254,488,254H462.11V172Zm-2.38,65.3c15,0,25.32-10.54,25.43-24.25s-10.28-24.35-25.43-24.35h-3.14v48.6Z"/>
                  </g>
                  <path class="cls-3" d="M216.35,172.07,235,198.29l18.61-26.22H278l-32.9,45.19V254H224.79V217.26l-32.9-45.19Z"/>
                  <g>
                    <path class="cls-3" d="M88.33,171.78c15.37,0,27.49,5.29,27.49,20.94a18.6,18.6,0,0,1-11.15,17.64c11.15,1.79,19,7.48,19,20.73C123.72,244.65,113,254,94.5,254H60.63V171.78ZM82.59,204c8.77,0,13.53-2.49,13.53-9.37,0-5.88-4.65-8.37-13.53-8.37H81V204Zm4.77,35.59c9.63,0,14.93-2.69,14.93-9.17,0-7.58-5.3-10.27-14.93-10.27H81v19.44Z"/>
                    <path class="cls-3" d="M183.78,188.23H153v16.35h29.44V221H153v16.54h30.74V254H132.7V171.78h51.08Z"/>
                  </g>
                  <path class="cls-4" d="M519.88,115.59a50.84,50.84,0,0,1-.67-7c0-3,6.82-2.25,6.82-2.25s12.18.45,14.08.45,2.24,2.59,2.24,2.71-.11,5.63-.11,8.12-3.13,2.25-3.13,2.25l-4.48-.11c-1.34,0-.89,5.41-.89,5.52s0,21.88-.45,27.4,3.24,19.06-8.94,28.76-33.42,4.4-38.12,2.25-3-5-2.79-5.18,1.23-5.42,2.79-8.46,3.8-1.24,3.91-1.24,1.57,3,15.88,3,11.51-14,11.73-14.54c-3.68,4.28-13.19,5.64-20.56,5.64s-21-6.43-21-26,12-28.87,25.15-28.87,17.89,7.67,17.89,7.56S519.88,115.59,519.88,115.59Zm-29.07,18c-1,9.7,4.48,15.9,11.52,16.57s14.31-5.63,15.09-10.71.45-9.7-1-12.18-7.15-4.17-8.72-4.51-5.81.12-5-.34S491.82,123.93,490.81,133.63Z"/>
                  <path class="cls-4" d="M410.29,108.34h15.38s3.42-.22,3.52,2.76V114c7.24-7.84,22.12-10,29.56-.34,4.93,6.62,3.72,16.11,3.72,16.33s-1.4,14.34-1.4,17,3.31.55,6.53.55,2.52,2.53,2.52,2.53.1,5.3.1,8.61-1.71,3-1.71,3a85.07,85.07,0,0,1-14.58,1.32c-17,0,6.53-40.59-15-40.59-7.14,0-9.25,4.3-10.25,7.39l-.61,18.86s-.4,1.77,1.11,1.77h4.43S436,150.26,436,152v8.49a2.29,2.29,0,0,1-2.41,2.54H410.19a2.3,2.3,0,0,1-2.42-2.54V152c0-1.76,2.42-1.65,2.42-1.65h4.42c1.61,0,1.41-1,1.41-2.43l.5-22.83s.4-3.64-1.71-3.64h-4.42a2.1,2.1,0,0,1-2.42-2.32l-.1-8.49A2.1,2.1,0,0,1,410.29,108.34Z"/>
                  <path class="cls-4" d="M369.64,107.89h17.3s3.84-.23,3.95,2.81c.12,3.27-1.24,38.3-1.24,38.3s-.45,1.8,1.24,1.8h5s2.71-.11,2.71,1.69v8.67a2.41,2.41,0,0,1-2.71,2.6H369.53a2.42,2.42,0,0,1-2.72-2.6v-8.67c0-1.8,2.72-1.69,2.72-1.69h5c1.81,0,1.58-1,1.58-2.48l.57-23.31s.45-3.72-1.92-3.72h-5a2.31,2.31,0,0,1-2.71-2.37l-.11-8.67A2.3,2.3,0,0,1,369.64,107.89Zm16.05-22.53c2.6.56,5.66,6.42,5.32,10.81s-5.77,7.66-10.86,7.66C368.85,103.83,368.4,81.53,385.69,85.36Z"/>
                  <path class="cls-4" d="M297.63,109.15H313s3.42-.22,3.52,2.76v2.87c7.24-7.83,22.12-10,29.56-.33,4.93,6.61,3.72,16.1,3.72,16.32s-1.41,14.34-1.41,17,3.32.55,6.54.55,2.51,2.54,2.51,2.54.1,5.29.1,8.6-1.71,3-1.71,3a84.92,84.92,0,0,1-14.58,1.32c-17,0,6.54-40.59-15-40.59-7.14,0-9.25,4.3-10.26,7.39l-.6,18.86s-.4,1.77,1.11,1.77H321s2.41-.11,2.41,1.65v8.5a2.28,2.28,0,0,1-2.41,2.53H297.53a2.29,2.29,0,0,1-2.41-2.53v-8.5c0-1.76,2.41-1.65,2.41-1.65H302c1.6,0,1.4-1,1.4-2.43l.51-22.83s.4-3.64-1.71-3.64h-4.43a2.1,2.1,0,0,1-2.41-2.32l-.1-8.49A2.1,2.1,0,0,1,297.63,109.15Z"/>
                  <path class="cls-4" d="M274.3,108.19a23,23,0,0,1,5.14.67,19.29,19.29,0,0,1,5.62,2.38l2.41,19.25-11.84,1.34-2.05-9.38a8.52,8.52,0,0,0-4.23-1.46,6.27,6.27,0,0,0-4.65,2.07q-2,2.07-2,6.09v22.92h9.66v11.82H239.88V152.07h7.24v-31.2h-7.85v-12H261.5V119.4a19.1,19.1,0,0,1,4.29-8Q268.75,108.2,274.3,108.19Z"/>
                  <path class="cls-4" d="M209.81,119.76l.29-.11-.48-7.26s-.09-3.21,1.82-3.21,7.44.22,7.44.11a2.13,2.13,0,0,1,2.67,2.24c0,2.67-.67,34.6-.67,34.6s-.28,3.84,1.34,3.84a31.73,31.73,0,0,0,4.68-.64s2.38-1,2.38,1.28,1.05,8.76,1.05,8.76a2.44,2.44,0,0,1-1.81,2.88c-2.29.64-11,2.14-15,1-3.25-.85-3.54-8.32-3.63-12h-.38c-8.11,18.15-39.22,18.79-39.22-16.56,0-12.49,5-19,10.49-22.42C191.11,106,203.71,109.18,209.81,119.76Zm-14.69,30.11c4.58,0,11-1.5,12.88-12.5,3-17.4-25.86-23.06-25.86-2.13C182.14,149.23,190.54,149.87,195.12,149.87Z"/>
                  <path class="cls-4" d="M158.82,158.61c-4.68,2.12-8.14,5.14-21.31,5.14-33.14,0-37.94-55.59-2.45-55.59,26,0,28.67,21.54,26.44,29.25,0,.11.23,3.68-6.25,3.68s-28.45,1.23-28.67,1,.67,8.6,12,8.6c5,0,7.7-.67,10.37-2,3.8-1.9,8.37-4.47,9.49-2,1,2.12,2.79,5.92,2.9,5.92S163.51,156.49,158.82,158.61Zm-33.58-26.79a114.4,114.4,0,0,0,11.71.12,80.36,80.36,0,0,0,11.16-1.9c.11-.11,1.34-11.5-12.94-9.94C122.89,121.44,125.13,131.71,125.24,131.82Z"/>
                  <path class="cls-4" d="M63.18,83.69c2.93,0,14.28.22,14.28.22A2.24,2.24,0,0,1,80,86.4c0,2.82-.51,61.19-.64,61.19s0,1.81,1,1.81l22.33-.34s4.2-.22,4.2,1.36v10.27s.39,2-2.29,2-41.71.11-41.71,0a2.6,2.6,0,0,1-2.3-2.94c0-2.94.26-73.06.13-73.06A2.38,2.38,0,0,1,63.18,83.69Z"/>
                  <text/>
                </svg>
            </div>
          </a>
        </div>
    </div>
    
    <div class="container-lg">
        <div class="content">
          <p class="title blue">{{ 'verify.specify_email' | translate }}</p>

                <div class="form-block">
                    
                  <div class="form">  
                    <p class="email">{{"forgot_pass.email" | translate}}:</p>
          
                    <input class="email" type="email" [(ngModel)]="email" (ngModelChange)="validateEmail(email)">
                  </div>

                  <div class="buttons">
                    <div class="button" (click)="openPopap()" [ngClass]="{'disableBtn': !validEmail }">{{ 'verify.verify_now' | translate }}</div>
                    <div class="button" (click)="verifyLater()" [ngClass]="{'disableBtn': !validEmail }">{{ 'verify.verify_later' | translate }}</div>
                  </div>
                 
                </div>
         </div>
    </div>
 <div></div>
</div>  

<div *ngIf="verify_now_popup.state" class="_bunner">
  <div class="popup">
      <div class="title">{{ 'verify.please_enter_code' | translate }} {{ email }} </div>

      <input class="input" maxlength="4" type="text" [(ngModel)]="code" (ngModelChange)="validateCode(code)">

      <div class="error" *ngIf="verify_now_popup.error_state">
        {{ 'messages.incorrect_code' | translate }}
      </div>

      <div class="buttons">
        <div class="bunner-button" (click)="closePopap()">{{ 'verify.close' | translate }}</div>
        <div class="bunner-button" [ngClass]="{'disableBtn': !validCode }" (click)="confirm()">{{ 'verify.confirm' | translate }}</div>
      </div>
  </div>
</div>