<div class="wrapperTableEdit" *ngIf="user" [ngClass]="{ clever: profile.ext_integration == 'clever' || (school.school_type == 'Clever' && editPage  && page != 'userprofile')}">
  <div class="stringEdit username" *ngIf="userHasEditAccess">
      <div class="title">{{ 'profile.username' | translate }}:</div>
      <input  type="text" [ngClass]="{'required': errorUsername, 'required2': !editPage && !user.username}"
              [(ngModel)]="user.username" 
              (input)="onChangeUsername()"
              (change)="onExistsUsername()"
              [disabled]="editPage"/>
  </div>
  <div class="stringEdit" *ngIf="errorUsername">
    <div class="title"></div>
     <div class="error">{{errorUsername | translate}}</div>
  </div>
  <div class="stringEdit password" *ngIf="userHasEditAccess">
      <div class="title">{{ 'profile.password' | translate }}:</div> 
      <div class="wrappass">
        <input type="{{typePassword}}" [(ngModel)]="user.password" (input)="onChangePassword()" 
              [ngClass]="{'required': errorPassword, 'required2': !editPage && !user.password}"/> 
        <a class="eye" [ngClass]="{'myinvisible': !show, 'myvisible': show}"
          (click)="toggleShow($event)"></a>
      </div>

  </div>
  <div class="stringEdit" *ngIf="errorPassword">
    <div class="title"></div>
    <div class="error">{{errorPassword | translate}}</div>
  </div>
  <div class="stringEdit firstname" *ngIf="userHasEditAccess">
      <div class="title">{{ 'profile.firstname' | translate }}:</div> 
      <input type="text" [(ngModel)]="user.firstname" 
                        (input)="onChangeSave()"
                        [ngClass]="{'required2': !user.firstname}"/> 
  </div>
  <div class="stringEdit lastname" *ngIf="userHasEditAccess">
    <div class="title">{{ 'profile.lastname' | translate }}:</div> 
    <input type="text" [(ngModel)]="user.lastname" 
                      (input)="onChangeSave()"
                      [ngClass]="{'required2': !user.lastname}"/> 
  </div>
  <div class="stringEdit date_of_birth" *ngIf="(page === 'assignedchild') && userHasEditAccess">
    <div class="title">{{'profile.date_of_birth' | translate}}:</div> 
    <app-datepicker (onChange)="getDateFrom($event)" 
                    [assessmentDate]="user.birthdate"></app-datepicker>
  </div>
  <div class="stringEdit _email" *ngIf="userHasEditAccess && page === 'userprofile'">
    <div class="title">{{ 'profile.email' | translate }}:</div> 
    <input type="text" 
           [(ngModel)]="user.email" (input)="onChangeEmail()" 
           [ngClass]="{'required': errorEmail, 'required2': !user.email}"/> 
    <div class="verified" *ngIf="profile.verified && user.email == lastSavedEmail; else elseBlock">
      {{ 'verify.verified' | translate }}  
    </div>

    <ng-template #elseBlock>
      <div class="button" (click)="emailVerification()"  [ngClass]="{'disableBtn': errorEmail || dataSaved}">
        {{ 'verify.verify_email' | translate }} 
      </div>
    </ng-template>

  </div>
  <div class="stringEdit lang" *ngIf="page === 'userprofile'">
    <div class="title">{{ 'profile.language' | translate }}:</div> 
    <select [(ngModel)]="user.language" (change)="onChangeLanguage()">
      <option [selected]="user.language == 'en' || !user.language" value="en">{{'profile.english' | translate}}</option>
      <option [selected]="user.language == 'es'" value="es">{{'profile.spanish' | translate}}</option>
    </select>
  </div>
  <div class="stringEdit" *ngIf="errorEmail">
    <div class="title"></div>
    <div class="error">{{errorEmail}}</div>
  </div>
</div>


<div *ngIf="verify_now_popup.state" class="_bunner">
  <div class="popup">
      <div class="title">{{ 'verify.please_enter_code' | translate }} {{ user.email }} </div>

      <input class="input" maxlength="4" type="text" [(ngModel)]="code" (ngModelChange)="validateCode(code)">

      <div class="error" *ngIf="verify_now_popup.error_state">
        {{ 'messages.incorrect_code' | translate }}
      </div>

      <div class="buttons">
        <div class="bunner-button" (click)="close()">{{ 'verify.close' | translate }}</div>
        <div class="bunner-button" [ngClass]="{'disableBtn': !validCode }" (click)="confirm()">{{ 'verify.confirm' | translate }}</div>
      </div>
  </div>
</div>