import {Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { AppState } from 'src/app/services';

@Component({
    selector: 'app-edituserm',
    templateUrl: './edituser.component.html',
    styleUrls: ['./edituser.component.scss'],
  })

export class EditUserManagementComponent implements OnInit {

    // unknownPage is value what to show us where we are
    // we dont go to link in menu
    // this is to show users where we are
    public unknownPage: string = 'admin_shared.edit_user';
    // button 'Save' is disable 
    // if not all fields are filled
    // if true - disables
    public isFlagSave: boolean = true;
    public user: {username: string, password?: string, lastname: string, firstname: string, email: string, birthdate?: any };

    public param: string; 
    public idschool: string;
    public school: any;

    public loading: boolean = true;

    constructor(private adminService: AdminService,
        private route: ActivatedRoute,
        private _location: Location,
        private appState: AppState,
        private router: Router) {
       // this.user = {username: null, password: null, lastname: null, firstname: null, email: null, birthdate: null };
    }

    public ngOnInit(): void {

        this.param = this.route.snapshot.params['mode'];

        let username = this.route.snapshot.params['user'];

        this.idschool = this.route.snapshot.params['idschool'];

        this.school = this.appState.restore('school');

        if ( this.param === "assignedchild") {

            this.adminService.getChildren(this.idschool).then((children: ITeacher[]) => {

                let _user = children.find((x) => x.username === username);
                // copy without link
                // because user can change something but 
                // not save
                this.user = Object.assign({}, _user);
   
            }).catch((error) => {
                console.log('Error get children by school: ', error);
            });   
        } else {

            this.adminService.getTeachers(this.idschool).then((teachers: ITeacher[]) => {

                let _user = teachers.find((x) => x.username === username);
                // copy without link
                // because user can change something but 
                // not save
                this.user = Object.assign({}, _user);
    
            }).catch((error) => {
            console.log('Error get teacher by school: ', error);
            });
        }
    }

    public onChangeUser(user: any) {
        this.user = user;
    }
    public onFlagSave(flag) {
        this.isFlagSave = flag;
    }

    public onBack() {
        this._location.back();
    }

    public Save(user) {

        this.loading = false;

        if (this.param === "assignedteacher") {

            this.adminService.editTeacher(user, this.idschool).then((response) => {

                this.adminService.resetTeachersCache();
                this.adminService.resetTeacherByClassCache();

                this.loading = true;
                this.onBack();

            }).catch((error) => {
              console.log('Error add teacher: ', error);
            });
    
        } else {
            
            this.adminService.editChild(user, this.idschool).then((response) => {

                this.adminService.resetChildrenCache();
                this.adminService.resetChildrenByClassCache();
                
                this.loading = true;
                this.onBack();

            }).catch((error) => {
              console.log('Error add child: ', error);
            });
        }
    }
}