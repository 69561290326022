import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SwipeService {

    public onSwipeLeft: Subject<void> = new Subject();
    public onSwipeRight: Subject<void> = new Subject();

    public swipeLeft() {
        this.onSwipeLeft.next();
    }

    public swipeRight() {
        this.onSwipeRight.next();
    }
}