<div class="wrapper">
    <div class="mb-3">
        <app-menu [submenu]='true' [mode]="" [details]="''" [school]="school"></app-menu> 
    </div>
    
    <div class="container-lg">
        <div class="table">
            <div class="header">
                <p>{{ subTile.titleKey | translate }}</p>
            </div>
            <div class="row mt-3">
                <div class="col-sm-6 col-md-4 col-lg-3" *ngFor='let mode of subTile.resources'>
                  <div class="card mb-3" (click)="onModeChoose(mode.key)">
                    <img class="card-img" src="/assets/img/tg-sub-tile-images/{{ageGroupFolder}}/{{subTile.folder}}/{{mode.src}}" alt="{{mode.title}}">
                    <div class="card-title-bottom bg-primary d-flex justify-content-center text-light p-2 font-weight-bold">
                      {{ mode.titleKey | translate }}
                    </div>
                  </div>
                </div>                              
            </div>             
        </div>
    </div>
</div>