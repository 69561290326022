import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TeacherService } from 'src/app/services/teacher.service';
import { ITableRow } from '../../lesson-plans.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-daily-lesson-table',
  templateUrl: './daily-lesson-table.component.html',
  styleUrls: ['./daily-lesson-table.component.scss', '../../../../teacher/teacher.component.scss'],
})
export class DailyLessonTableComponent implements OnInit, OnChanges {
 
  
  public ageTitle: string;
  public language: string;

   /**
   * Input `age parameter` from filters.
   */
    @Input()
    public age: string = null;
  
    /**
     * Input `week parameter` from filters.
     */
    @Input()
    public week: string = null;


    @Input()
  public tableData: Array<ITableRow> = [
    {
      id: 'st',
      sortBy: 1,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'll',
      sortBy: 2,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'pd',
      sortBy: 3,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'mm',
      sortBy: 4,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },

    {
      id: 'ir',
      sortBy: 5,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'cg',
      sortBy: 6,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    }
  ];

  @Output()
  public onSelect: EventEmitter<{ code: string; day: number }> = new EventEmitter();
  constructor(private teacherService: TeacherService, private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.getAgeTitle(this.age) 
    this.language = this.translateService.currentLang;
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  public vmOnSelected(code: string, day: number): void {
    day = day + 1;
    this.onSelect.emit({ code, day });
    window.scrollTo(0, 0);
  }

  public getAgeTitle(ageKey: string) {

    const age = this.teacherService.getAges().find((a) => a.key == ageKey);
    this.ageTitle = age.titleKey;
  }
}
