import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppState, TeacherService, ContentService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'teacher-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsWeeklyComponent implements OnInit {

  public title: string = this.translateService.instant('materials.title');
  public school: any;

  public template: any = null;
  public age: string = null;
  public week: string = null;
  public day: string = null;
  public period: string = null;
  public missing: boolean = false;
  public loader: boolean = false;
  public noDaily: boolean = false;

  public materials: any;
  public templates: any;

  constructor(private appState: AppState,
              private teacherService: TeacherService,
              private activatedRoute: ActivatedRoute,
              private contentService: ContentService,                
              private translateService: TranslateService) {

    this.age = this.appState.restore('age');
    this.week = this.appState.restore('week');
    this.day = this.appState.restore('day');

    if (!this.age) {
      this.appState.store('age', 'in', true);
      this.age = this.appState.get('age');
    }

    if (!this.week) {
        this.appState.store('week', 1, true);
        this.week = this.appState.get('week');
    }
    if (!this.day) {
      this.appState.store('day', 1, true);
      this.day = this.appState.get('day');
    }
  }

  ngOnInit(): void {
    
    this.school = this.appState.restore('school');
  
    this.period = this.activatedRoute.snapshot.params['datas'];
    this.title = this.period === 'week' ? 'materials.weekly_materials' : 'materials.daily_materials';
   
    if (this.period === 'week') {
     this.load(this.age, this.week, null);
    } else {
     this.loadDaily(this.age, this.week, this.day);
    }
    
  }

  public weekChanged(week) {

    this.week = week;
    if (this.period === 'week') {
      this.load(this.age, this.week, null);
    } else {
      this.loadDaily(this.age, this.week, this.day);
    }
  }
  public dayChanged(day) {

    this.day = day;
    this.loadDaily(this.age, this.week, this.day);
  }
  public _ageChanged(age) {
        
    this.age = age;
    
    if (this.period === 'week') {
      this.load(this.age, this.week, null);     
    } else {
      this.loadDaily(this.age, this.week, this.day);
    }
  } 

  public getAgeTitle(ageKey: string): string {

    const age = this.teacherService.getAges().find((a) => a.key == ageKey);
    return age.title;
  }

  private load(age, week, day) {

    const language = this.translateService.currentLang;

    this.loader = true;
    this.contentService
        .getMaterials(language, age, week, day)
        .catch((err) => {
          if (language !== 'en') {
              return this.contentService.getMaterials('en', age, week, day)
          } else { 
              throw err;
          }
        })
        .then((data) => {

            this.missing = false;
            this.template = data.template;  
 
            this.loader = false;            
        })
        .catch((err) => {

            this.missing = true;
            this.template = null;
            this.loader = false;            
        })
  }

  private loadDaily(age, week, day) {
    
    if (age === 'in' || age === 't1') { this.noDaily = true; this.template = null; return; }

    this.noDaily = false;
    this.loader = true;
    let activities = this.teacherService.getActivitiesInAge(this.age);

    let promise = [];

    activities.forEach((activity) => {
      const language = this.translateService.currentLang;
      promise.push(this.loadDailyActivity(language, age, week, day, activity));
    })
    
    Promise.all(promise).then((data) => {

      this.missing = false;
      let templates = data.map((x) => x.template);

      this.templates =  templates.map((x) => {
        return x.elements;
      }).map((x, i) => {
        return x.find((y) => y.name == "materials");
      }).map((x, i) => {
        return {
          elements: x.elements,
          name: x.name,
          type: x.type,
          attributes: { id: activities[i]}      
        }
      });
      this.template = {
        name: "materials",
        type: "element",
        elements: this.templates
      }

      this.loader = false;

    }).catch((err) => {
            
            this.missing = true;
            this.template = null;
            this.loader = false;
    });  
  }  

  private loadDailyActivity(language, age, week, day, activity) {
    return this.contentService.getDailyActivities(language, age, week, day, activity)
        .catch((err) => {
            if (language !== 'en') {
                return this.contentService.getDailyActivities('en', age, week, day, activity);
            } else {
                throw err;
            }
        });
  }

  public print(){
    window.print();
  }
}
