<div class="wrapper">
  <div class="mb-3">
    <app-menu [submenu]='true' [mode]="" [details]="''" [school]="school"></app-menu>
  </div>

  <div class="container-lg">
    <div *ngIf="age == 'in' || age == 't1' || age == 't2'" class="table">
      <div class="header">
        <p>
          <span *ngIf="sectionTitle != ''">{{ sectionTitle | translate}}</span>
          <span *ngIf="sectionTitle != '' && modeTitle != ''"> | </span>
          <span *ngIf="modeTitle != ''">{{ modeTitle  | translate }}</span>
        </p>
      </div>

      <!--Characteristics of Preschoolers-->
      <ng-container *ngIf="contentKey == 'ch'">
        <div class="body">
          <p>
            Infants and Toddlers are born ready to learn. Through interactions, relationships, and active exploration of
            the world around them, infants and toddlers begin to build their understanding of how to move, make things
            happen, communicate, and interact with the people around them. The relationships that infants and toddlers
            have with their caregivers are critical in the child’s learning and development. Caregivers play a crucial
            role in a child’s development. By providing secure, trusting, and predictable relationships with infants and
            toddlers you are providing them with the confidence to explore their environments and build a strong
            foundation for learning and development. Caring for infants and toddlers in groups can be challenging if
            caregivers are not aware of the characteristics of infants and toddlers. For example, in an infant room a
            caregiver may be responsible for 4 children that are in very different developmental stages such as a
            newborn, a 6-month-old, or a 9-month-old.
          </p>

          <div class="content mix">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p>
            Infants and young toddlers, and older toddlers each have distinct characteristics, and development is rapid.
            Here are some basic characteristics by age groups:
          </p>

          <ul class="list">
            <p class="list-title"> Young Infants: Birth – 6 months</p>

            <li>Sleeps often and shows alertness during waking.</li>
            <li>Cry to get needs met.</li>
            <li>Startles easily</li>
            <li>Are learning about their bodies</li>
            <li>Can move hands, feet, legs, arms, head.</li>
            <li>Likes to be held.</li>
            <li>Begin to self-soothe by sucking on fingers or hands</li>
            <li>Kick at mobiles</li>
            <li>Smiles when smiled at</li>
            <li>Begin to show emotions</li>
            <li>Turns head to see something</li>
            <li>Begins to hold head up</li>
            <li>Rolling over at roughly 4 to 6 months,</li>
            <li>Sitting up unassisted by 6 months old.</li>
            <li>Smile, babble and laugh or squeal with emotion.</li>
            <li>Begins to explore food with fingers</li>
            <li>Manipulates objects</li>
            <li>Repeats actions to make something happen again.</li>

            <p class="list-title">Older Infants: 6 to 12 months</p>

            <li>Begins to move independently, crawls or scoots.</li>
            <li>Eats solid foods.</li>
            <li>Holds own bottle.</li>
            <li>Shows stranger anxiety.</li>
            <li>Lifts arms up to be picked up.</li>
            <li>Explores interesting toys and may have favorite toys or objects.</li>
            <li>Babbles and tries to imitate adult language.</li>
            <li>May say simple words, such as "mama," and understand a limited vocabulary of basics, such as "no."</li>
            <li>Hand skills to use a pincer grasp, pick up and put down small objects and make attempts to scribble with
              a crayon or other writing tool.</li>
            <li>Recognizes familiar people and objects in his/her environment.</li>
            <li>By 10 to 12 months may creep, crawl and may support weight on legs.</li>
            <li>Learn object permanence (objects still around even when out of sight)</li>
            <li>Shows interest in rhymes, finger plays, and stories with props</li>

            <p class="list-title">Young Toddlers: 12 to 24 months</p>

            <li>Picks up and carries objects</li>
            <li>Walks, Throws, Climbs, runs, jumps</li>
            <li>Mouths objects</li>
            <li>Points to and names items</li>
            <li>Jumps, hops, skips, runs</li>
            <li>Uses two or more words in sentences</li>
            <li>Assembles simple puzzles</li>
            <li>Builds with blocks</li>
            <li>Scribbles, draws</li>
            <li>Follows simple directions</li>
            <li>Aware of emotions</li>
            <li>Easily frustrated</li>
            <li>Capable of self-help skills</li>

            <p class="list-title">Older Toddlers: 24-36 months</p>

            <li>Walk, run, and start learning to jump with both feet</li>
            <li>Pull or carry toys while walking</li>
            <li>Throw and kick a ball; try to catch with both hands</li>
            <li>Stand on tiptoes and balance on one foot</li>
            <li>Climb on furniture and playground equipment</li>
            <li>Walk upstairs while holding the railing; may alternate feet</li>
            <li>Start brushing own teeth and hair</li>
            <li>May pull pants up and down</li>
            <li>Turn on the faucet and wash hands</li>
            <li>Build a block tower of at least four blocks</li>
            <li>Start practicing snaps and zipping up (if you start the zip)</li>
            <li>Hold utensils and crayons with fingers instead of a fist, although at this age the grasp still may not
              be quite right</li>
            <li>Enjoy more complicated pretend play, like pretending that a box is a spaceship or assigning people
              characters when playing</li>
            <li>Remember and talk about things that happened in the past, using phrases like “the other day” or “a long
              time ago”</li>
            <li>Do three- to four-piece puzzles</li>
            <li>Group toys by type, size, or color</li>
            <li>Recite favorite books and nursery rhymes with you</li>
            <li>May follow two-step directions, such as “take off your coat and hang it up”</li>
            <li>Understand the words for familiar people, everyday objects, and body parts</li>
            <li>Use a variety of single words by 18 months and speak in sentences of two to four words by 24 months (may
              combine nouns and verbs, like “mommy eat”); have a vocabulary of 200+ words by 36 months</li>
            <li>Repeat words they hear</li>
            <li>Start asking “what’s that?” and “why?”</li>
            <li>Begin using plurals (dogs) and basic pronouns (me, you)</li>
            <li>Mimic what other kids and adults do and say, as well as how they say it</li>
            <li>Be happy to play near, if not with, other kids</li>
            <li>Start to realize they can do things without your help</li>
            <li>Disobey more than before, doing things they are told not to do, just to test what happens</li>
            <li>Have tantrums when frustrated</li>
            <li>Show increasing separation anxiety by 18 months, which typically eases a lot by 24 months.</li>
            <li>become increasingly independent and aware of themselves as their own person between 24 and 36 months
            </li>

          </ul>
        </div>
      </ng-container>

      <!--21st Century Learners-->
      <ng-container *ngIf="contentKey == 'cl'">
        <div class="body">

          <p class="epigraph">“You can’t teach about things that don’t exist yet, but you can teach someone to learn how
            to learn.“ <br>- Leo SaLemi</p>

          <p>
            Did you know that some experts identify that 85% of the jobs that will exist in 2030 have not been invented
            yet? What does that mean for us as early childhood educators? We need to think of the young children we work
            with as global learners who will need some more universal tools we know, such as collaboration, critical
            thinking, communication, and creativity, as well as those they will have to develop as they journey further
            in their life. These universal tools are sometimes called the Four C’s of 21st Century Learning. While
            changes will be rapid over time, these more universal tools are constant and can help children handle things
            that may come their way.
          </p>

          <p>
            The children we work with today are children of the world, an interconnected world that we as adults did not
            experience at an early age. While concrete experiences with real three-dimensional objects, actual in-person
            relationships, and authentic problems to solve are of the most critical importance, children of today have
            access to tools and technology that we didn’t have when even sending people to the moon. Technology itself
            is neither good nor bad, but how we use these tools as tools to progress the 4 C’s could not be more
            important.
          </p>

          <p>
            Teaching good problem-solving, being a critical thinking, will be important in solving new problems that our
            generations could not even dream of. People will be called to work together, collaboratively, to design
            greener spaces, determine how to best govern ourselves, and that takes a great deal of communication. It
            takes time and practice to understand verbal and non-verbal communication - and multiple languages, across
            cultures. But what excitement is there to be found in this new frontier.
          </p>

          <p>
            The Learning Beyond Paper (LBP) Curriculum focuses on HOW to learn as well as teaching content based on
            developing all areas of developing in themes. Think of it as weaving threads within a fabric that can be
            shaped into a shirt, a hat, or a fancy dress - it can become anything because of the fabric (universal
            tools).
          </p>

          <div class="content mix">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!--Introduction-->
      <ng-container *ngIf="contentKey == 'in'">
        <div class="body">
          <p>
            Learning Beyond Paper is a social-constructivist curriculum. Young Children are inherently social
            beings. As such, learning is most robust when children engage with peers and adults around
            activities where they are actively engaged. Children construct knowledge bridged upon what
            they already know, but it is that social, relationship-based connection that acts like glue -
            cementing understanding and seeking more.
          </p>

          <div class="content mix">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!--Role of the Teacher-->
      <ng-container *ngIf="contentKey == 'ro'">
        <div class="body">
          <ul class="list">
            <li>Recognize each child is an individual with different life experiences</li>
            <li>Understand that families are the child’s first teacher</li>
            <li>Establish relationships to engage the family in the child’s learning journey</li>
            <li>Observe and use informal and formal assessment tools to guide the development of
              learning opportunities to scaffold each child’s learning path</li>
            <li>Consider every minute of every day as a learning opportunity</li>
            <li>Organize, plan, and use reflection as a part of daily practice</li>
            <li>Remain curious and motivated as a teacher to continue Professional Development by
              attending conferences and workshops and sharing with colleagues (including online groups and forums)</li>
            <li>Recognize that research guides practice and acknowledge that the “this is the way it has
              always been done” mentality is not a reason to do something</li>
            <li>Ensure that each choice made demonstrates knowledge of child development and developmentally appropriate
              practice</li>
          </ul>

          <div class="content mix">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Teacher Interactions / Inclusion-->
      <ng-container *ngIf="contentKey == 'inclusion'">
        <div class="body">

          <p>
            Inclusion involves more than merely having children with disabilities in your classroom. The practice
            consists of providing a nurturing environment for them to make meaningful connections with typically
            developing peers as they play, learn, and grow. The benefits of inclusion for children with disabilities
            have been well documented and include increased outcomes in language, communication, and social skills.
            Typically developing peers are provided with daily opportunities to practice empathy, compassion, and
            friendship skills. While some children with disabilities may require significant accommodations to a
            particular lesson or even the classroom environment, many will benefit from simple strategies and supports.
            Making the accommodations necessary to maximize each child’s potential for a successful and enjoyable
            classroom experience is what inclusion is all about. Children of all ability levels learn best in
            developmentally appropriate environments that offer the support necessary to interact with their peers in
            meaningful ways. You may find that many of the children in your classroom will enjoy and benefit from the
            strategies you use to support a child with a disability.
          </p>

          <p>
            When thinking about an infant or toddler with a disability, there are some additional things to consider.
            You may have an infant or toddler with an identified special need, or you may notice a concern as you
            observe, plan, and reflect on the child’s development.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <ul class="list">
            <p class="list-title">Tips for Successfully Including Children with Special Needs</p>
            <li>Use positive, “People First” language. For instance, if it is necessary to refer to a toddler’s
              disability, say, “toddler with a visual impairment” rather than “visually impaired toddler” or “blind
              toddler.” If it is not necessary to address their disability, simply use the child’s name.</li>
            <li>Communicate frequently with families (e.g., daily texts, emails, photographs with short narratives, or a
              “school-home notebook”).</li>
            <li>Ask permission to exchange information with therapists (e.g., speech and language, occupational,
              physical) and identify helpful strategies.</li>
            <li>Learn about specific disabilities through families, therapists, and other reputable sources (see
              resources).</li>
            <li>Use specialized equipment and assistive technology when appropriate.</li>
            <li>Intentionally select materials and equipment to match children’s varying interests and ability levels.
            </li>
            <li>Help a child accomplish a complicated task by breaking it into smaller parts and providing pictures of
              each step.</li>
            <li>Take every opportunity to maximize a child’s chance of success.</li>
            <li>Provide books, posters, and materials that portray individuals with disabilities as strong and capable.
            </li>
            <li>Use verbal, visual, and physical cues to provide clear directions and expectations.</li>
            <li>Enhance oral language with pictures, symbols, props, and American Sign Language.</li>
            <li>Employ “First-Then” statements when modeling language for an infant or toddler, to help with
              understanding the order and expectations as the day proceeds (“First we will put the blocks away. Then we
              are going to wash our hands for lunch.”).</li>
            <li>Create a picture schedule that reflects daily activities and refer to it throughout the day. Be sure it
              is posted at infant and toddler level, which may even be adhered with contact-paper on the floor. </li>
            <li>Assist infants and toddlers with transitions by preparing them ahead of time and telling them what to
              expect. Have the next activity prepared to reduce transition time.</li>
            <li>Model for typically developing infants and toddlers how to initiate interactions with peers.</li>
            <li>Provide a small object (“fidget”) for a toddler to hold during group time.</li>
            <li>Plan for, and support, peer interactions.</li>
            <li>Provide play materials and activities designed for two or more children.</li>
            <li>Be creative and remember that learning should be fun!</li>
          </ul>
        </div>
      </ng-container>

      <!-- Teacher Interactions / Intentionality-->
      <ng-container *ngIf="contentKey == 'intentionality'">
        <div class="body">

          <p>
            Without a plan, it’s hard to know where to start or where you may end up. Teaching young children requires
            intentionality, a meaningful plan grounded in child development, and social-constructivism. Intentionality
            describes a mindset where you, as the teacher, create meaning - the why - behind all instructional
            decisions.
          </p>

          <p>
            Intentionality means that each activity, interaction, selected materials, or learning center have had
            deliberate thought put into it and matches what is most meaningful for young children’s learning and
            development. This could be in room arrangement, intentionally placing furniture to prevent an area for
            running, or a small group, planning carefully for three children’s learning needs in today’s group.
            Intentionality is also explaining the reasoning behind decisions to others - children, teachers, parents -
            to grow child development understanding and collaboration that benefits children.
          </p>

          <ul class="list">
            As an intentional teacher, you create a nurturing environment for young children by:
            <li>Communicating daily with children in positive and respectful tones</li>
            <li>Praising children and acknowledging their efforts using kind affirmative words</li>
            <li>Encouraging and motivating children to do more and to continue trying. </li>
            <li>Using words which inspire curiosity, creativity, and confidence</li>
            <li>Modeling practical conversational skills of listening and speaking and encouraging children to do the
              same</li>
            <li>Building positive relationships through immediate and appropriate responses to children's physical and
              emotional needs</li>
            <li>Encouraging children to express themselves freely and foster thinking through open-ended questions</li>
            <li>Demonstrating enthusiasm and respect by engaging with children —not just for behavior or academic
              interventions</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <ul class="list">
            Here are some characteristics of your role as an intentional teacher:
            <li>Recognize each child is an individual with different life experiences </li>
            <li>Understand that families are the child’s first teacher</li>
            <li>Establish relationships to engage the family in the child’s learning journey</li>
            <li>Observe and use informal and formal assessment tools to guide the development of learning opportunities
              to scaffold each child’s learning path </li>
            <li>Consider every minute of every day as a learning opportunity</li>
            <li>Organize, plan, and use reflection as a part of daily practice </li>
            <li>Remain curious and motivated as a teacher to continue Professional Development by attending conferences
              and workshops and sharing with colleagues (including online groups and forums)</li>
            <li>Recognize that research guides practice and acknowledge that the “this is the way it has always been
              done” mentality is not a reason to do something</li>
            <li>Ensure that each choice made demonstrates knowledge of child development and developmentally appropriate
              practice</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Teacher Interactions / Differentiated instruction-->
      <ng-container *ngIf="contentKey == 'instruction'">
        <div class="body">

          <p>
            Successful teachers recognize that all students come to the classroom with different strengths,
            interests, and experiences. Teachers must differentiate their instruction to address their student's
            unique learning styles and personalities. Tailoring lessons and activities to the diverse characteristics
            of each learner increases student engagement. You can create a supportive environment that maximizes
            learning opportunities for all children by adjusting activities, goals, groupings, and materials.
            Your preschool classroom may include three, four, or five-year-olds depending upon the time of year
            and if your program uses multi-age grouping. Even within 12 months (e.g., children who are three years,
            0 months through 3 years, 11 months, 29 days), there are considerable variances in development, experience,
            and skills. Differentiated Instruction is essential to truly meet children’s needs and help them continue
            growing.
            Differentiation does not mean teaching different things,
            but simply adjusting the level of complexity - based on the individual children.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p> <strong>How it Works:</strong> </p>

          <p>
            Consider the foundational skills needed for a child to understand a new concept.
            For example, before a child can create an AB pattern, he or she must understand the
            concept of classification and determine like and different objects. This skill allows
            the child to distinguish a pattern from a non- pattern. Next, the child must be able to
            copy a simple pattern using objects to duplicate a modeled example. Finally, they must
            demonstrate that they can extend or complete an established pattern by building on.
            Once the child is proficient at these tasks, they may begin to explore activities that
            encourage them to create a simple AB pattern. Because all children will not be ready to
            create AB patterns on the same day, it will be necessary to determine which skills will
            require further practice. Depending on where the child falls on this
            developmental progression, they may benefit from working on the skill in several ways:
          </p>

          <ul class="list">
            <li>Receiving individualized assistance from the teacher.</li>
            <li>Participating in small groups.</li>
            <li>Being paired with a peer whose skills are more advanced.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p>
            Once you have determined which children are ready to move on to creating AB patterns,
            encourage them to choose activities that will support this skill. Beginners will do
            best by creating patterns using color as the differentiating feature. Still, children
            with more experience may use shape, sound,
            or other distinguishing characteristics. A few examples of activities that address this skill are listed
            below:
          </p>

          <ul class="list">
            <li>Invite a child to choose two colors of paint and create an AB pattern on paper.</li>
            <li>Provide beads and string so that a child may create a necklace or bracelet using the pattern. </li>
            <li>Supply various colors of Unifix cubes and challenge a child to create a pattern with them.</li>
            <li>Encourage children to use unit blocks to create a horizontal or vertical pattern (e.g., rectangle,
              square).</li>
            <li>Challenge children to create a pattern using their bodies (e.g., stomp, clap or blink, nod).</li>
            <li>Invite a child to make a pattern using small cars and trucks.</li>
            <li>Ask children to create a pattern using markers and paper (e.g., red line, purple line, or circle,
              square).</li>
            <li>Supply Post-It Notes in two colors or sizes and encourage children to create patterns.</li>
            <li>Provide various instruments so that children may create patterns (e.g., one beat of the drum, one ring
              of the bell). </li>
            <li>Give children opportunities to create snack patterns (e.g., pretzel stick, goldfish cracker or apple
              slice, cheese cube). </li>
          </ul>

          <p>
            Children who have already mastered AB patterns may be encouraged to create ABC or AAB patterns.
            They might also choose to create their own pattern.
          </p>

          <ul class="list">
            <p class="list-title">Tips for Differentiating:</p>
            <li>Activate and assess children's prior knowledge.</li>
            <li>Employ ongoing-informal assessments to determine where students are and what their next step should be.
            </li>
            <li>Know your students and consider their personalities, interests, ability levels, and learning styles.
            </li>
            <li>Describe new concepts in multiple ways.</li>
            <li>Model and demonstrate the new concept.</li>
            <li>Support different learning styles (auditory, visual, tactile, and kinesthetic).</li>
            <li>Select materials that fit the student's interests and developmental needs.</li>
            <li>Develop clear learning goals using standards or curriculum.</li>
            <li>Recognize and provide different levels of support based on individual needs.</li>
            <li>Provide students with choices about how they will investigate a new concept or skill. </li>
            <li>Pair up a child with a learning buddy.</li>
            <li>Provide modeling and assistance, as necessary.</li>
            <li>Offer opportunities for hands-on, multi-sensory practice and exploration.</li>
            <li>Encourage students as they practice new concepts.</li>
            <li>Remember to keep it fun and positive! </li>
          </ul>

          <p class="epigraph">
            *Visit the Resources section for additional tips on Differentiating Instruction in the classroom.
          </p>
        </div>
      </ng-container>

      <!-- Teacher Interactions / Leading with Equity-->
      <ng-container *ngIf="contentKey == 'leading'">
        <div class="body">

          <p>
            Young children’s development in the first five years of life is extraordinary and unparalleled. It is during
            these years when the brain’s development helps set the tone for later learning and ultimately, success. We
            know that prevention, by teaching children appropriately and ensuring they meet developmental milestones and
            skill development, is much more productive and easier than intervention. Also, intervention earlier is more
            productive than as the child gets older.
          </p>

          <p>
            However, far too few children have access to high quality early childhood experiences, whether at home or
            school, and so far before children, children experience inequity. This can be improved best in programs that
            reflect on how to ensure that children and families have equitable access to attend. This may include
            scholarships or other supports and creative solutions. While some of these decisions are outside the realm
            of the classroom teacher’s responsibility, teachers have a voice to help shape program policy.
          </p>

          <p>
            Teachers also have a responsibility to continuously build a more equitable classroom. Like many other
            topics, it’s hard to ever know enough or do enough. It is a case of always working to improve conditions of
            equity, reflecting on our practice to see where we are and where we could go, and building relationships
            necessary to make equity a reality for ALL children. While this can seem incredibly daunting, every step
            toward creating equity, every action and strategy that benefits the children in your classroom, can make the
            life of an individual child that much better. That effect is invaluable. Visit the Resources section for
            additional topics on Equity.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Teacher Interactions / Interactions-->
      <ng-container *ngIf="contentKey == 'interactions'">
        <div class="body">

          <p>
            Interactions involve how a teacher communicates with and responds to the children in their classroom.
            Because learning is a social process, quality interactions are essential to the development of infants and
            toddlers across all domains. Meaningful interactions stretch cognitive abilities and teach infants and
            toddlers to get along well with others. Infants and toddlers spend a good portion of their day in early
            childhood classrooms, and the encounters they have there will shape their attitudes towards future
            educational experiences.
          </p>

          <p>
            Relationships with caring, responsive adults are at the very foundation of a young child's learning.
            Teachers must provide a consistent and inviting environment that is emotionally supportive of all children.
            Some suggestions for forming the type of connections with students that will foster meaningful interactions
            are listed below:
          </p>

          <ul class="list">
            <li>Get to know your student's backgrounds, temperaments, interests, and preferences.</li>
            <li>Respond to their needs and emotional cues consistently and with sensitivity.</li>
            <li>Validate children's feelings and match their affect.</li>
            <li>Create a joyful atmosphere, smile, and laugh often, make eye contact, and provide physical affection.
            </li>
            <li>Use a warm and welcoming tone of voice and maintain approachable body language.</li>
            <li>Encourage peer interaction and model social problem-solving strategies.</li>
            <li>Find ways to show children they are valuable members of your class, and you enjoy being their teacher.
            </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p>
            The connections you make with infants and toddlers are essential in providing meaningful interactions that
            will foster cognitive development. In the book, Powerful Interactions: How to Connect to Children to Extend
            their Learning (Dombro, Jablon, & Stetson, 2011), the authors discuss a three-step strategy for providing
            high-quality interactions that positively impact young children's growth and development. Teachers are
            encouraged to "be present, connect, and extend learning." Once you have determined a child's interests and
            established a meaningful connection, there are several ways to extend learning in infant and toddler
            classroom, such as:
          </p>

          <ul class="list">
            <li>Offering hands-on opportunities and encouraging exploration.</li>
            <li>Providing an array of engaging materials that address different interests and learning styles.</li>
            <li>Engaging in conversations with the children as they play.</li>
            <li>Looking for opportunities to stretch their thinking.</li>
            <li>Thoroughly answering their questions using language that they understand.</li>
            <li>Encouraging higher-order thinking by asking open-ended questions.</li>
            <li>Assisting children in linking new skills and knowledge to concepts with which they are already familiar.
            </li>
          </ul>

          <p>
            Consistently facilitating intentional, high-quality, and responsive interactions with infants and toddlers
            will lead to increased engagement, improved child outcomes, and a life-long appreciation for learning.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p><strong>Relating with Infants and Toddlers</strong></p>

          <p>Nurturing infants and toddlers socially and emotionally is of utmost importance and positively impacts
            Intellectual Development, Physical Development, Social Development, Behavioral Development, Emotional
            Development.</p>

          <p>Relationships lay the foundation for later developmental outcomes of Self-Confidence, Sound Mental Health,
            Motivation to Learn, Achievement, and Self-Control.</p>

          <ul class="list">
            When building relationships with and nurturing infants, consider these important points:
            <li>Interactions should inspire curiosity, creativity, and confidence. Always interact with infants and
              toddlers in positive and respectful tones.</li>
            <li>Praise their efforts using kind, affirmative words. Teachers should provide opportunities for infants to
              build on their accomplishments.</li>
            <li>Build positive relationships through your immediate and appropriate responses to infants’ physical and
              emotional needs.</li>
            <li>Demonstrate your enthusiasm and show respect as you engage with infants and toddlers.</li>
            <li>Interact with infants using sign language in multiple situations.</li>
            <li>Tone should be respectful at ALL times.</li>
          </ul>

          <div class="epigraph">
            *Visit the Resources section for additional information on teacher interactions
          </div>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_3l,'img')" src="{{  resources.resource_3l }}" alt="">
            <img (click)="openBunner($event, resources.resource_3m,'img')" src="{{  resources.resource_3m }}" alt="">
            <img (click)="openBunner($event, resources.resource_3r,'img')" src="{{  resources.resource_3r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Teacher Interactions / Scaffolding-->
      <ng-container *ngIf="contentKey == 'scaffolding'">
        <div class="body">

          <p>
            In construction, scaffolding is a temporary structure workers use to access heights and areas that are too
            challenging to reach. In education, scaffolding is the process of providing structured support and guidance
            for thinking and learning. You scaffold through language and social interactions. The goal of scaffolding is
            to offer children enough assistance to be more successful than they would be without it. You offer children
            ways to achieve a task through subtle interventions and varying levels of support.
          </p>

          <p>
            A key concept in scaffolding is the Zone of Proximal Development or ZPD, the range of competencies that a
            child can perform with assistance but cannot yet perform independently. Teachers can make the most of a
            child’s ZPD by regularly providing opportunities that stretch the child's existing knowledge and skills and
            providing the scaffolding necessary for success. Your role is to observe the child, assess their needs, and
            provide the support needed to reach the next level. Eventually, you remove the support (scaffolding), and
            the child will be able to complete the task independently. Scaffolding success uses a variety of strategies:
          </p>


          <p><ins>Hints</ins> - Provide just enough information to enhance the child’s understanding without giving away
            the answer.</p>
          <p><ins>Suggestions</ins> - Offer multiple ways to complete a task. </p>
          <p><ins>Modeling</ins> - Demonstrate ways to solve the problem or complete the task.</p>
          <p><ins>Questioning</ins> - Ask open-ended questions such as: “What do you think will happen if…?” Or, “What
            is another way you might…?”</p>
          <p><ins>Providing Feedback</ins> - Offer just enough feedback to move the child to the next level.</p>
          <p><ins>Offering Encouragement</ins> - Inspire the child to continue trying by commenting on their
            persistence, problem-solving abilities, or creativity.</p>


          <p>
            Scaffolding can take place in various settings, using a variety of tools and approaches. A teacher may ask
            how to make the block structure taller, or a peer may explain how to hold a crayon sideways to make a leaf
            rubbing. Scaffolding takes on many forms but always leads to the child coming away from experiences with
            more information than when they started.
          </p>

          <p>
            By understanding what children can achieve independently and what they can achieve with assistance from an
            adult, educators can develop plans to teach skills in the most effective manner possible.
          </p>

          <p> <strong>Open-Ended Questions:</strong> </p>

          <p>
            One way to scaffold a child’s learning is to ask open-ended questions. These inquiries encourage reasoning
            and creative thinking while helping the child see that there are often many solutions to a problem.
            Open-ended questions have no one right answer, require more than a one or two-word response, and encourage
            reflection, creativity, and prior knowledge. They also promote the use of advanced language. Asking children
            open-ended questions starts a conversation and supports brain development. As you implement the lessons
            provided in this curriculum, be sure to make good use of scaffolding and open-ended questioning. For more
            information about scaffolding, visit the Resources section.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!--Teacher Interactions /  Curriculum -->
      <ng-container *ngIf="contentKey == 'curriculum'">
        <div class="body">

          <p>
            Infants are born ready to learn. Through interactions, relationships, and active exploration of the world
            around them, infants and toddlers begin to build their understanding of how to move, make things happen,
            communicate, and interact with the people around them. The relationships that infants and toddlers have with
            their caregivers are critical in the child’s learning and development.
          </p>

          <p>
            Caregivers play a crucial role in the child’s development. By providing a secure, trusting, and predictable
            relationships with infants and toddlers, you are providing them with the confidence to explore their
            environments and build a strong foundation for learning and development. You as the teacher must learn and
            practice the relationship-building, brain-building strategy of serve and return. This is the basis of
            responsive caregiving and children’s learning and development in your classroom.
          </p>

          <p>
            Caring for infants and toddlers in groups can be challenging if caregivers are not aware of the
            characteristics of infants and toddlers. For example, in an infant room a caregiver may be responsible for 4
            children that are in very different developmental stages such as a newborn, a 6-month-old or a 9-month-old.
          </p>

          <ul class="list">
            Relationships lay the foundation for later developmental outcomes of:
            <li>Self Confidence</li>
            <li>Sound Mental Health</li>
            <li>Motivation to Learn</li>
            <li>Achievement</li>
            <li>Self-Control</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p>
            The caregiver’s role is to provide a safe, nurturing environment where routines are based on children’s
            individual needs and include transitions to and from home, diapering, feeding, naps for infants and
            toileting, meals, and naps for toddlers.
          </p>

          <p>
            Routines provide the opportunities for caregivers to observe and respond to each child’s interest and needs
            for much of the child’s learning. Learning Beyond Paper supports facilitation of infant/toddler
            individualized exploration and interactions which lays the foundation for responding to the child’s
            interests and needs.
          </p>

          <p>
            The environment needs to consist of materials and equipment to support the infant/toddler needs for
            consistent routines and exploration. The curriculum provides experiences to enhance growth and learning
            during routines, exploration, and interactions. The experiences are a guide however it is important to
            observe the child’s response and follow the child’s interests and needs.
          </p>

          <ul class="list">
            Important Curriculum Experience Tips for Infant and Toddler Teachers:
            <li>Activate and assess each infant and toddler's prior knowledge.</li>
            <li>Employ ongoing-informal assessments to determine where each infant or toddler is, and what their next
              step should be.</li>
            <li>Know each infant and toddler. Consider their personalities, interests, ability levels, and learning
              styles.</li>
            <li>Describe new concepts in multiple ways as you model and demonstrate the new concept.</li>
            <li>Support different learning styles (auditory, visual, tactile, and kinesthetic).</li>
            <li>Select materials that fit each child’s interests and developmental needs.</li>
            <li>Develop clear learning goals using standards or curriculum.</li>
            <li>Recognize and provide different levels of support based on individual needs.</li>
            <li>Provide infants and toddlers with choices about how they will investigate something new. </li>
            <li>Model, with actions and words, and provide assistance as necessary.</li>
            <li>Offer many, many, many opportunities for hands-on, multi-sensory practice and exploration.</li>
            <li>Encourage with your tone and non-verbal communication.</li>
            <li>Remember to keep it fun and positive!</li>

          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Teacher Interactions /  Serve and Return -->
      <ng-container *ngIf="contentKey == 'serve&return'">
        <div class="body">

          <p>
            There is likely no more important strategy for teachers and parents of infants and toddlers to learn,
            practice, and use consistently. Serve and return interactions build infant and toddler brains within an
            environment of safe, responsive caregiving.
          </p>

          <p>
            Serve and return interactions can be described initially by thinking of playing tennis. One player serves,
            and the other player returns the ball. A good game of tennis will have several back and forth plays after
            the serve. The same is true for the back-and-forth interactions between an adult caregiver and a baby or
            toddler. Over time, the number of interactions and the depth of interactions increases. Serve and Return is
            a means of scaffolding interactions learning with very young children.
          </p>

          <ol class="list withNumbers">
            There are five primary elements to Serve and Return:
            <li> Notice the serve and share the child’s focus of attention.</li>
            <li> Return the serve by supporting and encouraging.</li>
            <li> Give it a name.</li>
            <li> Take turns...and wait. Keep the interaction going back and forth.</li>
            <li> Practice endings and beginnings.</li>
          </ol>

          <p>
            More information, including video examples, about what Serve and Return is, as well as how to Serve and
            Return can be found in the Resources section.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Classroom Environment / Introduction -->
      <ng-container *ngIf="contentKey == 'introduction'">
        <div class="body">
          <p>
            The classroom’s physical environment, both indoor and outdoor, set the stage for learning. The physical
            environment sends strong messages to children and adults about what is valued, who is valued, and what
            learning can occur within the spaces designed. As the teacher, your mindset is visible in the environment.
            The type of activities and learning that go on are reflective of your mindset. The emotional environment
            created by the teacher is also important. You should look to see that the values between the physical and
            emotional environment show consistency.
          </p>

          <p>
            The majority of a child’s day is spent in the physical environment you design.
            Design your space in a way that offers children the opportunity to feel comfortable owning the space.
            It is their space as much as yours.
          </p>

          <p>
            No matter how your space currently looks, you must continuously reflect on the
            space and use of space to make updates and changes that provide improvement.
          </p>

          <p>
            When you are in the mindset of a reflective practitioner, nothing is ever indeed “done” or complete.
            While things should run smoothly, there are always opportunities to improve and enhance your program,
            thoughts, and experiences for children for this year’s particular group of children.
          </p>

          <p>
            The terms environment and space mean all indoor AND outdoor spaces children use. Sometimes, people consider
            only indoor space;
            however, outdoor spaces often have more space and opportunities to create than what may be available
            indoors.
            In most parts of the country, outdoor spaces can host a full range of activities, not just gross motor play,
            throughout the school year.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Designing the Indoor and Outdoor Environment -->
      <ng-container *ngIf="contentKey == 'indoor&outdoor'">
        <div class="body">
          <p>
            Here are a few questions to think about as you design your environment, both indoor and outdoor:
          </p>

          <p>
            <strong>Environmental Messages to Children:</strong>
          </p>

          <p>
            What messages do I want to send to children about this space? Does a child say/think/feel this in your
            physical environment?
          </p>

          <ul class="list">
            <li>This space is mine. It is designed just for me as a preschooler.</li>
            <li>My teacher trusts me to use these materials and put them in my reach so I can have access.</li>
            <li>I know where things are, I can access them, and it makes sense to me.</li>
            <li>I am becoming more independent and want to do things for myself. My classroom has everything organized
              so I can use what I need and help take care of the classroom when it is time to clean up. </li>
            <li>I am learning a lot of new things as I become more independent, and my teacher trusts in me.</li>
            <li>I am a competent and capable learner, and my teacher gives experiences to show and grow my learning.
            </li>
            <li>My teacher listens to my classmates and me. Both our spoken words and non-verbal cues send messages
              about how we use the physical environment.</li>
            <li>I feel at home here. This is MY classroom.</li>
          </ul>

          <p>
            <strong>Environmental Messages to Families:</strong>
          </p>

          <p>
            What messages do I want to send to families about this space? How might an adult family member respond to
            the environment?
          </p>

          <ul class="list">
            <li>Wow, my child is trusted here.</li>
            <li>Everything is at my child’s level so that they can be a part of the action.</li>
            <li>The organization tells me a lot of thought went into this space.</li>
            <li>There seems to be just the right amount out. It is not overwhelming and feels intentional and meaningful
              in this space. My child can learn and grow, feeling safe here.</li>
            <li>My child, and all children, are represented here (e.g., photographs, books, posters, etc.).</li>
            <li>This space tells me that teachers care about my child.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
          <p>
            <strong>Discovering Current Messages About Physical Environment: </strong>
          </p>

          <p>
            What messages are currently sent by the physical environment?
          </p>

          <ul class="list">
            <p class="list-title">Entry Space</p>
            <li>Does the entry space welcome families? Include images of families, comfortable adult-size furnishings,
              other pictures, and messages that say, “both you and your child are
              welcome here.”</li>
            <li>Are messages for families framed and phrased in a positive, inclusive way? (e.g., We love our ECDC
              families. Your family is important to us)</li>
            <li>Even if a program’s make-up is not diverse (e.g., race, ethnicity, language, ability), are visuals
              diverse? Would a potential new diverse family come to visit and feel represented?</li>
            <li>If using bulletin boards, are they current and inclusive of child-created elements? Bulletin boards
              should be free of faded, torn, or outdated material.</li>
            <li>Is there a current lesson plan posted and dated where families can easily view it? It is fine to post
              modifications for learning activities in addition to the lesson plan. Still,
              individual modifications and accommodations must not be displayed, due to confidentiality and legal
              reasons, including HIPAA and FERPA.</li>
          </ul>

          <ul class="list">
            <p class="list-title">Indoor Furniture and Ambience</p>
            <li>Is the furniture arranged so that there are not large spaces for running indoors?</li>
            <li>Are furniture pieces arranged so that there is line-of-sight supervision for teachers?</li>
            <li>Does the furniture arrangement, including area rugs, help define boundaries of various centers and send
              visual messages? </li>
            <li>Are there larger, open spaces for centers that need more space to work (e.g., construction/block) and
              smaller, cozy spaces that allow more personal space to rest and recharge? It can be overwhelming for some
              children to share space with so many others for so many hours a day.</li>
            <li>Does the classroom utilize daylight from windows, whenever possible?</li>
            <li>Are lamps used to create a homey feel, adjust light levels in the room, and to bring more soft light
              options than fluorescent lighting can? (pics of hanging lamps, touch lamps, area/bedside lamps in
              classrooms, twinkle lights) </li>
          </ul>

          <ul class="list">
            <p class="list-title">Outdoor Furniture & Ambience</p>
            <li>Does the space look like the community, using safe, native plants and topography to represent the area?
            </li>
            <li>Are steps taken to soften the hardscape by adding soft objects and soft surfaces (e.g., grass, mats, old
              comforter) for infants and young toddlers’ comfort?</li>
            <li>How have indoor centers been extended to provide opportunities to continue similar play outdoors (e.g.,
              seating area with books, infant/toddler-sized sensory table, mud kitchen, materials for building,
              wall/fence painting), and infant/toddler gross motor play?</li>
            <li>Are there spaces for individual or small numbers of toddlers to work and play that offer uninterrupted
              space as well as easy supervision?</li>
            <li>In what ways have I maximized the physical space provided to benefit children’s development and
              well-being throughout the day?</li>

          </ul>

          <ul class="list">
            <p class="list-title"> Materials </p>
            <li>Are materials intentional, meaning there is a purpose to including it in the environment? (e.g., not
              because it’s “cute” or theme-related)</li>
            <li>Are colors used and displayed in meaningful ways, and not overwhelming to the eye?</li>
            <li>Are natural materials and natural colors used?</li>
            <li>Is the space comfortable and homey, not institutional? Remember, children may spend 11 hours a day in
              this environment.</li>
            <li>Does the space represent the children and families expressly, and the community generally? Ask families
              to bring in family pictures, share family messages, and use pictures of the class’s children throughout
              the room. Including baby dolls, puzzles, artifacts, toy people sets, and other toys representing various
              races, ethnicities, and abilities in positive and affirming ways (e.g., not token or costumed) is
              essential, even if the group of children and families are not diverse.</li>
            <li>Does the space look like the community? This may include photos of local buildings and locations
              recognizable to children represented in a homemade book in the construction area, images of the local
              built environment superimposed on blocks to encourage play.</li>
            <li>Are well-loved materials included, so long that they are in good condition?</li>

          </ul>

          <ul class="list">
            <p class="list-title"> Quality and quantity of materials </p>
            <li>There are enough materials for a given center so that multiple children can use materials reasonably.
            </li>
            <li>Because toddlers have a difficult time sharing, identical materials are presented in sets equal to the
              number of children in the group to reduce frustration over materials (e.g., for a group of six, there are
              six puzzles available, six buckets and six shovels)</li>
            <li>Materials include authentic (real) objects whenever possible (e.g., actual kitchen tools and pots and
              pans in the mud kitchen). While child-size is important, so is authenticity.</li>
            <li>Variety, so that materials can be rotated to maintain interest.</li>
          </ul>

          <ul class="list">
            <p class="list-title"> Living things for children to care for</p>
            <li>
              <strong>Plants</strong>- There are many hearty plant varieties, including those that may not need much/any
              light.
              For plants at child-level, consider plants that are not poisonous if ingested.
              For plants at child-level, consider plants that are not poisonous if ingested. Plants can also be on top
              of high shelves or hung in baskets to bring nature indoors. Plant selection is also important outdoors and
              varies significantly based on your space, location (planting zone), and the sun/shade available. In many
              parts of the country, plants are an important part of creating outdoor classroom space.
              Follow your local licensing rules regarding plants (indoor and outdoor).<br>
              - Toddlers should be involved in caring for plants.<br>
              - Toddlers must learn how to handle plants so as not to damage them carefully.<br>
              - Include books about plant care as references in the classroom. <br>
            </li>
            <li>
              <strong>Animals</strong> - Choose classroom pets that you have researched, considering all of the animal’s
              needs.
              Some choices may include fish, hermit crab, rabbit, guinea pig.
              For pets that can be petted or handled, consider getting them while young and handling frequently.
              Follow your local licensing rules regarding pets in your classroom. (any other links - articles about
              classroom pets, anything from CDC?)<br>
              - Toddlers should be involved in caring for the pet(s).<br>
              - Toddlers must learn how to treat animals (e.g., don’t poke their bodies or faces) and how to read the
              animal’s cues (e.g., relaxed versus tense) to have positive experiences. <br>
              - Animals must always be handled with adult supervision, and children should wash their hands afterward.
              <br>
              - Include books about animal care (specific to the pet(s) you have) as references.<br>
            </li>
          </ul>

          <ul class="list">
            <p class="list-title">Accessible Materials</p>
            <li>Accessible materials are those which children have access to. Accessible materials are within child
              reach and at child level. They don’t need to ask to get them because they are accessible. Available
              materials are those that the teacher has available (e.g., books for new themes or arising interests,
              additional manipulatives, things to rotate) but they are not out for children to use at their level. Both
              have their place in an early childhood classroom.</li>
            <li>Are materials accessible for children to use (versus adults having to get and control access to
              materials)?</li>
            <li>Does the room arrangement send messages to children about where to use materials (e.g., A table adjacent
              to a shelf of manipulatives says this is where to bring the manipulatives, a soft mat for babies on open
              floor space with toys arranged to explore near)?</li>
            <li>Are materials for infants and toddlers accessible to them, with unsafe materials (e.g., cleaning
              supplies, choking hazards) inaccessible to children?</li>
            <li>Are extra consumable materials (e.g., chunky toddler crayons and markers, butcher block paper) readily
              available for me to restock and make accessible for specific activities?</li>
            <li>Are extra non-consumables (e.g., theme-related materials, additional books, props) organized and
              available, even if not in the classroom / outdoor space?</li>
          </ul>

          <ul class="list">
            <p class="list-title">Intentional Organization</p>
            <li>Can children see, reach, use, and return materials without assistance, promoting independence?</li>
            <li>Are materials organized in logical ways, based on the space/center? (add examples)</li>
            <li>Are all materials purposeful, used, and useful?</li>
            <li>Are unused or unneeded materials removed to prevent clutter and promote the intentional inclusion of
              materials?</li>
          </ul>

          <ul class="list">
            <p class="list-title">Promoting Independence</p>
            <li>Can the children in my group access the things they need to be successful independently?</li>
            <li>If I provide some support or experiences, how could children become more independent?</li>
          </ul>

          <ul class="list">
            <p class="list-title">Increasing child control while teachers are in charge</p>
            <li>Does my environment have me controlling what and how children access materials during most of the day,
              which does not promote independence?</li>
            <li>Have I set up the environment so that children know I am in charge, but they have control over many
              parts of the day and what/how they interact with the environment?</li>
            <li>Have I included children in setting up the environment?</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Classroom Environment / Health and Safety -->
      <ng-container *ngIf="contentKey == 'health&safety'">
        <div class="body">

          <p class="title">
            Health and Safety
          </p>

          <p>
            Teachers are required to follow Health and Safety Policies and Procedures, which address the
            health and safety of children, families, staff, and volunteers. Child Care programs develop
            these policies based on the local and state regulatory agencies that approve and monitor childcare programs.
            Additionally, if you program is accredited, there are health and safety requirements included there as well.
            Sometimes, nutrition is included with health and safety.
          </p>

          <p>
            The National Association for the Education of Young Children’s accreditation Standard 5 is Health.
            The Standard is: The program promotes the nutrition and health of children and protects children and staff
            from illness and injury. Children must be healthy and safe to learn and grow. Programs must be healthy and
            safe to support children’s healthy development. The NAEYC accreditation
            standards, including what to look for in an accredited program for each standard, can be found in the
            Resources section.
          </p>

          <p>
            In February 2020, the COVID-19 virus resulted in stricter requirements to help stop the spread of the virus,
            which changed the health and safety guidelines of Child Care program operations.
            The Center for Disease Control issued guidelines for Childcare providers which can be found in the Resources
            section.
          </p>

          <p class="title">
            Health and Safety Practices
          </p>

          <p>
            As the classroom teacher, you will consistently model health and safety practices that children
            will take part in to keep everyone in the classroom as healthy and safe as possible. Some
            policies and procedures may be school-wide or dictated by regulation (i.e., licensing or funding source),
            and others may be specific to your classroom.
          </p>

          <p>
            These include health practices and procedures for hand washing, toileting, toothbrushing,
            sneezing and coughing, nose-blowing, and what to do with objects that have been mouthed.
            You’ll want to make sure you know that safety practices and procedures are for things such
            as handling scissors, responding to an evacuation or shelter drill, moving safely in the
            classroom vs. outdoors, cleaning up a spill or mess, and keep ourselves and our classmates safe.
            You should expect that you will repeat expectations and model consistently over time. This repetition
            is typical with young children, so find songs or poems to help everyone remember, and approach it with
            a cheerful, non-judgmental attitude as you remind and re-share. There are many teachable moments for you
            to capitalize on when messes occur, or there are missteps. You will want to show as much patience and
            compassion as possible while teaching children about health and safety throughout the day.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Involving Children -->
      <ng-container *ngIf="contentKey == 'involving'">
        <div class="body">

          <p>Do children have a voice in how the physical environment develops during the year?</p>

          <ul class="list">
            <li>The physical environment continues to grow as the class grows. </li>
            <li>I keep adjusting things (adding, removing) as needed, but not in a way where the environment becomes
              unpredictable to children.</li>
            <li>I include children in conversations and the work of adjusting the physical environment because it is OUR
              classroom, not just mine. Even with infants and young toddlers who may not be able to share a lot of ideas
              using expressive words, I recognize them as people and include them as I talk about changes and moving
              things. Toddlers can help move things, reasonably for their age, which helps them know where things will
              be. </li>
          </ul>

          <p> <strong> Sharing Power With Children In Design of Physical Environment: </strong>

          <ul class="list">
            Am I willing to give up some control and share power with children in designing the physical environment
            during the year?
            <li>I am willing to have conversations with children about how the space is working / not working and get
              feedback from children.</li>
            <li>I am willing to have conversations with families about how the space is working / not working and get
              feedback from families.</li>
            <li>I am willing to use children’s and families’ ideas to adjust parts of the environment that are not
              working for the class.</li>
            <li>I am willing to include children in making adjustments because their input and valuing their voices
              are
              vital to their development.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p> <strong>Ideas for Maximizing Design of Indoor & Outdoor Classroom Spaces: </strong>

          <ul class="list">
            Every physical space, indoor and outdoor, will have its physical challenges and drawbacks. Do your best
            to
            take advantage of every space available and maximize its use. Here are a few ideas:
            <li>Design attractive and inviting spaces with available furniture. Remember, less is more. </li>
            <li>Create learning centers with ample storage for materials organized in a way that makes sense.</li>
            <li>Designate a large group gathering area where the entire group can fit comfortably. This is often
              where
              block/construction play occurs during center time, due to space needed for this type of play.</li>
            <li>Think of classroom organization as a place for everything and everything in its place.</li>
            <li>Add related materials on Monday, and additional materials on Wednesday, depending upon observations
              of
              need and interest. This will encourage exploration and enhance children’s learning and curiosity.</li>
            <li>Locate quiet areas apart from noisier ones. Remember, children need variety based on individual
              needs
              and preferences and cycles throughout the day. Children spend as many as 11 hours in your space, which
              can
              be overwhelming to certain children.</li>
            <li>Display children’s current art throughout the classroom. Be sure it is art rather than craft.</li>
            <li>A well-organized classroom encourages children to interact predictably and efficiently.
              Predictability
              in schedule and organization helps children to gain confidence in trust in you and school. </li>
            <li>Set up activities in a way that encourages independence, with materials accessible and organized
              nearby.
            </li>
            <li>Design individual workspaces with carpet squares, trays, work mats, etc. This helps to define
              personal
              space and sends a message as to how many children can comfortably work in an area.</li>
            <li>Display photographs of children engaged in activities and those of children’s families in attractive
              ways.</li>
            <li>Label shelves and storage containers with words and pictures to encourage independence in getting
              and
              returning materials. Write words in languages prevalent in the community, utilizing family/community
              resources if not within the center staff.</li>
            <li>Create a “lost and found” container for loose objects.</li>
            <li>Promote creativity with a variety of enhancements, including natural materials, found/recyclable
              materials. Sometimes, these are called loose parts. (can do a whole section on loose parts).</li>
            <li>For teacher-directed activities such as small groups or large groups, organize materials in advance
              for
              the task. This reduces child wait-time and enhances attention during teacher-directed activities.</li>
            <li>Use print in meaningful ways, from labeling and organizing materials to children’s names and other
              important words. A print-rich classroom establishes a connection between the spoken word and the
              written
              word. </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Classroom Environment / Centers -->
      <ng-container *ngIf="contentKey == 'centers'">
        <div class="body">

          <p><strong> Messaging to families</strong></p>

          <ul class="list">
            <li>
              Have a space set up where you can easily post messages to families on a dry-erase board.
              This is often near where sign-in/sign-out occurs, or near the classroom door,
              depending upon how your building is set up.
            </li>
          </ul>

          <p><strong>General Setup</strong></p>

          <ul class="list">
            <li>
              The classroom must meet the needs of the children who use the space
              and the teaching staff. Included are essential environmental considerations for several centers and
              setups,
              but keep these factors in mind when it comes to classroom setup: <br>
              - Remain flexible. Centers and their location can move as it makes sense to do so.<br>
              - Materials can move. Materials do not have to “live” in only one area. For example, books belong in every
              center (examples ahead), clipboards and pencils are useful in multiple spaces. <br>
              - Stay organized. Containers (including inventive ones) and labels, and “a space for everything, with
              everything in its space” help children feel secure knowing there is a plan. <br>
              - Less is more. While it is useful to have additional materials to add, what is at children’s level
              (accessible) should be enough without being overwhelming. Having too much out can make clean-up
              overwhelming. <br>
              - Supervision is key. Check your room set-up from several different spaces where you are likely to be when
              children are throughout the area (indoor and outdoor). While children need and deserve privacy and space,
              your setup should also allow for manageable supervision without interruption. <br>
            </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p> <strong>Indoor and Outdoor</strong></p>
          <ul class="list">
            <li>
              Many activities can occur both indoors and outdoors.
            </li>
          </ul>

          <p> <strong>Infusing literacy and math throughout each center</strong></p>

          <ul class="list">
            <li>
              It is helpful for children to experience literacy and math in meaningful ways. This starts with a
              classroom set up across centers. Here are a few suggestions:
            </li>
          </ul>

          <p> <strong> Literacy </strong></p>
          <ul class="list">
            <li>Include board books in every center. (e.g., books featuring cities, farms, buildings in the construction
              area, books about butterflies or lizards in the sensory/science center)</li>

            <li>Make writing and mark-making central. (e.g., have small clipboards and toddler-size crayons accessible
              for mark making)</li>
          </ul>

          <p> <strong> Mathematics </strong></p>

          <ul class="list">
            <li>Organize materials to encourage 1:1 correspondence</li>
            <li>Organize materials by color to encourage classification. The first way children classify objects is by
              color.</li>
            <li>Offer opportunities where counting is logical. (snack, manipulatives, organization like ice cube trays)
            </li>
            <li>Tools for measuring (measuring spoons and cups, sensory table containers marked with amounts) </li>
            <li>Provocations (how many cubes can you stack? </li>
          </ul>

          <p> <strong> Quiet / relaxation / safe space</strong></p>

          <ul class="list">
            <li> Separated from more active play areas </li>
            <li> Logical space for just a few children </li>
            <li> Soft surfaces and objects, a rug or carpet, stuffed animals, and puppets </li>
            <li> Visual sensory toys (e.g., liquid motion sensory toys, sensory bottles, stress balls) </li>
            <li> Pictures and photos of family displayed nicely on a wall, shelf, or binder </li>
            <li> Muted/filtered lighting or controlled lighting (lamps or lights with separate switches, curtains/blinds
              for windows) </li>
            <li> Cozy space </li>
          </ul>

          <p><strong>Construction / blocks</strong></p>

          <ul class="list">
            <li>Shelving for blocks and accessories and containers for accessories</li>
            <li>Organized and labeled blocks (at least two types, enough for a reasonable number of children in the
              space)</li>
            <li>Accessories that are always accessible, including construction or theme-related books </li>
            <li>Accessories that rotate, with extra accessories available to teachers</li>
            <li>Classmate blocks or cut-outs </li>
            <li>Overhead projector for shadow play and projection </li>
          </ul>

          <p><strong>Dramatic play</strong></p>

          <ul class="list">
            <li>Shelving and containers for props and supplies</li>
            <li>Housekeeping furniture sized for toddlers (e.g., kitchen set, pots and pans, dishes, table and chairs,
              cribs and baby dolls, highchairs)</li>
          </ul>


          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p><strong>Science / Sensory Table</strong></p>

          <ul class="list">
            <li>Shelving and containers for science materials</li>
            <li>Space to use materials (e.g., table, floor space)</li>
            <li>Scientific materials for general use (e.g., hand lens, microscope, tweezers, classroom pets, eye
              droppers, measuring cups and spoons, magnets, play dough, clipboards and pencils for sketches and plans).
            </li>
            <li>Scientific materials for specific themes or studies (e.g., books, robots, entomology, nature items,
              incubator, rocks, seeds, shells), with additional materials available to teachers</li>
            <li>Light table and overhead projector for shadow play and projection experiments </li>
          </ul>


          <p><strong> Writing</strong></p>
          <ul class="list">
          <li>Shelving and containers for writing materials</li>
          <li>Space to write (e.g., table or desk and chairs)</li>
          <li>Standard writing materials sized for toddlers (e.g., small clipboards and chunky pencils, plain copy
            paper, well-organized markers, and crayons)</li>
          </ul>

          <p> <strong>Art</strong></p>
          <ul class="list">
            <li>Shelving and containers for organized storage</li>
            <li>Toddler-size easels (preferably two, side-by-side)</li>
            <li>Areas to dry, store, and display children’s work (indoor or outdoor)</li>
            <li>Area to hang smocks </li>
            <li>Nearby access to child-level sink(s)</li>
            <li>Non-consumable materials (e.g., sponges, paintbrushes in varying sizes, paint cups, smocks, play dough
              props, stencils, shatterproof mirrors, pipe cleaners)</li>
            <li>Consumable materials accessible to children (e.g., various types of paper, markers, colored pencils,
              crayons, sharpened pencils, various washable paints (purchased or homemade), play dough, glue and glue
              sticks, chalk)</li>
            <li>Consumable and non-consumable materials for rotation, available to teachers. </li>
            <li>Overhead projector for shadow play and projection experimentation with design, light, and color</li>
          </ul>

          <p><strong>Gross Motor</strong></p>
          <ul class="list">
            <li>Toddler-size tricycles and helmets, including trikes with a back seat (e.g., taxi, bus, rickshaw)</li>
            <li>Wagons</li>
            <li>Parachute</li>
            <li>Balls (and air pump/needles)</li>
            <li>Large paintbrushes and rollers (think to paint your house) with buckets to paint water on fences,
              sidewalks, and building</li>
            <li>Sand toys, including vehicles</li>
            <li>Water table and toys</li>
            <li>Books and a book basket with a handle</li>
            <li>Outdoor easels (or fence surface) for painting</li>
            <li>Items for various uses, such as cones and beanbags</li>
            <li>Natural objects, stored in containers for use (e.g., pinecones, acorns/walnuts/seed pods, tree cookies,
              seashells, rocks, sticks)</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_3l,'img')" src="{{  resources.resource_3l }}" alt="">
            <img (click)="openBunner($event, resources.resource_3m,'img')" src="{{  resources.resource_3m }}" alt="">
            <img (click)="openBunner($event, resources.resource_3r,'img')" src="{{  resources.resource_3r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Setting up Shared-Use Spaces -->
      <ng-container *ngIf="contentKey == 'spaces'">
        <div class="body">
          <ul class="list">
            <strong>Small Group</strong>
            <li>Space and seating for the small group of 3-5 to work with the teacher (e.g., table and chairs,
              individual mats/carpet squares on the floor)</li>
            <li>Adult-level counter or top of the shelf where the day’s prepared small group materials are ready</li>
            <li>Location giving teacher visibility to scan the environment, and providing some separation from other
              activities</li>

            <strong>Large Group</strong>
            <li>Space and seating for the whole group to gather, with teacher seating visible to the entire group (e.g.,
              carpet for children, with low teacher chair)</li>
            <li>Display/storage space for day’s large group materials, preferably movable (e.g., big book display and
              storage or teacher’s learning center/chart stand)</li>
            <li>Access to cued-up music (e.g., CD player / portable Bluetooth speaker connected to iPod/phone)</li>

            <strong>Environment considerations for mealtimes</strong>
            <li>Rolling plastic or metal cart (2-3 levels) that can be in mealtime area as needed, and then rolled out
              of the way</li>
            <li>Easy access to food, serving containers, utensils, and plates/silverware (e.g., top of shelf/teacher
              counter, a cart)</li>
            <li>Child-safe space and means to clean and store cleaning supplies</li>
            <li>Appropriate-size seating and chairs to accommodate all children and staff sitting together, including
              low teacher-size chairs that fit the table</li>
            <li>Close access to child-size handwashing sink(s)</li>
            <li>Plan for how children will clean up (e.g., bus tub for dirty dishes, trash can nearby to scrape plate,
              dump milk in the sink rather than trash can). Set up the environment according to your plan.</li>

            <strong>Environment considerations for naptime </strong>
            <li>Cots or mats stored on rolling frame/container to reduce staff fatigue and speed process of naptime
              set-up and clean-up</li>
            <li>Laminated floor plan with a dry erase marker to indicate each child’s cot placement available for all
              staff</li>
            <li>Place children strategically to minimize distractors</li>
            <li>Arrangement to allow easy supervision as the teacher moves around the room</li>
            <li>Play/stream quiet music</li>
            <li>Reduce light (e.g., cover windows, turn main room lights off, use small lamps/night lights as needed)
            </li>
            <li>Reduce the temperature slightly, if possible</li>

          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Technology in the Classroom -->
      <ng-container *ngIf="contentKey == 'technology'">
        <div class="body">

          <p>
            It is essential children make digital connections as they are growing up in a digital world.
            However, technology must be purposeful and intentional as an open-ended tool to extend learning,
            grow relationships, and share understandings with peers and significant adults (i.e., family and teachers).
            Technology includes those items that improve the quality of life.
            Learning Beyond Paper provides age-appropriate technology connections throughout the curriculum.
          </p>

          <p>
            The U.S. <strong>Department of Education and the U.S. Department of Health and Human Services collaborated
              in the
              development of the Early Learning</strong> and Educational
            Technology Policy Brief in 2016 to promote developmentally appropriate use of technology in homes and early
            learning settings.
          </p>

          <p>
            The Departments’ four guiding principles for the use of technology with early learners are as follows:
          </p>

          <ul class="list">
            <li><strong>Guiding Principle #1:</strong> Technology—when used appropriately—can be a tool for learning.
            </li>
            <li><strong>Guiding Principle #2:</strong> Technology should be used to increase access to learning
              opportunities for all children.</li>
            <li><strong>Guiding Principle #3:</strong> Technology may be used to strengthen relationships among parents,
              families, early educators, and young children.</li>
            <li><strong>Guiding Principle #4:</strong> Technology is more effective for learning when adults and peers
              interact or co-view with young children.</li>
          </ul>

          <ul class="list">
            <strong>Examples of Technology in the preschool classroom</strong>
            <li>A cash register in the housekeeping center for children to play grocery shopping</li>
            <li>Assisting children with using a digital camera to take pictures of their work</li>
            <li>Using the computer with a child to search for an age-appropriate website to answer a child’s question or
              interest</li>
            <li>Providing a variety of audiobooks/player in your reading center</li>
            <li>Collect outdated cell phones (take out batteries) for children’s exploration</li>
            <li>Model appropriate technology behavior</li>
            <li>Provide opportunities for children to share technology tools to encourage interactions</li>
          </ul>


          <p class="epigraph">
            *Visit the Resources section for additional for additional examples of technology in the classroom.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Structure / Classroom Community-->
      <ng-container *ngIf="contentKey == 'community'">
        <div class="body">
          <p class="title">Creating a Sense of Community</p>

          <p>
            Creating a sense of community should begin from the first day of school and continue throughout the year. A
            new group of children arrives with different interests, abilities, cultures, and families. Many may have
            experienced childcare since they were infants, while others are experiencing it for the first time. How you
            meet and greet each child and family is a role model for meeting and greeting their peers. Part of creating
            a sense of community is to remember that “the teacher is the environment. The teacher creates a sense of
            community that is equitable for all children.
          </p>

          <ul class="list">
            <strong>As a teacher, you will:</strong>
            <li>Create a welcoming classroom that reflects and celebrates children’s identities, including images of
              children (e.g., self-portraits) and families (e.g., photos), and offer different opportunities for
              children to learn about themselves and each other throughout the curriculum</li>
            <li>Show respect for children of different cultural backgrounds in school settings</li>
            <li>Welcome families and children to share their cultural heritages at school-wide events such as cultural
              fairs or a cultural week and include a sustained focus on children’s social identities throughout the year
            </li>
            <li>Include children’s books and media in early childhood classrooms which represent different cultures and
              diversity</li>
            <li>Are enthusiastic and use positive verbal and nonverbal language</li>
            <li>Empower children to have some influence over decisions in play</li>
            <li>Ensure children have input in curriculum topics, parts of the daily schedule, and creating class rules
              and job responsibilities</li>
            <li>Give children the space they need to explore, experiment, and make—and learn from—mistakes</li>
            <li>Provide experiences of working together to solve problems in both play and projects</li>
            <li>Understand that a strong classroom community is one in which children feel empowered and valued, and
              children will learn and grow</li>
            <li>Model behaviors of respect, caring, self-control, and fair decision-making</li>

          </ul>

          <p class="epigraph"> *Visit the Resources section for additional support on classroom schedules. </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Classroom Structure / Classroom Routine-->
      <ng-container *ngIf="contentKey == 'routine'">
        <div class="body">

          <p><strong>Together Time:</strong></p>

          <p>This is a time of building community and sharing as well as learning. Keep it short and interesting and
            follow the interest level of toddlers. If toddlers become restless, try an action song or something that
            uses large muscle movement. Don’t be afraid to stop together time early if toddlers are not interested.
            Then, in your planning, focus on adjusting to your group’s needs and what will engage them.</p>

          <p><strong>Together time is meant to share activities and experiences that: </strong></p>

          <ul class="list">
            <li>Build structure into the program, so the children know what to expect each day.
            <li>Are meaningful to children (e.g., presented for children’s benefit and at their level)
            <li>Presented in an engaging way
            <li>Always include movement (e.g., small motor movements like fingerplays, large motor movements like
              dancing and actions).
            <li>Introduce new concepts or things that are occurring or available (e.g., new materials in centers, change
              to daily schedule such as a planned fire drill, a new friend has joined the group)
            <li>Share thoughts and ideas, listen, and talk to each other, participate in new learning experiences, read
              together, sing together, and interact with one another
          </ul>

          <p><strong>Together Time Schedule Frequency and Purpose:</strong></p>

          <ul class="list">
            <li>Twice a day: once in the morning, and once just before time to go home</li>
            <li>Begin the year with 5 minutes and increase to 10 minutes as the children’s attention spans increase
            </li>
            <li>A consistent transition activity to gather and dismiss children</li>
            <li>Alternate active and passive activities</li>

            <p><strong>Beginning of the day:</strong></p>

            <p>
              This is a large group, teacher-led activity, and occurs in the morning at a time in
              the schedule when the group has arrived. Use this time to set the tone for the day or week. Establish a
              routine that includes an opening song or finger play and activities which may also include:
            </p>

            <li>Taking attendance</li>
            <li>Morning message</li>
            <li>Shared reading</li>
            <li>Introduction to new materials </li>
            <li>Time to focus children on the upcoming daily/weekly events </li>
            <li>Create meaningful ways to introduce concepts such as before and after and later and next. </li>

            <p><strong>End of day:</strong></p>
            <p>
              This is the second large-group, teacher-led activity.
              This is time to bring the children together to review the day before the group begins leaving for the day.
            </p>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p><strong>Learning Center Time:</strong></p>

          <p>
            Learning Centers provide opportunities for exploration as children make decisions (self-direct)
            about their activity choices, interactions, and direction. They are guiding their learning, with
            peers or solo. Learning centers should include newly rotated materials weekly,
            ideally on Monday and mid-week as the play progresses, to keep interest high.
          </p>

          <ul class="list">
            <p><strong>Examples of Learning Centers:</strong></p>
            <li>Art Center</li>
            <li>Construction Center</li>
            <li>Dramatic Play Center</li>
            <li>Mathematics/Number Center (often combined with manipulatives)</li>
            <li>Reading/Literacy Center</li>
            <li>Science/Sensory Center</li>
            <li>Alphabet/Writing Center (introducing letters without expectation of toddlers traditionally writing
              letters)</li>
          </ul>

          <p><strong>Schedule Frequency and Purpose:</strong></p>

          <ul class="list">
            <li>Schedule twice daily.</li>
            <li>Length is a minimum of 45 minutes each, with 60 to 90 minutes desirable.</li>
            <li>Children may work in centers they choose, taking into account space considerations. </li>
            <li>Children practice skills they are learning, building relationships with peers, and responsibility for
              actions and use of materials and space.</li>
          </ul>

          <p><strong>Teacher Role:</strong></p>

          <ul class="list">
            <li>Set-up a management system that allows children to rotate freely through centers.</li>
            <li>Establish a clean-up routine.</li>
            <li>Encourage children to explore different centers.</li>
            <li>Interact with children in centers using open-ended questions and open-ended materials, allowing
              discovery of material use.</li>
            <li>Model, model, model how to play and interact with friends, use different materials, and clean up. </li>
            <li>Don’t forget to model. It is that important.</li>
          </ul>

          <p><strong>Small Group Instruction:</strong></p>

          <ul class="list">
            <li>Small Group Instruction times can run concurrently with centers if you have scheduled center time for at
              least 60 minutes, and if staffing and children’s attention allows. If this model is used, the daily
              schedule and lesson plans should reflect this. </li>
            <li>Feel free to incorporate small group instruction throughout the day. Usually, there are no more than
              three to five children in a small group activity. A small group activity could last a maximum of 10
              minutes.</li>
            <li>Small group times provide the teacher with opportunities for close interaction necessary for focused
              instruction and skills assessment.</li>
            <li>Small Groups are designed to focus on a skill or concept in an engaging, interesting manner. </li>
            <li>All children may not participate in a small group every day. </li>
            <li>Determine a small group schedule throughout the week for those children in need of reinforcement on that
              skill or concept. </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p><strong>Outdoor Adventures:</strong></p>

          <p>
            Outdoor Adventures - Go outside every day the weather permits. Check your state regulations for the minimum
            amount
            of time your age group should spend outside.
            Most, if not all, indoor activities can occur outside too. Make your space into an outdoor classroom.
          </p>

          <ul class="list">
            <li>Play and Learn - an outdoor experience where children can engage in supervised free play. </li>
            <li>Walk around and get involved with children’s play and conversation.</li>
            <li>Outdoor play supports the development of necessary social skills and gross motor skills. </li>
            <li>Provide materials such as balls, tricycles, and balance beams to encourage movement, and learning center
              materials to promote constructive play. </li>
            <li>Rotate materials to maintain interest. </li>
          </ul>

          <p><strong>Arrival at school:</strong></p>

          <p>Establishing an arrival routine will provide children with a structure to know what to expect and feel safe
            at school.</p>
          <p>When children first enter the classroom each morning, they should feel welcomed. </p>
          <p>
            Each child’s individual and past experiences may influence the child’s reaction to transitioning from home
            to school.
            Whether the children arrive all at once or trickle in, the following tips may help provide a predictable
            routine to start the school year.
            Your routine activities will change as children learn and grow. Children will feel successful if they start
            the day excited to be in school.
          </p>

          <ul class="list">
            <li>Think of a choice of 3 rituals you could use to greet the children. Include a visual so they can choose
              which greeting they want to use handshake, hug, or high five. No-contact options include an air hug,
              thumbs up, hang loose sign.</li>
            <li>Ask the child, “How do you feel today,” and have the child point to the picture of how they feel on a
              poster of faces with different expressions? </li>

            <li>Prepare your classroom for the day before the children arriving, </li>
            <li>Greet children and families with a smile and speak their name and add a few words of welcome. </li>
            <li>Avoid making comments about what the child is wearing or how they look. </li>
            <li>Upon arrival, encourage the parent to stay a few minutes and help the child settle in. </li>
            <li>Create a visual picture of the arrival routine. </li>
          </ul>

          <p><strong>Morning Routine:</strong>(Your routine will be individualized based on your schedule and school
            policies/procedures.)</p>

          <ul class="list">
            <li>Include a sign-in routine, an age-appropriate activity with a variety of writing tools, and large pieces
              of unlined paper for the beginning of the school year. </li>
            <li>Put personal items in designated cubbies labeled with each child’s name</li>
            <li>Wash hands</li>
            <li>Encourage the family member to say a cheery goodbye </li>
            <li>Prepare a variety of tabletop activities available to children</li>
          </ul>

          <p><strong>Snack and Mealtime</strong></p>

          <p>Snack time is an opportunity for learning in all the domains for learning.</p>
          <p>Snacks served at a table allow youngsters to navigate serving themselves food and drinks. </p>
          <p>Teachers sit and interact with the children focusing on healthy eating and self-help skills (e.g., serving
            self, opening packaging, feeding self).</p>

          <p><strong>Transitions:</strong></p>

          <p>
            Transitions are the periods between activities. This is the time for children to interact with the visual
            schedule.
            They can use a clothes pin to mark the clip on the next activity or point as they identify the activity
            which comes next.
          </p>
          <p>
            Transition songs, chants, rhymes, sounds, and pictures are helpful to children to move to the next activity
            to assist them with focusing on a smooth transition in a group.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_3l,'img')" src="{{  resources.resource_3l }}" alt="">
            <img (click)="openBunner($event, resources.resource_3m,'img')" src="{{  resources.resource_3m }}" alt="">
            <img (click)="openBunner($event, resources.resource_3r,'img')" src="{{  resources.resource_3r }}" alt="">
          </div>

          <p><strong>Routines:</strong></p>

          <p>
            The schedule is the “big picture” of the main activities you and the children engage in daily.
            Routines, on the other hand, are the steps done along the way to complete certain portions of the schedule.
            Routines help provide a pattern and predictability to one’s day.
          </p>

          <ul class="list">
            <p><strong>Examples of routines:</strong></p>
            <li>Arrival</li>
            <li>Bathroom time</li>
            <li>Handwashing</li>
            <li>Meals/Snacks</li>
            <li>Clean-Up </li>
            <li>Naptime </li>
            <li>Dismissal </li>
          </ul>

          <p><strong>Classroom Rules:</strong></p>

          <ul class="list">
            <li>Involve children in creating rules</li>
            <li>Posted rules are positive and use affirmative language. </li>
            <li>The use of pictures will enhance children’s readability and understanding.</li>
            <li>A suggested guide for this age is a maximum of four rules.</li>
            <li>Keep the rules short and simple.</li>
            <li>Once you have decided on a set list of rules as a group, reinforce them by walking through each rule.
            </li>
            <li>Adding picture cues and physical actions to your rules will help the children remember the rules.</li>
            <li>Post the rules in several locations throughout your classroom.</li>
            <li>Post at eye level and include visuals with children having diverse skin tones and eye colors.</li>
          </ul>

          <p class="epigraph">*Visit the Resource section for additional support on classroom routines. </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_4l,'img')" src="{{  resources.resource_4l }}" alt="">
            <img (click)="openBunner($event, resources.resource_4m,'img')" src="{{  resources.resource_4m }}" alt="">
            <img (click)="openBunner($event, resources.resource_4r,'img')" src="{{  resources.resource_4r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Structure /  Schedule -->
      <ng-container *ngIf="contentKey == 'schedule'">
        <div class="body">

          <ul class="list">
            <p>
              Your schedule may vary based on your program’s operating hours and needs,
              but there are essential considerations when developing a schedule. Your schedule must provide:
            </p>

            <li>blocks of time to enable children opportunities for discovery as they are involved in integrated,
              active, engaged learning experiences.</li>
            <li>a balance of large group (e.g., together time), small group, individual activities that include
              child-selected groups, and teacher-selected groups.</li>
            <li>times, when children can work and play together with peers as well as alone (self-selected by the
              child).</li>
            <li>time planned for physical movement and outdoor activities as well as snacks/meals and rest times.</li>
            <li>a sense of what comes next. A predictable schedule helps children anticipate what will happen, and thus
              feel more secure.</li>
          </ul>

          <ul class="list">
            <p><strong>Tips for developing a schedule:</strong></p>

            <li>For shared spaces (e.g., a playground), you will need to plan a program-wide schedule with others.</li>
            <li>Consider the type of activity and alternate active and passive activities.</li>
            <li>Plan your day and be organized. Prepare things needed for each part of the schedule in advance (e.g.,
              have your large group book and materials out with music cued up before children come to large group time,
              ensure paint and brushes are available at the easel before center time, fill the water table before
              outdoor play begins). This reduces wait time and problem behavior.</li>
            <li>Think about the transitions/ routines throughout your day and allow time for transitions and routines,
              so children (and you) are not frustrated with unrealistic expectations.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <ul class="list">
            <p><strong>Time Needed for Routine Parts of Day:</strong></p>

            <p>For infants and young toddlers, there are some additional parts of the day that are routine, but also
              very important learning opportunities. Here are a few to consider:</p>

            <li> <strong>Sign Language</strong> — Using sign language provides infants an opportunity to communicate
              before they can verbalize using words. These activities should be repeated often throughout the week.</li>
            <li> <strong>Feeding Time</strong> — Feeding time offers opportunities for one-on one interactions and
              bonding.</li>
            <li> <strong>Changing Time</strong> — Like Feeding Time, Changing Time also provides opportunities for one
              on-one interactions. These activities have been created to maintain the infant’s attention, build skills,
              and encourage interactions.</li>
            <li> <strong>Tummy Time</strong> — Tummy Time allows for the development of neck, arm, leg, back, and
              stomach muscles for infants who are not yet crawling. These activities also provide opportunities for
              reaching, grasping, and tracking. As infants engage in Tummy Time, peer interactions are encouraged and
              adults should also be on the floor, so babies see others’ faces, not just feet.</li>
            <li> <strong>Outdoor Exploration</strong> — Outdoor activities are in addition to regularly available
              outdoor options such as sandbox, water play, trikes, swings, and slides. They are theme-related and should
              be presented every day the weather permits. Check your state regulations for the amount of time your age
              group should spend outside.</li>

            <p><strong>Exploration Times</strong></p>
            <li>Exploration times are experiences that correspond with the weekly themes which are typically presented
              at the beginning of each day.</li>

            <p><strong>Language Exploration</strong></p>
            <li>Enhances language development which includes expressive language, receptive language, and early literacy
            </li>

            <p><strong>Music and Movement</strong></p>
            <li>These activities provide infants the opportunity to respond to music and experiment with vocalization
              and sound</li>
            <li>They also promote physical and language development</li>

            <p><strong>Creative Exploration</strong></p>
            <li>These activities include finger plays, stories with props, and hands-on creative activities where
              infants will explore simple art materials</li>

            <p><strong>STEAM (Science, Technology, Engineering, Arts, Mathematics):</strong></p>

            <li>Cognitive Exploration—These activities focus on areas included in the Cognitive Domain.</li>
            <li>Engineering introduces different ways to solve a problem, such as how to build a ramp, how to keep a
              baby doll in the stroller, or how to keep water in a funnel.</li>
            <li>Technology introduces various tools to help complete a task, such as using a pulley to lift a bucket to
              the top of the climber, recording a song to share with parents later, or taking a picture of a block
              structure before cleaning it up so it is easier to remember your work.</li>
            <li>Mathematics introduces numbers and counting, shapes and patterns, sorting and classifying, and
              measurement and comparison.</li>
            <li>Social Studies includes family, people, and community.</li>
            <li>Science focuses on learning about the world through senses.</li>
            <li>Cognitive Processes include thinking skills and problem solving</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p>
            Older Toddlers will have many of the same routine parts of the day as preschoolers, though their attention
            for whole group and small group activities is shorter
          </p>


          <ul class="list">
            <li>Large Group/Together time (5 to 10 minutes each in the morning and toward the end of the day)</li>
            <li>Small group time (10 minutes), scheduled small group times</li>
            <li>Learning Center time (at least 45 min each) morning and afternoon</li>
            <li>Outdoor time (30 min each) morning and afternoon</li>
            <li>Snack time (15 minutes each) morning and afternoon </li>
            <li>Lunch (30 min) </li>
            <li>Naptime (at least 2 hours)</li>
          </ul>

          <p>
            Post your schedule with different audiences in mind, but primarily families and children. Post your schedule
            with the times for each segment of the day for your families in a conspicuous place. Some programs have a
            parent information board (pic) or an online place where families can view the schedule (pic). Licensing may
            also require a schedule to be posted, so be sure to follow any specifications for this.
          </p>

          <p>For children, a visual schedule is necessary and explained in more detail below.</p>

          <p><strong>Visual Schedules:</strong></p>

          <p>Children are visual learners, and creating a visual schedule provides a pictorial representation of what
            they will be doing throughout the day. It helps children predict what is coming next, which helps children
            feel safe and secure.
          </p>

          <p><strong>Creating a Visual Schedule:</strong></p>

          <ul class="list">
            <li>Pictures should be larger than words. Use stock photos until you take pictures of your children during
              each segment of the day. Use pictures of your actual class to reaffirm and motivate, which is easy to do
              with a digital camera.</li>
            <li>Using 8x11 pictures will allow the children to see the activities efficiently. </li>
            <li>Post at children’s eye level preferably left to right (but can be top to bottom/vertical if space does
              not allow horizontal placement). In an infant or young toddler classroom, the visual schedule may even be
              on the floor, adhered with contact paper - at their level.</li>
            <li>Place the pictures in a sheet protector so that you can change them more easily. </li>
            <li>Adjust the schedule with additional pages/pictures as needed (e.g., weather, a special activity, field
              trip).</li>
          </ul>

          <p><strong>Using a Visual Schedule with Toddlers:</strong></p>

          <ul class="list">
            <li>During the first few weeks, or any time there is a schedule change, talk about the schedule/schedule
              change, and refer to it during Together Time.</li>
            <li>Go to the visual schedule on the wall frequently to point out what is coming up next.</li>
            <li>Refer to the schedule often so that children begin to use it as a reference too.</li>
            <li>Make your schedule interactive by placing a clothespin or other child-friendly clip on the schedule.
              Move the clip (or have a child move the clip) as the schedule changes throughout the day. This helps
              children to understand the movement of time during the day. </li>
          </ul>


          <div class="content images">
            <img (click)="openBunner($event, resources.resource_3l,'img')" src="{{  resources.resource_3l }}" alt="">
            <img (click)="openBunner($event, resources.resource_3m,'img')" src="{{  resources.resource_3m }}" alt="">
            <img (click)="openBunner($event, resources.resource_3r,'img')" src="{{  resources.resource_3r }}" alt="">
          </div>

          <p><strong>Sample Schedules:</strong></p>

          <p>Schedules for infants in your care are individualized based on the infant’s routines for feeding,
            diapering, and sleeping. Individual schedules will be developed in coordination with the family to ensure
            consistency with routines and rituals at home.</p>

          <p>Nurturing infants socially and emotionally is of utmost importance and positively impacts Intellectual
            Development, Physical Development, Social Development, Behavioral Development, Emotional Development.</p>

          <p>
            Relationships lay the foundation for later developmental outcomes of Self-Confidence, Sound Mental Health,
            Motivation to Learn, Achievement, and Self-Control.
          </p>

          <ul class="list">
            When building relationships with and nurturing infants, consider these important points:
            <li>Interactions should inspire curiosity, creativity, and confidence. Always interact with infants in
              positive and respectful tones.</li>
            <li>Praise their efforts using kind, affirmative words. Teachers should provide opportunities for infants to
              build on their accomplishments.</li>
            <li>Build positive relationships through your immediate and appropriate responses to infants’ physical and
              emotional needs.</li>
            <li>Demonstrate your enthusiasm and show respect as you engage with infants.</li>
            <li>Interact with infants using sign language in multiple situations.</li>
            <li>Tone should be respectful at ALL times.</li>
          </ul>

          <p>
            Consistent routines, activities that happen at about the same time and in about the same way each day,
            provide comfort and a sense of safety to young children. Whether it is time to play, time for a snack, a
            nap, or a loved one to return, knowing what will happen next gives babies and toddlers security and
            emotional stability.
          </p>

          <ul class="list">

            <p><strong>Morning:</strong></p>

            <li>Greetings and check-ins:</li>
            <li>Diapering, naps, and feeding as needed</li>
            <li>Exploration of sensory materials, objects, toys</li>
            <li>Reading and exploring books</li>
            <li>Music and movement</li>
            <li>Outdoor exploration</li>

            <p><strong>Afternoon:</strong></p>

            <li>Diapering, naps, and mealtime as needed</li>
            <li>Quiet music, reading, and low lights</li>
            <li>Exploring materials, objects, toys</li>
            <li>Music and movement</li>
            <li>Outdoor exploration</li>

            <p><strong>Late Afternoon:</strong></p>

            <li>Diapering, naps, and mealtime as needed</li>
            <li>Reading and exploring books</li>
            <li>Exploring materials, objects, toys</li>
            <li>Music and movement</li>
            <li>Outdoor exploration</li>
            <li>Transition to family</li>

          </ul>

          <p>Your licensing agency may require a schedule posted with times. Create the room schedule with times
            consistent with your program hours of operation. The caregiving routines provide opportunities for teachers
            to facilitate learning in all areas of development.</p>

          <p> <strong>Sample Schedule - Younger Toddlers - 12 to 24 months and Older Toddlers - 24 to 36
              months:</strong></p>

          <div class="tables infant">
            <table>
              <tr>
                <th> 8 am to 9 </th>
                <th> Arrival </th>
              </tr>
              <tr>
                <th> 9 am to 9:15 </th>
                <th> Breakfast/morning snack </th>
              </tr>
              <tr>
                <th> 9:30 to 10:00 </th>
                <th> Outdoor time </th>
              </tr>
              <tr>
                <th> 10:15 to 10:30 </th>
                <th> Story time / Together Time </th>
              </tr>
              <tr>
                <th> 10:45 to 11:30 </th>
                <th> Exploration with sensory materials, toys, and objects <br> Prepare for lunch </th>
              </tr>
              <tr>
                <th> 11:30 to 12:00 </th>
                <th> Lunch </th>
              </tr>
              <tr>
                <th> 12:15 to 2:00 </th>
                <th> Nap </th>
              </tr>
              <tr>
                <th> 2:15 to 3:00 </th>
                <th> Outdoor time </th>
              </tr>
              <tr>
                <th> 3:15 to 3:30 </th>
                <th> Snack time </th>
              </tr>
              <tr>
                <th> 3:45 to 4:00 </th>
                <th> Story time </th>
              </tr>
              <tr>
                <th> 4:00 to 5:00 </th>
                <th> Exploration with sensory materials, toys, and objects <br> Dismissal </th>
              </tr>
            </table>
            <p>15-minute transition times and care routines between activities</p>
            <p>Schedule times can be adapted to your program schedule. </p>
            <p><strong>Classroom Jobs for Older Toddlers</strong></p>
            <p>Starting the day with Together Time creates a sense of community. As members of a community, it is
              important for children to share the responsibilities of classroom life. Assigning classroom jobs is an
              ideal way to teach children responsibility and build their confidence. Brain research suggests that
              providing opportunities to practice decision-making and reasoning skills enhance children’s abilities to
              perform such tasks. Classroom jobs afford children these valuable opportunities while allowing them to
              rehearse meaningful responsibility by completing jobs that assist their school family (Siegel & Bryson,
              2011).</p>

            <ul class="list">
              <p><strong>What should you consider?</strong></p>
              <li>Take time to share why it is vital to have a list of classroom jobs and discuss why they matter.</li>
              <li>Follow up with why the job is essential and details of how to do the job. </li>
              <li>Assign every child a job. </li>
              <li>The number of jobs listed will depend on the number of children in the class.</li>
              <p><strong>Preparation of Chart.</strong></p>
              <li>Provide laminated visuals for each job. </li>
              <li>Create and laminate name cards with each child’s picture and first name.</li>
              <li>Display the jobs in such a way that every child can quickly identify his or her job title.</li>
              <p><strong>Time to get started.</strong></p>
              <li>Designate a start date and decide how often jobs will rotate.</li>
              <li>Describe each job and teach children how to do it.</li>
              <li>Provide time to reflect with children about how jobs are going, and if the job needs changes.</li>
              <li>Observe the children’s implementation of the job and step in to scaffold if needed.</li>
              <li>Provide daily, specific feedback with examples of what you are seeing that is going well.</li>
              <p><strong>Jobs will vary with the needs of individual classrooms. Some suggestions include:</strong></p>
              <li>Song Selector- Chooses a song to sing during together time and background music for center time.</li>
              <li>Librarian- Chooses a book for story time and is responsible for returning all books in the library
                center to the shelf.</li>
              <li>Zookeeper-Feeds classroom pet/s and changes water as needed</li>
              <li>Gardener- Waters plants (indoors and outdoors) and weeds as necessary</li>
              <li>Light Monitor-Turns the lights off when the class leaves the classroom and turns them on when they
                return</li>
              <li>Line Leader- Opens the door and walks at the front of the line.</li>
              <li>Caboose- Closes the door and walks at the back of the line.</li>
              <li>Meteorologist- Observes and reports the weather to the class (may use tools such as rain gauges,
                windsocks, and thermometers)</li>
              <li>Snack Helper- Counts the number of plates, napkins, or cups needed and passes them out.</li>
              <li>Greeter- Greets peers and visitors as they enter the classroom.</li>
              <li>Goodbye Wisher- Says “goodbye” to visitors as they leave the classroom and wishes them a nice day.
              </li>
              <li>Hand Sanitizer- Provides a pump of hand sanitizer before or after designated activities.</li>
              <li>Marker Monitor- Monitors markers to be sure that correct lids are on tightly</li>
              <li>Table Washer- Wipes and washes tables after snacks and messy activities</li>
              <li>Playground Supply Managers- Carries playground supplies (balls, sand toys, etc.) outside and collects
                them when playtime is over.</li>
            </ul>

          </div>
        </div>
      </ng-container>

      <!-- Learning Experiences / Complexity and Depth -->
      <ng-container *ngIf="contentKey == 'complexity&depth'">
        <div class="body">
          <p>
            Within the Learning Beyond Paper curriculum, it is essential to know that all children, and especially
            infants and toddlers, will begin in your classroom with varying levels of skills and knowledge. This can be
            due to huge variation in month-by-month development, experience (or lack of), or possibly developmental
            delay (identified or not). It is important to keep expectations positive and high for children. Children
            will work up to your age-realistic expectations and necessary level, with the right encouragement,
            scaffolding, and modeling, as described in the teacher guide and professional development. While individual
            children may have challenges, use these challenges to understand how to best support the child’s learning
            rather than to lower expectations for what a child can achieve.
          </p>

          <p>
            The Scope and Sequence provided is valuable to ensuring that your planning will help typically-developing
            children are progressing in a way to make sure that they will be able to meet age-appropriate standards by
            the end of the year, or consistent with their age. The Scope and Sequence covers an entire calendar year and
            breaks down the year’s standard into quarterly targets.
          </p>

          <p>
            When you think about the topics of scaffolding and the Zone of Proximal Development, presented earlier in
            this guide, breaking down skills into smaller steps along the way is a way to build competence of skills
            through practice and building along the way. Imagine if you were going to run a marathon on a certain date,
            there would be training along the way, building up endurance and distance over time, rather than arriving at
            the gate expecting to do well without the necessary steps along the way.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Learning Experiences / Linking the Standards -->
      <ng-container *ngIf="contentKey == 'linking'">
        <div class="body">
          <p>
            The term intentional has been used repeatedly in this teacher’s manual. As a teacher, intentionality and
            reflective practice are symbiotic - one cannot exist without the other. When thinking about the curriculum,
            the actual activities and experiences children take part in through the day; it is critical to plan the
            steps to help children achieve standards (state, local, or program) by the end of the program year.
            Standards are the finish line, but there are many incremental steps to get to that point. For infants and
            toddlers, standards or benchmarks are often typical developmental milestones.
          </p>

          <p>
            As the teacher, it is your job to plan, scaffold, and create experiences that tie to the incremental
            progress infants and toddlers will make through the year. It is never enough that an activity is cute or
            fun. It must be intentional, meaningful, engaging, and targeted toward building children’s competencies:
            focusing on what each child can do, challenging them, and planning the next steps. This is especially
            important for infants and toddlers, because their attention span is generally shorter the younger they are.
            The more meaningful and engaging things are, the more targeted to the specific individual child or class,
            the better conditions are for learning and connection.
          </p>

          <p>
            Learning Beyond Paper is designed to scaffold children’s skills and developmental progression across domains
            to meet general standards adopted by most states and programs. Remember, especially for infants and
            toddlers, this is tied to developmental milestones. Your small groups will help you work strategically with
            children who need additional scaffolding and opportunities for practice.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Learning Experiences / Planning and Reflection -->
      <ng-container *ngIf="contentKey == 'planning&reflection'">
        <div class="body">
          <p>
            You should plan regularly, updating plans based on the curriculum as well as observation of the children in
            your class. As you complete any progress monitoring or assessment, it is important to use these data to
            inform your next steps.
          </p>

          <p>
            Minimally, play with your colleagues that teach with you in the same class. For maximum benefit, work with
            the director to determine if planning can occur with other preschool teachers. You can gain efficiency, get
            additional ideas and insights, and build collaboration in this way.
          </p>

          <p>
            Throughout the day or by the end of the day, take a few minutes to make notes about the day. Within the
            week, have a planned time to actively reflect - with colleagues, if possible - on the learning based on what
            you have planned and how children responded to plans. This involves thinking about the progress of
            individual children, groups of children, and the group as a whole. It includes you thinking about your
            perspective, challenging yourself on things that you could do differently or better (even if you don’t yet
            know how to do something differently yet), and reflecting on what went well or what could have gone better.
            It is not about being critical or judgmental; rather, seeing things accurately and from different lenses.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Learning Experiences / Play and Authenticity -->
      <ng-container *ngIf="contentKey == 'play&authenticity'">
        <div class="body">
          <p>
            Play is the vehicle for learning with young children. Through play, children learn things more quickly in a
            way that sticks. Play is the manner in which children practice and refine their skills, have fun (a
            necessary component in learning), and try things they otherwise may not. Vygotsky famously stated, “In play,
            a child is always above his average age, above his daily behavior; in play, it is as though he were a head
            taller than himself
          </p>

          <p>
            You can plan authentic experiences - solving real problems that come up throughout the day WITH children,
            involving even infants and toddlers in small ways with set-up and clean-up, helping to make play dough for
            the class, and numerous activities that are necessary and important. Children prefer “real” to busy work and
            will remain attentive for longer when engaged and the vision is viewed as important.
          </p>

          <p>
            One important way that teachers of infants and toddlers encourage play is to provide the right activity or
            toy at the right time, and ensure children have time to explore, as most things they encounter are novel to
            them as less-experienced humans.
          </p>

          <p>
            Another important way that teachers of infants and toddlers encourage play is through teacher-guided
            experiences where the teacher is sportscasting (talking aloud about what is going on in an enthusiastic,
            positive voice) and interacting with the child(ren).
          </p>

          <p>
            There are some skills that are highly unlikely to spontaneously occur during play alone. Skills can be
            practiced in teacher-guided experiences (e.g., circle time, small groups, or even 1:1 experiences), but
            still retain the most benefit when this is done in a playful way where children’s senses are activated and
            there is active engagement.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Learning Experiences / Standards-Based Learning -->
      <ng-container *ngIf="contentKey == 'sb_learning'">
        <div class="body">
          <p>
            Early childhood educators, more than most people, realize that young children do not divide their learning
            into subjects or neat and tidy lessons. More often than not, learning comes through repeated experiences
            that build upon past experiences. The scaffolding, modeling, and language support you provide as the teacher
            is invaluable to each child’s development. To provide the best scaffolding and ability for children to reach
            their full potential, you must understand the standards your program uses well. Often, standards are known
            as child outcomes or Early Learning and Development Guidelines (ELDG), and these terms are interchangeable
            in this guide. These will vary from state to state, or even by program funding (i.e., state PreK or Head
            Start), but these documents have many commonalities. Visit the Resources section to view additional support
            and links.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Role-of-Assessment / Child-Level Assessment -->
      <ng-container *ngIf="contentKey == 'child_level'">
        <div class="body">

          <p>Purpose and Big Picture:</p>

          <p>Ongoing assessment is an essential component of quality early childhood programs. When teachers have
            detailed knowledge about their student's developmental skill levels, they can more effectively scaffold or
            differentiate learning. Progress monitoring and assessment, ongoing throughout the year, offer a way to see
            all aspects of the child’s and class’ developmental and skill growth throughout the year. </p>

          <p>You will have an individual relationship with each child, yet it is important to not let that relationship
            sway your data collection when assessing or using progress monitoring. Remain neutral, with a clear lens.
            Later, after data collection, you will interpret the results to understand what you have collected.</p>

          <p>Types of Assessment and Progress Monitoring:</p>

          <ul class="list">
            Progress monitoring assessments:
            <li>Anecdotal Notes - Narrative writing about exactly what is seen as a child is playing or working. </li>
            <li>Checklists - A means of noting if a child has completed something, or has a level of skill (e.g., not
              yet, progressing, mastered)</li>
            <li>Screenings - provide a "developmental snapshot" of the child, based upon defined standards. They yield
              information that helps determine individualized learning objectives and the need for formal evaluation
            </li>
            <li>Work Samples - A child’s work, dated, related to some aspect of development or a standard. Work Samples
              are sometimes called artifacts, such as a piece of artwork, a photograph of a block structure, a picture,
              or an audio or video clip.</li>
          </ul>

          <p>Using Child-Level Assessment Results:</p>

          <p>
            Sometimes, various assessments are required to satisfy a supervisor or a funding source. Regardless of the
            “why” - when data - any sort of assessment or progress monitoring - is collected on a child, you have an
            ethical responsibility to maintain confidentiality of individual results AND to use that information to
            benefit the child. It is important to look at various (multiple) data points to see what story the data
            tells. This is often called triangulating data. Is the child progressing as expected, experiencing
            unexpected challenges in one area or another? We do not want to depend upon one data point, as it may or may
            not be an accurate depiction of the child.
          </p>

          <p>
            It is important to look at individual child results AND aggregate results (look at the entire class) too.
            This will help you understand where the class is as a whole, which children may need more support in small
            groups, or if there is a child struggling that may need a different look to understand them better.
          </p>

          <p>
            These results should be intentionally used as a part of planning. If reviewing and interpreting data is new
            to you, don’t worry. Everyone must start somewhere. Practice makes permanence. Ask your director or a more
            experienced staff person for support too. This is also an advantage of planning in collaboration with other
            teachers because this opportunity can be a place where assessment results are discussed, confidentially, to
            increase understanding.
          </p>


          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p>Learning Beyond Paper Progress Monitoring:</p>

          <p>
            The LBP Progress Monitoring Tool should be used as described in the Professional Development to understand
            how children are growing and learning in your classroom. We always want to look at children with a
            strengths-based perspective - what they CAN do, where their strengths ARE - and build from this. If a child
            doesn’t have a skill, we frame it as “not yet” - indicating a positive frame of reference (“You can’t do it
            yet, but you’ll get it. We are going to work on it”).
          </p>

          <p>Ages and Stages Questionnaires (ASQ):</p>

          <p>caregiver - or both. For infants and toddlers, ASQ-3 is available for children of the following age in
            months: 2, 4, 6, 8, 9, 10, 12, 14, 16, 18, 20, 22, 24, 27, 30, 33, and 36. The ASQ-3 is currently available
            in English, Spanish, Arabic, Chinese, French, and Vietnamese.</p>

          <p>
            A second, dedicated screening tool, Ages and Stages Questionnaires: Social-Emotional, Second Edition
            (ASQ:SE-2) is available to better understand a child’s social-emotional development in the areas of
            self-regulation, compliance, social-communication, adaptive functioning, autonomy, affect, and interaction
            with people. The ASQ:SE-2 is completed by a parent. For infants and toddlers, the ASQ:SE-2 is available
            based on the following months of age: 2, 6, 12, 18, 24, 30, and 36. It is available in English, Arabic,
            French, and Spanish. It is rated by the parent as “often or always,” “sometimes,” or “rarely or never.”
          </p>

          <p>
            Both tools can be used as a paper version or online. The tools have been rigorously tested and can help
            identify a child who may be at risk for developmental delays.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Role-of-Assessment / Introduction -->
      <ng-container *ngIf="contentKey == 'ra_introduction'">
        <div class="body">

          <p>Assessment is an organized way of collecting and measuring information about a particular topic. It plays
            an integral role in the classroom. Depending on the type utilized, it can yield data that: informs
            individualized learning goals, shapes classroom instruction, signals the need for further evaluation, or
            provides suggestions for classroom-wide improvement.</p>
          <p>Teaching young children requires a lot of precision. Knowing what to do, when to do it, and generally how
            to put the pieces together in a way that makes each day full of meaning and opportunity, learning within an
            emotionally safe space. Teaching is both an art and a craft that can be further honed by continuous learning
            and reflection cycles.</p>
          <p>You may be fortunate enough to have an early childhood coach - either as a separate role or as a part of a
            position that a director may play. Unlike a sports team or an orchestra conductor, most of your work will
            not happen in the presence of a coach. There are tools to help you reflect on practices good teachers
            develop to make children’s learning in your classroom the best experience possible. </p>
          <p>There are also means to look at quality at a program-wide level. Tools that look at an entire program are
            also crucial because greater consistency and quality across all classrooms usually means a better experience
            for children and families. It can say that the workplace’s quality is high, and employee satisfaction is
            essential to further opportunities and keep valued staff working together and moving in the same direction.
          </p>
          <p>Depending on any federal, state, or local funding your program receives, you or your program may be
            required to use specific tools as a part of your requirements. Even if required, it is crucial to go beyond
            the mindset of “I completed this requirement.” and use both the tool and the data gathered responsibly. Any
            time that data is collected, you have an ethical responsibility to children to use that data (information)
            to help improve yourself and the program for the benefit of children’s learning and development.</p>
          <p>Included are some common classroom-level and program-level assessments but note this list may not be
            exhaustive.

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Role-of-Assessment/ Classroom Level -->
      <ng-container *ngIf="contentKey == 'classroom_level'">
        <div class="body">

          <p>CLASS (Classroom Assessment Scoring System) Tool:</p>

          <p>The CLASS tool measures caregiver interactions from birth through 12th grade. There are different
            iterations for various age groups. A caregiver can be in either a home or classroom setting and denotes the
            process of teachers and caregivers interacting with children, separate from the content taught.</p>
          <p>CLASS is essential because it looks at the experienced interactions a child may have throughout the day
            with all staff in the classroom - it measures the “how” teachers are going about their practice rather than
            “what” they are teaching. Children learn from those they have a relationship connection to, which amplifies
            why the “how” is very important to children’s learning. Relationships are the heart of successful
            interactions that lead to positive development and learning for children.</p>
          <p>Using the CLASS tool to understand how well a classroom is interacting, and to drive teachers to improve
            their interactions, can be an essential step in making a good preschool classroom GREAT. For children,
            teachers who promote interactions can make each day better for young children’s learning. Higher quality
            interactions promote greater readiness for kindergarten and better success down the road - fewer repeated
            grades, higher graduation rates, and more likely to attend college.</p>
          <p>The Pre-K CLASS tool is designed for use in preschool classrooms with 3- and 4-year-olds. Organized around
            three main areas called domains: emotional/behavioral support, classroom organization, and instructional
            support, each domain has various dimensions that include items looking more precisely at teacher
            interactions with young children. Each item (42 total), dimension, and the overall score is on a scale from
            1 to 7, with scores in the 1-2 range as low, scores in the 3-5 range as middle, and scores of 6-7 as high.
          </p>
          <p>Certain domains and dimensions are easier to score higher on, while others are more challenging to score
            high on. </p>

          <p>Getting Started with CLASS:</p>

          <p>Your classroom can get started by having a Pre-K CLASS observer come to complete an assessment. The
            Director often organizes observations, as often a program will have every classroom observed. The assessment
            will typically take a couple of hours, with the observer carefully watching teachers interact with children
            throughout the day’s routine and taking some breaks from observation. The observer will take notes while
            trying to remain neutral (not interacting) with children and teachers. </p>
          <p>If you have not taken part in observations much, it can feel a bit nerve-wracking, but try your best to go
            about your day as naturally as possible. Be yourself. The observer is not looking for perfection from you or
            your children. Conversely, the observer is looking at how you handle the day and what occurs in a preschool
            classroom. Your children will notice and react or interact differently than you usually do.</p>

          <p>Receiving Your Pre-K CLASS Results:</p>

          <p> The CLASS observation will generate a rating for each dimension and an overall Pre-K CLASS score for the
            classroom. If the classroom has more than one teacher, the rating is based on all teachers, as children
            experience interactions from all teachers in the classroom.</p>
          <p> Regardless of the classroom’s rating, it is essential to note that having a starting point - a baseline -
            is vital to growth. CLASS is about observed practice, not your value. Do not take it personally, even though
            it can feel personal because teaching is a very personal calling. </p>
          <p> You are on a path to improving - all professionals should continue to improve. You need to know where you
            are so that you can make a plan for where you would like to go - where you would like to grow.</p>
          <p> Remember, there are some domains and dimensions that are easier to score higher on, while others are
            challenging to score high on. Most importantly, though, some small improvements to interactions can have
            grand changes in children’s outcomes. Keep focusing on how to improve your “how” by taking part in training
            such as MMCI (Making the Most of Childhood Interactions), or other opportunities available in person or
            online from Teachstone-approved trainers.</p>
          <p> For more information and purchase of materials and professional development, visit the Resources section.
          </p>

          <p>Environmental Rating Scales:</p>

          <p>Environmental rating scales look at the quality of a group program for a particular age of children. The
            Early Childhood Environmental Rating Scale(R), Third Edition (ECERS-3) is a tool to assess preschool
            classrooms (ages 3-5). </p>
          <p>Like CLASS, ECERS-3 is observational and uses a trained observer. The ECERS-3 includes six areas -
            subscales that look at a total of 35 items. The subscales are space and furnishings, personal care routines,
            language, and literacy, learning activities, interaction, and program structure.</p>
          <p>The results of the ECERS-3 can give a teacher information about how to improve their program to benefit
            children’s experience. It can also shed light for a director to better understand the classroom environment
            and workplace characteristics (e.g., dedicated break room or planning time away from children) that are
            important to staff longevity. </p>
          <p>There are ERS for infant and toddler classrooms, school-age programs, and family childcare homes. Some
            programs use ERS and CLASS in combination to have a multifocal lens on their program’s quality. More
            information about environmental rating scales, including the purchase of materials and professional
            development, can be found here within the Resources section. </p>

          <p class="epigraph">
            *Visit the Resources section for additional information on child-level assessments.
          </p>
        </div>
      </ng-container>

      <!-- Role-of-Assessment / Program Accreditation -->
      <ng-container *ngIf="contentKey == 'accreditation'">
        <div class="body">

          <p>
            Early Childhood Program Accreditation is a way for your program to self-reflect on practices. When ready, an
            outside entity (reviewer(s)) will evaluate the quality of your program based on its criteria. If your
            program selects a reputable accreditation, it may attract families and high-quality staff. Program
            accreditation may also be used in your state to determine funding levels or other criteria related to a
            local, state, or federal funding stream. If so, the funding stream usually determines the accreditation
            bodies that it accepts, so be sure the program accreditation your program seeks is on the approved list, if
            such a list exists.
          </p>

          <p>NAEYC Early Learning Program Accreditation:</p>

          <p>The NAEYC program accreditation has been around for more than 30 years. There are four stages: enroll and
            self-study, apply and self-assess, candidate for the site visit, and maintain accreditation. View the
            Resources section to learn more information on NAEYC’s program accreditation. </p>

          <p>Accredited Professional Preschool Learning Environment (APPLE):</p>

          <p>
            APPLE accreditation has existed for more than 20 years and focuses on balancing quality standards and the
            financial impact of implementing quality standards. There are five steps: enroll, self-study, on-site
            verification, accreditation, and accreditation continuance.
          </p>

          <p class="epigraph">
            *Visit the Recourses section for more information on Accreditation.
          </p>
        </div>
      </ng-container>

      <!-- Role-of-Assessment / Program Assessment -->
      <ng-container *ngIf="contentKey == 'program_level'">
        <div class="body">

          <p>
            Program-level assessments are important to program-wide reflection and change, viewing cross-classroom
            inconsistencies, and gathering information from varying sources. A program-level assessment usually follows
            a periodic basis (e.g., annual, semi-annual) as a way to take a pulse of the program. Some program-level
            assessments are used in conjunction with one another, or with classroom-level assessments to give a complete
            picture of how the program as a whole and parts are functioning.
          </p>

          <p>Early Education Essential Survey:</p>

          <p>
            The Early Education Essentials Survey, developed by the Ounce of Prevention Fund with the University of
            Chicago, is a newer tool developed to look at organizational conditions needed to grow quality to a level
            where the children benefit at the levels research shows are possible. Modeled after an elementary-age tool
            initially designed around the research on school improvement, the Early Education Essential Survey documents
            strong research
          </p>

          <p>
            This tool surveys teachers/staff as well as parents. The surveys, conducted with the University of Chicago,
            measure organizational conditions around six components or relational trust: effective instructional
            leaders, collaborative teachers, involved families, supportive environment, ambitious instruction, and
            parent voice. The resulting reports and data-use and improvement tools to make plans for change and
            improvement over time.
          </p>

          <p>
            More information about the Early Education Essentials in the Resources Section.
          </p>

          <p>Program Quality Assessments (PQAs):</p>

          <p>
            The Program Quality Assessment (PQA), developed by High Scope, has several iterations (i.e., infant-toddler,
            preschool, family childcare). PQAs have seven domains to review program quality and teacher strengths:
            learning environment, daily routine, adult-child interaction, curriculum planning and assessment, parent
            involvement and family services, staff qualifications and development, and program management.
          </p>

          <p class="epigraph">
            *Visit the Recourses section for more information on Program Level Assessment
          </p>

        </div>
      </ng-container>

      <!-- Family-Engagement / Authenticity -->
      <ng-container *ngIf="contentKey == 'authenticity'">
        <div class="body">

          <p>Partnering with families with authenticity is both a disposition and a belief. As a disposition, you are
            intentional with strategies to connect with families, and believe they are a partner who brings value to
            your work and into the life of their preschooler. When authenticity is a belief, you will have a true desire
            to partner with the family. </p>
          <p>For a teacher, being authentic means you continually work to build and maintain trust in the partnership
            and a desire to provide a quality program for the child in a safe, nurturing environment where the
            child’s/family’s needs and interests are priority. Relationships have their ups and downs and partnering
            with families is no different. As an authentic and intentional partner, you strive to maintain
            relationships, make changes as needed, and agree with the family to keep the child as the focus. The
            stronger your connection is with a family, the easier it is to have conversations when the topic is not as
            light-hearted - such as if there are developmental concerns or anything a family may be more sensitive
            about. </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Family-Engagement / Beginning Program -->
      <ng-container *ngIf="contentKey == 'beginning'">
        <div class="body">

          <p>The family is the key to building a relationship with children in your preschool classroom. We value the
            family as the child’s first teacher. The child’s family has the information needed to provide a safe,
            nurturing, learning environment where the child’s individual needs are met. </p>
          <p>Engaging families builds a partnership where there is trust, respect, and a common goal of positive
            communication to meet the child’s needs. As you learn more and more about the child and family, the
            relationship becomes stronger and positively supports the child’s growth and development. </p>
          <p>Family engagement starts with an understanding that families are diverse and interactions and opportunities
            for involvement need to support individual family choices. </p>
          <p> A partnership is when each party has the opportunity to be involved in decision making when asking for a
            commitment of time and involvement in program activities.</p>
          <p> Visit the Resources section to view NAEYC Advancing Equity Recommendations for Early Childhood Educators.
          </p>

          <p><strong>Beginning the Year:</strong></p>

          <p>Starting the year on the right foot by focusing on building the relationship. This begins when the child
            joins your program, regardless of the time of year. It also occurs when the child transitions to a new
            classroom. They may have familiarity with the program, but each new set of teachers must develop or
            strengthen their connections with families. Getting to know each child’s personality and strengths with the
            support of their family helps you plan better, because you can know the child, their interests, and what
            their family knows about how to work with their preschooler best.</p>
          <p>Teachers need to develop their plan, with guidance from the program’s leadership, and make it their own.
            One valuable strategy that many programs use is to have consistency in activities, such as open houses and
            conferences, sending consistent messaging to families about the class’ activities, or daily processes - and
            also room for teachers to individualize their approach within the classroom. This way, there is some
            consistency for families as children advance from class to class, or for families who have children in
            multiple rooms - and opportunity for an individual teacher personalization.</p>

          <p><strong>Connecting Strategies:</strong></p>

          <ul class="list">
            Here are some common, positive strategies to building a positive connection with the families of the
            children you teach.
            <li>Send a Welcome letter to the family prior to the beginning of the program. </li>
            <li>Schedule a time to meet with the family to find out about their child’s daily routines and interests.
            </li>
            <li>If you do not speak the families’ language check with your Director to request a translator. </li>
            <li>Ask families their preferred name and how to pronounce (if needed).</li>
            <li>Express your goal of wishing to be a partner in their child’s learning experiences. </li>
            <li>Ask open -ended questions to learn more about the child’s schedule, needs, and interests.</li>
            <li>Ask families their preferred method of communication to ensure home and program information is shared
              daily at the beginning and end of the day.</li>
            <li>Introduce the family to all adults who are assigned to their child’s room. </li>
            <li>Post pictures of room staff and provide a short biography.</li>
            <li>Ask the family for a picture to display on a family board. </li>
            <li>Smile and make eye contact as you greet the family by name. </li>
            <li>At the end of each day share something positive about the child that happened during the day.</li>
            <li>Remind families they are always welcome to visit. </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Family-Engagement / Languages -->
      <ng-container *ngIf="contentKey == 'languages'">
        <div class="body">

          <p> Developing a partnership with families requires an ability for the teacher and family to listen,
            comprehend and respond with one another. You may or may not speak the language(s) of the children and
            families you work with. Even if you do not, you can be open to connecting with the family, even though
            additional strategies and effort will be required on both ends. </p>
          <p> When there is a language barrier there needs to be accommodations to ensure lines of communication are
            open in both directions. The challenge is that you speak a different language than the family, not that they
            do not speak your language - an important perspective, especially if you speak the dominant language and the
            family does not. For example, you can say, “I speak English, and the family speaks a Mayan dialect,” rather
            than “They don’t speak English.” This reframes the challenge as a need to find ways of communicating rather
            than a dominance for one language over another.</p>
          <p> The United States is unique in that there is not a national language, and in the past many decades, the
            understanding of keeping home or cultural language, while learning the language(s) in the geographic area
            you reside in both have value. Home language is a part of a child’s roots - a connection to the family you
            see as well as their ancestors. </p>


          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p><u>Language-Related Strategies</u></p>

          <ul class="list">
            <li>Collaborate with agencies serving families who may not speak the dominant language to access resources
              for yourself as you begin your journey to develop a partnership demonstrating an authentic interest in
              learning about each family’s culture. Sometimes, these agencies offer translation services.</li>
            <li>Demonstrate authenticity by creating an environment where families/children see a reflection of
              themselves through pictures, toys, books, learning experiences.</li>
            <li>Begin to learn phrases of greeting in the family’s language and each day make a point to connect with
              the family for an informal chat. </li>
            <li>If you do not speak the child’s language, find out some important basic words, such as hello,
              hungry/eat, thirsty/drink, bathroom, outside, inside, so that you can understand the child’s basic needs.
              Write the words/phrases phonetically (how they sound) so that you can practice using them.</li>
            <li>Use visuals to aid communicating and speak slower than normal pace if a family member understands some
              of your language. As someone is learning another language, it may be understandable to them if at a slower
              pace. </li>
            <li>Get suggestions from families of music you can play during greeting time that they find welcoming.</li>
            <li>Post signs in languages of the families you serve.</li>
            <li>Translate forms used in your classroom into languages of families you serve.</li>
            <li>Work with your program administrator to have a staff person who speaks a common language with families
              or a translator available for parent meetings, or even better, each day at drop off and pick-up times, in
              order to ensure clear and timely communication. If your program has many different languages represented,
              this becomes a bit more challenging, but it is important to coordinate with intentionality and shows an
              effort to create equity.</li>
            <li>Do not give up. Be open to continuing to find better and better solutions, learning more, and keeping
              the child’s growth and development needs at the heart of things. Your intentional effort is likely to be
              recognized by the family, even if there are challenges or misunderstandings that may occur.</li>

          </ul>

          <p class="epigraph">
            *Visit the Resources section for additional support on Languages
          </p>
        </div>
      </ng-container>

      <!-- Family-Engagement / Partnering -->
      <ng-container *ngIf="contentKey == 'partnering'">
        <div class="body">

          <p>The following are strategies for partnering with families in an authentic and genuine way, particularly
            when you need to communicate with them about their preschooler:</p>

          <ul class="list">
            <li>When communicating with families, make a list of points you wish to cover. </li>
            <li>Introduce yourself and share the purpose of the communication. Is it to share information (more one-way)
              or discuss a concern?</li>
            <li>Begin your sentences with genuine positive points about their child and use “I” statements such as “I
              have noticed”, “I am wondering”, “I am concerned”, this is less likely to cause defensiveness. </li>
            <li>Be sure to reinforce that you recognize the family is their child’s first teacher and as an educator the
              knowledge they can provide is important to the child’s progress. </li>
            <li>Listen first, maintain eye contact, and respond by restating what you believe was shared. </li>
            <li>Invite families to take part in decision making when discussing a concern. </li>
            <li>Send a follow-up email thanking the family for taking the time to meet with you summarizing decisions
              which may have been made. </li>
            <li>Reflect weekly on the ways you have reached out to families in building a positive relationship.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Family-Engagement / Understanding Perspectives -->
      <ng-container *ngIf="contentKey == 'perspectives'">
        <div class="body">

          <p>
            Developing a positive relationship with families starts with empathy. Consider the perspective of a family
            who is dropping off their child for the first time trusting they will be in a safe, nurturing environment.
            Additionally, family perspectives come from the parent(s) and their unique experiences related to where and
            how they were raised, including cultural and religious beliefs. Sometimes, it is easy to connect with
            families who are similar to you in one way or another, but ALL families and children you work with deserve
            understanding and differences in perspectives is healthy. Remember that as a professional, you have an
            obligation to work well with children’s families as the child’s first - and most important - teacher.
          </p>

          <p>
            What is empathy? Here are two definitions related to the early childhood education profession:
          </p>

          <p>
            “Empathy is the ability to feel what the child or family member is feeling, understand what the child or
            family member is feeling, communicate that understanding to them, and then respond in ways that meet their
            needs.” <br>
            -Peck, Maude, Brotherson
          </p>

          <p>“Empathy also helps us understand people whose values, views, and behaviors are different from our own.”
            <br>
            -Calloway-Thomas
          </p>

          <p><strong>Perspective-Building Strategies:</strong></p>

          <ul class="list">
            <li>Practice “reading” non-verbal messages from families, with the understanding that non-verbal messages
              can be different from region to region or based on cultural background.</li>
            <li>Get to know your own non-verbal messages. Sometimes, if what we hear is not similar to our own
              experience, our faces or body reacts in a way that sends a message of non-acceptance or discomfort.
              Practice keeping neutral or positive non-verbal messaging, so that there is not unintentional judgement
              felt by a family.</li>
            <li>Invite families to share information about themselves and their child by asking them to complete a
              survey. Ask your Director for assistance in developing a survey if the program does not presently have
              one.</li>
            <li>Ask open-ended questions to provide the family with the opportunity to choose what they wish to share
              about their family structure, culture, interests, hobbies. </li>
            <li>Create a welcoming atmosphere for everyone and celebrate who they are - similarities and differences.
            </li>
            <li>Consider families as a partner in decision making as they know their child’s strengths and needs. </li>
            <li>A list of community agencies might be helpful to connect families to resources when families express a
              need. </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Using-Learning-Beyond /  Getting Started  -->
      <ng-container *ngIf="contentKey == 'start'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Using-Learning-Beyond /  Finding What You Need -->
      <ng-container *ngIf="contentKey == 'finding'">
        <div class="body">
           <div class="video_block">
              <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
                <video playsinline src="{{ resources.resource_1m }}">
                </video>
                <div class="triangle"></div>
              </div>
              </div>
        </div>
      </ng-container>

      <!--Teacher Interactions  / Webinar -->
      <ng-container *ngIf="contentKey == 'ti_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Webinar -->
      <ng-container *ngIf="contentKey == 'ce_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Classroom Structure / Webinar -->
      <ng-container *ngIf="contentKey == 'cs_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Learning Experiences / Webinar -->
      <ng-container *ngIf="contentKey == 'le_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Role of Assessments / Webinar -->
      <ng-container *ngIf="contentKey == 'ra_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Family Engagement / Webinar -->
      <ng-container *ngIf="contentKey == 'fe_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>

    <div *ngIf="age == 'ps' || age == 'pk'" class="table">
      <div class="header">
        <p>
          <span *ngIf="sectionTitle != ''">{{ sectionTitle | translate }}</span>
          <span *ngIf="sectionTitle != '' && modeTitle != ''"> | </span>
          <span *ngIf="modeTitle != ''">{{ modeTitle | translate }}</span>
        </p>
      </div>

      <!--Characteristics of Preschoolers-->
      <ng-container *ngIf="contentKey == 'ch'">
        <div class="body">
          <p>
            Preschoolers, 3- and 4-year-olds, offer a bright, exciting livelihood to the preschool
            classroom. Preschoolers are developing independence, which can be frustrating and
            exhilarating. This independence doesn’t occur without opportunity and practice, and
            you play a critical role in the growing freedom and success that preschoolers in your
            class feel.
          </p>

          <p>
            Preschoolers tend to be ego-centric, but developmentally, they are open to learning
            about others - compassion - and how they impact other people and things - empathy -
            and how other people affect them. You need to find ways to remain in control of the
            classroom while giving preschoolers many opportunities to hold power. Understanding
            the general characteristics of preschoolers can help you to find ways to share power
            with children.
          </p>

          <div class="content mix">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <ul class="list">
            In general, preschoolers:
            <li>Like to complete self-care activities by themselves and want to help with classroom jobs. They learn the
              satisfaction of completing something by themselves.</li>
            <li>Learn all about their world and how it works. They will ask "why" frequently.</li>
            <li>Practice being a friend to other children their age.</li>
            <li>Put their rapidly-growing motor skills to practical use, learning complex movements such as hopping,
              skipping, and climbing - and for many - near-constant action.</li>
            <li>Develop speech pronunciation during this time, and practice talking and interacting with others plays a
              huge part.</li>
            <li>Understand the difference between right and wrong.</li>
            <li>Start to learn the difference between reality and fantasy.</li>
            <li>Display new fears, especially when unexpected things happen. Predictability is essential to preschoolers
              feeling confident and secure.</li>
            <li>Learn to follow simple rules in the games such as Duck, Duck, Goose. They have a difficult time not
              winning and may have a hard time when they don’t win every time.</li>
            <li>Are heavily influenced by what they experience. Modeling is a powerful tool.</li>
            <li>Learn with their whole bodies, and most love to taste, touch, move, explore, smell, watch, and wonder.
            </li>
            <li>Begin to develop literacy skills. They learn to write letters - usually those from their name first.
              They begin to recognize alphabet letters,
              first being those from their name.
              They also become skilled at rote alphabet knowledge and counting.
              That is, singing the alphabet song, reciting numbers in order (more or less), although neither may be in
              response to specific letters or counting actual objects.</li>
            <li>Look up to adults - you as a teacher and family members - and seek approval and acknowledgment. When you
              ask a child a question,
              a preschooler will try to give what they believe to be the correct answer and understand why the response
              may be right.</li>
            <li>Learn from repetitive activities, such as songs, books, and oral storie/s.
              They request favorites repeatedly, and they gain different levels of understanding through repeated
              exposure and experience.</li>
            <li>Show innate curiosity, and don’t want to miss out on things. This behavior can look like
              distractibility.</li>
            <li>Struggle to understand another’s perspective or emotions.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!--21st Century Learners-->
      <ng-container *ngIf="contentKey == 'cl'">
        <div class="body">

          <p class="epigraph">“You can’t teach about things that don’t exist yet, but you can teach someone to learn how
            to learn.“ <br>- Leo SaLemi</p>

          <p>
            Did you know that some experts identify that 85% of the jobs that will exist in 2030 have not been
            invented yet? What does that mean for us as early childhood educators? We need to think of the young
            children we work with as global learners who will need some more universal tools we know, such as
            collaboration, critical thinking, communication, and creativity, as well as those they will have to develop
            as they journey further in their life. These universal tools are sometimes called the Four C’s of 21st
            Century Learning. While changes will be rapid over time, these more universal tools are constant and can
            help children handle things that may come their way.
          </p>

          <p>
            The children we work with today are children of the world, an interconnected world that we as adults did
            not experience at an early age. While concrete experiences with real three-dimensional objects, actual
            in-person relationships, and authentic problems to solve are of the most critical importance, children of
            today have access to tools and technology that we didn’t have when even sending people to the moon.
            Technology itself is neither good nor bad, but how we use these tools as tools to progress the 4 C’s could
            not
            be more important.
          </p>

          <p>
            Teaching good problem-solving, being a critical thinking, will be important in solving new problems that
            our generations could not even dream of. People will be called to work together, collaboratively, to
            design greener spaces, determine how to best govern ourselves, and that takes a great deal of
            communication. It takes time and practice to understand verbal and non-verbal communication - and
            multiple languages, across cultures. But what excitement is there to be found in this new frontier.
          </p>

          <p>
            The Learning Beyond Paper (LBP) Curriculum focuses on HOW to learn as well as teaching content based
            on developing all areas of developing in themes. Think of it as weaving threads within a fabric that can be
            shaped into a shirt, a hat, or a fancy dress - it can become anything because of the fabric (universal
            tools).
          </p>

          <div class="content mix">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!--Introduction-->
      <ng-container *ngIf="contentKey == 'in'">
        <div class="body">
          <p>
            Learning Beyond Paper is a social-constructivist curriculum. Young Children are inherently social
            beings. As such, learning is most robust when children engage with peers and adults around
            activities where they are actively engaged. Children construct knowledge bridged upon what
            they already know, but it is that social, relationship-based connection that acts like glue -
            cementing understanding and seeking more.
          </p>

          <div class="content mix">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!--Role of the Teacher-->
      <ng-container *ngIf="contentKey == 'ro'">
        <div class="body">
          <ul class="list">
            <li>Recognize each child is an individual with different life experiences</li>
            <li>Understand that families are the child’s first teacher</li>
            <li>Establish relationships to engage the family in the child’s learning journey</li>
            <li>Observe and use informal and formal assessment tools to guide the development of
              learning opportunities to scaffold each child’s learning path</li>
            <li>Consider every minute of every day as a learning opportunity</li>
            <li>Organize, plan, and use reflection as a part of daily practice</li>
            <li>Remain curious and motivated as a teacher to continue Professional Development by
              attending conferences and workshops and sharing with colleagues (including online groups and forums)</li>
            <li>Recognize that research guides practice and acknowledge that the “this is the way it has
              always been done” mentality is not a reason to do something</li>
            <li>Ensure that each choice made demonstrates knowledge of child development and developmentally appropriate
              practice</li>
          </ul>

          <div class="content mix">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Teacher Interactions / Inclusion-->
      <ng-container *ngIf="contentKey == 'inclusion'">
        <div class="body">

          <p>
            Inclusion involves more than merely having children with disabilities in your classroom.
            The practice consists of providing a nurturing environment for them to make meaningful connections
            with typically developing peers as they play, learn, and grow. The benefits of inclusion for children
            with disabilities have been well documented and include increased outcomes in language, communication,
            and social skills. Typically developing peers are provided with daily opportunities to practice empathy,
            compassion, and friendship skills. While some children with disabilities may require significant
            accommodations
            to a particular lesson or even the classroom environment, many will benefit from simple strategies and
            supports.
            Making the accommodations necessary to maximize each child’s potential for a successful and enjoyable
            classroom
            experience is what inclusion is all about. Children of all ability levels learn best in developmentally
            appropriate
            environments that offer the support necessary to interact with their peers in meaningful ways. You may find
            that
            many of the children in your classroom will enjoy and benefit from the strategies you use to support a child
            with a disability.
            Visit the Resources section for additional support on Inclusion.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <ul class="list">
            <p class="list-title">Tips for Successfully Including Children with Special Needs</p>
            <li>Use positive, “People First” language. For instance, if it is necessary to refer to a child’s
              disability, say, “child with a visual impairment” rather than “visually impaired child” or “blind child.”
              If it is not necessary to address their disability, simply use the child’s name.</li>
            <li>Communicate frequently with families (e.g., daily texts, emails, photographs with short narratives, or a
              “school-home notebook”).</li>
            <li>Ask permission to exchange information with therapists (e.g., speech and language, occupational,
              physical) and identify helpful strategies.</li>
            <li>Learn about specific disabilities through families, therapists, and other reputable sources (see
              resources).</li>
            <li>Use specialized equipment and assistive technology when appropriate.</li>
            <li>Intentionally select materials and equipment to match children’s varying interests and ability levels.
            </li>
            <li>Help a child accomplish a complicated task by breaking it into smaller parts and providing pictures of
              each step.</li>
            <li>Take every opportunity to maximize a child’s chance of success.</li>
            <li>Provide books, posters, and materials that portray individuals with disabilities as strong and capable.
            </li>
            <li>Use verbal, visual, and physical cues to provide clear directions and expectations.</li>
            <li>Enhance oral language with pictures, symbols, props, and American Sign Language.</li>
            <li>Employ “First-Then” statements (“First put the blocks away, then you may choose another center.”).</li>
            <li>Create a picture schedule that reflects daily activities and refer to it throughout the day.</li>
            <li>Assist children with transitions by preparing them ahead of time and telling them what to expect.</li>
            <li>Teach typically developing peers to initiate interactions with peers.</li>
            <li>Provide a small object (“fidget”) for the child to hold during group time.</li>
            <li>Plan for, and support, peer interactions.</li>
            <li>Provide play materials and activities designed for two or more children.</li>
            <li>Be creative and remember that learning should be fun!</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Teacher Interactions / Intentionality-->
      <ng-container *ngIf="contentKey == 'intentionality'">
        <div class="body">

          <p>
            Without a plan, it is hard to know where to start or where you may end up.
            Teaching young children requires intentionality, a meaningful plan grounded in child development,
            and social constructivism.
            ntentionality describes a mindset where you, as the teacher, create meaning - the why - behind
            all instructional decisions.
          </p>

          <p>
            Intentionality means that each activity, interaction, selected materials, or learning
            center has had deliberate thought put into it and matches what is most meaningful for young
            children’s learning and development. This could be in room arrangement, intentionally placing
            furniture to prevent an area for running, or a small group, planning carefully for three children’s
            learning needs in today’s group. Intentionality is also explaining the reasoning behind decisions to others
            - children,
            teachers, parents - to grow child development understanding and collaboration that benefits children.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <ul class="list">
            As an intentional teacher, you create a nurturing environment for young children by:
            <li>Communicating daily with children in positive and respectful tones</li>
            <li>Praising children and acknowledging their efforts using kind affirmative words</li>
            <li>Encouraging and motivating children to do more and to continue trying. </li>
            <li>Using words which inspire curiosity, creativity, and confidence</li>
            <li>Modeling practical conversational skills of listening and speaking and encouraging children to do the
              same</li>
            <li>Building positive relationships through immediate and appropriate responses to children's physical and
              emotional needs</li>
            <li>Encouraging children to express themselves freely and foster thinking through open-ended questions</li>
            <li>Demonstrating enthusiasm and respect by engaging with children —not just for behavior or academic
              interventions</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Teacher Interactions / Differentiated instruction-->
      <ng-container *ngIf="contentKey == 'instruction'">
        <div class="body">

          <p>
            Successful teachers recognize that all students come to the classroom with different strengths,
            interests, and experiences. Teachers must differentiate their instruction to address their student's
            unique learning styles and personalities. Tailoring lessons and activities to the diverse characteristics
            of each learner increases student engagement. You can create a supportive environment that maximizes
            learning opportunities for all children by adjusting activities, goals, groupings, and materials.
            Your preschool classroom may include three, four, or five-year-olds depending upon the time of year
            and if your program uses multi-age grouping. Even within 12 months (e.g., children who are three years,
            0 months through 3 years, 11 months, 29 days), there are considerable variances in development, experience,
            and skills. Differentiated Instruction is essential to truly meet children’s needs and help them continue
            growing.
            Differentiation does not mean teaching different things,
            but simply adjusting the level of complexity - based on the individual children.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p> <strong>How it Works:</strong> </p>

          <p>
            Consider the foundational skills needed for a child to understand a new concept.
            For example, before a child can create an AB pattern, he or she must understand the
            concept of classification and determine like and different objects. This skill allows
            the child to distinguish a pattern from a non- pattern. Next, the child must be able to
            copy a simple pattern using objects to duplicate a modeled example. Finally, they must
            demonstrate that they can extend or complete an established pattern by building on.
            Once the child is proficient at these tasks, they may begin to explore activities that
            encourage them to create a simple AB pattern. Because all children will not be ready to
            create AB patterns on the same day, it will be necessary to determine which skills will
            require further practice. Depending on where the child falls on this
            developmental progression, they may benefit from working on the skill in several ways:
          </p>

          <ul class="list">
            <li>Receiving individualized assistance from the teacher.</li>
            <li>Participating in small groups.</li>
            <li>Being paired with a peer whose skills are more advanced.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p>
            Once you have determined which children are ready to move on to creating AB patterns,
            encourage them to choose activities that will support this skill. Beginners will do
            best by creating patterns using color as the differentiating feature. Still, children
            with more experience may use shape, sound,
            or other distinguishing characteristics. A few examples of activities that address this skill are listed
            below:
          </p>

          <ul class="list">
            <li>Invite a child to choose two colors of paint and create an AB pattern on paper.</li>
            <li>Provide beads and string so that a child may create a necklace or bracelet using the pattern. </li>
            <li>Supply various colors of Unifix cubes and challenge a child to create a pattern with them.</li>
            <li>Encourage children to use unit blocks to create a horizontal or vertical pattern (e.g., rectangle,
              square).</li>
            <li>Challenge children to create a pattern using their bodies (e.g., stomp, clap or blink, nod).</li>
            <li>Invite a child to make a pattern using small cars and trucks.</li>
            <li>Ask children to create a pattern using markers and paper (e.g., red line, purple line, or circle,
              square).</li>
            <li>Supply Post-It Notes in two colors or sizes and encourage children to create patterns.</li>
            <li>Provide various instruments so that children may create patterns (e.g., one beat of the drum, one ring
              of the bell). </li>
            <li>Give children opportunities to create snack patterns (e.g., pretzel stick, goldfish cracker or apple
              slice, cheese cube). </li>
          </ul>

          <p>
            Children who have already mastered AB patterns may be encouraged to create ABC or AAB patterns.
            They might also choose to create their own pattern.
          </p>

          <ul class="list">
            <p class="list-title">Tips for Differentiating:</p>
            <li>Activate and assess children's prior knowledge.</li>
            <li>Employ ongoing-informal assessments to determine where students are and what their next step should be.
            </li>
            <li>Know your students and consider their personalities, interests, ability levels, and learning styles.
            </li>
            <li>Describe new concepts in multiple ways.</li>
            <li>Model and demonstrate the new concept.</li>
            <li>Support different learning styles (auditory, visual, tactile, and kinesthetic).</li>
            <li>Select materials that fit the student's interests and developmental needs.</li>
            <li>Develop clear learning goals using standards or curriculum.</li>
            <li>Recognize and provide different levels of support based on individual needs.</li>
            <li>Provide students with choices about how they will investigate a new concept or skill. </li>
            <li>Pair up a child with a learning buddy.</li>
            <li>Provide modeling and assistance, as necessary.</li>
            <li>Offer opportunities for hands-on, multi-sensory practice and exploration.</li>
            <li>Encourage students as they practice new concepts.</li>
            <li>Remember to keep it fun and positive! </li>
          </ul>

          <p class="epigraph">
            *Visit the Resources section for additional tips on Differentiating Instruction in the classroom.
          </p>
        </div>
      </ng-container>

      <!-- Teacher Interactions / Leading with Equity-->
      <ng-container *ngIf="contentKey == 'leading'">
        <div class="body">

          <p>
            Young children’s development in the first five years of life is extraordinary and unparalleled.
            It is during these years when the brain’s development helps set the tone for later learning and ultimately,
            success.
            We know that prevention, by teaching children appropriately and ensuring they meet developmental milestones
            and skill development,
            is much more productive and easier than intervention.
            Also, intervention earlier is more productive than as the child gets older.
          </p>

          <p>
            However, far too few children have access to high quality early childhood experiences,
            whether at home or school, and so far before children, children experience inequity.
            This can be improved best in programs that reflect on how to ensure that children and
            families have equitable access to attend. This may include scholarships or other supports
            and creative solutions. While some of these decisions are outside the realm of the classroom teacher’s
            responsibility,
            teachers have a voice to help shape program policy.
          </p>

          <p>
            Teachers also have a responsibility to continuously build a more equitable classroom.
            Like many other topics, it is hard to ever know enough or do enough. It is a case of
            always working to improve conditions of equity, reflecting on our practice to see where
            we are and where we could go, and building relationships necessary to make equity a
            reality for ALL children. While this can seem incredibly daunting, every step toward
            creating equity, every action and strategy that benefits the children in your classroom,
            can make the life of an individual child that much better. That effect is invaluable.
            Visit the Resources section for additional subjects on Equity.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Teacher Interactions / Interactions-->
      <ng-container *ngIf="contentKey == 'interactions'">
        <div class="body">

          <p>
            Interactions involve how a teacher communicates with and responds to the children in their classroom.
            Because learning is a social process, quality interactions are essential to preschoolers' development
            across all domains. Meaningful interactions stretch preschooler's cognitive abilities and teach them to
            get along well with others. Preschoolers spend a good portion of their day in school,
            and the encounters they have there will shape their attitudes towards future educational experiences.
          </p>

          <p>
            Relationships with caring, responsive adults are at the very foundation of a young child's learning.
            Teachers must provide a consistent and inviting environment that is emotionally supportive of all children.
            Some suggestions for forming the type of
            connections with students that will foster meaningful interactions are listed below:
          </p>


          <ul class="list">
            <li>Get to know your student's backgrounds, temperaments, interests, and preferences.</li>
            <li>Respond to their needs and emotional cues consistently and with sensitivity.</li>
            <li>Validate children's feelings and match their affect.</li>
            <li>Create a joyful atmosphere, smile, and laugh often, make eye contact, and provide physical affection.
            </li>
            <li>Use a warm and welcoming tone of voice and maintain approachable body language.</li>
            <li>Encourage peer interaction and model social problem-solving strategies.</li>
            <li>Find ways to show children they are valuable members of your class, and you enjoy being their teacher.
            </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p>
            The connections you make with preschoolers are essential in providing meaningful
            interactions that will foster cognitive development. In the book, Powerful Interactions:
            How to Connect to Children to Extend their Learning (Dombro, Jablon, & Stetson, 2011),
            the authors discuss a three-step strategy for providing high-quality interactions that
            positively impact young children's growth and development. Teachers are encouraged to
            "be present, connect, and extend learning." Once you have determined a child's interests and established a
            meaningful connection,
            there are several ways to extend learning in the preschool classroom, such as:
          </p>

          <ul class="list">
            <li>Offering hands-on opportunities and encouraging exploration.</li>
            <li>Providing an array of engaging materials that address different interests and learning styles.</li>
            <li>Engaging in conversations with the children as they play.</li>
            <li>Looking for opportunities to stretch their thinking.</li>
            <li>Thoroughly answering their questions using language that they understand.</li>
            <li>Encouraging higher order thinking by asking open-ended questions.</li>
            <li>Assisting children in linking new skills and knowledge to concepts with which they are already familiar.
            </li>
          </ul>

          <p>
            Consistently facilitating intentional, high-quality interactions with preschoolers will lead to increased
            engagement,
            improved child outcomes, and a life-long appreciation for learning.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Teacher Interactions / Scaffolding-->
      <ng-container *ngIf="contentKey == 'scaffolding'">
        <div class="body">

          <p>
            In construction, scaffolding is a temporary structure workers use to access heights and areas that
            are too challenging to reach. In education, scaffolding is the process of providing structured
            support and guidance for thinking and learning. You scaffold through language and social interactions.
            The goal of scaffolding is to offer children enough assistance to be more successful than they would be
            without it.
            You offer children ways to achieve a task through subtle interventions and varying levels of support.
          </p>

          <p>
            A key concept in scaffolding is the Zone of Proximal Development or ZPD, the range of competencies
            that a child can perform with assistance but cannot yet perform independently. Teachers can make
            the most of a child’s ZPD by regularly providing opportunities that stretch the child's existing
            knowledge and skills and providing the scaffolding necessary for success. Your role is to observe
            the child, assess their needs, and provide the support needed to reach the next level. Eventually,
            you remove the support (scaffolding),
            and the child will be able to complete the task independently.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>


          <p><ins>Hints</ins> - Provide just enough information to enhance the child’s understanding without giving away
            the answer.</p>
          <p><ins>Suggestions</ins> - Offer multiple ways to complete a task. </p>
          <p><ins>Modeling</ins> - Demonstrate ways to solve the problem or complete the task.</p>
          <p><ins>Questioning</ins> - Ask open-ended questions such as: “What do you think will happen if…?” Or, “What
            is another way you might…?”</p>
          <p><ins>Providing Feedback</ins> - Offer just enough feedback to move the child to the next level.</p>
          <p><ins>Offering Encouragement</ins> - Inspire the child to continue trying by commenting on their
            persistence, problem-solving abilities, or creativity.</p>


          <p>
            Scaffolding can take place in various settings, using a variety of tools and approaches.
            A teacher may ask how to make the block structure taller, or a peer may explain how to hold
            a crayon sideways to make a leaf rubbing. Scaffolding takes on many forms but always leads
            to the child coming away from experiences with more information than when they started.
          </p>

          <p>
            By understanding what children can achieve independently and what
            they can achieve with assistance from an adult, educators can develop plans
            to teach skills in the most effective manner possible.
            For more information about scaffolding, check out these links:
          </p>

          <p> <strong>Open-Ended Questions:</strong> </p>

          <p>
            One way to scaffold a child’s learning is to ask open-ended questions.
            These inquiries encourage reasoning and creative thinking while helping the child see
            that there are often many solutions to a problem. Open-ended questions have no one right answer,
            require more than a one or two-word response, and encourage reflection, creativity, and prior knowledge.
            They also promote the use of advanced language. Asking children open-ended questions starts
            a conversation and supports brain development. As you implement the lessons provided in this curriculum,
            be sure to make good use of scaffolding and open-ended questioning.
            Visit the Resource section to view examples of age-appropriate open-ended questions.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Introduction -->
      <ng-container *ngIf="contentKey == 'introduction'">
        <div class="body">
          <p>
            The classroom’s physical environment, both indoor and outdoor, set the stage for learning.
            The physical environment sends strong messages to children and adults about what is valued,
            who is valued, and what learning can occur within the spaces designed. As the teacher, your
            mindset is visible in the environment. The type of activities and learning that go on are
            reflective of your mindset. The emotional environment created by the teacher is also important.
            You should look to see that the values between the physical and emotional environment show consistency.
          </p>

          <p>
            The majority of a child’s day is spent in the physical environment you design.
            Design your space in a way that offers children the opportunity to feel comfortable owning the space.
            It is their space as much as yours.
          </p>

          <p>
            No matter how your space currently looks, you must continuously reflect on the
            space and use of space to make updates and changes that provide improvement.
          </p>

          <p>
            When you are in the mindset of a reflective practitioner, nothing is ever indeed “done” or complete.
            While things should run smoothly, there are always opportunities to improve and enhance your program,
            thoughts, and experiences for children for this year’s particular group of children.
          </p>

          <p>
            The terms environment and space mean all indoor AND outdoor spaces children use. Sometimes, people consider
            only indoor space;
            however, outdoor spaces often have more space and opportunities to create than what may be available
            indoors.
            In most parts of the country, outdoor spaces can host a full range of activities, not just gross motor play,
            throughout the school year.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Designing the Indoor and Outdoor Environment -->
      <ng-container *ngIf="contentKey == 'indoor&outdoor'">
        <div class="body">
          <p>
            Here are a few questions to think about as you design your environment, both indoor and outdoor:
          </p>

          <p>
            <strong>Environmental Messages to Children:</strong>
          </p>

          <p>
            What messages do I want to send to children about this space? Does a child say/think/feel this in your
            physical environment?
          </p>

          <ul class="list">
            <li>This space is mine. It is designed just for me as a preschooler.</li>
            <li>My teacher trusts me to use these materials and put them in my reach so I can have access.</li>
            <li>I know where things are, I can access them, and it makes sense to me.</li>
            <li>I am becoming more independent and want to do things for myself. My classroom has everything organized
              so I can use what I need and help take care of the classroom when it is time to clean up. </li>
            <li>I am learning a lot of new things as I become more independent, and my teacher trusts in me.</li>
            <li>I am a competent and capable learner, and my teacher gives experiences to show and grow my learning.
            </li>
            <li>My teacher listens to my classmates and me. Both our spoken words and non-verbal cues send messages
              about how we use the physical environment.</li>
            <li>I feel at home here. This is MY classroom.</li>
          </ul>

          <p>
            <strong>Environmental Messages to Families:</strong>
          </p>

          <p>
            What messages do I want to send to families about this space? How might an adult family member respond to
            the environment?
          </p>

          <ul class="list">
            <li>Wow, my child is trusted here.</li>
            <li>Everything is at my child’s level so that they can be a part of the action.</li>
            <li>The organization tells me a lot of thought went into this space.</li>
            <li>There seems to be just the right amount out. It is not overwhelming and feels intentional and meaningful
              in this space. My child can learn and grow, feeling safe here.</li>
            <li>My child, and all children, are represented here (e.g., photographs, books, posters, etc.).</li>
            <li>This space tells me that teachers care about my child.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p>
            <strong>Discovering Current Messages About Physical Environment: </strong>
          </p>

          <p>
            What messages are currently sent by the physical environment?
          </p>

          <ul class="list">
            <p class="list-title">Entry Space</p>
            <li>Does the entry space welcome families? Include images of families, comfortable adult-size furnishings,
              other pictures, and messages that say, “both you and your child are
              welcome here.”</li>
            <li>Are messages for families framed and phrased in a positive, inclusive way? (e.g., We love our ECDC
              families. Your family is important to us)</li>
            <li>Even if a program’s make-up is not diverse (e.g., race, ethnicity, language, ability), are visuals
              diverse? Would a potential new diverse family come to visit and feel represented?</li>
            <li>If using bulletin boards, are they current and inclusive of child-created elements? Bulletin boards
              should be free of faded, torn, or outdated material.</li>
            <li>Is there a current lesson plan posted and dated where families can easily view it? It is fine to post
              modifications for learning activities in addition to the lesson plan. Still,
              individual modifications and accommodations must not be displayed, due to confidentiality and legal
              reasons, including HIPAA and FERPA.</li>
          </ul>

          <ul class="list">
            <p class="list-title">Indoor Furniture and Ambience</p>
            <li>Is the furniture arranged so that there are not large spaces for running indoors?</li>
            <li>Are furniture pieces arranged so that there is line-of-sight supervision for teachers?</li>
            <li>Does the furniture arrangement, including area rugs, help define boundaries of various centers and send
              visual messages? </li>
            <li>Are there larger, open spaces for centers that need more space to work (e.g., construction/block) and
              smaller, cozy spaces that allow more personal space to rest and recharge? It can be overwhelming for some
              children to share space with so many others for so many hours a day.</li>
            <li>Does the classroom utilize daylight from windows, whenever possible?</li>
            <li>Are lamps used to create a homey feel, adjust light levels in the room, and to bring more soft light
              options than fluorescent lighting can? (pics of hanging lamps, touch lamps, area/bedside lamps in
              classrooms, twinkle lights) </li>
          </ul>

          <ul class="list">
            <p class="list-title">Outdoor Furniture & Ambience</p>
            <li>Does the space look like the community, using native plants and topography to represent the area? (need
              pics of this)</li>
            <li>Are steps taken to soften the hardscape by adding soft objects and plants? </li>
            <li>How have indoor centers been extended to provide opportunities to continue similar play outdoors (e.g.,
              seating area with books, sensory table, mud kitchen, materials for building, wall/fence painting), and
              gross motor play?</li>
            <li>Are there spaces for individual or small numbers of children to work and play that offer privacy, space,
              and supervision?</li>
            <li>In what ways have I maximized the physical space provided to benefit children’s development and
              well-being throughout the day?</li>
          </ul>

          <ul class="list">
            <p class="list-title"> Materials </p>
            <li>Are materials intentional, meaning there is a purpose to including it in the environment? (e.g., not
              because it’s “cute” or theme-related)</li>
            <li>Are colors used and displayed in meaningful ways, and not overwhelming to the eye?</li>
            <li>Are natural materials and natural colors used?</li>
            <li>Is the space comfortable and homey, not institutional? Remember, children may spend 11 hours a day in
              this environment.</li>
            <li>Does the space represent the children and families expressly, and the community generally? Ask families
              to bring in family pictures, share family messages, and use the
              class’s children throughout the room. Include puzzles, artifacts, toy people sets, and other toys
              representing various races, ethnicities, and abilities in positive and affirming ways (e.g., not token, or
              costumed) is essential, even if the group of children and families are not diverse.</li>
            <li>Does the space look like the community? This may include photos of local buildings and locations
              recognizable to children represented in a homemade book in the construction area, images of the local
              built environment superimposed on blocks to encourage play.</li>
            <li>Are well-loved materials included, so long that they are in good condition?</li>
          </ul>

          <ul class="list">
            <p class="list-title"> Quality and quantity of materials </p>
            <li>There are enough materials for a given center so that multiple children can use materials reasonably.>
            </li>
            <li>Materials include authentic (real) objects whenever possible (e.g., actual kitchen tools and pots and
              pans in the mud kitchen). While child-size is important, so is authenticity.></li>
            <li>Variety></li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <ul class="list">
            <p class="list-title"> Living things for children to care for</p>
            <li>
              <strong>Plants</strong>- There are many hearty plant varieties, including those that may not need much/any
              light.
              For plants at child-level, consider plants that are not poisonous if ingested.
              Plants can also be on top of high shelves or hung in baskets to bring nature indoors.
              Plant selection is also important outdoors and varies significantly based on your space,
              location (planting zone), and the sun/shade available. In many parts of the country,
              plants are an important part of creating outdoor classroom space. Follow your local licensing rules
              regarding plants (indoor and outdoor).<br>
              - Children should be involved in caring for plants.<br>
              - Children must learn how to handle plants so as not to damage them carefully.<br>
              - Include books about plant care as references in the classroom. <br>
            </li>
            <li>
              <strong>Animals</strong> - Choose classroom pets that you have researched, considering all of the animal’s
              needs.
              Some choices may include fish, hermit crab, rabbit, guinea pig.
              For pets that can be petted or handled, consider getting them while young and handling frequently.
              Follow your local licensing rules regarding pets in your classroom. (any other links - articles about
              classroom pets, anything from CDC?)<br>
              - Children should be involved in caring for the pet(s).<br>
              - Children must learn how to treat animals (e.g., do not poke their bodies or faces) and how to read the
              animal’s cues (e.g., relaxed versus tense) to have positive experiences. <br>
              - Animals must always be handled with adult supervision, and children should wash their hands afterward.
              <br>
              - Include books about animal care (specific to the pet(s) you have) as references.<br>
            </li>
          </ul>

          <ul class="list">
            <p class="list-title">Accessible Materials</p>
            <li>Accessible materials are those which children have access to. Accessible materials are within child
              reach and at child level. They do not need to ask to get them, because they are accessible. Available
              materials are those that the teacher has available (e.g., books for new themes or arising interests,
              additional manipulatives, things to rotate) but they are not out for children to use at their level. Both
              have their place in a preschool classroom.</li>
            <li>Are materials accessible for children to use (versus adults having to get and control access to
              materials)?</li>
            <li>Does the room arrangement send messages to children about where to use materials (e.g., a table adjacent
              to a shelf of manipulatives says this is where to bring the manipulatives, an open floor space near a
              dollhouse invites children to sit down and play with the dollhouse)?</li>
            <li>Are materials for my use accessible to me, with unsafe materials (e.g., teacher scissors) inaccessible
              to children?</li>
            <li>Are extra consumable materials readily available for me to restock?</li>
            <li>Are extra non-consumables (e.g., theme-related materials, additional books, props) organized and
              available, even if not in the classroom / outdoor space?</li>
          </ul>

          <ul class="list">
            <p class="list-title">Intentional Organization</p>
            <li>Can children see, reach, use, and return materials without assistance, promoting independence?</li>
            <li>Are materials organized in logical ways, based on the space/center? (add examples)</li>
            <li>Are all materials purposeful, used, and useful?</li>
            <li>Are unused or unneeded materials removed to prevent clutter and promote the intentional inclusion of
              materials?</li>
          </ul>

          <ul class="list">
            <p class="list-title">Promoting Independence</p>
            <li>Can the children in my group access the things they need to be successful independently?</li>
            <li>If I provide some support or experiences, how could children become more independent?</li>
          </ul>

          <ul class="list">
            <p class="list-title">Increasing child control while teachers are in charge</p>
            <li>Does my environment have me controlling what and how children access materials during most of the day,
              which does not promote independence?</li>
            <li>Have I set up the environment so that children know I am in charge, but they have control over many
              parts of the day and what/how they interact with the environment?</li>
            <li>Have I included children in setting up the environment?</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_3l,'img')" src="{{  resources.resource_3l }}" alt="">
            <img (click)="openBunner($event, resources.resource_3m,'img')" src="{{  resources.resource_3m }}" alt="">
            <img (click)="openBunner($event, resources.resource_3r,'img')" src="{{  resources.resource_3r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Classroom Environment / Health and Safety -->
      <ng-container *ngIf="contentKey == 'health&safety'">
        <div class="body">

          <p class="title">
            Health and Safety
          </p>

          <p>
            Teachers are required to follow Health and Safety Policies and Procedures, which address the
            health and safety of children, families, staff, and volunteers. Child Care programs develop
            these policies based on the local and state regulatory agencies that approve and monitor childcare programs.
            Additionally, if you program is accredited, there are health and safety requirements included there as well.
            Sometimes, nutrition is included with health and safety.
          </p>

          <p>
            The National Association for the Education of Young Children’s accreditation Standard 5 is Health.
            The Standard is: The program promotes the nutrition and health of children and protects children and staff
            from illness and injury. Children must be healthy and safe to learn and grow. Programs must be healthy and
            safe to support children’s healthy development. The NAEYC accreditation
            standards, including what to look for in an accredited program for each standard, can be found in the
            Resources section.
          </p>

          <p>
            In February 2020, the COVID-19 virus resulted in stricter requirements to help stop the spread of the virus,
            which changed the health and safety guidelines of Child Care program operations.
            The Center for Disease Control issued guidelines for Childcare providers which can be found in the Resources
            section.
          </p>

          <p class="title">
            Health and Safety Practices
          </p>

          <p>
            As the classroom teacher, you will consistently model health and safety practices that children
            will take part in to keep everyone in the classroom as healthy and safe as possible. Some
            policies and procedures may be school-wide or dictated by regulation (i.e., licensing or funding source),
            and others may be specific to your classroom.
          </p>

          <p>
            These include health practices and procedures for hand washing, toileting, toothbrushing,
            sneezing and coughing, nose-blowing, and what to do with objects that have been mouthed.
            You’ll want to make sure you know that safety practices and procedures are for things such
            as handling scissors, responding to an evacuation or shelter drill, moving safely in the
            classroom vs. outdoors, cleaning up a spill or mess, and keep ourselves and our classmates safe.
            You should expect that you will repeat expectations and model consistently over time. This repetition
            is typical with young children, so find songs or poems to help everyone remember, and approach it with
            a cheerful, non-judgmental attitude as you remind and re-share. There are many teachable moments for you
            to capitalize on when messes occur, or there are missteps. You will want to show as much patience and
            compassion as possible while teaching children about health and safety throughout the day.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Involving Children -->
      <ng-container *ngIf="contentKey == 'involving'">
        <div class="body">

          <p> Do children have a voice in how the physical environment develops during the year?</p>
          <ul class="list">
            <li>The physical environment continues to grow as the class grows. </li>
            <li>I keep adjusting things (adding, removing) as needed, but not in a way where the environment becomes
              unpredictable to children.</li>
            <li>I include children in conversations and the work of adjusting the physical environment because it is OUR
              classroom, not just mine.</li>
          </ul>

          <p> <strong> Sharing Power With Children In Design of Physical Environment: </strong>

          <ul class="list">
            Am I willing to give up some control and share power with children in designing the physical environment
            during the year?
            <li>I am willing to have conversations with children about how the space is working / not working and get
              feedback from children.</li>
            <li>I am willing to have conversations with families about how the space is working / not working and get
              feedback from families.</li>
            <li>I am willing to use children’s and families’ ideas to adjust parts of the environment that are not
              working for the class.</li>
            <li>I am willing to include children in making adjustments because their input and valuing their voices
              are
              vital to their development.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p> <strong> Ideas for Maximizing Design of Indoor & Outdoor Classroom Spaces: </strong>

          <ul class="list">
            Every physical space, indoor and outdoor, will have its physical challenges and drawbacks. Do your best
            to
            take advantage of every space available and maximize its use. Here are a few ideas:
            <li>Design attractive and inviting spaces with available furniture. Remember, less is more. </li>
            <li>Create learning centers with ample storage for materials organized in a way that makes sense.</li>
            <li>Designate a large group gathering area where the entire group can fit comfortably. This is often
              where
              block/construction play occurs during center time, due to space needed for this type of play.</li>
            <li>Think of classroom organization as a place for everything and everything in its place.</li>
            <li>Add related materials on Monday, and additional materials on Wednesday, depending upon observations
              of
              need and interest. This will encourage exploration and enhance children’s learning and curiosity.</li>
            <li>Locate quiet areas apart from noisier ones. Remember, children need variety based on individual
              needs
              and preferences and cycles throughout the day. Children spend as many as 11 hours in your space, which
              can
              be overwhelming to certain children.</li>
            <li>Display children’s current art throughout the classroom. Be sure it is art rather than craft.</li>
            <li>A well-organized classroom encourages children to interact predictably and efficiently.
              Predictability
              in schedule and organization helps children to gain confidence in trust in you and school. </li>
            <li>Set up activities in a way that encourages independence, with materials accessible and organized
              nearby.
            </li>
            <li>Design individual workspaces with carpet squares, trays, work mats, etc. This helps to define
              personal
              space and sends a message as to how many children can comfortably work in an area.</li>
            <li>Display photographs of children engaged in activities and those of children’s families in attractive
              ways.</li>
            <li>Label shelves and storage containers with words and pictures to encourage independence in getting
              and
              returning materials. Write words in languages prevalent in the community, utilizing family/community
              resources if not within the center staff.</li>
            <li>Create a “lost and found” container for loose objects.</li>
            <li>Promote creativity with a variety of enhancements, including natural materials, found/recyclable
              materials. Sometimes, these are called loose parts. (can do a whole section on loose parts).</li>
            <li>For teacher-directed activities such as small groups or large groups, organize materials in advance
              for
              the task. This reduces child wait-time and enhances attention during teacher-directed activities.</li>
            <li>Use print in meaningful ways, from labeling and organizing materials to children’s names and other
              important words. A print-rich classroom establishes a connection between the spoken word and the
              written
              word. </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Classroom Environment /  Centers -->
      <ng-container *ngIf="contentKey == 'centers'">
        <div class="body">


          <p> <strong> Messaging to families</strong></p>

          <ul class="list">
            <li>
              Have a space set up where you can easily post messages to families on a dry-erase board.
              This is often near where sign-in/sign-out occurs, or near the classroom door,
              depending upon how your building is set up.
            </li>
          </ul>

          <p><strong> General Setup</strong></p>

          <ul class="list">
            <li>
              The classroom must meet the needs of the children who use the space
              and the teaching staff. Included are essential environmental considerations for several centers and
              setups,
              but keep these factors in mind when it comes to classroom setup: <br>
              - Remain flexible. Centers and their location can move as it makes sense to do so.<br>
              - Materials can move. Materials do not have to “live” in only one area. For example, books belong in every
              center (examples ahead), clipboards and pencils are useful in multiple spaces. <br>
              - Stay organized. Containers (including inventive ones) and labels, and “a space for everything, with
              everything in its space” help children feel secure knowing there is a plan. <br>
              - Less is more. While it is useful to have additional materials to add, what is at children’s level
              (accessible) should be enough without being overwhelming. Having too much out can make clean-up
              overwhelming. <br>
              - Supervision is key. Check your room set-up from several different spaces where you are likely to be when
              children are throughout the area (indoor and outdoor). While children need and deserve privacy and space,
              your setup should also allow for manageable supervision without interruption. <br>
            </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <ul class="list">
            <li>
              Many activities can occur both indoors and outdoors.
            </li>
          </ul>

          <p><strong>Infusing literacy and math throughout each center</strong></p>

          <ul class="list">
            <li>
              It is helpful for children to experience literacy and math in meaningful ways. This starts with a
              classroom set up across centers. Here are a few suggestions:
            </li>
          </ul>

          <p> <strong> Literacy </strong></p>

          <ul class="list">
            <li> Include books in every center. (e.g., books featuring cities, farms, buildings in the construction
              area,
              books about butterflies or lizards in the sensory/science center)</li>

            <li> Make writing and mark-making central. (e.g., have clipboards and sharpened pencils available to sketch
              ideas in the construction area, write and make marks, menus, and notes in dramatic play) </li>
          </ul>

          <p> <strong> Mathematics </strong></p>

          <ul class="list">
            <li> Organize materials to encourage 1:1 correspondence. </li>
            <li> Offer opportunities where counting is logical. (snack, manipulatives, organization like ice cube trays)
            </li>
            <li> Tools for measuring (measuring spoons and cups, sensory table containers marked with amounts) </li>
            <li> Provocations (how many cubes can you stack? </li>
            <li> Diagrams (about blocks) </li>
          </ul>

          <p> <strong> Quiet / relaxation / safe space</strong></p>

          <ul class="list">
          <li> Separated from more active play areas </li>
          <li> Logical space for just a few children </li>
          <li> Soft surfaces and objects, a rug or carpet, stuffed animals, and puppets </li>
          <li> Visual sensory toys (e.g., liquid motion sensory toys, sensory bottles, stress balls) </li>
          <li> Pictures and photos of family displayed nicely on a wall, shelf, or binder </li>
          <li> Muted/filtered lighting or controlled lighting (lamps or lights with separate switches, curtains/blinds
            for windows) </li>
          <li> Cozy space </li>
          </ul>

          <p><strong>Construction / blocks</strong></p>

          <ul class="list">
            <li>Shelving for blocks and accessories and containers for accessories <br>
              - Organized and labeled blocks (at least two types, enough for a reasonable number of children in the
              space) </li>
            <li>Accessories that are always accessible, including construction or theme-related books</li>
            <li>Accessories that rotate, with extra accessories available to teachers</li>
            <li>Classmate blocks or cut-outs</li>
            <li>Overhead projector for shadow play and projection</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p><strong>Dramatic play</strong></p>

          <ul class="list">
            <li>Shelving and containers for props and supplies</li>
            <li>Housekeeping furniture (e.g., kitchen set, table, and chairs, crib, highchair, broom set)</li>
            <li>Props for multiple themes (e.g., calculators, adding machines, cash registers, notepads and pencils,
              poker
              chips, phones, non-theme costumes/accessories)</li>
            <li>Props for specific themes (e.g., family/home, dentist, doctor, office, grocery store, veterinarian),
              with
              theme-related books and extra accessories (i.e., prop boxes) available to teachers (Ideas for prop boxes
              can
              be found online</li>
          </ul>

          <p> <strong>Science / Sensory Table</strong></p>

          <ul class="list">
            <li>Shelving and containers for science materials</li>
            <li>Space to use materials (e.g., table, floor space)</li>
            <li>Scientific materials for general use (e.g., hand lens, microscope, tweezers, child-safe plants,
              classroom
              pets, eye droppers, measuring cups and spoons, magnets, play dough, clipboards and pencils for sketches
              and
              plans) (Ideas for science center materials can be found online
            <li>Scientific materials for specific themes or studies (e.g., books, robots, entomology, nature items,</li>
            incubator, rocks, seeds, shells), with additional materials available to teachers
            <li>Light table and overhead projector for shadow play and projection experiments</li>
          </ul>

          <p><strong> Writing</strong></p>


          <ul class="list">
            <li>Shelving and containers for writing materials</li>
            <li>Space to write (e.g., table or desk and chairs)</li>
            <li>Standard writing materials (e.g., clipboards and pencils, plain copy paper, well-organized markers and
              crayons, sharpened pencils, erasers, a model of alphabet letters, cards with classmates’ names and photos
              for reference)</li>
            <li>Writing materials for rotation (e.g., alphabet stamps, individual chalk or dry erase boards, specialized
              paper, cards with theme-related words for reference, envelopes, greeting cards)</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_3l,'img')" src="{{  resources.resource_3l }}" alt="">
            <img (click)="openBunner($event, resources.resource_3m,'img')" src="{{  resources.resource_3m }}" alt="">
            <img (click)="openBunner($event, resources.resource_3r,'img')" src="{{  resources.resource_3r }}" alt="">
          </div>

          <p> <strong>Art</strong></p>

          <ul class="list">
            <li>Shelving and containers for organized storage</li>
            <li>Easels (preferably two, side-by-side)</li>
            <li>Areas to dry, store, and display children’s work (indoor or outdoor)</li>
            <li>Area to hang smocks</li>
            <li>Nearby access to child-level sink(s)</li>
            <li>Non-consumable materials (e.g., scissors, glue bottles, sponges, paintbrushes in varying sizes, paint
              cups, smocks, play dough props, stencils, shatterproof mirrors, wire, beads)</li>
            <li>Consumable materials accessible to children (e.g., various types of paper, markers, colored pencils,
              crayons, sharpened pencils, various washable paints (purchased or homemade), play dough, glue and glue
              sticks, chalk)</li>
            <li>Consumable and non-consumable materials for rotation, available to teachers. (Ideas for consumable and
              non-consumable materials available in online resources</li>
            <li>Overhead projector for shadow play and projection experimentation with design, light, and color</li>
          </ul>

          <p><strong>Gross Motor</strong></p>
          <ul class="list">
            <li>Tricycles and helmets, including trikes with a back seat (e.g., taxi, bus, rickshaw)</li>
            <li>Wagons</li>
            <li>Parachute</li>
            <li>Balls (and air pump/needles)</li>
            <li>Large paintbrushes and rollers (think to paint your house) with buckets to paint water on fences,
              sidewalks, and building</li>
            <li>Sand toys, including vehicles</li>
            <li>Water table and toys</li>
            <li>Books and a book basket with a handle</li>
            <li>Outdoor easels (or fence surface) for painting</li>
            <li>Items for various uses, such as cones and beanbags</li>
            <li>Natural objects, stored in containers for use (e.g., pinecones, acorns/walnuts/seed pods, seashells,
              rocks, sticks)</li>

          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_4l,'img')" src="{{  resources.resource_4l }}" alt="">
            <img (click)="openBunner($event, resources.resource_4m,'img')" src="{{  resources.resource_4m }}" alt="">
            <img (click)="openBunner($event, resources.resource_4r,'img')" src="{{  resources.resource_4r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Setting up Shared-Use Spaces -->
      <ng-container *ngIf="contentKey == 'spaces'">
        <div class="body">
          <ul class="list">
            <strong>Small Group</strong>
            <li>Space and seating for the small group of 3-5 to work with the teacher (e.g., table and chairs,
              individual mats/carpet squares on the floor)</li>
            <li>Adult-level counter or top of the shelf where the day’s prepared small group materials are ready</li>
            <li>Location giving teacher visibility to scan the environment, and providing some separation from other
              activities</li>
            <strong>Large Group</strong>
            <li>Space and seating for the whole group to gather, with teacher seating visible to the entire group (e.g.,
              carpet for children, with low teacher chair)</li>
            <li>Display/storage space for day’s large group materials, preferably movable (e.g., big book display and
              storage or teacher’s learning center/chart stand)</li>
            <li>Access to cued-up music (e.g., CD player / portable Bluetooth speaker connected to iPod/phone)</li>
            <strong>Environment considerations for mealtimes</strong>
            <li>Rolling plastic or metal cart (2-3 levels) that can be in mealtime area as needed, and then rolled out
              of the way</li>
            <li>Easy access to food, serving containers, utensils, and plates/silverware (e.g., top of shelf/teacher
              counter, a cart)</li>
            <li>Child-safe space and means to clean and store cleaning supplies</li>
            <li>Appropriate-size seating and chairs to accommodate all children and staff sitting together, including
              low teacher-size chairs that fit the table</li>
            <li>Close access to child-size handwashing sink(s)</li>
            <li>Plan for how children will clean up (e.g., bus tub for dirty dishes, trash can nearby to scrape plate,
              dump milk in the sink rather than trash can). Set up the environment according to your plan.</li>
            <strong>Environment considerations for naptime </strong>
            <li>Cots or mats stored on rolling frame/container to reduce staff fatigue and speed process of naptime
              set-up and clean-up</li>
            <li>Laminated floor plan with a dry erase marker to indicate each child’s cot placement available for all
              staff</li>
            <li>Place children strategically to minimize distractors</li>
            <li>Arrangement to allow easy supervision as the teacher moves around the room</li>
            <li>Play/stream quiet music</li>
            <li>Reduce light (e.g., cover windows, turn main room lights off, use small lamps/night lights as needed)
            </li>
            <li>Reduce the temperature slightly, if possible</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Technology in the Classroom -->
      <ng-container *ngIf="contentKey == 'technology'">
        <div class="body">

          <p>
            It is essential children make digital connections as they are growing up in a digital world.
            However, technology must be purposeful and intentional as an open-ended tool to extend learning,
            grow relationships, and share understandings with peers and significant adults (i.e., family and teachers).
            Technology includes those items that improve the quality of life.
            Learning Beyond Paper provides age-appropriate technology connections throughout the curriculum.
          </p>

          <p>
            The U.S. <strong>Department of Education and the U.S. Department of Health and Human Services collaborated
              in the
              development of the Early Learning</strong> and Educational
            Technology Policy Brief in 2016 to promote developmentally appropriate use of technology in homes and early
            learning settings.
          </p>

          <p>
            The Departments’ four guiding principles for the use of technology with early learners are as follows:
          </p>

          <ul class="list">
            <li><strong>Guiding Principle #1:</strong> Technology—when used appropriately—can be a tool for learning.
            </li>
            <li><strong>Guiding Principle #2:</strong> Technology should be used to increase access to learning
              opportunities for all children.</li>
            <li><strong>Guiding Principle #3:</strong> Technology may be used to strengthen relationships among parents,
              families, early educators, and young children.</li>
            <li><strong>Guiding Principle #4:</strong> Technology is more effective for learning when adults and peers
              interact or co-view with young children.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <ul class="list">
            <strong>Examples of Technology in the preschool classroom</strong>
            <li>A cash register in the housekeeping center for children to play grocery shopping</li>
            <li>Assisting children with using a digital camera to take pictures of their work</li>
            <li>Using the computer with a child to search for an age-appropriate website to answer a child’s question or
              interest</li>
            <li>Providing a variety of audiobooks/player in your reading center</li>
            <li>Collect outdated cell phones (take out batteries) for children’s exploration</li>
            <li>Model appropriate technology behavior</li>
            <li>Provide opportunities for children to share technology tools to encourage interactions</li>
          </ul>
          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p class="epigraph">
            *Visit the Resources section for additional for additional examples of technology in the classroom.
          </p>
        </div>
      </ng-container>

      <!-- Classroom Structure / Classroom Community-->
      <ng-container *ngIf="contentKey == 'community'">
        <div class="body">
          <p class="title">Creating a Sense of Community</p>

          <p>
            Creating a sense of community should begin from the first day of preschool and continue throughout the year.
            A new group of children arrives with different interests, abilities, cultures, and families.
            Many may have experienced childcare since they were infants, while others are experiencing it for the first
            time.
            How you meet and greet each child and family is a role model for meeting and greeting their peers.
            Part of creating a sense of community is to remember that “the teacher is the environment.
            The teacher creates a sense of community that is equitable for all children.
          </p>

          <ul class="list">
            <strong>As a teacher, you will:</strong>
            <li>Create a welcoming classroom that reflects and celebrates children’s identities, including images of
              children (e.g., self-portraits) and families (e.g., photos), and offer different opportunities for
              children to learn about themselves and each other throughout the curriculum</li>
            <li>Show respect for children of different cultural backgrounds in school settings</li>
            <li>Welcome families and children to share their cultural heritages at school-wide events such as cultural
              fairs or a cultural week and include a sustained focus on children’s social identities throughout the year
            </li>
            <li>Include children’s books and media in early childhood classrooms which represent different cultures and
              diversity</li>
            <li>Are enthusiastic and use positive verbal and nonverbal language</li>
            <li>Empower children to have some influence over decisions in play</li>
            <li>Ensure children have input in curriculum topics, parts of the daily schedule, and creating class rules
              and job responsibilities</li>
            <li>Give children the space they need to explore, experiment, and make—and learn from—mistakes</li>
            <li>Provide experiences of working together to solve problems in both play and projects</li>
            <li>Understand that a strong classroom community is one in which children feel empowered and valued, and
              children will learn and grow</li>
            <li>Model behaviors of respect, caring, self-control, and fair decision-making</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p><u>Classroom Community:</u></p>

          <p>
            Starting the day with Together Time creates a sense of community.
            As members of a community, it is important for children to share the responsibilities of classroom life.
            Assigning classroom jobs is an ideal way to teach children responsibility and build their confidence.
            Brain research suggests that providing opportunities to practice decision-making and reasoning skills
            enhance
            children’s abilities to perform such tasks. Classroom jobs afford children these valuable opportunities
            while allowing
            them to rehearse meaningful responsibility by completing jobs that assist their school family.
          </p>

          <ul class="list">
            <p>What should you consider?</p>

            <li>Take time to share why it is vital to have a list of classroom jobs and discuss why they matter.</li>
            <li>Ask the children to help you create the list of jobs during the first week of school as you introduce
              the day’s schedule.</li>
            <li>Point out different tasks to add to their list. </li>
            <li>Keep a running list on chart paper and display it prominently.</li>
            <li>Prepare open-ended questions designed to help the children think of jobs they should include on their
              chart.</li>
            <li>Follow up with why the job is essential and details of how to do the job. </li>
            <li>Assign every child a job. </li>
            <li>The number of jobs listed will depend on the number of children in the class.</li>

            <p>Preparation of Chart</p>

            <li>Provide laminated visuals for each job. </li>
            <li>Create and laminate name cards with each child’s picture and first name.</li>
            <li>Display the jobs in such a way that every child can quickly identify his or her job title.</li>

            <p>Time to get started.</p>

            <li>Designate a start date and decide how often jobs will rotate. </li>
            <li>Describe each job and teach children how to do it. </li>
            <li>Provide time to reflect with children about how jobs are going, and if the job needs changes. </li>
            <li>Observe the children’s implementation of the job and step in to scaffold if needed. </li>
            <li>Provide daily, specific feedback with examples of what you are seeing that is going well. </li>

            <p>Jobs will vary with the needs of individual classrooms. Some suggestions include:</p>

            <li>Song Selector- Chooses a song to sing during together time and background music for center time.</li>
            <li>Librarian- Chooses a book for story time and is responsible for returning all books in the library
              center to the shelf.</li>
            <li>Zookeeper-Feeds classroom pet/s and changes water as needed</li>
            <li>Gardener- Waters plants (indoors and outdoors) and weeds as necessary</li>
            <li>Light Monitor-Turns the lights off when the class leaves the classroom and turns them on when they
              return</li>
            <li>Line Leader- Opens the door and walks at the front of the line.</li>
            <li>Caboose- Closes the door and walks at the back of the line.</li>
            <li>Meteorologist- Observes and reports the weather to the class (may use tools such as rain gauges,
              windsocks, and thermometers)</li>
            <li>Snack Helper- Counts the number of plates, napkins, or cups needed and passes them out.</li>
            <li>Greeter- Greets peers and visitors as they enter the classroom.</li>
            <li>Goodbye Wisher- Says “goodbye” to visitors as they leave the classroom and wishes them a nice day.</li>
            <li>Hand Sanitizer- Provides a pump of hand sanitizer before or after designated activities.</li>
            <li>Marker Monitor- Monitors markers to be sure that correct lids are on tightly</li>
            <li>Table Washer- Wipes and washes tables after snacks and messy activities</li>
            <li>Playground Supply Managers- Carries playground supplies (balls, sand toys, etc.) outside and collects
              them when playtime is over.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Structure / Classroom Community-->
      <ng-container *ngIf="contentKey == 'routine'">
        <div class="body">

          <p><strong>Together Time:</strong></p>

          <p>This is a time of building community and sharing as well as learning.</p>

          <ul class="list">

            <p>To share activities and experiences that: </p>

            <li>Build structure into the program, so the children know what to expect each day. </li>
            <li>Are meaningful to children (e.g., presented for children’s benefit and at their level)</li>
            <li>Presented in an engaging way</li>
            <li>Always include movement (e.g., small motor movements like fingerplays, large motor movements like
              dancing and actions).</li>
            <li>Introduce new concepts or things that are occurring or available (e.g., new materials in centers, change
              to daily schedule such as a planned fire drill, a new friend has joined the group)</li>
            <li>Share thoughts and ideas, listen, and talk to each other, participate in new learning experiences, read
              together, sing together, and interact with one another</li>

            <p><strong>Together Time Schedule Frequency and Purpose:</strong></p>

            <li>Twice a day: once in the morning, and once just before time to go home</li>
            <li>Begin the year with 10 minutes and increase to 15 minutes as the children’s attention spans increase
            </li>
            <li>A consistent transition activity to gather and dismiss children</li>
            <li>Alternate active and passive activities</li>

            <p><strong>Beginning of the day: </strong></p>

            <p>
              This is a large group, teacher-led activity, and occurs in the morning at a time in the
              schedule when the group has arrived. Use this time to set the tone for the day or week.
              Establish a routine that includes an opening song or finger play and activities which may also include:
            </p>

            <li>Taking attendance</li>
            <li>Morning message</li>
            <li>Shared reading</li>
            <li>Introduction to new materials </li>
            <li>Time to focus children on the upcoming daily/weekly events </li>
            <li>Create meaningful ways to introduce concepts such as before and after and later and next. </li>

            <p><strong> End of day: </strong></p>

            <p>
              This is the second large-group, teacher-led activity.
              This is time to bring the children together to review the day before the group begins leaving for the day.
            </p>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p><strong>Learning Center Time:</strong></p>

          <p>
            Learning Centers provide opportunities for exploration as children make decisions (self-direct)
            about their activity choices, interactions, and direction. They are guiding their learning, with
            peers or solo. Learning centers should include newly rotated materials weekly,
            ideally on Monday and mid-week as the play progresses, to keep interest high.
          </p>

          <ul class="list">
            <p>Examples of Learning Centers:</p>
            <li>Alphabet Center</li>
            <li>Art Center</li>
            <li>Construction Center</li>
            <li>Dramatic Play Center</li>
            <li>Mathematics/Number Center (often combined with manipulatives)</li>
            <li>Reading/Literacy Center</li>
            <li>Science/Sensory Center</li>
            <li>Writing Center</li>
          </ul>

          <p><strong>Schedule Frequency and Purpose:</strong></p>

          <ul class="list">
            <li>Schedule twice daily.</li>
            <li>Length is a minimum of 45 minutes each, with 60 to 90 minutes desirable.</li>
            <li>Children may work in centers they choose, taking into account space considerations. </li>
            <li>Children practice skills they are learning, building relationships with peers, and responsibility for
              actions and use of materials and space.</li>
          </ul>

          <p><strong>Teacher Role:</strong></p>

          <ul class="list">
            <li>Set-up a management system that allows children to rotate freely through centers.</li>
            <li>Establish a clean-up routine.</li>
            <li>Encourage children to explore different centers.</li>
            <li>Interact with children in centers using open-ended questions and open-ended materials, allowing
              discovery of material use.</li>
          </ul>

          <p><strong>Small Group Instruction:</strong></p>

          <ul class="list">
            <li>Small Group Instruction times can run concurrently with centers if you have scheduled center time for at
              least 60 minutes.</li>
            <li>Feel free to incorporate small group instruction throughout the day. Usually, there are no more than
              three to five children in a small group activity. A small group activity could last about 10 to 15
              minutes.</li>
            <li>Small groups can occur concurrently within center time, if Center Time is at least an hour long. If this
              model is used, the daily schedule and lesson plans should reflect this. </li>
            <li>Small group times provide the teacher with opportunities for close interaction necessary for focused
              instruction and skills assessment.</li>
            <li>Small Groups are designed to focus on a skill or concept. </li>
            <li>All children may not participate in a small group every day. </li>
            <li>Determine a small group schedule throughout the week for those children in need of reinforcement on that
              skill or concept.</li>
          </ul>

          <p><strong>Outdoor Adventures:</strong></p>

          <p>
            Outdoor Adventures - Go outside every day the weather permits. Check your state regulations for the minimum
            amount
            of time your age group should spend outside.
            Most, if not all, indoor activities can occur outside too. Make your space into an outdoor classroom.
          </p>

          <ul class="list">
            <li>Play and Learn - an outdoor experience where children can engage in supervised free play. </li>
            <li>Walk around and get involved with children’s play and conversation.</li>
            <li>Outdoor play supports the development of necessary social skills and gross motor skills. </li>
            <li>Provide materials such as balls, tricycles, and balance beams to encourage movement, and learning center
              materials to promote constructive play. </li>
            <li>Rotate materials to maintain interest. </li>
          </ul>

          <p><strong>Arrival at school:</strong></p>

          <p>Establishing an arrival routine will provide children with a structure to know what to expect and feel safe
            at school.</p>
          <p>When children first enter the classroom each morning, they should feel welcomed. </p>
          <p>
            Each child’s individual and past experiences may influence the child’s reaction to transitioning from home
            to school.
            Whether the children arrive all at once or trickle in, the following tips may help provide a predictable
            routine to start the school year.
            Your routine activities will change as children learn and grow. Children will feel successful if they start
            the day excited to be in school.
          </p>

          <ul class="list">
            <li>Think of a choice of 3 rituals you could use to greet the children. Include a visual so they can choose
              which greeting they want to use handshake, hug, or high five. No-contact options include an air hug,
              thumbs up, hang loose sign.</li>
            <li>Ask the child, “How do you feel today,” and have the child point to the picture of how they feel on a
              poster of faces with different expressions? </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p><strong>Arrival at school:</strong></p>

          <ul class="list">
            <li>Prepare your classroom for the day before the children arriving, </li>
            <li>Greet children and families with a smile and speak their name and add a few words of welcome. </li>
            <li>Avoid making comments about what the child is wearing or how they look. </li>
            <li>Upon arrival, encourage the parent to stay a few minutes and help the child settle in. </li>
            <li>Create a visual picture of the arrival routine. </li>
          </ul>

          <p><strong>Morning Routine:</strong> (Your routine will be individualized based on your schedule and school
            policies/procedures.)</p>

          <ul class="list">
            <li>Include a sign-in routine, an age-appropriate activity with a variety of writing tools, and large pieces
              of unlined paper for the beginning of the school year. </li>
            <li>Put personal items in designated cubbies labeled with each child’s name</li>
            <li>Wash hands</li>
            <li>Encourage the family member to say a cheery goodbye </li>
            <li>Prepare a variety of tabletop activities available to children</li>
          </ul>

          <p><strong>Snack and Mealtime:</strong></p>

          <p>Snack time is an opportunity for learning in all the domains for learning.</p>
          <p>Preschool snacks served at a table allow youngsters to navigate serving themselves food and drinks.</p>
          <p>Teachers sit and interact with the children focusing on healthy eating and self-help skills (e.g., serving
            self, opening packaging, feeding self).</p>

          <p><strong>Transitions:</strong></p>

          <p>
            Transitions are the periods between activities. This is the time for children to interact with the visual
            schedule.
            They can use a clothes pin to mark the clip on the next activity or point as they identify the activity
            which comes next.
          </p>
          <p>
            Transition songs, chants, rhymes, sounds, and pictures are helpful to children to move to the next activity
            to assist them with focusing on a smooth transition in a group.
          </p>

          <p><strong>Routines:</strong></p>

          <p>
            The schedule is the “big picture” of the main activities you and the children engage in daily.
            Routines, on the other hand, are the steps done along the way to complete certain portions of the schedule.
            Routines help provide a pattern and predictability to one’s day.
          </p>

          <ul class="list">
            <p><strong>Examples of routines:</strong></p>
            <li>Arrival</li>
            <li>Bathroom time</li>
            <li>Handwashing</li>
            <li>Meals/Snacks</li>
            <li>Clean-Up </li>
            <li>Naptime </li>
            <li>Dismissal </li>
          </ul>

          <p><strong>Classroom Rules:</strong></p>

          <ul class="list">
            <li>Involve children in creating rules</li>
            <li>Posted rules are positive and use affirmative language. </li>
            <li>The use of pictures will enhance children’s readability and understanding.</li>
            <li>A suggested guide for this age is a maximum of four rules.</li>
            <li>Keep the rules short and simple.</li>
            <li>Once you have decided on a set list of rules as a group, reinforce them by walking through each rule.
            </li>
            <li>Adding picture cues and physical actions to your rules will help the children remember the rules.</li>
            <li>Post the rules in several locations throughout your classroom.</li>
            <li>Post at eye level and include visuals with children having diverse skin tones and eye colors.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_3l,'img')" src="{{  resources.resource_3l }}" alt="">
            <img (click)="openBunner($event, resources.resource_3m,'img')" src="{{  resources.resource_3m }}" alt="">
            <img (click)="openBunner($event, resources.resource_3r,'img')" src="{{  resources.resource_3r }}" alt="">
          </div>
        </div>
      </ng-container>

      <!-- Classroom Structure /  Schedule -->
      <ng-container *ngIf="contentKey == 'schedule'">
        <div class="body">

          <ul class="list">
            <p>
              Your schedule may vary based on your program’s operating hours and needs,
              but there are essential considerations when developing a schedule. Your schedule must provide:
            </p>

            <li>blocks of time to enable children opportunities for discovery as they are involved in integrated,
              active, engaged learning experiences.</li>
            <li>a balance of large group (e.g., together time), small group, individual activities that include
              child-selected groups, and teacher-selected groups.</li>
            <li>times, when children can work and play together with peers as well as alone (self-selected by the
              child).</li>
            <li>time planned for physical movement and outdoor activities as well as snacks/meals and rest times.</li>
            <li>a sense of what comes next. A predictable schedule helps children anticipate what will happen, and thus
              feel more secure.</li>
          </ul>

          <ul class="list">
            <p><strong>Tips for developing a schedule:</strong></p>

            <li>For shared spaces (e.g., a playground), you will need to plan a program-wide schedule with others.</li>
            <li>Consider the type of activity and alternate active and passive activities.</li>
            <li>Plan your day and be organized. Prepare things needed for each part of the schedule in advance (e.g.,
              have your large group book and materials out with music cued up before children come to large group time,
              ensure paint and brushes are available at the easel before center time, fill the water table before
              outdoor play begins). This reduces wait time and problem behavior.</li>
            <li>Think about the transitions/ routines throughout your day and allow time for transitions and routines,
              so children (and you) are not frustrated with unrealistic expectations.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <ul class="list">
            <p><strong>Time Needed for Routine Parts of Day:</strong></p>

            <li>Large Group/Together time (10 to 15 minutes each in the morning and toward the end of the day)</li>
            <li>Small group time (10-15 minutes), scheduled small group times</li>
            <li>Learning Center time (at least 45 min each) morning and afternoon</li>
            <li>Outdoor time (30 min each) morning and afternoon</li>
            <li>Snack time (15 minutes each) morning and afternoon </li>
            <li>Lunch (30 min) </li>
            <li>Naptime (at least 90 minutes)</li>
          </ul>

          <p>
            Post your schedule with different audiences in mind, but primarily families and children.
            Post your schedule with the times for each segment of the day for your families in a conspicuous place.
            Some programs have a parent information board (pic) or an online place where families can view the schedule
            (pic).
            Licensing may also require a schedule to be posted, so be sure to follow any specifications for this.
          </p>

          <p>For children, a visual schedule is necessary and explained in more detail below.</p>

          <p><strong>Visual Schedules:</strong></p>

          <p>
            Children are visual learners, and creating a visual schedule provides a pictorial representation of
            what they will be doing throughout the day.
            It helps children predict what is coming next, which helps children feel safe and secure.
          </p>

          <ul class="list">
            <p><strong>Creating a Visual Schedule:</strong></p>
            <li>Pictures should be larger than words. Use stock photos until you take pictures of your children during
              each segment of the day. Use pictures of your actual class to reaffirm and motivate, which is easy to do
              with a digital camera.</li>
            <li>Using 8x11 pictures will allow the children to see the activities efficiently. </li>
            <li>Post at children’s eye level preferably left to right (but can be top to bottom/vertical if space does
              not allow horizontal placement).</li>
            <li>Place the pictures in a sheet protector so that you can change them more easily. </li>
            <li>Adjust the schedule with additional pages/pictures as needed (e.g., weather, a special activity, field
              trip).</li>
          </ul>

          <ul class="list">
            <p><strong>Using a Visual Schedule:</strong></p>
            <li>During the first few weeks, or any time there is a schedule change, talk about the schedule/schedule
              change, and refer to it during Together Time.</li>
            <li>Go to the visual schedule on the wall frequently to point out what is coming up next.</li>
            <li>Refer to the schedule often so that children begin to use it as a reference too.</li>
            <li>Make your schedule interactive by placing a clothespin or other child-friendly clip on the schedule.
              Move the clip (or have a child move the clip) as the schedule changes throughout the day. This helps
              children to understand the movement of time during the day.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

          <p><strong>Sample Schedules:</strong></p>

          <div class="tables">
            <table>
              <tr>
                <th> <u>Sample Schedule 1:</u></th>
                <th></th>
              </tr>
              <tr>
                <th> 8 am to 9 </th>
                <th> Arrival </th>
              </tr>
              <tr>
                <th> 9 am to 9:15 </th>
                <th> Together time </th>
              </tr>
              <tr>
                <th> 9:30 to 9:45 </th>
                <th> Small Group </th>
              </tr>
              <tr>
                <th> 10:00 to 10:15 </th>
                <th> Snack Time </th>
              </tr>
              <tr>
                <th> 10:30 to 11:00 </th>
                <th> Outdoor Time </th>
              </tr>
              <tr>
                <th> 11:15 to 12:00 </th>
                <th> Center Time </th>
              </tr>
              <tr>
                <th> 12:15 to 12:45 </th>
                <th> Lunch </th>
              </tr>
              <tr>
                <th> 1:00 to 2:30 </th>
                <th> Nap Time </th>
              </tr>
              <tr>
                <th> 2:45 to 3:15 </th>
                <th> Outdoor time </th>
              </tr>
              <tr>
                <th> 3:30 to 3:45 </th>
                <th> Small Group Time </th>
              </tr>
              <tr>
                <th> 4:45 to 5:00 </th>
                <th> Together Time </th>
              </tr>
              <tr>
                <th> 5:00 to 6 PM </th>
                <th> Center Time/Dismissal </th>
              </tr>
            </table>

            <table>
              <tr>
                <th> <u>Sample Schedule 2:</u></th>
                <th></th>
              </tr>
              <tr>
                <th> 6:30 am to 8:30 </th>
                <th> Arrival </th>
              </tr>
              <tr>
                <th> 8:15 to 8:45 </th>
                <th> Breakfast </th>
              </tr>
              <tr>
                <th> 9 am to 9:15 </th>
                <th> Together Time </th>
              </tr>
              <tr>
                <th> 9:15 to 9:30 </th>
                <th> Small Group </th>
              </tr>
              <tr>
                <th> 9:45 to 10:15 </th>
                <th> Outdoor Time </th>
              </tr>
              <tr>
                <th> 10:30 to 11:30 </th>
                <th> Center Time </th>
              </tr>
              <tr>
                <th> 11:30 to 11:45 </th>
                <th> Small Group </th>
              </tr>
              <tr>
                <th> 12:00 to 12:30 </th>
                <th> Lunch </th>
              </tr>
              <tr>
                <th> 12:45 to 2:15 </th>
                <th> Nap Time </th>
              </tr>
              <tr>
                <th> 2:30 to 3:00 </th>
                <th> Outdoor time </th>
              </tr>
              <tr>
                <th> 3:15 to 3:30 </th>
                <th> Snack Time </th>
              </tr>
              <tr>
                <th> 3:45 to 4:00 </th>
                <th> Together Time </th>
              </tr>
              <tr>
                <th> 4:15 to dismissal </th>
                <th> Center Time </th>
              </tr>
            </table>
          </div>
        </div>
      </ng-container>

      <!-- Learning Experiences / Complexity and Depth -->
      <ng-container *ngIf="contentKey == 'complexity&depth'">
        <div class="body">
          <p>
            Within the Learning Beyond Paper preschool curriculum, it is essential to know that all children may not
            start the school year with the requisite skills and knowledge needed from the prior year. Even if this is
            the case, it is important to keep expectations positive and high for children. Children will work up to your
            expectations and necessary level, with the right encouragement, scaffolding, and modeling, as described in
            the teacher guide and professional development. While individual children may have challenges, use these
            challenges to understand how to best support the child’s learning rather than to lower expectations for what
            a child can achieve.
          </p>

          <p>
            The Scope and Sequence provided is valuable to ensuring that your planning will help typically-developing
            children are progressing in a way to make sure that they will be able to meet age-appropriate standards by
            the end of the school year. The Scope and Sequence covers an entire calendar year and breaks down the year’s
            standard into quarterly targets.
          </p>

          <p>
            When you think about the topics of scaffolding and the Zone of Proximal Development, presented earlier in
            this guide, breaking down skills into smaller steps along the way is a way to build competence of skills
            through practice and building along the way. Imagine if you were going to run a marathon on a certain date,
            there would be training along the way, building up endurance and distance over time, rather than arriving at
            the gate expecting to do well without the necessary steps along the way.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Learning Experiences / Linking the Standards -->
      <ng-container *ngIf="contentKey == 'linking'">
        <div class="body">
          <p>
            The term intentional has been used repeatedly in this teacher’s manual. As a teacher, intentionality and
            reflective practice are symbiotic - one cannot exist without the other. When thinking about the curriculum,
            the actual activities and experiences children take part in through the day; it is critical to plan the
            steps to help children achieve standards (state, local, or program) by the end of the program year.
            Standards are the finish line, but there are many incremental steps to get to that point.
          </p>

          <p>
            As the teacher, it is your job to plan, scaffold, and create experiences that tie to the incremental
            progress children will make through the year. It is never enough that an activity is cute or fun. It must be
            intentional, meaningful, engaging, and targeted toward building children’s competencies: focusing on what
            each child can do, challenging them, and planning the next steps.
          </p>

          <p>
            Learning Beyond Paper is designed to scaffold children’s skills across domains of development to meet
            general standards adopted by most states and programs. Your small groups will help you work strategically
            with children who need additional scaffolding and opportunities for practice.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Learning Experiences / Planning and Reflection -->
      <ng-container *ngIf="contentKey == 'planning&reflection'">
        <div class="body">
          <p>
            You should plan regularly, updating plans based on the curriculum as well as observation of the children in
            your class. As you complete any progress monitoring or assessment, it is important to use these data to
            inform your next steps.
          </p>

          <p>
            Minimally, play with your colleagues that teach with you in the same class. For maximum benefit, work with
            the director to determine if planning can occur with other preschool teachers. You can gain efficiency, get
            additional ideas and insights, and build collaboration in this way.
          </p>

          <p>
            Throughout the day or by the end of the day, take a few minutes to make notes about the day. Within the
            week, have a planned time to actively reflect - with colleagues, if possible - on the learning based on what
            you have planned and how children responded to plans. This involves thinking about the progress of
            individual children, groups of children, and the group as a whole. It includes you thinking about your
            perspective, challenging yourself on things that you could do differently or better (even if you don’t yet
            know how to do something differently yet), and reflecting on what went well or what could have gone better.
            It is not about being critical or judgmental; rather, seeing things accurately and from different lenses.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Learning Experiences / Play and Authenticity -->
      <ng-container *ngIf="contentKey == 'play&authenticity'">
        <div class="body">
          <p>
            Play is the vehicle for learning with young children. Through play, children learn things more quickly in a
            way that sticks. Play is the manner in which children practice and refine their skills, have fun (a
            necessary component in learning), and try things they otherwise may not. Vygotsky famously stated, “In play,
            a child is always above his average age, above his daily behavior; in play, it is as though he were a head
            taller than himself.
          </p>

          <p>
            You can plan authentic experiences - solving real problems that come up throughout the day WITH children,
            involving children in set-up and clean-up, helping to make play dough for the class, and numerous activities
            that are necessary and important. Children prefer “real” to busy work and will remain attentive for longer
            when engaged and the vision is viewed as important.
          </p>

          <p>
            This is not to say that teacher-directed experiences are not important or necessary. There are some skills
            that are highly unlikely to spontaneously occur during play alone. Skills can be practiced in
            teacher-directed experiences (e.g. circle time, small groups, or even 1:1 experiences), but still retain the
            most benefit when this is done in a playful way where children’s senses are activated and there is active
            engagement.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Learning Experiences / Standards-Based Learning -->
      <ng-container *ngIf="contentKey == 'sb_learning'">
        <div class="body">
          <p>
            Early childhood educators, more than most people, realize that young children do not divide their learning
            into subjects or neat and tidy lessons. More often than not, learning comes through repeated experiences
            that build upon past experiences. The scaffolding, modeling, and language support you provide as the teacher
            is invaluable to each child’s development. To provide the best scaffolding and ability for children to reach
            their full potential, you must understand the standards your program uses well. Often, standards are known
            as child outcomes or Early Learning and Development Guidelines (ELDG), and these terms are interchangeable
            in this guide. These will vary from state to state, or even by program funding (i.e., state PreK or Head
            Start), but these documents have many commonalities. Visit the Resources section to view additional support
            and links.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Role-of-Assessment / Child Assessment -->
      <ng-container *ngIf="contentKey == 'child_level'">
        <div class="body">

          <p>Purpose and Big Picture:</p>

          <p>Ongoing assessment is an essential component of quality preschool programs. When teachers have detailed
            knowledge about their student's developmental skill levels, they can more effectively scaffold or
            differentiate learning. Progress monitoring and assessment, ongoing throughout the year, offer a way to see
            all aspects of the child’s and class’ developmental and skill growth throughout the year.</p>

          <p>You will have an individual relationship with each child, yet it is important to not let that relationship
            sway your data collection when assessing or using progress monitoring. Remain neutral, with a clear lens.
            Later, after data collection, you will interpret the results to understand what you have collected. Visit
            the Resources section for information on NAEYC’s Advancing Equity Recommendations for Early Childhood
            Educators</p>

          <p>Types of Assessment and Progress Monitoring:</p>

          <ul class="list">
            Progress monitoring assessments:
            <li>Anecdotal Notes - Narrative writing about exactly what is seen as a child is playing or working. </li>
            <li>Checklists - A means of noting if a child has completed something, or has a level of skill (e.g., not
              yet, progressing, mastered)</li>
            <li>Screenings - provide a "developmental snapshot" of the child, based upon defined standards. They yield
              information that helps determine individualized learning objectives and the need for formal evaluation
            </li>
            <li>Work Samples - A child’s work, dated, related to some aspect of development or a standard. Work Samples
              are sometimes called artifacts, such as a piece of artwork, a photograph of a block structure, a picture,
              or an audio or video clip.</li>
          </ul>

          <p>Using Child-Level Assessment Results:</p>

          <p>
            Sometimes, various assessments are required to satisfy a supervisor or a funding source. Regardless of the
            “why” - when data - any sort of assessment or progress monitoring - is collected on a child, you have an
            ethical responsibility to maintain confidentiality of individual results AND to use that information to
            benefit the child. It is important to look at various (multiple) data points to see what story the data
            tells. This is often called triangulating data. Is the child progressing as expected, experiencing
            unexpected challenges in one area or another? We do not want to depend upon one data point, as it may or may
            not be an accurate depiction of the child
          </p>

          <p>
            It is important to look at individual child results AND aggregate results (look at the entire class) too.
            This will help you understand where the class is as a whole, which children may need more support in small
            groups, or if there is a child struggling that may need a different look to understand them better.
          </p>

          <p>
            These results should be intentionally used as a part of planning. If reviewing and interpreting data is new
            to you, do not worry. Everyone must start somewhere. Practice makes permanence. Ask your director or a more
            experienced staff person for support too. This is also an advantage of planning in collaboration with other
            teachers because this opportunity can be a place where assessment results are discussed, confidentially, to
            increase understanding
          </p>


          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p>Learning Beyond Paper Progress Monitoring:</p>

          <p>
            The LBP Progress Monitoring Tool should be used as described in the Professional Development to understand
            how children are growing and learning in your classroom. We always want to look at children with a
            strengths-based perspective - what they CAN do, where their strengths ARE - and build from this. If a child
            does not have a skill, we frame it as “not yet” - indicating a positive frame of reference (“You cannot do
            it yet, but you’ll get it. We are going to work on it”).
          </p>

          <p>Ages and Stages Questionnaires (ASQ):</p>

          <p>The Ages and Stages Questionnaires-3 (ASQ-3) is a very useful tool, based on the child’s age, to understand
            and support healthy child development. It is not specific to a particular curriculum or approach.</p>

          <p>
            The ASQ-3 is a comprehensive screening tool that covers 5 developmental areas: communication, gross motor,
            fine motor, problem solving, and personal-social. There are 6 questions per area, and it is rated yes,
            sometimes, and not yet. The ASQ-3 can be completed by the parent or caregiver - or both. At the preschool
            level, ASQ-3 is available every 6 months, at 36, 42, 48, 54, and 60 months. The ASQ-3 is currently available
            in English, Spanish, Arabic, Chinese, French, and Vietnamese.
          </p>

          <p>
            A second, dedicated screening tool, Ages and Stages Questionnaires: Social-Emotional, Second Edition
            (ASQ:SE-2) is available to better understand a child’s social-emotional development in the areas of
            self-regulation, compliance, social-communication, adaptive functioning, autonomy, affect, and interaction
            with people. The ASQ:SE-2 is completed by a parent. At the preschool level, the ASQ:SE-2 is available at the
            36, 48, and 60-month intervals. It is available in English, Arabic, French, and Spanish. It is rated by the
            parent as “often or always,” “sometimes,” or “rarely or never.”
          </p>

          <p>
            Both tools can be used as a paper version or online. The tools have been rigorously tested and can help
            identify a child who may be at risk for developmental delays.
          </p>

          <p>
            For more information and purchase of materials and professional development, visit the Resources section.
          </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_2l,'img')" src="{{  resources.resource_2l }}" alt="">
            <img (click)="openBunner($event, resources.resource_2m,'img')" src="{{  resources.resource_2m }}" alt="">
            <img (click)="openBunner($event, resources.resource_2r,'img')" src="{{  resources.resource_2r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Role-of-Assessment / Introduction -->
      <ng-container *ngIf="contentKey == 'ra_introduction'">
        <div class="body">
          <p> Assessment is an organized way of collecting and measuring information about a particular topic. It plays
            an integral role in the preschool classroom. Depending on the type utilized, it can yield data that: informs
            individualized learning goals, shapes classroom instruction, signals the need for further evaluation, or
            provides suggestions for classroom-wide improvement. </p>
          <p> Teaching young children requires a lot of precision. Knowing what to do, when to do it, and generally how
            to put the pieces together in a way that makes each day full of meaning and opportunity, learning within an
            emotionally safe space. Teaching is both an art and a craft that can be further honed by continuous learning
            and reflection cycles.</p>
          <p> You may be fortunate enough to have an early childhood coach - either as a separate role or as a part of a
            position that a director may play. Unlike a sports team or an orchestra conductor, most of your work will
            not happen in the presence of a coach. There are tools to help you reflect on practices good teachers
            develop to make children’s learning in your classroom the best experience possible. </p>
          <p> There are also means to look at quality at a program-wide level. Tools that look at an entire program are
            also crucial because greater consistency and quality across all classrooms usually means a better experience
            for children and families. It can say that the workplace’s quality is high, and employee satisfaction is
            essential to further opportunities and keep valued staff working together and moving in the same direction.
          </p>
          <p> Depending on any federal, state, or local funding your program receives, you or your program may be
            required to use specific tools as a part of your requirements. Even if required, it is crucial to go beyond
            the mindset of “I completed this requirement.” and use both the tool and the data gathered responsibly. Any
            time that data is collected, you have an ethical responsibility to children to use that data (information)
            to help improve yourself and the program for the benefit of children’s learning and development.</p>
          <p> Included in this section are some common classroom-level and program-level assessments but note this list
            may not be exhaustive.</p>


          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Role-of-Assessment/ Classroom Level -->
      <ng-container *ngIf="contentKey == 'classroom_level'">
        <div class="body">

          <p>CLASS (Classroom Assessment Scoring System) Tool:</p>

          <p>The CLASS tool measures caregiver interactions from birth through 12th grade. There are different
            iterations for various age groups. A caregiver can be in either a home or classroom setting and denotes the
            process of teachers and caregivers interacting with children, separate from the content taught.</p>
          <p>CLASS is essential because it looks at the experienced interactions a child may have throughout the day
            with all staff in the classroom - it measures the “how” teachers are going about their practice rather than
            “what” they are teaching. Children learn from those they have a relationship connection to, which amplifies
            why the “how” is very important to children’s learning. Relationships are the heart of successful
            interactions that lead to positive development and learning for children.</p>
          <p>Using the CLASS tool to understand how well a classroom is interacting, and to drive teachers to improve
            their interactions, can be an essential step in making a good preschool classroom GREAT. For children,
            teachers who promote interactions can make each day better for young children’s learning. Higher quality
            interactions promote greater readiness for kindergarten and better success down the road - fewer repeated
            grades, higher graduation rates, and more likely to attend college.</p>
          <p>The Pre-K CLASS tool is designed for use in preschool classrooms with 3- and 4-year-olds. Organized around
            three main areas called domains: emotional/behavioral support, classroom organization, and instructional
            support, each domain has various dimensions that include items looking more precisely at teacher
            interactions with young children. Each item (42 total), dimension, and the overall score is on a scale from
            1 to 7, with scores in the 1-2 range as low, scores in the 3-5 range as middle, and scores of 6-7 as high.
          </p>
          <p>Certain domains and dimensions are easier to score higher on, while others are more challenging to score
            high on. </p>

          <p>Getting Started with CLASS:</p>

          <p>Your classroom can get started by having a Pre-K CLASS observer come to complete an assessment. The
            Director often organizes observations, as often a program will have every classroom observed. The assessment
            will typically take a couple of hours, with the observer carefully watching teachers interact with children
            throughout the day’s routine and taking some breaks from observation. The observer will take notes while
            trying to remain neutral (not interacting) with children and teachers. </p>
          <p>If you have not taken part in observations much, it can feel a bit nerve-wracking, but try your best to go
            about your day as naturally as possible. Be yourself. The observer is not looking for perfection from you or
            your children. Conversely, the observer is looking at how you handle the day and what occurs in a preschool
            classroom. Your children will notice and react or interact differently than you usually do.</p>

          <p>Receiving Your Pre-K CLASS Results:</p>

          <p> The CLASS observation will generate a rating for each dimension and an overall Pre-K CLASS score for the
            classroom. If the classroom has more than one teacher, the rating is based on all teachers, as children
            experience interactions from all teachers in the classroom.</p>
          <p> Regardless of the classroom’s rating, it is essential to note that having a starting point - a baseline -
            is vital to growth. CLASS is about observed practice, not your value. Do not take it personally, even though
            it can feel personal because teaching is a very personal calling. </p>
          <p> You are on a path to improving - all professionals should continue to improve. You need to know where you
            are so that you can make a plan for where you would like to go - where you would like to grow.</p>
          <p> Remember, there are some domains and dimensions that are easier to score higher on, while others are
            challenging to score high on. Most importantly, though, some small improvements to interactions can have
            grand changes in children’s outcomes. Keep focusing on how to improve your “how” by taking part in training
            such as MMCI (Making the Most of Childhood Interactions), or other opportunities available in person or
            online from Teachstone-approved trainers.</p>
          <p> For more information and purchase of materials and professional development, visit the Resources section.
          </p>

          <p>Environmental Rating Scales:</p>

          <p>Environmental rating scales look at the quality of a group program for a particular age of children. The
            Early Childhood Environmental Rating Scale(R), Third Edition (ECERS-3) is a tool to assess preschool
            classrooms (ages 3-5). </p>
          <p>Like CLASS, ECERS-3 is observational and uses a trained observer. The ECERS-3 includes six areas -
            subscales that look at a total of 35 items. The subscales are space and furnishings, personal care routines,
            language, and literacy, learning activities, interaction, and program structure.</p>
          <p>The results of the ECERS-3 can give a teacher information about how to improve their program to benefit
            children’s experience. It can also shed light for a director to better understand the classroom environment
            and workplace characteristics (e.g., dedicated break room or planning time away from children) that are
            important to staff longevity. </p>
          <p>There are ERS for infant and toddler classrooms, school-age programs, and family childcare homes. Some
            programs use ERS and CLASS in combination to have a multifocal lens on their program’s quality. More
            information about environmental rating scales, including the purchase of materials and professional
            development, can be found here within the Resources section. </p>

          <p class="epigraph">
            *Visit the Resources section for additional information on child-level assessments.
          </p>
        </div>
      </ng-container>

      <!-- Role-of-Assessment / Program Accreditation -->
      <ng-container *ngIf="contentKey == 'accreditation'">
        <div class="body">

          <p>
            Early Childhood Program Accreditation is a way for your program to self-reflect on practices. When ready, an
            outside entity (reviewer(s)) will evaluate the quality of your program based on its criteria. If your
            program selects a reputable accreditation, it may attract families and high-quality staff. Program
            accreditation may also be used in your state to determine funding levels or other criteria related to a
            local, state, or federal funding stream. If so, the funding stream usually determines the accreditation
            bodies that it accepts, so be sure the program accreditation your program seeks is on the approved list, if
            such a list exists.
          </p>

          <p>NAEYC Early Learning Program Accreditation:</p>

          <p>The NAEYC program accreditation has been around for more than 30 years. There are four stages: enroll and
            self-study, apply and self-assess, candidate for the site visit, and maintain accreditation. View the
            Resources section to learn more information on NAEYC’s program accreditation. </p>

          <p>Accredited Professional Preschool Learning Environment (APPLE):</p>

          <p>
            APPLE accreditation has existed for more than 20 years and focuses on balancing quality standards and the
            financial impact of implementing quality standards. There are five steps: enroll, self-study, on-site
            verification, accreditation, and accreditation continuance.
          </p>

          <p class="epigraph">
            *Visit the Recourses section for more information on Accreditation.
          </p>

        </div>
      </ng-container>

      <!-- Role-of-Assessment / Program Assessment -->
      <ng-container *ngIf="contentKey == 'program_level'">
        <div class="body">

          <p>
            Program-level assessments are important to program-wide reflection and change, viewing cross-classroom
            inconsistencies, and gathering information from varying sources. A program-level assessment usually follows
            a periodic basis (e.g., annual, semi-annual) as a way to take a pulse of the program. Some program-level
            assessments are used in conjunction with one another, or with classroom-level assessments to give a complete
            picture of how the program as a whole and parts are functioning.
          </p>

          <p>Early Education Essential Survey:</p>

          <p>
            The Early Education Essentials Survey, developed by the Ounce of Prevention Fund with the University of
            Chicago, is a newer tool developed to look at organizational conditions needed to grow quality to a level
            where the children benefit at the levels research shows are possible. Modeled after an elementary-age tool
            initially designed around the research on school improvement, the Early Education Essential Survey documents
            strong research
          </p>

          <p>
            This tool surveys teachers/staff as well as parents. The surveys, conducted with the University of Chicago,
            measure organizational conditions around six components or relational trust: effective instructional
            leaders, collaborative teachers, involved families, supportive environment, ambitious instruction, and
            parent voice. The resulting reports and data-use and improvement tools to make plans for change and
            improvement over time.
          </p>

          <p>
            More information about the Early Education Essentials in the Resources Section.
          </p>

          <p>Program Quality Assessments (PQAs):</p>

          <p>
            The Program Quality Assessment (PQA), developed by High Scope, has several iterations (i.e., infant-toddler,
            preschool, family childcare). PQAs have seven domains to review program quality and teacher strengths:
            learning environment, daily routine, adult-child interaction, curriculum planning and assessment, parent
            involvement and family services, staff qualifications and development, and program management.
          </p>

          <p class="epigraph">
            *Visit the Recourses section for more information on Program Level Assessment
          </p>

        </div>
      </ng-container>

      <!-- Family-Engagement / Authenticity -->
      <ng-container *ngIf="contentKey == 'authenticity'">
        <div class="body">

          <p>Partnering with families with authenticity is both a disposition and a belief. As a disposition, you are
            intentional with strategies to connect with families, and believe they are a partner who brings value to
            your work and into the life of their preschooler. When authenticity is a belief, you will have a true desire
            to partner with the family. </p>
          <p>For a teacher, being authentic means you continually work to build and maintain trust in the partnership
            and a desire to provide a quality program for the child in a safe, nurturing environment where the
            child’s/family’s needs and interests are priority. Relationships have their ups and downs and partnering
            with families is no different. As an authentic and intentional partner, you strive to maintain
            relationships, make changes as needed, and agree with the family to keep the child as the focus. The
            stronger your connection is with a family, the easier it is to have conversations when the topic is not as
            light-hearted - such as if there are developmental concerns or anything a family may be more sensitive
            about. </p>


          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Family-Engagement / Beginning Program -->
      <ng-container *ngIf="contentKey == 'beginning'">
        <div class="body">

          <p> The family is the key to building a relationship with children in your preschool classroom. We value the
            family as the child’s first teacher. The child’s family has the information needed to provide a safe,
            nurturing, learning environment where the child’s individual needs are met. </p>
          <p> Engaging families builds a partnership where there is trust, respect, and a common goal of positive
            communication to meet the child’s needs. As you learn more and more about the child and family, the
            relationship becomes stronger and positively supports the child’s growth and development. </p>
          <p> Family engagement starts with an understanding that families are diverse and interactions and
            opportunities for involvement need to support individual family choices. </p>
          <p> A partnership is when each party has the opportunity to be involved in decision making when asking for a
            commitment of time and involvement in program activities.</p>
          <p> Visit the Resources section to view NAEYC Advancing Equity Recommendations for Early Childhood Educators.
          </p>

          <p><strong>Beginning the Year:</strong></p>

          <p>Starting the year on the right foot by focusing on building the relationship. This begins when the child
            joins your program, regardless of the time of year. It also occurs when the child transitions to a new
            classroom. They may have familiarity with the program, but each new set of teachers must develop or
            strengthen their connections with families. Getting to know each child’s personality and strengths with the
            support of their family helps you plan better, because you can know the child, their interests, and what
            their family knows about how to work with their preschooler best.</p>
          <p>Teachers need to develop their plan, with guidance from the program’s leadership, and make it their own.
            One valuable strategy that many programs use is to have consistency in activities, such as open houses and
            conferences, sending consistent messaging to families about the class’ activities, or daily processes - and
            also room for teachers to individualize their approach within the classroom. This way, there is some
            consistency for families as children advance from class to class, or for families who have children in
            multiple rooms - and opportunity for an individual teacher personalization.</p>

          <p><strong>Connecting Strategies:</strong></p>

          <ul class="list">
            Here are some common, positive strategies to building a positive connection with the families of the
            children you teach.
            <li>Send a Welcome letter to the family prior to the beginning of the program. </li>
            <li>Schedule a time to meet with the family to find out about their child’s daily routines and interests.
            </li>
            <li>If you do not speak the families’ language check with your Director to request a translator. </li>
            <li>Ask families their preferred name and how to pronounce (if needed).</li>
            <li>Express your goal of wishing to be a partner in their child’s learning experiences. </li>
            <li>Ask open -ended questions to learn more about the child’s schedule, needs, and interests.</li>
            <li>Ask families their preferred method of communication to ensure home and program information is shared
              daily at the beginning and end of the day.</li>
            <li>Introduce the family to all adults who are assigned to their child’s room. </li>
            <li>Post pictures of room staff and provide a short biography.</li>
            <li>Ask the family for a picture to display on a family board. </li>
            <li>Smile and make eye contact as you greet the family by name. </li>
            <li>At the end of each day share something positive about the child that happened during the day.</li>
            <li>Remind families they are always welcome to visit. </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Family-Engagement / Languages -->
      <ng-container *ngIf="contentKey == 'languages'">
        <div class="body">

          <p>Developing a partnership with families requires an ability for the teacher and family to listen, comprehend
            and respond with one another. You may or may not speak the language(s) of the children and families you work
            with. Even if you do not, you can be open to connecting with the family, even though additional strategies
            and effort will be required on both ends. </p>

          <p>When there is a language barrier there needs to be accommodations to ensure lines of communication are open
            in both directions. The challenge is that you speak a different language than the family, not that they do
            not speak your language - an important perspective, especially if you speak the dominant language and the
            family does not. For example, you can say, “I speak English, and the family speaks a Mayan dialect,” rather
            than “They don’t speak English.” This reframes the challenge as a need to find ways of communicating rather
            than a dominance for one language over another.</p>

          <p>The United States is unique in that there is not a national language, and in the past many decades, the
            understanding of keeping home or cultural language, while learning the language(s) in the geographic area
            you reside in both have value. Home language is a part of a child’s roots - a connection to the family you
            see as well as their ancestors. </p>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

          <p><u>Language-Related Strategies</u></p>

          <ul class="list">
            <li>Collaborate with agencies serving families who may not speak the dominant language to access resources
              for yourself as you begin your journey to develop a partnership demonstrating an authentic interest in
              learning about each family’s culture. Sometimes, these agencies offer translation services.</li>
            <li>Demonstrate authenticity by creating an environment where families/children see a reflection of
              themselves through pictures, toys, books, learning experiences.</li>
            <li>Begin to learn phrases of greeting in the family’s language and each day make a point to connect with
              the family for an informal chat. </li>
            <li>If you do not speak the child’s language, find out some important basic words, such as hello,
              hungry/eat, thirsty/drink, bathroom, outside, inside, so that you can understand the child’s basic needs.
              Write the words/phrases phonetically (how they sound) so that you can practice using them.</li>
            <li>Use visuals to aid communicating and speak slower than normal pace if a family member understands some
              of your language. As someone is learning another language, it may be understandable to them if at a slower
              pace. </li>
            <li>Get suggestions from families of music you can play during greeting time that they find welcoming.</li>
            <li>Post signs in languages of the families you serve.</li>
            <li>Translate forms used in your classroom into languages of families you serve.</li>
            <li>Work with your program administrator to have a staff person who speaks a common language with families
              or a translator available for parent meetings, or even better, each day at drop off and pick-up times, in
              order to ensure clear and timely communication. If your program has many different languages represented,
              this becomes a bit more challenging, but it is important to coordinate with intentionality and shows an
              effort to create equity.</li>
            <li>Do not give up. Be open to continuing to find better and better solutions, learning more, and keeping
              the child’s growth and development needs at the heart of things. Your intentional effort is likely to be
              recognized by the family, even if there are challenges or misunderstandings that may occur. </li>
          </ul>

          <p class="epigraph">
            *Visit the Resources section for additional support on Languages
          </p>
        </div>
      </ng-container>

      <!-- Family-Engagement / Partnering -->
      <ng-container *ngIf="contentKey == 'partnering'">
        <div class="body">

          <p>The following are strategies for partnering with families in an authentic and genuine way, particularly
            when you need to communicate with them about their preschooler:</p>

          <ul class="list">
            <li>When communicating with families, make a list of points you wish to cover. </li>
            <li>Introduce yourself and share the purpose of the communication. Is it to share information (more one-way)
              or discuss a concern?</li>
            <li>Begin your sentences with genuine positive points about their child and use “I” statements such as “I
              have noticed”, “I am wondering”, “I am concerned”, this is less likely to cause defensiveness. </li>
            <li>Be sure to reinforce that you recognize the family is their child’s first teacher and as an educator the
              knowledge they can provide is important to the child’s progress. </li>
            <li>Listen first, maintain eye contact, and respond by restating what you believe was shared. </li>
            <li>Invite families to take part in decision making when discussing a concern. </li>
            <li>Send a follow-up email thanking the family for taking the time to meet with you summarizing decisions
              which may have been made. </li>
            <li>Reflect weekly on the ways you have reached out to families in building a positive relationship.</li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Family-Engagement / Understanding Perspectives -->
      <ng-container *ngIf="contentKey == 'perspectives'">
        <div class="body">

          <p>
            Developing a positive relationship with families starts with empathy. Consider the perspective of a family
            who is dropping off their child for the first time trusting they will be in a safe, nurturing environment.
            Additionally, family perspectives come from the parent(s) and their unique experiences related to where and
            how they were raised, including cultural and religious beliefs. Sometimes, it is easy to connect with
            families who are similar to you in one way or another, but ALL families and children you work with deserve
            understanding and differences in perspectives is healthy. Remember that as a professional, you have an
            obligation to work well with children’s families as the child’s first - and most important - teacher.
          </p>

          <p>
            What is empathy? Here are two definitions related to the early childhood education profession:
          </p>

          <p>
            “Empathy is the ability to feel what the child or family member is feeling, understand what the child or
            family member is feeling, communicate that understanding to them, and then respond in ways that meet their
            needs.” <br>
            -Peck, Maude, Brotherson
          </p>

          <p>“Empathy also helps us understand people whose values, views, and behaviors are different from our own.”
            <br>
            -Calloway-Thomas
          </p>

          <p><strong>Perspective-Building Strategies:</strong></p>

          <ul class="list">
            <li>Practice “reading” non-verbal messages from families, with the understanding that non-verbal messages
              can be different from region to region or based on cultural background.</li>
            <li>Get to know your own non-verbal messages. Sometimes, if what we hear is not similar to our own
              experience, our faces or body reacts in a way that sends a message of non-acceptance or discomfort.
              Practice keeping neutral or positive non-verbal messaging, so that there is not unintentional judgement
              felt by a family.</li>
            <li>Invite families to share information about themselves and their child by asking them to complete a
              survey. Ask your Director for assistance in developing a survey if the program does not presently have
              one.</li>
            <li>Ask open-ended questions to provide the family with the opportunity to choose what they wish to share
              about their family structure, culture, interests, hobbies. </li>
            <li>Create a welcoming atmosphere for everyone and celebrate who they are - similarities and differences.
            </li>
            <li>Consider families as a partner in decision making as they know their child’s strengths and needs. </li>
            <li>A list of community agencies might be helpful to connect families to resources when families express a
              need. </li>
          </ul>

          <div class="content images">
            <img (click)="openBunner($event, resources.resource_1l,'img')" src="{{  resources.resource_1l }}" alt="">
            <img (click)="openBunner($event, resources.resource_1m,'img')" src="{{  resources.resource_1m }}" alt="">
            <img (click)="openBunner($event, resources.resource_1r,'img')" src="{{  resources.resource_1r }}" alt="">
          </div>

        </div>
      </ng-container>

      <!-- Using-Learning-Beyond /  Getting Started  -->
      <ng-container *ngIf="contentKey == 'start'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Using-Learning-Beyond /  Finding What You Need -->
      <ng-container *ngIf="contentKey == 'finding'">
        <div class="body">
          <div class="video_block">
             <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
               <video playsinline src="{{ resources.resource_1m }}">
               </video>
               <div class="triangle"></div>
             </div>
             </div>
       </div>
      </ng-container>

      <!--Teacher Interactions  / Webinar -->
      <ng-container *ngIf="contentKey == 'ti_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Classroom Environment / Webinar -->
      <ng-container *ngIf="contentKey == 'ce_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Classroom Structure / Webinar -->
      <ng-container *ngIf="contentKey == 'cs_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Learning Experiences / Webinar -->
      <ng-container *ngIf="contentKey == 'le_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Role of Assessments / Webinar -->
      <ng-container *ngIf="contentKey == 'ra_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Family Engagement / Webinar -->
      <ng-container *ngIf="contentKey == 'fe_webinar'">
        <div class="body">
          <div class="video_block">
            <div (click)="openBunner($event, resources.resource_1m,'video')" class="video">
              <video playsinline src="{{ resources.resource_1m }}">
              </video>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="bunner.state" class="_bunner">
      <div class="_image" (clickOutside)="onClickedOutside($event)">
        <img *ngIf="bunner.type == 'img'" src="{{bunner.src}}" alt="">
        <video *ngIf="bunner.type == 'video'" id="movie" width="640" height="360" autoplay preload controls>
          <source src="{{bunner.src}}" />
          <object id="flowplayer" type="application/x-shockwave-flash" width="640" height="360"></object>
        </video>
        <div *ngIf="bunner.type == 'img'" class="_button" (click)="bunner.state = false"> <span class="_close"></span>
        </div>
      </div>
    </div>
  </div>
</div>