<div class="wrapper">
  <app-menu   [submenu]='true' [details]="title" [school]="school"></app-menu>

  <div class="container-lg">
    <div class="row mt-3">
        <div class="col-sm-6 col-md-4 col-lg-3" *ngFor='let standard of standards'>
          <div class="card mb-3" (click)="onStandardChoose(standard.key)">
            <img class="card-img" src="/assets/img/modes/standards/{{standard.key}}.jpg" alt="{{standard.title}}">
            <div class="card-title-bottom bg-primary d-flex justify-content-center text-light p-2 font-weight-bold">
              {{standard.titleKey | translate}}
            </div>
          </div>
        </div>                              
    </div>
  </div>
  
</div>