<app-menu [home]="false" [pageAdmin]="true" [unknownPage]="unknownPage" [school]="currentSchool"></app-menu>
<app-filterbyschool-component (onChange)="onChangeSchool($event)" [back]='true'></app-filterbyschool-component>
<!-- if user have one or more schools -->
<div >
    <div class="wrapperPage container-lg">
        <div class="blockWrapperButtons">
            <div class="groupButtons" *ngIf="currentSchool.school_type != 'Clever'">
                <button (click)="onCreateClass(currentSchool.idnumber)">{{ 'admin_shared.new_class' | translate}}</button>
            </div>

            <div class="groupButtons" (click)="syncAllClasses()" *ngIf="currentSchool.school_type == 'Clever'">
                <button>{{ 'clever.sync_classes' | translate}}</button>
            </div>

            <div class="wrapperInputElement">
                <p *ngIf="currentSchool.school_type != 'Clever'">
                    <label for="closedclas">{{ 'admin_shared.show_closed_classes' | translate}}</label>
                    <input type="checkbox"  class="checkboxClosedClasses" [(ngModel)]="showClosedClasses" id="closedclas"/> 
                </p>
                <p>{{'shared.search' | translate}}: <input type="text" placeholder="{{ 'admin_shared.cm_placeholder' | translate}}" 
                                [(ngModel)]="findingClas" /></p>

            </div>
        </div>

        <div class="tableCustom"  [ngClass]="{'topCleverTable': currentSchool.school_type == 'Clever' }">
            <div class="rowCustom header">
                <div class="cell firstcolumn">{{'shared.class' | translate}}</div>
                <div class="cell date">{{ 'admin_shared.period' | translate}}</div>
                <div class="cell mainTeacher">{{ 'admin_shared.main_teacher' | translate}}</div>
                <div class="cell action">{{'assessment.actions' | translate}}</div>
            </div>
            <div class="rowCustom" *ngFor="let class of listClasses |  filter: findingClas: 'name': showClosedClasses">
                <div class="cell firstcolumn">
                     <div>{{class.name}}</div> 
                     <div>{{getAgeGroupTitle(class.age_group) | translate}}</div>
                </div>
                <div class="cell date">
                        {{class.date_begin ? getLocaleDate(class.date_begin) : ''}} <br>
                        {{class.date_end ? getLocaleDate(class.date_end) : ''}}</div>
                <div class="cell mainTeacher">{{class.main_tchr_first}} {{class.main_tchr_last}}</div>
                <div class="cell action">
                    <div class="icon edit" (click)="onEditClass(currentSchool.idnumber, class.idnumber)"></div>
                    <div class="icon delete" (click)="onDeleteClass(class)"></div>
                </div>
            </div>
        </div>

        <div class="groupButtons cleverBtns"  *ngIf="currentSchool.school_type== 'Clever'">
            <button (click)="loadCleverCasses()">{{ 'clever.load_classes' | translate}}</button>
        </div>

        <div class="blockWrapperButtons clever" *ngIf="cleverBlock">

            <div class="wrapperInputElement cleverFiters">
               <input type="text" placeholder="{{ 'clever.search_by_cn_id' | translate}}" [(ngModel)]="findingClasByID" />

               <input type="text" placeholder="{{ 'clever.search_by_tn' | translate}}" [(ngModel)]="findingClasByTeacherName" />
            </div>
        </div>

        <div class="tableCustom bottomCleverTable"  *ngIf="cleverBlock">
            <div class="rowCustom header">
                <div class="cell firstcolumn">{{'clever.id' | translate}}</div>
                <div class="cell className">{{ 'clever.class_name' | translate}}</div>
                <div class="cell mainTeacher">{{ 'admin_shared.main_teacher' | translate}}</div>
                <div class="cell action">{{'assessment.actions' | translate}}</div>
            </div>
            <div class="rowCustom" *ngFor="let class of listCleverClasses 
            |  filter: findingClasByID: 'idnumber': true 
            |  filter: findingClasByTeacherName: 'teacher_first_name': true">

                <div class="cell firstcolumn">
                     <div>{{class.idnumber}}</div> 
                     <div>{{getAgeGroupTitle(class.age_group) | translate}}</div>
                </div>
                <div class="cell className">
                    <div>{{class.name}}</div>
                </div>
                <div class="cell mainTeacher">
                    <div> {{class.teacher_first_name}} {{class.teacher_last_name }}</div>
                </div>
                <div class="cell action">
                    <div class="icon assign" (click)="syncClass(class)"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--end block -->
<!-- If user dont have school -->
<div *ngIf="listSchools && listSchools.length == 0" class="msgNoSchool container-lg">
    {{ 'admin_shared.user_doesnt_have_school' | translate}}
</div>
<!-- end block -->


<!-- Loader spinner-->
<div class="myloader" *ngIf="!loading">
    <loader></loader>
</div>
<!--end spinner -->