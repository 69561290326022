import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { IResource } from 'src/app/services';

@Component({
  selector: 'app-home-connection',
  templateUrl: './home-connection.component.html',
  styleUrls: ['./home-connection.component.scss'],
})
export class HomeConnectionComponent implements OnChanges {
  public url: string = null;
  public local: boolean = true;

  @Input()
  public resources: Array<IResource> = null;

  constructor(private sanitizer: DomSanitizer) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.resources) {
      this.url = this.resources.length > 0 ? this.resources[0].data : null;

      if (this.url) {
        this.local = this.url.startsWith('data');
      }
    } else {
      this.url = null;
    }
  }

  public vmLink() {
    return this.sanitizer.bypassSecurityTrustUrl(this.url);
  }

}
