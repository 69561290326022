import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { FilterPipe }  from './FilterPipe';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { TranslateService } from '@ngx-translate/core';
import {  AppState } from 'src/app/services';
@Component({
    selector: 'app-classmanager',
    templateUrl: './classmanagement.component.html',
    styleUrls: ['./classmanagement.component.scss'],
  })

export class ClassManagementComponent implements OnInit {

    public unknownPage: string = 'admin_home.class_management';
    
    public listSchools: ISchool[];
    public currentSchool: ISchool;
    public listClasses: IClass[]; 
    public listCleverClasses: any[] = [];
    public findingClas: string;
    public findingClasByID: string;
    public findingClasByTeacherName: string;
    public showClosedClasses: boolean;
    public cleverBlock: boolean = false;
    public loading: boolean = false;
   
    constructor(private adminService: AdminService,
                private teacherService: TeacherService,
                private router: Router,
                private appState: AppState,
                private activatedRoute: ActivatedRoute,
                private translateService: TranslateService ) {

    }

    public ngOnInit(): void {
    
        this.currentSchool = this.appState.restore('school');    
    }
    
    public load() {
      
        this.getClasses()
    }

    public onChangeSchool(school): void {

        this.currentSchool = school;
        this.loading = false;
        this.load();
    }

    public getAgeGroupTitle(idAge) {

       let age = this.adminService.getAgeGroup().find((x) => x.key === idAge);
        return age ? age.title : null;
    }

    public onBack() {
        this.router.navigate(['/admin/home']);
    }

    public onEditClass(idschool: string, idclass: string) {
        this.router.navigate([idschool, idclass], { relativeTo: this.activatedRoute })
    }

    public onDeleteClass(item: any) {

        if (confirm(this.translateService.instant('admin_import.are_you_sure'))) {
            // delete class and delete from array for view on page
            this.loading = false;

            this.adminService.deleteClass(item.idnumber).then(() => {

                this.listClasses = this.listClasses.filter((x) => x.idnumber != item.idnumber);

                if( this.currentSchool.school_type == 'Clever' ) {
                    
                    this.listCleverClasses = [...this.listCleverClasses,  {
                        'age_group': item.age_group,
                        'date_begin': item.date_begin,
                        'date_end': item.date_end,
                        'idnumber': item.idnumber,
                        'teacher_first_name': item.teacher_last_name,
                        'teacher_last_name': item.main_tchr_last,
                        'main_tchr_username': item.teacher,
                        'name': item.name
                    }].sort(this.sortByName);
                }
            
                this.adminService.resetClassesCache();
                this.loading = true;

            }).catch((error) => {
                
                this.loading = true;
                console.log(this.translateService.instant('admin_import.error_delete_class'), error);
            });
        }        
    }

    public onCreateClass(idschool: string) {

        this.router.navigate([idschool], { relativeTo: this.activatedRoute })
    }
    
    public getLocaleDate(date) {
        
        let _date = new Date(date * 1000);

        return  _date.toLocaleDateString();
    }

    public getClasses(){

        this.adminService.getClasses(this.currentSchool.idnumber).then((listClasses: IClass[]) => {

            this.listClasses = listClasses.sort(this.sortByName);
            this.loading = true;

        }).catch((error) => {

            console.log('Error Loading Classes: ', error);
            
        });

    }
    
    public getCLeverClassaes(){

        this.adminService.getCleverClassListBySchool(this.currentSchool.idnumber, this.currentSchool.district).then((listCleverClasses: IClass[]) => {

            this.listCleverClasses = listCleverClasses.filter( cleverClass => 
                !this.listClasses.some( item => cleverClass.idnumber === item.idnumber)
            ).sort(this.sortByName)

            this.loading = true;
        }).catch((error) => {
            console.log('Error Loading Classes: ', error);
        });
    }
    
    public loadCleverCasses(){

        this.cleverBlock = true;
        this.loading = false;
        this.getCLeverClassaes()
    }

    public syncClass(item:any) {

        this.loading = false;

        let district_id = this.currentSchool.district;
        let school_id = this.currentSchool.idnumber;
        let class_id = item.idnumber;

        this.adminService.syncClass(district_id, school_id, class_id)
            .then(response => {

                this.updateLists(item)
                this.loading = true;
            })
            
            .catch(error => { 
                this.loading = true;
                console.error('Error syncing class:', error);
            });
    }

    public async syncAllClasses() {

        this.loading = false;
        for (const item of this.listClasses) {
            let district_id = this.currentSchool.district;
            let school_id = this.currentSchool.idnumber;
            let class_id = item.idnumber;
    
            try {
                await this.adminService.syncClass(district_id, school_id, class_id);
                
            } catch (error) {
                console.error('Error syncing class:', class_id, error);
                this.loading = true;
                return; 
            }
        }
        
        this.adminService.resetClassesCache(); 
        this.adminService.resetChildrenByClassCache();  
        this.adminService.resetChildrenCache();  
        this.adminService.resetTeacherByClassCache();  
        this.adminService.resetTeachersCache();   
        console.log('All classes have been synced successfully');
        this.loading = true;
    }
    
    public updateLists(item: any){

        this.listClasses = [...this.listClasses, {
            'age_group': item.age_group,
            'date_begin': item.date_begin,
            'date_end': item.date_end,
            'idnumber': item.idnumber,
            'main_tchr_first': item.teacher_first_name,
            'main_tchr_last': item.teacher_last_name,
            'main_tchr_username': item.teacher,
            'name': item.name
        }].sort(this.sortByName);
        
        this.adminService.resetClassesCache();  
        
        this.listCleverClasses = this.listCleverClasses.filter(cleverClass => cleverClass.idnumber !== item.idnumber).sort(this.sortByName);
    }

    public sortByName(a, b) {
        if (a.name < b.name) {
            return -1;
        } else if (a.name > b.name) {
            return 1;
        } else {
            return 0;
        }
    }
}