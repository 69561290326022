import { Component, OnInit } from '@angular/core';
import { AdminService, TeacherService } from 'src/app/services';
import { LoginService } from '../../services/login.service';
import { AppState } from '../../services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'after-login',
  providers: [LoginService],
  templateUrl: './after-login.component.html',
  styleUrls: ['./after-login.component.scss']
})
export class AfterLoginComponent implements OnInit {
  
  public accessError: boolean = false;
  public projectError: boolean = false;
  public message: string = '';
  public loading: boolean = false;

  constructor(
    private router: Router,
    public appState: AppState,
    private loginService: LoginService,
    private adminService: AdminService,
    private teacherService: TeacherService,
    private translateService: TranslateService

  ) { }

  ngOnInit(): void {
    
    this.handlePostLoginTasks();
  }

 public handlePostLoginTasks(): void {
    // Get CDN Access.
    // Get profile and navigate, in any `CDN Access` response.
    this.loginService.getCdnAccess().subscribe(
      () => {
        this.getProfile();
      },
      (error) => {
        this.getProfile();
      }
    );
  }

  private getProfile() {

    this.loginService.profile().subscribe((profile) => {
      
      const isLBP = profile.project === 'lbp' ? true : false;
      const isTeacher = profile.roles.includes('teacher');
      const isEditor = profile.roles.includes('coursecreator');
      const isAdmin = profile.roles.includes('schooladmin');
      const isStudent = profile.roles.includes('student') && profile.roles.length == 1;
     

      if (profile.lang) {
        this.appState.store('language',profile.lang, true);
        this.translateService.use(profile.lang);
      }

      // reset teacher and admin caches on login
      this.adminService.resetAllCache();
      this.teacherService.resetCache();

      if (isLBP) {
        if (profile.email == 'teacher@learningbeyondpaper.com') {
          this.router.navigate(['/email-verification']);
        } else {
          if (isAdmin) {
            this.adminService.resetAllCache();
            this.router.navigate(['/admin']);
          } else if (isTeacher) {
            this.router.navigate(['/teacher']);
          } else if (isEditor) {
            this.router.navigate(['/editor']);
          } else if (isStudent) {
            this.router.navigate(['/parent']);
          } else {
            this.accessError = true;
          }
        }
      } else {
        this.message = 'User does not exist in LBP';
        this.projectError = this.loading = true;
      }
    });
  }
}