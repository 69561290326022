import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';

@Component({
  selector: 'app-language-filter',
  templateUrl: '../base/filter-base.component.html',
  styleUrls: ['../base/filter-base.component.scss'],
  // tslint:disable-next-line: no-outputs-metadata-property
  outputs: ['onSelect', 'onTemplateClear'],
})
export class LanguageFilterComponent extends FilterBaseComponent implements OnInit, OnChanges {
  title = 'Language';

  @Input()
  public reset: boolean = false;

  @Input()
  public set isTemplateChanged(value: boolean) {
    this.templateChanged = value;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.options = [
      { title: 'English', value: 'en' },
      { title: 'Spanish', value: 'es' },
    ];
  }

  public ngOnChanges(): void {
    if (this.reset) {
      this.value = null;
      this.reset = false;
    }
  }
}
