import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AppState } from '../services/app.service';
import { of } from 'rxjs';

@Injectable()
export class BaseGuard implements CanActivate {

  protected roleToCheck: string;
  protected redirectUrl: string;
  protected router: Router;
  protected appState: AppState;

  public canActivate() {
    // no need to check for any roles
    if (!this.roleToCheck) {
       return of(false);
    }

    // get current user role
    let profile = this.appState.restore('profile');

    if (!profile) {
        // if not found than redirect to login page
        this.redirectTo();
        return of(false);
    } else {
        
        if (this.checkAdminRole(profile) && this.roleToCheck === 'teacher') {
          return of(true);
        }

        //check user roles
        let checked = this.checkUserRoles(profile);

        if (checked) {
            // if role is avaialble than allowe to proceed
            return of(true);
        } else {
            // otherwise block access and redirect to login page
            this.redirectTo();
            return of(false);
        }
    }
  };

  public checkUserRoles(userInfo) {
      return userInfo.roles && userInfo.roles.some((role) => this.roleToCheck === role);
  };

  // if user has role 'schooladmin' - he can go to teacher page
  public checkAdminRole(userInfo) {
      return userInfo.roles && userInfo.roles.some((role) => role === 'schooladmin');
  };

  public redirectTo() {
    // not logged in so redirect to sso page
      this.router.navigate([this.redirectUrl]);
  };

}
