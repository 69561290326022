import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/services/app.service';
import { SwipeService } from 'src/app/services';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    providers: []
})
export class FiltersComponent implements OnInit, OnDestroy {

    public week: number = 1;
    public day: number = 1;
  
    // enable / disable flags
    @Input() public showWeek: boolean = false;
    @Input() public showDay: boolean = false;
    @Input() public useSwipe: boolean = true;  
    @Input() public backBtnDisabled: boolean = false;
    @Input() public showAge: boolean = false;

    // week / day changed event for external subscribers
    @Output() public weekChanged: EventEmitter<number> = new EventEmitter();
    @Output() public dayChanged: EventEmitter<number> = new EventEmitter();
    @Output() public _ageChanged = new EventEmitter();

    // internal swipe service subscriptions
    private swipeLeftSubscription: Subscription;
    private swipeRightSubscription: Subscription;

    public dayPicker: boolean = false;
    public weekPicker: boolean = false;
    public backBtnState: boolean = true;

    public weeks: Number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52]
    public days: Number[] = [1,2,3,4,5]

    constructor (private appState: AppState, private swipeService: SwipeService) { }

    ngOnInit(): void {
        
        // get initial value for week / day
         let _day =  this.appState.restore('day');
         let _week =  this.appState.restore('week');

         if ( _day ) {
            this.day = parseInt(_day);
         } else{

            this.appState.store('day', 1, true);
            this.day = parseInt( this.appState.restore('day'));
         }

         if ( _week ) {
             this.week = parseInt(_week);     
         } else {

            this.appState.store('week', 1, true);
            this.week = parseInt( this.appState.restore('week'));
         }

         if (this.backBtnDisabled) this.backBtnState = false;
        // subscribe to swipe service
        if (this.useSwipe) {
            this.swipeLeftSubscription = this.swipeService.onSwipeLeft.subscribe(() => this.swipeLeft());
            this.swipeRightSubscription = this.swipeService.onSwipeRight.subscribe(() => this.swipeRight());
        }
    }

    ngOnDestroy(): void {

        // unsubscribe from swipe service
        // to prevent double subscription
        if (!!this.swipeLeftSubscription) {
            this.swipeLeftSubscription.unsubscribe();
        }
        if (!!this.swipeRightSubscription) {
            this.swipeRightSubscription.unsubscribe();
        }
    }


    public back () {
        history.back();
    }
    
    public onClickedOutside( e: Event , picker: string) {
        if( this.dayPicker || this.weekPicker ){
            if( picker === 'weekPicker'){
                this.weekPicker = false;
            } 
            if( picker === 'dayPicker'){
                this.dayPicker =  false;
            }
        }
    }

 
    public calendarToggle ( e: Event, calendar: string){
        e.stopPropagation()
        e.preventDefault()

        if( calendar === 'weeks'){
            this.weekPicker = !this.weekPicker;
        }
        if( calendar === 'days'){
            this.dayPicker =!this.dayPicker;
        }
    }

    public weekIncrease() {
        this.week = this.week < 52 ? this.week + 1: 1;
        this.appState.store('week', this.week, true);
        this.weekChanged.emit(this.week);
    }

    public weekDecrease() {
        this.week = this.week > 1 ? this.week - 1: 52;
        this.appState.store('week', this.week, true);
        this.weekChanged.emit(this.week);
    }

    public dayIncrease() {

        if (this.day === 5) {
            this.day = 1;
            this.weekIncrease();
        } else {
            this.day++;
        }

        this.appState.store('day', this.day, true);
        this.dayChanged.emit(this.day);
    }

    public onWeekClick(week){
        this.week = week;
        this.appState.store('week', this.week, true);
        this.weekChanged.emit(this.week);
        this.weekPicker = false;
    }

    public onDayClick(day){   
        this.day = day;
        this.appState.store('day', this.day, true);
        this.dayChanged.emit(this.day);
        this.dayPicker = false;
    }

    public dayDecrease() {

        if (this.day === 1) {
            this.day = 5;
            this.weekDecrease();
        } else {
            this.day--;
        }

        this.appState.store('day', this.day, true);
        this.dayChanged.emit(this.day);
    }
    public ageChanged(age) {

      this._ageChanged.emit(age);
    }

    private swipeLeft() {

        // if day filter is active increment day
        // else if week filter is active increment week
        // otherwise do nothing
        if (this.showDay) {
            this.dayIncrease();
        } else if (this.showWeek) {
            this.weekIncrease();
        }
    }

    private swipeRight() {

        
        // if day filter is active decrease day
        // else if week filter is active decrease week
        // otherwise do nothing
        if (this.showDay) {
            this.dayDecrease();
        } else if (this.showWeek) {
            this.weekDecrease();
        }
    }

    
}
