import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterStudentAssessment',
})

export class FilterStudentAssessmentPipe implements PipeTransform {

    transform(items: any[], studentName: string, args: string[], actual: boolean): any {

        if (!items) return;

        if (!studentName) { 

            if (actual) { return items.filter((x) => x.active === 1) } 

            else { return items }
        } else {
            
            if (actual) {  
                
                items = items.filter((x) => x.active === 1);

                return FilterStudentAssessmentPipe.filter(items, studentName, args); 
            } 

            else { return FilterStudentAssessmentPipe.filter(items, studentName, args); }
        }

      }
    
      static filter(items: any[], studentName: string, args: string[]) {
        const toCompare = studentName.toLowerCase();
    
        function checkInside(item: any, studentName: string) {
          return args.some((_property) => {
            return item[_property].toString().toLowerCase().includes(toCompare);
          });
        }
    
        return items.filter(function (item) {
          return checkInside(item, studentName);
        });
      }
}
