<div class="wrapper">
    <div class="mb-3">
        <app-menu  [submenu]='true' [mode]="'assessment.title'" [details]="''" [school]="school"></app-menu>
    </div>
    
    <div class="container-lg">
        <div *ngIf="!!classes; else loading">
            <table *ngIf="classes.length > 0; else noClasses" class="table">
                <tr class="text-center">
                    <th class="title">{{'shared.class' | translate}}</th>
                    <th class="groupTitle" *ngFor="let group of groups">{{group.titleKey | translate}}</th>
                </tr>
                <tr class="classes" *ngFor="let item of classes">
                    <td class="_title">
                       {{item.title}} 
                    </td>
                    <td class="group" *ngFor="let group of groups">
                       <div class="links">
                            <div class="online icon">
                                <div class="svg-wrapper"  (click)="vmOpen(group.key, item.idnumber)">
                                    <div class="svg"></div>
                                </div>
                            </div>
                            <div class="download icon"> 
                                    <div (click)="openFile(group.key)" class="svg-wrapper">
                                            <div  class="svg"></div> 
                                    </div>
                            </div>
                       </div>
                    </td>
                </tr>
            </table>
        </div>
    
        <ng-template #noClasses>{{'assessment.no_class' | translate}}</ng-template>
        <ng-template #loading>
            <div class="loader">
                <loader></loader>
            </div>
        </ng-template>
    </div>
</div>