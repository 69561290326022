import {Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ISchool } from 'src/app/interfaces/ISchool';
import { AdminService, AppState } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import { IAssessmentAge } from 'src/app/interfaces/IAssessmentAge';

@Component({
    selector: 'app-student-assessment',
    templateUrl: './assessmentByChild.component.html',
    styleUrls: ['./assessmentByChild.component.scss'],
  })

export class AdminAssesmentStudentComponent implements OnInit {

    public unknownPage: string = 'admin_shared.child_assessments';

    public schools: ISchool;
    public assessments;
    public school: any;
   
    public loading: boolean = false;
    public userInfo: any;

    constructor(private adminService: AdminService,
        private activatedRoute: ActivatedRoute,
        private appState: AppState,
        private _location: Location,
        private router: Router,
        private translateService: TranslateService) {
       
    }

    public ngOnInit(): void {

        this.school = this.appState.restore('school');

        let user = this.activatedRoute.snapshot.params['user'];

        this.adminService.getUserInfo(user).then((userInfo) => {
            this.userInfo = userInfo;
        });

        this.adminService.getAssessmentByUsername(user).then((assessments) => {

            const unique = [...new Set(assessments.map(item => item.age))];

            this.assessments = unique.map((age) => {

                return {
                    ageKey: age,
                    age: this.getNameAssessmentAge(age),
                    username: user,
                    assessments: this.getAssessment(assessments, age)
                }
            }).sort((a, b) => (a.ageKey < b.ageKey ? -1 : 1));
            this.loading = true;
        })
    }

    private getNameAssessmentAge(age) {

        let ages = this.adminService.getAssessment();

        return ages.find((x) => x.key === age).titleKey;
    }
    private getAssessment(assessments, age) {

        return assessments.filter((x) => x.age === age)
                          .sort((a, b) => (a.date > b.date ? -1 : 1));
    }

    public onEdit(assessment) {
        this.router.navigate(['/admin/home/rep', assessment.age, assessment.id, assessment.student]);
    }
    public onDelete(assessment) {

        if (confirm(this.translateService.instant('admin_import.are_you_sure'))){

            this.adminService.deleteAssessment(assessment.id, assessment.student).then((response) => {

                let _assessment = this.assessments.find((x) => x.ageKey === assessment.age);

                _assessment.assessments = _assessment.assessments.filter((x) => x.id !== assessment.id);


                if (_assessment.assessments.length === 0) {

                    this.assessments = this.assessments.filter((x) => x.ageKey !== assessment.age);
                }
                this.adminService.resetChildrenByAssessmentCache();
            })
        }
    }
    public onBack() {
        this._location.back();
    }
    public getToLocaleDate(date) {

        let _date = new Date(date * 1000);

        return _date.toLocaleDateString();
    }

}