import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';
import { TeacherService } from 'src/app/services/teacher.service';

@Component({
  selector: 'app-activity-filter',
  templateUrl: '../base/filter-base.component.html',
  styleUrls: ['../base/filter-base.component.scss'],
  // tslint:disable-next-line: no-outputs-metadata-property
  outputs: ['onSelect', 'onTemplateClear'],
})
export class ActivityFilterComponent extends FilterBaseComponent implements OnInit, OnChanges {
  title = 'Activity';

  private activities: Array<{ key: string; title: string }> = null;

  @Input()
  public reset: boolean = false;

  @Input()
  public set isTemplateChanged(value: boolean) {
    this.templateChanged = value;
  }

  @Input()
  public age: string = null;

  constructor(private teacherService: TeacherService) {
    super();
    this.activities = this.teacherService.getActivities();
  }

  ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.reset) {
      this.value = null;
      this.reset = false;
    }

    if (this.age === 'in' || this.age === 't1' || this.age === 't2') {
      this.options = ['st', 'll', 'pd', 'mm'].map((_item: string) => {
        const activity: { key: string; title: string } = this.activities.find((_activity) => _activity.key === _item);
        return { title: activity.title, value: activity.key };
      });
    } else {
      this.options = ['st','ll', 'pd', 'ir', 'cg'].map((_item: string) => {
        const activity: { key: string; title: string } = this.activities.find((_activity) => _activity.key === _item);
        return { title: activity.title, value: activity.key };
      });
    }
  }
}
