import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/services/app.service'

import { MessagesService, SessionService } from 'src/app/services'


@Injectable()
export class LoginService {

    private apiRoot: string = environment.api;
    private apiKey: string = environment.apiKey;

    constructor(private appState: AppState,
                private http: HttpClient,
                private sessionService: SessionService) {
        // empty
    }

    /**
     * Login into the system
     * @param login user login
     * @param password user password
     */
    public login(login, password): Observable<any> {

        const body = { login, password };        
        const url = `${this.apiRoot}/login`;

        // check user login and password
        // if successfull remember session value
        return this.http
                    .post(url, body)
                    .pipe(
                        tap((response) => {

                            this.appState.store('session', response.session);
                            sessionStorage.setItem('usrActiv_' + response.session,'0');

                            this.sessionService.restoreSessionTimeout();
                            this.sessionService.setCheckSession(response.session);
                        })
                    );
    }

    /**
     * Get user profile
     */
    public profile(): Observable<any> {
      

        const session = this.appState.restore('session');

        const url = `${this.apiRoot}/profile`;
        const headers = new HttpHeaders({ 'x-vms-session': session });

        // check user login and password
        // if successfull remember profile value
        return this.http
                    .get(url, { headers })
                    .pipe(
                        tap((response) => {
                            this.appState.store('profile', response);
                        })
                    );
    }

    /**
     * Post User Email
     */
    public resetEmails(email: string) {

        const headers = new HttpHeaders();
        const url = `${this.apiRoot}/user/reset-emails`;

        const body = {
            "apikey": this.apiKey,
            "email": email
         };         

        return this.http.post(url, body, { headers }).toPromise();     
    }

    /**
     * Reset User Password
     */
    public resetPassword(passToken: string, username: string, password: string ) {

        const headers = new HttpHeaders();
        const url = `${this.apiRoot}/user/reset-password`;

        const body = {
            "apikey": this.apiKey,
            "passToken": passToken, 
            "username": username,
            "password": password 
        }
        
        return this.http.put(url, body, { headers }).toPromise();     
    }

    /**
     * Function to get `CDN Access`.
     */
    public getCdnAccess(){
        const url: string = `${this.apiRoot}/getCDNAccess`;
        const session = this.appState.get('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        return this.http.get(url, { headers, withCredentials: true});
    }

    /**
     * Initiate SAML login flow
     * @param client client id
     */
    public samlInit(client: string) {

        const url = `${this.apiRoot}/saml/${client}/init`;

        // start SAML flow at API end
        return this.http.get(url).subscribe();
    }

    /**
     * Login with Clever
     * @param code clever auth code
     */
    public clever(code: string) {

        const url = `${this.apiRoot}/clever/auth/login`;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(url, { code }, { headers });
    }

}