import {Component, OnInit } from '@angular/core';
import { MenuComponent } from '../../shared/menu/menu.component';
import { AdminService } from 'src/app/services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/services';

@Component({
    selector: 'app-admin-mode',
    templateUrl: './mode.component.html',
    styleUrls: ['./mode.component.scss'],
  })

export class AdminModeComponent implements OnInit {

    public modes = [];
    public school: any;
    public profile;
    public verify_popup_state = false;
    public belive_error_popup = { state: false, text: "Unable to Login."}

    constructor(private adminService: AdminService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private appState: AppState) {
    }

    public ngOnInit(): void {
        this.modes = this.adminService.getModes();
        this.profile = this.appState.restore('profile');
    }

    public onModeChoose(mode:string): void {

      // create new mode with differents url
      if (mode === 'start') {

        window.open("https://www.learningbeyondpaper.com/getting-started-admin", "_blank");

      } else if (mode === 'shop') {

        //Shop for Materials
        window.open("https://www.learningbeyondpaper.com/materials", "_blank");
        
      } else if (mode === 'training') {

        let _profile = this.appState.restore('profile');

        if(_profile.verified) {

          this.adminService.belive()
          .then((url) => window.open(url, "_blank"))
          .catch((error) => {
            if (error.error && error.error.message) {
              console.log(error.error.message)
      
              this.belive_error_popup.state = true;
            } 
          });
        }
        else {
          this.verify_popup_state = true;
        }

      } else {

        this.router.navigate([ mode ], { relativeTo: this.activatedRoute });
      }
      
    }
    public onChangeSchool(school) {
      this.school = school;
    }

    public closePopup() {
      this.belive_error_popup.state = false; 
    }
 
    public goToProfile(){
      this.router.navigate(['/profile']);
    }

    public verifyLater(){
      this.verify_popup_state = false;
    }
}