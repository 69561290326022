import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AppState, TeacherService } from 'src/app/services';

@Component({
    selector: 'teacher-daily-activities',
    templateUrl: './daily.activities.component.html',
    styleUrls: ['./daily.activities.component.scss']
})
export class DailyActivitiesComponent {

    public title: string = this.translateService.instant('daily_activities.title');
    public school: any;
    public activities: any[];
    public active: string;
    public language: string;

    public template: string = null;
    public age: string = null;
    public week: string = null;
    public day: string = null;
    public missing: boolean = false;

    // is daily view avaialble
    public daily: boolean;

    constructor(private appState: AppState,
                private router: Router,
                private activatedRoute: ActivatedRoute,                
                private teacherService: TeacherService,
                private translateService: TranslateService) {              


        this.school = this.appState.restore('school');
        this.age = this.appState.restore('age');
        this.day = this.appState.restore('day');
        this.week = this.appState.restore('week');
        this.language = this.translateService.currentLang;

        if (!this.age) {
            this.appState.store('age', 'in', true);
            this.age = this.appState.get('age');
        }
        this.daily = this.teacherService.hasDailyView(this.age);

        if (!this.week) {
            this.appState.store('week', 1, true);
            this.week = this.appState.get('week');
        }

        if (!this.day) {
            this.appState.store('day', 1, true);
            this.day = this.appState.get('day');
        }

        this.day = this.daily ? this.appState.get('day') : null;


        this.vmGetActivities();

        // check if activity was selected from outside
        // if yes than activate corresponding tab
        const selectedActivity = this.activatedRoute.snapshot.params['activity'];
        if (!!selectedActivity) {
            this.active = selectedActivity;
            
        } else {
            let $activity = this.appState.restore('activity');
            if (!!$activity) {
                this.active = $activity;
            }
        }
        
    }

    public weekChanged(week) {

      this.week = week;
    }

    public dayChanged(day) {

      this.day = day;
    } 
    public _ageChanged(age) {
        
        this.age = age;

        this.daily = this.teacherService.hasDailyView(this.age);

        this.day = this.daily ? this.appState.get('day') : null;
        this.vmGetActivities();
    }
     
    public print(){
        window.print();
    }
    
    public getAgeTitle(ageKey: string): string {

        const age = this.teacherService.getAges().find((a) => a.key == ageKey);
        return age.titleKey;
    }
    
    public getAcivityTitle(activity){
        let titleKey = "topics." + activity;
        return titleKey;
    }

    private vmGetActivities(){
        // get list of activities
        // filter them depending on selected age
        this.activities = this.teacherService.getActivities();
        if (this.age === 'in' || this.age === 't1' || this.age === 't2') {
            this.activities = this.activities.filter((a) => a.key === 'st' || a.key === 'll' || a.key === 'pd' || a.key === 'mm');
        } else {
            this.activities = this.activities.filter((a) => a.key === 'st' || a.key === 'll' || a.key === 'pd' || a.key === 'ir' || a.key === 'cg');
        }

        let $activity = this.appState.restore('activity');
        let isActivity = this.activities.find((x) => x.key === $activity);

        if (!isActivity) {
            const selectedActivity = this.activatedRoute.snapshot.params['activity'];
            if (!!selectedActivity) {
                this.active = selectedActivity;
            }
        }
      
    }
}



