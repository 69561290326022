
<div class="wrapper">
    <app-menu [submenu]='true'  [mode]="'resources.title'" [details]="''" [school]="school"></app-menu> 
  
    <div class="container-lg">
      <div class="table">
        <div class="header">
          <p>{{'resources.title' | translate}}</p>
      </div>
        <div class="row mt-3">
          <div class="col-sm-6 col-md-4 col-lg-3" *ngFor="let section of sections">
             
           <div class="card mb-3" (click)="onSectionChoose(section.key)">
              <img class="card-img" src="/assets/img/sections/Resources/{{section.src}}" alt="{{section.title}}">
              <div class="card-title-bottom bg-primary d-flex justify-content-center text-light p-2 font-weight-bold">
                {{ section.titleKey | translate }}               
              </div>
            </div>
          </div>                              
      </div>
      </div>
      
    </div>   
    
  </div>

  