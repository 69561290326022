import { Component, Input, OnChanges, OnInit} from '@angular/core';
import { AppState } from 'src/app/services';

@Component({
  selector: 'app-state-standards',
  templateUrl: './state-standards.component.html',
  styleUrls: ['./state-standards.component.scss']
})
export class StateStandardsComponent implements OnInit {
  public title: string = 'State Standards';
  public school: any;
  @Input() public  selectedState: number;

  public stateStandards = [
    { id: 1, key: 'Alabama', title: 'Alabama Department of Early Childhood Education', websites: ['http://children.alabama.gov/firstclass/firstclass-guidelines/'], resources: ['http://children.alabama.gov/wp-content/uploads/sites/4/2017/03/2013_Developmental_Standards_with_missing_pages.pdf'] },
    { id: 2, key: 'Alaska', title: 'Alaska Early Learning Guidelines', websites: ['https://www.alaskaelg.org/'], resources: ['https://www.alaskaelg.org/wp-content/uploads/2019/12/AEYC-Early-Childhood-Guidelines-final-web.pdf'] },
    { id: 3, key: 'Arizona', title: 'Arizona Department of Education', websites: ['https://www.azed.gov/ece/preschool/'], resources: ['https://cms.azed.gov/home/GetDocumentFile?id=5acd475b3217e1183c539fa5'] },
    { id: 4, key: 'Arkansas',  title: 'Arkansas Department of Human Services', websites: ['https://humanservices.arkansas.gov/about-dhs/dccece'], resources: ['https://www.arheadstart.org/Ark_Early_Learning_Standards%20(19)%20(1).pdf', 'http://www.arkansas.gov/childcare/general/infantframework.pdf'] },
    { id: 5, key: 'California',  title: 'California Department of Education', websites: ['https://www.cde.ca.gov/sp/cd/re/psfoundations.asp'], resources: ['https://www.cde.ca.gov/sp/cd/re/documents/preschoollf.pdf', 'https://www.cde.ca.gov/sp/cd/re/documents/psfoundationsvol2.pdf', 'https://www.cde.ca.gov/sp/cd/re/documents/preschoolfoundationsvol3.pdf'] },
    { id: 6, key: 'Colorado',  title: 'Colorado Department of Education', websites: ['https://www.cde.state.co.us/early/preschoolstandardsresources'], resources: ['https://earlylearningco.org/pdf/ELDG_Guidelines_English.pdf', 'https://www.cde.state.co.us/early/preschoolstandardsresources'] },
    { id: 7, key: 'Connecticut',  title: 'Connecticut Early Learning Education', websites: ['https://www.eastconn.org/consultation-and-professional-development/standards/ct-early-learning-development-standards'], resources: ['https://www.ct.gov/oec/lib/oec/earlycare/elds/sections/standards.pdf'] },
    { id: 8, key: 'Delaware',  title: 'Delaware Department of Education', websites: ['https://www.doe.k12.de.us/resources'], resources: ['http://www.dhss.delaware.gov/dms/epqc/birth3/files/deinfant_0211.pdf', 'https://dieecpd.org/static/uploads/files/elfpreschool9-10.pdf'] },
    { id: 9, key: 'District of Columbia', title: 'DC Early Learning Standards',  websites: ['https://osse.dc.gov/publication/district-columbia-early-learning-standards-dc-els'], resources: ['http://osse.dc.gov/sites/default/files/dc/sites/osse/publication/attachments/521_Early_Learning_Standards.pdf','https://osse.dc.gov/service/early-learning'] },
    { id: 10, key: 'Florida',  title: 'Florida Office of Early Learning', websites: ['http://flbt5.floridaearlylearning.com/'], resources: ['http://flbt5.floridaearlylearning.com/docs/OEL-SR15BtoK.pdf'] },
    { id: 11, key: 'Georgia',  title: 'Georgia Early Learning and Development Standards (GELDS)', websites: ['http://www.gelds.decal.ga.gov/Default.aspx'], resources: ['http://www.decal.ga.gov/documents/attachments/Content_Standards.pdf'] },
    { id: 12, key: 'Hawaii',  title: 'Hawaii Early Learning and Development Standards', websites: ['http://uhfamily.hawaii.edu/publications/DevelopmentalGuidelinesAndEarlyLearningActivities.aspx'], resources: ['http://p3hawaii.org/sites/default/files/HELDS%20continuum%204_23_2013.pdf'] },
    { id: 13, key: 'Idaho', title: 'Idaho Department of Health and Welfare', websites: ['https://healthandwelfare.idaho.gov/Children/EarlyChildhoodInfo/tabid/80/Default.aspx'], resources: ['http://idahotc.com/Portals/13/Docs/8_25_14/e%20guide/Idaho%20Core%20Standard%20eGuidelines.pdf'] },
    { id: 14, key: 'Illinois', title: 'Illinois Early Learning Project', websites: ['https://illinoisearlylearning.org/ields/'], resources: ['https://illinoisearlylearning.org/wp-content/uploads/2017/02/guidelines.pdf'] },
    { id: 15, key: 'Indiana', title: 'Indiana Department of Education Early Learning', websites: ['https://www.doe.in.gov/earlylearning/foundations'], resources: ['https://www.doe.in.gov/sites/default/files/earlylearning/foundations-2015-august-12.pdf'] },
    { id: 16, key: 'Iowa', title: 'Iowa Department of Education', websites: ['https://educateiowa.gov/pk-12/early-childhood/early-childhood-standards'], resources: ['https://educateiowa.gov/sites/files/ed/documents/IowaEarlyLearningStandards-3rdEdition12.20.18_508.pdf'] },
    { id: 17, key: 'Kansas', title: 'Kansas Department of Education', websites: ['https://www.ksde.org/Portals/0/Early%20Childhood/KsEarlyLearningStandards.pdf'], resources: ['https://www.ksde.org/Agency/Division-of-Learning-Services/Special-Education-and-Title-Services/Early-Childhood'] },
    { id: 18, key: 'Kentucky', title: 'Kentucky Education Professional Standards Board', websites: ['http://www.epsb.ky.gov/mod/book/view.php?id=133&chapterid=76'], resources: ['https://www.fcps.net/cms/lib/KY01807169/Centricity/Domain/2285/preschool/standards.pdf'] },
    { id: 19, key: 'Louisiana', title: 'Louisiana`s Early Learning Guidelines', websites: ['https://www.zerotothree.org/resources/817-louisiana-s-early-learning-guidelines'], resources: ['http://www.dss.state.la.us/assets/docs/searchable/OFS/LAEarlyLearningGuide.pdf'] },
    { id: 20, key: 'Maine', title: 'Maine Department of Education', websites: ['https://www.maine.gov/doe/learning/earlychildhood/publicpreschool/curriculum'], resources: ['https://www.maine.gov/earlylearning/standards/infantsandtoddlersguidelines.pdf'] },
    { id: 21, key: 'Maryland', title: 'Maryland Division of Early Childhood', websites: ['https://earlychildhood.marylandpublicschools.org/prek-grade-2/maryland-early-learning-framework'], resources: ['https://earlychildhood.marylandpublicschools.org/system/files/filedepot/4/msde-pedagogy-report-_appendix_2016.pdf'] },
    { id: 22, key: 'Massachusetts', title: 'Massachusetts Department of Early Education and Care (EEC)', websites: ['https://www.mass.gov/eec-learning-standards-and-curriculum-guidelines'], resources: ['https://www.mass.gov/files/documents/2018/12/20/20110519_infant_toddler_early_learning_guidelines.pdf'] },
    { id: 23, key: 'Michigan', title: 'Michigan Department of Education', websites: ['https://www.michigan.gov/mde/0,4615,7-140-63533---,00.html'], resources: ['https://www.michigan.gov/documents/mde/ECSQ_IT_approved_422341_7.pdf'] },
    { id: 24, key: 'Minnesota', title: 'Minnesota Department of Education', websites: ['https://education.mn.gov/MDE/dse/early/'], resources: ['http://www.isd47.org/media/2017/02/MN-ECIP-022017-web.pdf?x64048'] },
    { id: 25, key: 'Mississippi', title: 'Mississippi Department of Education', websites: ['https://www.mdek12.org/EC'], resources: ['https://www.mdek12.org/sites/default/files/documents/OAE/OEER/Early%20Childhood/Infants%20through%20Four-Year-Old%20Early%20Learning%20Standards%202018.05.16%20-%20APA.pdf'] },
    { id: 26, key: 'Missouri', title: 'Missouri Department of Elementary and Secondary Education', websites: ['https://dese.mo.gov/early-extended-learning/early-learning/missouri-early-learning-goals'], resources: ['https://dese.mo.gov/sites/default/files/eel-el-2013-MELGoals.pdf'] },
    { id: 27, key: 'Montana', title: 'Montana Early Childhood Project', websites: ['https://mtecp.org/links.html'], resources: ['https://www.mtecp.org/pdfs/infanttoddlerelgs.pdf'] },
    { id: 28, key: 'Nebraska', title: 'Nebraska Department of Education', websites: ['https://www.education.ne.gov/oec/early-learning-guidelines/'], resources: ['https://cdn.education.ne.gov/wp-content/uploads/2018/06/ELG-PDF.pdf'] },
    { id: 29, key: 'Nevada',  title: 'Nevada Department of Education Office of Early Learning and Development',websites: ['http://www.nevadaregistry.org/department-of-education-office-of-early-learning-and-development/pre-k-standards.html'], resources: ['http://www.nevadaregistry.org/fb_files/InfantToddlerGuidelines.pdf'] },
    { id: 30, key: 'New Hampshire', title: 'New Hampshire Department of Education', websites: ['https://www.education.nh.gov/who-we-are/deputy-commissioner/early-childhood-education'], resources: ['https://www.dhhs.nh.gov/dcyf/cdb/documents/nh-early-learning-standards.pdf'] },
    { id: 31, key: 'New Jersey', title: 'New Jersey Division of Early Childhood Education', websites: ['https://www.nj.gov/education/ece/guide/'], resources: ['https://www.nj.gov/education/ece/guide/standards/birth/standards.pdf'] },
    { id: 32, key: 'New Mexico', title: 'New Mexico Early Learning', websites: ['https://www.earlylearningnm.org/early-learning-guidelines'], resources: ['https://www.newmexicokids.org/wp-content/uploads/2015/05/Early_Learning_Guidelines_Birth_thru_Kindergarten_July_2014.pdf'] },
    { id: 33, key: 'New York', title: 'New York State Next Generation Standards', websites: ['http://www.p12.nysed.gov/earlylearning/standards/'], resources: ['http://www.earlychildhoodnyc.org/pdfs/eng/ELG.pdf'] },
    { id: 34, key: 'North Carolina', title: 'North Carolina Foundations for Early Learning and Development', websites: ['https://ncchildcare.ncdhhs.gov/Foundations-for-Early-Learning-and-Development'], resources: ['https://ncchildcare.ncdhhs.gov/Portals/0/documents/pdf/N/NC_Foundations.pdf?ver=2017-05-16-105950-953'] },
    { id: 35, key: 'North Dakota', title: 'North Dakota Department of Public Instruction', websites: ['https://www.nd.gov/dpi/education-programs/early-childhood-education'], resources: ['http://education.ohio.gov/getattachment/Topics/Early-Learning/Head-Start/ODE-headstart-crosswalk-1.pdf.aspx?lang=en-US'] },
    { id: 36, key: 'Ohio', title: 'Ohio Department of Education', websites: ['http://education.ohio.gov/Topics/Early-Learning/Early-Learning-Content-Standards/Birth-Through-Pre_K-Learning-and-Development-Stand'], resources: ['http://education.ohio.gov/getattachment/Topics/Early-Learning/Head-Start/ODE-headstart-crosswalk-1.pdf.aspx?lang=en-US '] },
    { id: 37, key: 'Oklahoma', title: 'Oklahoma State Department of Education', websites: ['https://sde.ok.gov/early-childhood-and-family-education'], resources: ['http://www.okdhs.org/OKDHS%20Publication%20Library/10-23.pdf'] },
    { id: 38, key: 'Oregon', title: 'Oregon Department of Education Early Learning and Kindergarten Guidelines', websites: ['https://www.oregon.gov/ode/students-and-family/FullDayK/Pages/Early-Learning-and-Kindergarten-Guidelines.aspx'], resources: ['https://www.oregon.gov/ode/students-and-family/FullDayK/Documents/ODE_EarlyLearningStandards_final.pdf'] },
    { id: 39, key: 'Pennsylvania', title: 'Pennsylvania Department of Education', websites: ['https://www.education.pa.gov/Early%20Learning/Early%20Learning%20Standards/Pages/Infant-Toddler-Pre-K-Learning-Standards.aspx'], resources: ['https://www.pakeys.org/wp-content/uploads/2017/11/2014-Pennsylvania-Learning-Standards-for-Early-Childhood-Infants-Toddlers.pdf'] },
    { id: 40, key: 'Rhode Island', title: 'Rhode Island Early Learning and Development Standards', websites: ['https://www.ride.ri.gov/InstructionAssessment/EarlyChildhoodEducation/EarlyLearningandDevelopmentStandards.aspx'], resources: ['https://rields.com/wp-content/uploads/2014/08/RIELDS_standards_web.pdf'] },
    { id: 41, key: 'South Carolina', title: 'South Carolina Department of Education', websites: ['https://ed.sc.gov/instruction/early-learning-and-literacy/early-learning/standards/'], resources: ['https://ed.sc.gov/scdoe/assets/File/instruction/early-learning-literacy/South%20Carolina%20Early%20Learning%20Standards%202017_Accessible%20Version.pdf', 'https://ed.sc.gov/instruction/early-learning-and-literacy/early-learning/'] },
    { id: 42, key: 'South Dakota', title: 'South Dakota Department of Early Education', websites: ['https://doe.sd.gov/birthto3/'], resources: ['https://dss.sd.gov/docs/childcare/early_learning_guidelines.pdf'] },
    { id: 43, key: 'Tennessee', title: 'Tennessee Early Childhood Education Early Learning Developmental Standards', websites: ['https://www.tn.gov/education/instruction/academic-standards/early-learning-development-standards.html'], resources: ['https://www.tn.gov/content/dam/tn/education/standards/tnelds/std_tnelds_birth-4yo.pdf'] },
    { id: 44, key: 'Texas', title: 'Texas Education Agency', websites: ['https://tea.texas.gov/academics/early-childhood-education/texas-prekindergarten-guidelines'], resources: ['https://tea.texas                                                                                                                                                                                                         .gov/sites/default/files/PKG_Final_2015_navigation.pdf'] },
    { id: 45, key: 'Utah', title: 'Utah State Board of Education', websites: ['https://www.schools.utah.gov/curr/preschool?mid=1181&tid=1'], resources: [] },
    { id: 46, key: 'Vermont', title: 'State of Vermont Early Learning Education', websites: ['https://education.vermont.gov/student-support/early-education/vermont-early-learning-standards'], resources: ['https://education.vermont.gov/sites/aoe/files/documents/edu-early-education-early-learning-standards.pdf'] },
    { id: 47, key: 'Virginia', title: 'Virginia Department of Education', websites: ['http://www.doe.virginia.gov/early-childhood/curriculum/index.shtml'], resources: ['https://www.dss.virginia.gov/files/division/cc/provider_training_development/intro_page/publications/milestones/milestones_one_document/Milestones_Revised.pdf'] },
    { id: 48, key: 'Washington', title: 'Washington State Department of Children, Youth and Families', websites: ['https://www.dcyf.wa.gov/services/earlylearning-childcare/early-achievers/quality-standards'], resources: ['https://www.dcyf.wa.gov/sites/default/files/pubs/EL_0015.pdf', 'https://www.geteduca.com/frameworks/washington-state-early-learning-development-guidelines/', 'https://www.k12.wa.us/student-success/support-programs/early-learning-washington-state/early-learning-and-development-guidelines'] },
    { id: 49, key: 'West Virginia', title: 'West Virginia Department of Education', websites: ['http://www.wvearlychildhood.org/'], resources: ['http://www.wvearlychildhood.org/resources/WV_Early_Learning_Standards_Framework_Infant_Toddler.pdf', 'https://wvde.us/tree/early-learning-p-5/'] },
    { id: 50, key: 'Wisconsin', title: 'Wisconsin Department of Public Instruction', websites: ['https://dpi.wi.gov/early-childhood/practice'], resources: ['https://dpi.wi.gov/sites/default/files/imce/fscp/pdf/ec-wmels-rev2013.pdf'] },
    { id: 51, key: 'Wyoming', title: 'Wyoming Early Childhood Network', websites: ['https://wyqualitycounts.org/providers/early-learning-guidelines-and-foundations/'], resources: ['https://wyquality.wpengine.com/wp-content/uploads/2015/01/Early-Learning-Guidelines.pdf', 'https://edu.wyoming.gov/educators/standards/early-childhood-readiness/'] },
  ]

  constructor(private appState: AppState) { }

  ngOnInit(): void {
    
    this.school = this.appState.restore('school');
    let _selectedState = this.appState.restore('selectedState');

    if(_selectedState){
      this.selectedState = _selectedState;
    }
  }

  onStateChange(){
    this.appState.store('selectedState', this.selectedState, true);
  }
  
  public back(){
    history.back();
  }
}
