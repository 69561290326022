<div class="filter-block">
  <!--label start-->
  <label for="{{ title }}-filter">{{ title }}</label>
  <!--label end-->
  <!--select start-->
  <select name="{{ title }}-filter" id="{{ title }}-filter" [(ngModel)]="value" (mousedown)="vmOnParameterChange($event);" (change)="vmOnSelect($event)" required>
    <option [ngValue]="null">Choose</option>
    <option value="{{ option.value }}" *ngFor="let option of options">{{ option.title }}</option>
  </select>
  <!--select end-->
</div>
