<div class="wrapper">
    <!---- top menu  -->
    <div class="mb-3">
        <app-menu [home]="false" [pageAdmin]="true" [unknownPage]='unknownPage' [school]="school"></app-menu>
        <div class="wrapperComponentColor">
            <div class="wrapperComponent">
                <app-filterbyschool-component [back]="true" [disabled]="true"> </app-filterbyschool-component>
            </div>
        </div>
    </div>
    <!---end top menu -->

    <div class="wrapperPageMain container-lg">
        <div class="groupButtons">
            <button (click)="Save()"
                    [disabled]="isFlagSave"
                    [ngClass]="{'disableBtn': isFlagSave }">{{ 'profile.save' | translate}}</button>
        </div>
        <div class="wrapperTableEdit">
            <div class="stringEdit wrapperColorPicker" >
                <div class="title">{{ 'admin_bm.color' | translate}}:</div>
                <input type="text" id="hex" placeholder="" [(ngModel)]="color" [readonly]="true" (click)="onShowColorPicker()"/>
                <button (click)="vmResetColor()" class="btn_reset">{{ 'admin_bm.reset_color' | translate}}</button>
                <div class="wrapperColorChrome" *ngIf="showColorPicker">
                    <color-chrome [color]="color_chrome" (onChangeComplete)="changeComplete($event)"></color-chrome>
                </div>
            </div>
            <div class="stringEdit">
                <div class="title">{{ 'admin_bm.logo' | translate}}:</div>
                <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile01"
                      aria-describedby="inputGroupFileAddon01" accept='image/png'
                      (input)="vmFileUpload($event)"/>
                    <label class="custom-file-label" for="inputGroupFile01">{{ 'admin_bm.choose_file' | translate}}</label>
                </div>
                <button (click)="vmResetLogo()" class="btn_reset">{{ 'admin_bm.reset_logo' | translate}}</button>
            </div>
        </div>
    </div>

    <div class="wrapper_logo" *ngIf="logo">           
            <img src="{{ logo }}" controls  /> 
            <div class="wrapperDownloadSVG">
                <svg (click)="vmDownload(logo)" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" 
                     x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                    <g>
                    <path d="M239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0-33.941 0L280 310.059V48a24 24 0 0 0-48 0v262.059l-49.539-49.539a24 24 0 0 0-33.941 0 24 24 0 0 0 0 33.941z" fill="#004ab3" data-original="#000000" class=""></path>
                    <path d="M464 232a24 24 0 0 0-24 24v184H72V256a24 24 0 0 0-48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40V256a24 24 0 0 0-24-24z" fill="#004ab3" data-original="#000000" class="">
                    </path></g>
                </svg>
            </div>            
    </div>    
</div>
<div *ngIf="showColorPicker" class="blockHidePicker" (click)="onShowColorPicker()"></div>

<!-- Loader spinner-->
<div class="myloader" *ngIf="loading">
    <loader></loader>
  </div>
  <!--end spinner -->