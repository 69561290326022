import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class FilterBaseComponent {
  /**
   * Filters Title.
   * This value is also used for `label attribute 'for' and select 'name' and 'id' attributes.`
   */
  public title: string = null;

  /**
   * Filters value.
   */
  public value: string = null;

  public options: Array<IOption> = null;

  public onSelect: EventEmitter<string> = new EventEmitter();
  public onTemplateClear: EventEmitter<boolean> = new EventEmitter();

  protected templateChanged: boolean = false;

  constructor() {
    // empty
  }

  /**
   * Method to make action when value selected.
   */
  public vmOnSelect(e: any): void {
    this.onSelect.emit(this.value);
  }

  public vmOnParameterChange(e: any): void {
    if (this.templateChanged) {
      e.preventDefault();

      const userConfirm: boolean = confirm('Your changes will be lost.');

      if (userConfirm) {
        this.templateChanged = false;
        this.onTemplateClear.emit(true);
      }
    }
  }
}

export interface IOption {
  title: string;
  value: any;
}
