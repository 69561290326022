<section class="filters-panel-comp border-bottom shadow-sm bg-light rounded">
  <!--container start-->
  <div class="container-fluid">
    <!--row start-->
    <div class="row">
      <!--column start-->
      <div class="col">
        <!--filters form start-->
        <form action="" class="filters-form" #filterForm="ngForm">
          <!--left block start-->
          <div class="left-block">
            <!--top panel start-->
            <div class="filters-form__top-panel">

              <!--filter start-->
              <app-language-filter
                [isTemplateChanged]="templateChanged"
                (onSelect)="parameters.language = $event; vmOnLanguageSelect($event)"
                (onTemplateClear)="vmOnTemplateClear($event)"
              ></app-language-filter>
              <!--filter end-->

              <!--filter start-->
              <app-age-filter
                [isTemplateChanged]="templateChanged"
                (onSelect)="parameters.age = $event; vmOnAgeSelect($event)"
                (onTemplateClear)="vmOnTemplateClear($event)"
              ></app-age-filter>
              <!--filter end-->

              <!--filter start-->
              <app-mode-filter
                *ngIf="age"
                [isTemplateChanged]="templateChanged"
                [age]="age"
                (onSelect)="parameters.mode = $event; vmOnModeSelected($event)"
                (onTemplateClear)="vmOnTemplateClear($event)"
              ></app-mode-filter>
              <!--filter end-->

              <!--filter start-->
              <app-week-filter
                *ngIf="filtersToShow.week"
                [reset]="!parameters.week"
                [isTemplateChanged]="templateChanged"
                (onSelect)="parameters.week = $event"
                (onTemplateClear)="vmOnTemplateClear($event)"
              ></app-week-filter>
              <!--filter end-->

              <!--filter start-->
              <app-day-filter
                *ngIf="filtersToShow.day"
                [reset]="!parameters.day"
                [isTemplateChanged]="templateChanged"
                (onSelect)="parameters.day = $event"
                (onTemplateClear)="vmOnTemplateClear($event)"
              ></app-day-filter>
              <!--filter end-->

              <!--filter start-->
              <app-activity-filter
                *ngIf="filtersToShow.activity"
                [reset]="!parameters.activity"
                [isTemplateChanged]="templateChanged"
                [age]="age"
                (onSelect)="parameters.activity = $event"
                (onTemplateClear)="vmOnTemplateClear($event)"
              ></app-activity-filter>
              <!--filter end-->
            </div>
            <!--top panel end-->
            <!--bottom panel start-->
            <div class="filters-form__bottom-panel"></div>
            <!--bottom panel end-->
          </div>
          <!--left block end-->
        </form>
        <!--filters form end-->       
        <!-- buttons start-->
        <div class="buttons">
          <!--submit button start-->
          <button class="btn btn-primary" (click)="vmOnLoad(filterForm)">Load</button>
          <!--submit button end-->
           <!--right block start-->
           <div class="right-block"> 
              <!--version start-->
            <div class="template-version" *ngIf="version">Version: {{ version }}</div>
            <!--version end-->

            <!--save button start-->
            <button class="btn btn-primary save-btn" [ngClass]="{ disabled: !templateChanged }" (click)="vmOnSave(filterForm)">{{ 'profile.save' | translate}}</button>
            <!--save button end-->
          </div>
          <!--right block end-->
        </div>
        <!--buttons block-->
      </div>
      <!--column end-->
    </div>
    <!--row end-->
  </div>
  <!--container end-->
</section>
