<div class="wrapper">
  <app-menu [home]="false" [pageAdmin]="true" [school]="school"></app-menu>
  <app-filterbyschool-component (onChange)="onChangeSchool($event)"></app-filterbyschool-component>
  
  <div class="container-lg">
      <div class="row mt-3">
          <div class="col-sm-6 col-md-4 col-lg-3" *ngFor='let mode of modes'>
            <div class="card mb-3" (click)="onModeChoose(mode.key)">
              <img class="card-img" src="/assets/img/modes/{{mode.key}}.jpg" alt="{{mode.title}}">
              <div class="card-title-bottom bg-primary d-flex justify-content-center text-light p-2 font-weight-bold">
                 {{ mode.titleKey | translate }}
              </div>
            </div>
          </div>                              
      </div>
    </div>
</div>

<div *ngIf="verify_popup_state" class="_bunner">
  <div class="popup">

      <div class="title">{{ 'verify.you_email_is_not_verify' | translate }} </div>

      <div class="buttons">
        <div class="bunner-button" (click)="verifyLater()">{{ 'verify.verify_later' | translate }}</div>
        <div class="bunner-button" (click)="goToProfile()">{{ 'verify.go_to_profile' | translate }}</div>
      </div>
  </div>
</div>


<div *ngIf="belive_error_popup.state" class="_bunner">
  <div class="popup _error">
      <div class="title">{{ belive_error_popup.text}}</div>
      <div class="close-button" (click)="closePopup()">{{ 'verify.close' | translate }}</div>
  </div>
</div>