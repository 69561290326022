import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
})

export class FilterPipe implements PipeTransform {
    transform(items: any[], searchTerm: string, propertyToFilter: string, closed: boolean): any {
        if (!items) return;

        searchTerm = searchTerm ? searchTerm.toLowerCase() : '';

        const filteredItems = closed 
            ? items 
            : items.filter(x => !x.date_end || !(x.date_end * 1000 <= Date.now()));

        if (!searchTerm || !propertyToFilter) return filteredItems;

        return filteredItems.filter(item => {
            if (propertyToFilter === 'teacher_first_name') {
                const fullName = `${item.teacher_first_name} ${item.teacher_last_name}`.toLowerCase();
                return fullName.indexOf(searchTerm) !== -1;
            } else if(propertyToFilter  === 'idnumber'){
                const idnumber_and_className = `${item.idnumber} ${item.name}`.toLowerCase();
                return idnumber_and_className.indexOf(searchTerm) !== -1;
            }
            
            else {
                return item[propertyToFilter] 
                    ? item[propertyToFilter].toLowerCase().indexOf(searchTerm) !== -1 
                    : false;
            }
        });
    }
}
