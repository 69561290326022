import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { ISchool } from 'src/app/interfaces/ISchool';
import { AppState } from 'src/app/services';
import {Location} from '@angular/common';

@Component({
    selector: 'app-filterbyschool-component',
    templateUrl: './filterBySchool.component.html',
    styleUrls: ['./filterBySchool.component.scss'],
  })

export class FilterBySchoolComponent implements OnInit {

    @Output() onChange = new EventEmitter<any>();
    @Input() back: boolean = false;
    @Input() disabled: boolean = false;
    
    public listSchools: ISchool[];
    public currentSchool: ISchool;
   
    constructor(private adminService: AdminService, 
                private appState: AppState,
                private _location: Location) {
    }

    public ngOnInit(): void {
        this.adminService.getSchool().then((listSchools: ISchool[]) => {

            this.listSchools = listSchools.sort((a, b) => (a.name < b.name ? -1 : 1));
            
            let school = this.appState.restore('school');

            if (school) {
                this.currentSchool = this.listSchools.find((x) => x.idnumber === school.idnumber);

                if (this.currentSchool) {
                    this.onChange.emit(this.currentSchool);
                } else {

                    this.currentSchool = this.listSchools[0];

                    this.onChange.emit(this.currentSchool);    
                }        

            }  else {

                this.currentSchool = this.listSchools[0];

                this.onChange.emit(this.currentSchool);              

            } 
            this.appState.store('school', this.currentSchool);

        }).catch((error) => {
            console.log('Error Loading Schools: ', error);
        });

    }
    
    public onChangeSchool() {

        this.appState.store('school', this.currentSchool);
        
        this.adminService.vmResetLogo();

        this.onChange.emit(this.currentSchool);
    }

    public onBack(){
        this._location.back();
    }
}