<app-menu [home]="false" [pageAdmin]="true" [unknownPage]='unknownPage' [school]="school"></app-menu>

<app-filterbyschool-component (onChange)="onChangeSchool($event)" [back]="true" [disabled]="true">
    
</app-filterbyschool-component>

<div class="wrapperPage container-lg" [ngClass]="{'clever': currentSchool.school_type == 'Clever' }" >
    <div class="groupButtons">
        <button 
            [disabled]="isFlagSave"
            [ngClass]="{'disableBtn': isFlagSave }"
            (click)="Save(currentClass, currentTeacher)">{{ 'profile.save' | translate}}</button>
    </div>
    <div class="wrapperTableEdit" *ngIf="currentClass">
        <div class="stringEdit">
            <div class="title">{{ 'admin_shared.class_name' | translate}}:</div>
            <input  type="text" [(ngModel)]="currentClass.name" 
                                (input)="onChangeSave()"/>
        </div>
        <div class="stringEdit">
            <div class="title">{{ 'admin_shared.period_from' | translate}}:</div> 
            <app-datepicker (onChange)="getDateFrom($event)" 
                            [assessmentDate]="currentClass.date_begin"></app-datepicker>
            
            <div class="title2">{{ 'admin_shared.to' | translate}}:</div> 
            <app-datepicker (onChange)="getDateTo($event)" 
                            [assessmentDate]="currentClass.date_end"></app-datepicker>
        </div>
        <div class="stringEdit" *ngIf="errorDate">
            <div class="title"></div>
            <div class="error">{{errorDate | translate}}</div>
        </div>
        <div class="stringEdit">
            <div class="title">{{ 'admin_shared.main_teacher' | translate}}:</div>
            <ng-select [(ngModel)]="currentTeacher" 
                        [clearable]="false" 
                        [clearOnBackspace]="false"
                        [searchable]="false"
                        (change)="onChangeSave()">
                <ng-option *ngFor="let teacher of listTeachers" [value]="teacher.username">{{teacher.firstname}} {{teacher.lastname}}</ng-option>
            </ng-select>
        </div>
        <div class="stringEdit">
            <div class="title">{{'shared.age_group' | translate}}:</div>
            <ng-select [(ngModel)]="currentClass.age_group" 
                        [clearable]="false" 
                        [clearOnBackspace]="false"
                        [searchable]="false"
                        (change)="onChangeSave()">
                <ng-option *ngFor="let age_group of age_groups" [value]="age_group.key">{{age_group.title | translate}}</ng-option>
            </ng-select>
        </div>
    </div>
</div>

<div class="container-lg" >    
    <div class="wraperTab">
        <div class="wraperTab2">
            <div (click)="onOpenList('children')" 
                class="tab"
                [ngClass]="{'active': flagList === 'children'}">{{ 'admin_shared.children' | translate}}</div>
            <div (click)="onOpenList('teacher')" 
                class="tab"
                [ngClass]="{'active': flagList === 'teacher'}">{{'menu.teacher' | translate}}</div>
        </div>
        <div *ngIf="currentSchool.school_type != 'Clever'">
            <button *ngIf="flagList === 'children'" 
                    (click)="onCreateChild()">{{ 'admin_shared.create_child' | translate}}</button>
            <button *ngIf="flagList === 'children'" 
                    (click)="onImport()">{{ 'admin_shared.import' | translate}}</button>
            <button *ngIf="flagList === 'children'" 
                    (click)="onAssignedToClassroom('assignedchild')">{{ 'admin_shared.add_child' | translate}}</button>
            <button *ngIf="flagList === 'teacher'" 
                    (click)="onAssignedToClassroom('assignedteacher')">{{ 'admin_shared.add_teacher' | translate}}</button>
        </div>
    </div>    
</div>

<div class="line"></div>

<div class="wrapperBackground">
    <div class="customMargin container-lg">
        <div [ngSwitch]="flagList" class="tableInfoUnderTab">
            <div *ngSwitchCase="'children'">
                <div class="tableCustom children">
                    <div class="rowCustom header">
                        <div class="cell">{{'assessment.child_name' | translate}}</div>
                        <div class="cell date">{{'profile.date_of_birth' | translate}}</div>
                        <div class="cell username">{{'profile.username' | translate}}</div>
                        <div class="cell action">{{ 'admin_shared.action' | translate}}</div>
                    </div>
                    <div class="rowCustom" *ngFor="let child of childrenByClass">
                        <div class="cell left">{{child.firstname}} {{child.lastname}}</div>
                        <div class="cell date">{{getLocaleDate(child.birthdate) }}</div>
                        <div class="cell username">{{child.username}}</div>
                        <div class="cell action">
                            <div class="icon assessment" (click)="onAssessment(child.username)"></div>
                            <div class="icon edit" (click)="onGoToEdit('assignedchild', child.username)"></div>
                            <div class="icon delete"  *ngIf="currentSchool.school_type != 'Clever'" (click)="deleteChildFromClass(child.username)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="'teacher'">
                <div class="tableCustom">
                    <div class="rowCustom header">
                        <div class="cell name">{{ 'admin_shared.teacher_name' | translate}}</div>
                        <div class="cell usernameTeacher">{{'profile.username' | translate}}</div>
                        <div class="cell action">{{ 'admin_shared.action' | translate}}</div>
                    </div>
                    <div class="rowCustom" *ngFor="let teacher of teachersByClass">
                        <div class="cell left name">{{teacher.firstname}} {{teacher.lastname}}</div>
                        <div class="cell usernameTeacher">{{teacher.username}}</div>
                        <div class="cell action">
                            <div class="icon edit" (click)="onGoToEdit('assignedteacher', teacher.username)"></div>
                            <div class="icon delete" *ngIf="currentSchool.school_type != 'Clever'" (click)="deleteTeacherFromClass(teacher.username)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Loader spinner-->
<div class="myloader" *ngIf="!loading">
    <loader></loader>
</div>
<!--end spinner -->