import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() page: string;
  public littleSpinner: boolean = false;

  constructor() { }

  ngOnInit(): void {
    
    if (!!this.page){
      this.littleSpinner = true;
    }
  }
}
