import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AppState } from 'src/app/services/app.service';
import { TeacherService } from 'src/app/services/teacher.service';


@Component({
  selector: 'app-menu-ages',
  templateUrl: './ages.component.html',
  styleUrls: ['./ages.component.scss', '../menu.component.scss'],
  providers: [ TeacherService, AdminService, AppState]
})
export class AgesComponent implements OnInit, OnChanges  {

    public ages = [];

    public ageTitle: string;
    public ageKey: string;
    public modeKey: string;
    public adminmodeKey: string;
  
    public firstname: string;
  
    // available modules
    public editor: boolean = false;
    public teacher: boolean = false;
    public admin: boolean = false;
    public parent: boolean = false;
    public student: boolean = false;
    public role: string;
  
    public ageGroupControlState: boolean = false;
    public ageGroupPickerState:  boolean = false;
    
    // toggler for collapse / expand menu
    public isMenuCollapsed = true;
  
    public dropdownMenuState  = false;
  
    @Input() home: boolean = true;
    @Input() pageAdmin: boolean = false;
    // User Profile Page
    @Input() UPpage: boolean = false;
    @Input() mode: string;
    @Input() details: string;
    @Input() adminmode: string;
    @Input() ageGroupControlDisabled: boolean;

    @Output() public ageChanged = new EventEmitter();

    constructor ( private appState: AppState,
        private teacherService : TeacherService,
        private adminService: AdminService) {

    }

    ngOnInit(): void {
        this.ages = this.teacherService.getAges();

        // in order to receive key 
        // then we can go to link
        // if (this.adminmode)  this.adminmodeKey = this.getAdminModeKey(this.adminmode);   
        if (this.ageGroupControlDisabled) this.ageGroupControlState = true;
        if (this.mode) this.modeKey = this.getModeKey(this.mode);
        
        this.ageKey = this.appState.restore('age');
        if (this.ageKey) {
            this.ageTitle = this.getAgeTitle(this.ageKey);
        } else {
          this.ageTitle = this.getAgeTitle('in');
          this.appState.store('age', 'in', true);
        }

    }

    ngOnChanges(changes: SimpleChanges) {

    }
    public onClickedOutside(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        
        if (this.ageGroupPickerState) {
          this.ageGroupPickerState = false;
        }
    }
    public onAgeChoose( age:string ): void {
        this.ageKey = age;
        this.appState.store('age', age, true);
    
        this.ageGroupPickerState = false;
        this.ageTitle = this.getAgeTitle(age);
        this.ageChanged.emit(this.ageKey);
    }
    public getModeKey(mode:string): string {

        const _mode = this.teacherService.getModes().find((a) => a.title == mode);
        return _mode.key;
      }
    
      public getAdminModeKey(adminmode: string): string {
      
        const _adminmode = this.adminService.getModes().find((a) => a.title === adminmode);
        return _adminmode.key;
    }
    public getAgeTitle(ageKey: string): string {

        const age = this.teacherService.getAges().find((a) => a.key == ageKey);
        return age.titleKey;
    }
    public openAgeGroupPicker(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this.ageGroupPickerState = true;
    }
}