import {Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { AppState } from 'src/app/services';

@Component({
    selector: 'app-edituser',
    templateUrl: './edituser.component.html',
    styleUrls: ['./edituser.component.scss'],
  })

export class EditUserComponent implements OnInit {

    // unknownPage is value what to show us where we are
    // we dont go to link in menu
    // this is to show users where we are
    public unknownPage: string = 'admin_shared.edit'; // new teacher

    public username: string;
    public user: {username: string, password?: string, lastname: string, firstname: string, email: string, birthdate: any };
    public param: string;
    public idschool: string;
    public school: any;

    // flag for button Save
    // all fields should be filled
    public isFlagSave: boolean = true;

    public loading: boolean = true;

    constructor(private router: Router,
        private adminService: AdminService,
        private route: ActivatedRoute,
        private _location: Location,
        private appState: AppState) {
         //   this.user = {username: null, password: null, lastname: null, firstname: null, email: null, birthdate: null };
    }
    public ngOnInit(): void {

        this.username = this.route.snapshot.params['user'];

        let idclass = this.route.snapshot.params['idclass'];

        this.param = this.route.snapshot.params['title'];
        this.idschool = this.route.snapshot.params['idschool'];
        this.school = this.appState.restore('school');

        this.unknownPage = this.param === 'assignedchild' ? 'admin_shared.edit_child' : 'admin_shared.edit_teacher';
       
        if ( this.param === "assignedchild") {

            this.adminService.getChildrenByClass(idclass).then((childrenByClass: ITeacher[]) => {
          
                let _user = childrenByClass.find((x) => x.username === this.username);
                // copy without link
                // because user can change something but 
                // not save
                this.user = Object.assign({}, _user);
    
            }).catch((error) => {
                console.log('Error get children by class: ', error);
            });   
        } else {

            this.adminService.getTeachersByClass(idclass).then((teachersByClass: ITeacher[]) => {

                let _user = teachersByClass.find((x) => x.username === this.username);
                // copy without link
                // because user can change something but 
                // not save
                this.user = Object.assign({}, _user);
    
            }).catch((error) => {
            console.log('Error get teacher by class: ', error);
            });
        }
    }

    public onChangeUser(user: any) {
        this.user = user;
    }
    public onFlagSave(flag) {
        this.isFlagSave = flag;
    }

    public onBack() {
        this._location.back();
    }

    public Save(user) {

        this.loading = false;

        if (this.param === "assignedteacher") {

            this.adminService.editTeacher(this.user, this.idschool).then((response) => {
               
                this.adminService.resetTeachersCache();
                this.adminService.resetTeacherByClassCache();

                this.loading = true;

                this.onBack();

            }).catch((error) => {
              console.log('Error add teacher: ', error);
            });
    
        } else {
            
            this.adminService.editChild(user, this.idschool).then((response) => {

                this.adminService.resetChildrenCache();
                this.adminService.resetChildrenByClassCache();

                this.loading = true;

                this.onBack();

            }).catch((error) => {
              console.log('Error add teacher: ', error);
            });
        }
    }
}