import {Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { RouterExtService } from 'src/app/services/RouterExtService';
import { AppState } from 'src/app/services';

@Component({
    selector: 'app-addclass',
    templateUrl: './addclass.component.html',
    styleUrls: ['../editclass/editclass.component.scss'],
  })

export class AddClassComponent implements OnInit {

    // unknownPage is value what to show us where we are
    // we dont go to link in menu
    // this is to show users where we are
    public unknownPage: string = 'admin_shared.add_class';

    public age_groups;
    public current_age_group;
    public listTeachers: ITeacher[];
    // button 'Save' is disable 
    // if not all fields are filled
    // if true - disables
    public isFlagSave: boolean = true;

    public loading: boolean = false;

    // values for edit class
    public classPeriodFrom: string;
    public classPeriodTo: any;
    public currentName: string;
    // username current teacher who is assigned on this class
    public currentTeacher: string;
    public idschool: string;
    public school: any;

    //if end date is earliear the start date
    public errorDate: string;

    private previousUrl: string;

    constructor(private router: Router,
                private adminService: AdminService,
                private route: ActivatedRoute,
                private _location: Location,
                private routerService: RouterExtService,
                private appState: AppState) {


    }

    public ngOnInit(): void {
  
      this.getDateFrom(moment(new Date()).unix());
      this.getDateTo(moment(new Date()).unix());
     
      this.previousUrl = this.routerService.getPreviousUrl();

      this.age_groups = this.adminService.getAgeGroup();
      this.idschool = this.route.snapshot.params['idschool'];
      this.school = this.appState.restore('school');
      /// after save
      // we go to editing page
      // but when we click back
      // we must go to class management
      if (this.previousUrl.includes('/admin/home/cl/' + this.idschool + '/')) {
        this.router.navigate(['/admin/home/cl']);
        this._location.back();
      }
      // get list of teachers for field
      this.adminService.getTeachers(this.idschool).then((teachers: ITeacher[]) => {

        this.listTeachers = teachers;

      }).catch((error) => {
        console.log('Error get teacher: ', error);
      });

    }
    public onBack() {

      this._location.back();
    }

    // check all fields
    // all fields should be filled
    public onChangeSave() {

      let flagDates = this.onCheckDates(this.classPeriodFrom, this.classPeriodTo);

      if (this.currentName && this.classPeriodFrom && this.classPeriodTo &&
         this.currentTeacher && this.current_age_group && !flagDates) {
            this.isFlagSave = false;
      } else {
        this.isFlagSave = true;
      }  
    }
    // start date must be less than end date
    private onCheckDates(beginDate, endDate): boolean {

      if (!endDate || !beginDate) return true;

      if (beginDate < endDate) {
        this.errorDate = null;
        return false;
      } else {
        this.errorDate = 'Start date should be less than End date';
        return true;
      }
    }
    public Save(name: string, classPeriodFrom: string, classPeriodTo: string,  teacher: string, age_group: string) {

      this.loading = true;
      this.isFlagSave = true;
    
      this.adminService.addClasses(name, this.idschool, classPeriodFrom, classPeriodTo, teacher, age_group).then((response: {generated_idnumber: string}) => {
        
        this.adminService.resetClassesCache();

        this.router.navigate(['/admin/home/cl', this.idschool, response.generated_idnumber]);   
        
        this.loading = false;

      }).catch((error) => {
        console.log('Error Edit Class: ', error);

        this.loading = false;
      });
    }
    public getDateFrom(date) {
      this.classPeriodFrom = date;
      // check all field
      // all field must be filled
      // start date must be less than end date
      this.onChangeSave();
    }
    public getDateTo(date) {

      // classPeriodTo default value is current date + 1year
      if (!this.classPeriodTo) {

        let f = moment(new Date(date * 1000)).add(1, 'years').format('YYYY/MM/DD');

        let _date = new Date(f);
  
        this.classPeriodTo = moment(_date).unix();
      } else {
        this.classPeriodTo = date;
      }

      this.onChangeSave();
    }
}