

 <div class="wrapper">
    <app-menu [home]="false" [pageAdmin]="true" [unknownPage]="unknownPage" [school]="currentSchool"></app-menu>
    <app-filterbyschool-component (onChange)="onChangeSchool($event)" [back]='true'></app-filterbyschool-component>

    <div class="container-lg">
        <div class="blockWrapperButtons">
            <div class="groupButtons">
                <!--<button (click)="onBack()">Back</button>-->
            </div>

            <div class="wrapperInputElement">
              <p>
                <label for="actual">{{'admin_shared.show_actual_children' | translate}}</label>
                <input type="checkbox"  class="checkboxActualChildren"
                [(ngModel)]="showActualChildren" id="actual"/> 
              </p>
              <p>{{'shared.search' | translate}}: <input type="text" placeholder="{{'admin_shared.placeholder' | translate}}" 
                  [(ngModel)]="searchText" /></p>

            </div>
        </div>

        <table  class="table">
            <ng-container >
    
              <tr class="thead text-center">
                <th class="first">{{'assessment.child_name' | translate}}</th> 
                <th class="second">{{'shared.date' | translate}}</th> 
                <th class="third">{{'assessment.actions' | translate}}</th>
              </tr>
    
              <ng-container *ngFor="let child of children | filterStudentAssessment: searchText:['lastname', 'firstname', 'username'] : showActualChildren">
                <tr class="tbody">
                  <td class="first">
                    <div class="_row d-flex mb-1">
                      <span><p> {{child.firstname}} {{child.lastname}}</p></span>
                    </div>
                    <ng-container *ngIf="child.assessments.length > 0">
                      <div class="_row d-flex mb-1 flex-end" *ngFor="let assessment of child.assessments; let i = index">
                        
                      </div>
                    </ng-container> 
                  </td>
    
                  <td class="second">
                    <div class="_row d-flex mb-1 flex-end">
                     
                    </div>
                    <ng-container *ngIf="child.assessments.length > 0">
                      <div class="_row d-flex mb-1 flex-end" *ngFor="let assessment of child.assessments; let i = index">
                        <span><p>{{getToLocaleDate(assessment.date)}}</p></span>
                      </div>
                    </ng-container>   

                  </td>
                  <td  class="second icons">
                    <div class="_row d-flex mb-1 flex-end">
                     
                    </div>
                    <ng-container *ngIf="child.assessments.length > 0">
                      <div class="_row d-flex mb-1 flex-end" *ngFor="let assessment of child.assessments">
                        <div class="_btn edit" (click)="onEdit(assessment)">
                          <span  class="svg-wrapper edit">
                            <div class="svg"></div>
                          </span>
                        </div>
    
                        <div class="_btn" (click)="onDelete(assessment)">
                          <span  class="svg-wrapper delete">
                            <div class="svg"></div>
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
          </ng-container>
        </table>

    </div>
</div>

<!-- Loader spinner-->
<div class="myloader" *ngIf="!loading">
    <loader></loader>
</div>
<!--end spinner -->