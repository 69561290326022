<section class="materials-view-comp">
  <div class="header">
    <p>{{'materials.title' | translate}}</p>
  </div> 

  <!--table start-->
  <table class="table">
    <thead>
      <tr>
        <td colspan="2">
          <div class="print-title">
            <div>{{'materials.title' | translate}}</div>
            <div class="dash" *ngIf="age">–</div>
            <div *ngIf="age">{{ getAgeTitle(age) }}</div>
            <div class="dash" *ngIf="age && week">–</div>
            <div *ngIf="week">{{'shared.week' | translate}} {{ week }}</div>
            <div class="dash" *ngIf="week && day">–</div>
            <div *ngIf="day">{{'shared.day' | translate}} {{ day }}</div>
          </div>
        </td>
      </tr>
    </thead>
    <tbody>
      <!--table row start-->
      <tr *ngFor="let activity of tableData.activities">
        <!--column with title start-->
        <td class="icon" scope="row">
          <div class="svg-wrapper"  [ngClass]="{es_icons:language == 'es'}">
            <div class="svg" [ngClass]="{al: activity.id == 'al',
                                       cg: activity.id == 'cg',
                                       ir: activity.id == 'ir',
                                       ll: activity.id == 'll',
                                       mm: activity.id == 'mm',
                                       pd: activity.id == 'pd',
                                       st: activity.id == 'st' }">
            </div>
          </div>
        </td>
        <!--column with title end-->

        <!--column with day activity start-->
        <td *ngFor="let item of activity.items" (click)="vmOnSelected(activity.id)">
          <!--list start-->
          <ul class="plan-list" [ngClass]="{twoColumns: item.items.length > 3}">
            <li *ngFor="let text of item.items" [ngClass]="{withoutPoint: item.items.length == 1}">{{ text }}</li>
          </ul>
          <!--list end-->
        </td>
      </tr>
      <!--table row end-->
    </tbody>
  </table>
  <p class="copyright">{{ getCopyrite() }}</p>
  <!--table end-->
</section>