import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  public showStandart(textOrTpl: string | TemplateRef<any>, delay: number = 3000): void {
    this.show(textOrTpl, { classname: 'text-center', delay });
  }

  public showSuccess(textOrTpl: string | TemplateRef<any>, delay: number = 3000): void {
    this.show(textOrTpl, { classname: 'bg-success text-light text-center', delay });
  }

  public showDanger(textOrTpl: string | TemplateRef<any>, checkForRepeat: boolean = false, delay: number = 3000): void {
    if (checkForRepeat) {
      const repeat: boolean = this.toasts.some((_toast) => _toast.textOrTpl === textOrTpl);

      if (!repeat) {
        this.show(textOrTpl, { classname: 'bg-danger text-light text-center', delay });
      }
    } else {
      this.show(textOrTpl, { classname: 'bg-danger text-light text-center', delay });
    }
  }

  public remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  private show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }
}