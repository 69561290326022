import {Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { AppState } from 'src/app/services/app.service';
import { HttpClient } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core';;
import { AdminService } from 'src/app/services';
@Component({
    selector: 'app-adminbrand',
    templateUrl: './brandmanagement.html',
    styleUrls: ['./brandmanagement.scss'],
  })


export class AdminBrandComponent implements OnInit {

    public unknownPage: string = 'admin_home.brand_management';
    public color: any;
    public color_chrome: any = '#ffffff';
    public logo: any;
    public school: any;

    public isFlagSave: boolean = false;
    public loading: boolean = false;
    //
    private _color: string;
    private _logo: any;
  
    public showColorPicker: boolean = false;
    constructor(private appState: AppState, private http: HttpClient,  
                private adminService: AdminService,
                private _location: Location,
                private translateService: TranslateService) {

    }

    public ngOnInit(): void {

      this.school = this.appState.restore('school');
      this._color = this.school.color;
      this._logo = this.school.logo;
      if (this.school.color) {

        this.color = this.school.color;
        this.color_chrome = this.school.color;
        
        this.vmSetColorInput(this.school.color);        
      }    
      if (this.school.logo){
        this.vmSetLogo(this.school);
      } 
      this.onChangeSave(); 
    }
    public changeComplete(color: any) {
    
      this.color = color.color.hex;
      this.color_chrome = color.color.hex;

      this.vmSetColorInput(color.color.hex);
      this.onChangeSave();
    }
    public onShowColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    }
    public vmFileUpload(e: Event): void {

      const filesInput: HTMLInputElement = document.querySelector('#inputGroupFile01');

      for (let index = 0; index < filesInput.files.length; index++) {

        const file: File = filesInput.files.item(index);

        if (file.type !== 'image/png') { alert(this.translateService.instant('admin_shared.use_png')); return;  };
        const reader: FileReader = new FileReader();

        reader.addEventListener('load', (event: ProgressEvent) => {
 
          const target: any = event.target;
  
          let resource: any = {
            title: 'logo',
            data: target.result,
            type: file.type,
          };

          this.logo = resource.data;
          this._logo = resource.data;

          this.onChangeSave();
        });
  
        reader.readAsDataURL(file);
      
      }

    }
    public Save() {

      this.loading = true;

      let school = this.appState.restore('school');
      let logo = this.logo ? 1 : 0;

      Promise.all([this.adminService.editSchool(school, this.color, logo),
                   this.adminService.updateLogo(this.logo, school.idnumber)]).then((response) => {
                    
                  let currentSchool = {
                    idnumber: school.idnumber,
                    name: school.name,
                    color: this.color,
                    logo: logo
                  }
                  this.loading = false;
                  this.appState.store('school', currentSchool);
                  this.adminService.resetSchoolsCache();
                  this.adminService.resetSchoolLogoCach();
                  this.adminService.vmResetLogo();
                  this._location.back();
      })
   
    }
    public vmResetColor() {

      this.color_chrome = '#ffffff';
      this.color = null;

      this.vmSetColorInput(this.color_chrome);
      this.onChangeSave();
    }
    public vmResetLogo() {
      this.logo = null;
      this._logo = 0;
      this.onChangeSave();
    }
    private vmSetColorInput(color: string) {

      const hex = document.getElementById('hex');
      if (hex != null) {
        hex.style.backgroundColor = color;
      }
    }
    private vmSetLogo(school: any){

      if (!school.logo) { return; } 

      this.adminService.getResourceLogo(school.idnumber).then((res) => {

        const reader = new FileReader();
        reader.readAsDataURL(res);

        reader.addEventListener('load', (event: ProgressEvent) => {
          const target: any = event.target;
          this.logo = target.result;
          this._logo = 2;
        });
      }) 
    }
    public async vmDownload(logo: any){

      const blob = await fetch(logo).then((res) => res.blob());

      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = `${this.school.name}_Logo`;

      a.click();

    }
    // check all fields
    // all fields should be filled
    public onChangeSave() {

      let logo = this.school.logo ? 1 : 0;
      let color = this.color ? this.color : 0;
      let _color = this._color ? this.color : 0;
      let _logo = this._logo ? this._logo : 0;
      if (color !== _color || logo !== _logo || _logo === 2) {
            this.isFlagSave = false;
      } else {
        this.isFlagSave = true;
      }  
    }
}