import { Component, Input, OnChanges, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-standards',
  templateUrl: './standards.component.html',
  styleUrls: ['./standards.component.scss']
})
export class StandardsComponent implements OnInit {

  public title: string = this.translateService.instant('standards.title');
  public school: any;
  @Input() public  selectedState: number;

  public standards = [
   { title: 'Alignment', key: 'alignment', titleKey: 'standards.alignment' }
  // { title: 'State Standards', key: 'stateStandards', titleKey: 'standards.state_standards'}
  ]

  constructor( private router: Router, 
               private activatedRoute: ActivatedRoute, 
               private appState: AppState,
               private translateService: TranslateService) { }

  ngOnInit(): void {
    this.school = this.appState.restore('school');
  }

  public onStandardChoose( key: string) : void {

    if (key === 'alignment') {
      //Shop for Materials
      window.open("https://www.learningbeyondpaper.com/lbp-alignment", "_blank");
      
    } else {

      this.router.navigate( [key], { relativeTo: this.activatedRoute });
    }
  }

}
