import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppState } from '../../services/app.service';
import { LoginService } from '../../services/login.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AdminService, TeacherService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login',
  providers: [LoginService, FormBuilder],
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  public submit_btn: boolean = true;
  public loginError: boolean = false;
  public passwordError: boolean = false;
  public accessError: boolean = false;
  public incorrectDataError: string;
  public projectError: boolean = false;

  public submitted: boolean = false;
  public error: string = '';
  public loading: any;

  public login: string = '';
  public password: string = '';
  public language: string = '';

  constructor(private router: Router,
              public appState: AppState,
              private loginService: LoginService,
              private adminService: AdminService,
              private teacherService: TeacherService,
              private translateService: TranslateService) {}


  public ngOnInit() {
    sessionStorage.clear();

    if (this.appState.restore('language')) {
      this.language = this.appState.restore('language');
    } else {
      this.language = 'en';
      this.appState.store('language','en', true);
    }

    this.translateService.use(this.language);
  }

  public onSubmit() {
    if (this.login != '' && this.password != '') {
      this.adminService.resetSchoolsCache();
      // sessionStorage.clear();

      this.submit_btn = false;
      this.loginService.login(this.login, this.password).subscribe(
        () => {
          this.router.navigate(['/after-login']);
        },
        (error) => {
          this.submit_btn = true;

          if (error && error.error.code === 'USER_NOT_FOUND_IN_LBP') {
            this.incorrectDataError = 'login.incorrectDataError_1';
          } else if (error && error.error.code === 'USER_NOT_ASSIGNED_TO_SCHOOL') {
            this.incorrectDataError = 'login.incorrectDataError_2';
          } else if (error && error.error.code === 'USER_HAS_NO_ACTIVE_SCHOOL') {
            this.incorrectDataError = 'login.incorrectDataError_3';
          } else if (error) {
            this.incorrectDataError = 'error';
          }
        }
      );
    } else {
      this.loginError = true;
      this.passwordError = true;
    }
  }

  public inputChange() {
    this.loginError = false;
    this.passwordError = false;
    this.accessError = false;
    this.incorrectDataError = null;
    this.projectError = false;
  }
}
