import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseGuard } from './base.guard';
import { AppState } from '../services';

@Injectable({
  providedIn: 'root' 
})

export class EditorGuard  extends BaseGuard  {
 
  constructor(protected router: Router, protected appState: AppState) {
    super();
    this.redirectUrl = 'login';
    this.roleToCheck = 'coursecreator';
  }

}
