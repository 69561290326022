import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeService } from 'src/app/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public currentYear;

  constructor(private translateService: TranslateService,  private timeService: TimeService) { }

  ngOnInit(): void {
    this.timeService.getCurrentTime().subscribe({
    next: (data) => {
      this.currentYear = new Date(data.datetime).getFullYear();
    },
    error: () => {
      this.currentYear = new Date().getFullYear();
    }
  });
  }

  public getCopyrite(){

    let _copyright = this.translateService.instant('shared.copyright');
    let result =  _copyright.replace('2024', this.currentYear);
    return  result;
  }
}
