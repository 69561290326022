import { Component, OnInit, SimpleChanges, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/services/app.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { ContentService } from 'src/app/services/content.service';
import { ToastService } from '../../../services/index';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'teacher-class-reflections',
  templateUrl: './class.reflections.component.html',
  styleUrls: ['./class.reflections.component.scss']
})
export class ClassReflectionsComponent implements OnInit {

  public title: string = 'Teacher Reflections';
  public classTitle: string = null;
  public school: any;

  public age: string = null;
  public week: string = null;  
  public day: string = null;

  public reflection: any[];

  private classId: string;
  private version: string;
  
  public hasUnsavedChanges:boolean = false;
  public loader:boolean = false;

  constructor(private appState: AppState,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private contentService: ContentService,
              private teacherService: TeacherService,
              private toastService: ToastService,
              private translateService: TranslateService) {

    this.age = this.appState.restore('age');
    this.week = this.appState.restore('week');
    this.day = this.appState.restore('day');

    if (!this.age) {
      this.appState.store('age', 'in', true);
      this.age = this.appState.get('age');
    }

    if (!this.week) {
      this.appState.store('week', 1, true);
      this.week = this.appState.get('week');
    }

    if (!this.day) {
      this.appState.store('day', 1, true);
      this.day = this.appState.get('day');
    }
  

    // get class title
    this.classId = this.activatedRoute.snapshot.params['class'];
    this.teacherService
        .getClasses()
        .then((classes) => {
          this.classTitle = classes.find((item) => item.idnumber === this.classId).title;
        });
  }

  ngOnInit(): void {
  
    this.school = this.appState.restore('school');

    this.load(this.age, this.week, this.day, this.classId);
  }

  public onAnswerChanged(){
    this.hasUnsavedChanges = true;
  }

  public exit() {
      // return back to teacher reflections overview page
      this.hasUnsavedChanges = false;
      history.back();
  }

  public submit() {

    // convert reflections data
    const reflection = this.reflection
                          .map((question) => ({
                            id: question.id,
                            text: question.value,
                            answers: !!question.answers ? question.answers.filter((answer) => !!answer.value).map((answer) => answer.id) : null
                          }));
    
    // save reflections
    // and exit to reflections overview page
    this.teacherService
        .saveReflections(this.classId, this.week, this.day, reflection, this.version)
        .then(() => {

          // exit
          this.exit();
        },
        (error) => {
          const info = this.translateService.instant('shared.save_failed')
          this.toastService.showDanger(info);
        }); 
  }

  /**
   * Load reflections per day.
   * Before loading content check if reflection was already submitted
   * @param age age code
   * @param week week number
   * @param day day number
   * @param classId class identifier
   */
  private async load(age: string, week: string, day: string, classId: string) {
 
    const language = this.translateService.currentLang;

    this.loader = true;
    // load reflection content
    this.contentService
        .getReflections(language, age)
        .catch((err) => {
          if (language !== 'en') {
              return this.contentService.getReflections('en', age)
          } else { 
              throw err;
          }
        })
        .then((content) => {

            this.reflection = content.body;
            this.version = content.version; 
            
            // try loading previously saved reflection
            this.teacherService
                .getReflection(classId, week, day)
                .then((stored) => {

                  if (!!stored) {
                    
                    // if previously saved reflection found
                    // than restore previously selected answers
                    // and previously entered text
                    JSON.parse(stored)
                        .forEach((previous) => {
                          
                            // find matching question
                            const question = this.reflection.find((question) => question.id === previous.id);

                            // restore entered text
                            if (!!previous.text) {
                              question.value = previous.text;
                            }

                            // restore selected answers
                            if (!!previous.answers && !!question.answers) {
                              previous.answers.forEach((selected) => {
                                
                                const answer = question.answers.find((answer) => answer.id === selected);
                                answer.value = true
                              })
                            }
                        });
                  }
                  
                  this.loader = false;                  
                }) 
                .catch(() => {
                  this.loader = false;
              })
        });
  }
}
