import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState, TeacherService, ContentService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'teacher-materials',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss', '../standards/standards.component.scss']
})
export class MaterialComponent implements OnInit {

  public title: string = this.translateService.instant('materials.title');
  public school: any;
  public age: string;

  public materials = [
   { title: 'Weekly Materials', key: 'week', titleKey: 'materials.weekly_materials' },
   { title: 'Daily Materials', key: 'day', titleKey: 'materials.daily_materials'}
  ]

  constructor(private appState: AppState, private router: Router, private activatedRoute: ActivatedRoute,                
    private translateService: TranslateService) {

  }

  ngOnInit(): void {
    
    this.school = this.appState.restore('school');

    this.age = this.appState.restore('age');
    if (this.age == 'pk'){
      this.materials.push(
        { title: 'Letter Cards', key: 'cards', titleKey: 'materials.letter_cards'}
      )
    }
  }
  public onMaterialChoose(key: string){
    if(key == 'cards') {

      window.open("https://www.learningbeyondpaper.com/pre-k-templates", "_blank");
    } else {

      this.router.navigate( [key], { relativeTo: this.activatedRoute });
    }
  }
}
