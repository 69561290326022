import { Component, OnInit } from '@angular/core';
import { TeacherService } from 'src/app/services/teacher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/services';

@Component({
  selector: 'app-resources-teacher',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesTeacherComponent implements OnInit {

  public sections = [];
  public school: any;
  private age: string;

  constructor( private router: Router, private activatedRoute: ActivatedRoute, 
               private teacherService: TeacherService,
               private appState: AppState) { }

  ngOnInit(): void {

    this.school = this.appState.restore('school');
    
    let sections = this.teacherService.getSectionsResources();
    this.age = this.appState.restore('age');
    this.sections = sections.filter((x: any) => x.age == this.age || !x.age);
  }

  public onSectionChoose( key:string ) {

      switch (key) {
        case 'teacher_guide':
          this.router.navigate(['home/tg'], { relativeTo: this.activatedRoute.parent });
          break;
        case 'st': 
          this.router.navigate(['home/st'], { relativeTo: this.activatedRoute.parent });
          break;
        default:

          let links = this.teacherService.getResourcesForPDF();
          let url = links.find((x) => x.key === key).resources[this.age];
  
          window.open(url, "_blank");

          break;
      }
  }

}
