import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TeacherService } from 'src/app/services/teacher.service';
import { AdminService } from 'src/app/services/admin.service';
import { AppState } from 'src/app/services/app.service';

@Component({
  selector: 'app-teacher-mode',
  templateUrl: './mode.component.html',
  styleUrls: ['./mode.component.scss'],
  providers: [ TeacherService, AppState]
})

export class ModeComponent implements OnInit {

  public modes  = [];
  public school: any;
  public profile;
  public verify_popup_state = false;
  public belive_error_popup = { state: false, text: "Unable to Login."}

  constructor(private appState: AppState,
              private router: Router,
              private activatedRoute: ActivatedRoute, 
              private adminService: AdminService,
              private teacherService: TeacherService) {
    // empty
  }

  ngOnInit(): void {

    this.modes = this.teacherService.getModes();
    this.profile = this.appState.restore('profile');
    this.school = this.appState.restore('school');       
    if (!this.school) {
      this.vmGetSchool();
    }
  }

  public onModeChoose( mode:string ): void {

    this.appState.store('mode', mode, true);
    let age = this.appState.restore('age');

    if (mode === 'training') {

      let _profile = this.appState.restore('profile');

      if(_profile.verified) {
        
        this.adminService.belive()
        .then((url) => window.open(url, "_blank"))
        .catch((error) => {
          if (error.error && error.error.message) {
            console.log(error.error.message)
    
            this.belive_error_popup.state = true;
          } 
        });
      
     } else {
      this.verify_popup_state = true;
     }

    } else if (mode === 'da') {
    
      this.router.navigate([ mode, 'st' ], { relativeTo: this.activatedRoute });

    } else if (mode === 'mt' && (age === 'in' || age === 't1')) {

      this.router.navigate([ mode, 'week' ], { relativeTo: this.activatedRoute });

    } else {
      this.router.navigate([ mode ], { relativeTo: this.activatedRoute });
    }
  }

  public closePopup() {
    this.belive_error_popup.state = false; 
  }

  public goToProfile(){
    this.router.navigate(['/profile']);
  }

  public verifyLater(){
    this.verify_popup_state = false;
  }

  private async vmGetSchool() {
    this.school = await this.teacherService.getSchool();
    this.appState.store('school', this.school);      
  }
}
