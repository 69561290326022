import { Pipe, PipeTransform } from '@angular/core';
import { IClass } from 'src/app/interfaces/IClass';
import { ITeacher } from 'src/app/interfaces/ITeacher';

@Pipe({
    name: 'adminfilterReflection',
})

export class AdminFilterReflection implements PipeTransform {
    transform(items: any[], age: any, clas: IClass, teacher: ITeacher, closed: boolean): any {

        if (!items) return;

        if (!closed) {
            items = items.filter((x) => !x.date_end || !(x.date_end * 1000 <= Date.now()));
        }

        return items.filter((x) => {
            return ((age === undefined || x.age_group === age.key || age.key === null) && 
                   (teacher === undefined || x.username === teacher.username || teacher.username === null) &&
                   (clas === undefined || x.idnumber === clas.idnumber || clas.idnumber === null))
        })
    }
}