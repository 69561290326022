import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';
       
@Component({
  selector: 'saml-init',
  templateUrl: './saml-init.component.html',  
  providers: [ LoginService ],
  styleUrls: ['./saml-init.component.scss']
})
export class SamlInitComponent {

  public client: string;

  constructor(private activatedRoute: ActivatedRoute,
              private loginService: LoginService) {

    this.client = this.activatedRoute.snapshot.params.client;
    this.loginService.samlInit(this.client);
  }
}
