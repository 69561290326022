import {Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { AppState } from 'src/app/services';

@Component({
    selector: 'app-toclassroom',
    templateUrl: './assignedToClassroom.component.html',
    styleUrls: ['./assignedToClassroom.component.scss'],
  })

export class AssignedToClassroomComponent implements OnInit {

    // unknownPage is value what to show us where we are
    // we dont go to link in menu
    // this is to show users where we are
    public unknownPage: string = 'admin_shared.add_to_classroom'; // child
    public titlePage: string;
    public school: any;

    public availableTeachers: ITeacher[];
    public availableChildren: ITeacher[];

    public searchText: string = '';

    public idclass: string;

    public loading: boolean = false;

    constructor(private router: Router,
        private adminService: AdminService,
        private route: ActivatedRoute,
        private _location: Location,
        private appState: AppState) {

    }

    public ngOnInit(): void {

        this.titlePage = this.route.snapshot.params['title'];
        this.idclass = this.route.snapshot.params['idclass'];
        let idschool = this.route.snapshot.params['idschool'];
        this.school = this.appState.restore('school');

        this.unknownPage = this.titlePage === 'assignedchild' ? 'admin_shared.add_child_to_classroom' : 'admin_shared.add_teacher_to_classroom';
        
        if ( this.titlePage === 'assignedteacher') {
            Promise.all([this.adminService.getTeachers(idschool), 
                this.adminService.getTeachersByClass(this.idclass)]).then(([teachers, teachersByClass])=> {

                    this.availableTeachers = teachers.filter(x => !teachersByClass.some(y => x.username === y.username));
                    this.loading = true;
                })

        } else {
            Promise.all([this.adminService.getChildren(idschool), 
                this.adminService.getChildrenByClass(this.idclass)]).then(([children, childrenByClass])=> {
    
                    this.availableChildren = children.filter(x => !childrenByClass.some(y => x.username === y.username));
                    this.loading = true;
                })
        }



    }
    public onCreateTeacher() {
        this.router.navigate(['./user'], { relativeTo: this.route });
    }
    public onCreateChild() {
        this.router.navigate(['./user'], { relativeTo: this.route });
    }
    public onBack() {
        this._location.back();
    }
    public onAssignChild(username) {
        
        this.adminService.assignChild(username, this.idclass).then((response) => {
            
            this.availableChildren = this.availableChildren.filter((x) => x.username != username);

            this.adminService.resetChildrenByClassCache();
            
        }).catch((error) => {
            console.log('Error assign child: ', error);
        });
        
    }

    public onAssignTeacher(username) {

         this.adminService.assignTeacher(username, this.idclass).then((response) => {
            
            this.availableTeachers = this.availableTeachers.filter((x) => x.username != username);

            this.adminService.resetTeacherByClassCache();

        }).catch((error) => {
            console.log('Error assign teacher: ', error);
        });
    
    }
    public getLocaleDate(date) {
        
        let _date = new Date(date * 1000);

        return  _date.toLocaleDateString();
    }
}