import {Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import * as moment from 'moment';
import { AppState, MessagesService } from 'src/app/services';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
  })

export class UserComponent implements OnInit {

    // unknownPage is value what to show us where we are
    // we dont go to link in menu
    // this is to show users where we are
    public unknownPage: string;

    public user: {username: string, password: string, lastname: string, firstname: string, email: string, birthdate: string };
    public idschool: string;
    public param: string;
    public loading: boolean = true;
    public school: any;

    // flag for button Save
    // all fields should be filled
    public isFlagSave: boolean = true;

    constructor(private adminService: AdminService,
                private route: ActivatedRoute,
                private _location: Location,
                private router: Router,
                private appState: AppState,
                private getMsgError: MessagesService) {
          this.user = {username: null, password: null, lastname: null, firstname: null, email: null, birthdate: null };
    }

    public ngOnInit(): void {

      this.param = this.route.snapshot.params['title'];

      this.idschool = this.route.snapshot.params['idschool'];

      this.unknownPage = this.param === 'assignedchild' ? 'admin_shared.new_child' : 'admin_shared.new_teacher';
      this.school = this.appState.restore('school');
    }

    public onNewUser(user) {
      this.user =  user;
    }
    public onFlagSave(flag) {
      this.isFlagSave = flag;
    }

    public onBack() {
      this._location.back();
    }
    public Save(user) {

      this.getMsgError.getExistUsername(this.user.username).then((response: { exists: boolean}) => {

        this.loading= true;
        if (!response.exists) {

          this.loading = false;
          // if there are whitespaces at the ends of the string
          user.username.trim();

          if (this.param === "assignedteacher") {

            this.adminService.addTeacher(user, this.idschool).then((response) => {
              
              this.adminService.resetTeachersCache();

              this.loading = true;

              this.onBack();

            }).catch((error) => {
              console.log('Error add teacher: ', error);
            });

          } else {
            // option parameter
            let clas: string = null;
            let where = this.route.snapshot.params['where'];

            if (where) {

              clas = this.route.snapshot.params['idclass'];
            }

            this.adminService.addChild(user, this.idschool, clas).then((response) => {
        
              this.adminService.resetChildrenCache();
              this.adminService.resetChildrenByClassCache();

              this.loading = true;

              this.onBack();

            }).catch((error) => {
              console.log('Error add teacher: ', error);
            });
          }
        }
      })
    }
}