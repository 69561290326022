
<div class="wrapper">
    <div class="mb-3">
        <app-menu [home]="false" [pageAdmin]="true" [unknownPage]='unknownPage' [school]="school"></app-menu>
        <div class="wrapperComponentColor">
            <div  class="container-lg wrapperPage">
                <div class="wrapperComponent">
                    <app-filters [showWeek]="true" (weekChanged)="weekChanged($event)"></app-filters>
                    <app-filterbyschool-component (onChange)="onChangeSchool($event)"></app-filterbyschool-component>
                </div>
            </div>
        </div>
    </div>


    <div class="container-lg wrapperPage">
        <div class="wrapperFilter">
            <div class="stringEdit">
                <div class="wrap">
                    <div class="title">{{'menu.teacher' | translate}}:</div>
                    <ng-select [(ngModel)]="currentTeacher" 
                                [clearable]="false" 
                                [clearOnBackspace]="false"
                                [searchable]="false"
                                (change) = "onSaveLocaleStorage()">
                        <ng-option *ngFor="let teacher of listTeachers" [value]="teacher">{{teacher.firstname}} {{teacher.lastname}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="stringEdit">
                <div class="wrap">
                    <div class="title twoColumn">{{'shared.age_group' | translate}}:</div>
                    <ng-select [(ngModel)]="currentAgeGroup" 
                                [clearable]="false" 
                                [clearOnBackspace]="false"
                                [searchable]="false"
                                (change) = "onSaveLocaleStorage()">
                        <ng-option *ngFor="let age_group of listAgeGroups" [value]="age_group">{{ age_group.title | translate }}</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="wrapperFilter">
            <div class="stringEdit">
                <div class="wrap">
                    <div class="title">{{'shared.class' | translate}}:</div>
                    <ng-select [(ngModel)]="currentClass" 
                                [clearable]="false" 
                                [clearOnBackspace]="false"
                                [searchable]="false"
                                (change) = "onSaveLocaleStorage()">
                        <ng-option *ngFor="let clas of listClasses" [value]="clas">{{clas.name}}</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="wrapperInputElement">
                <p><label for="closedclas">{{ 'admin_shared.show_closed_classes' | translate}}</label>
                    <input type="checkbox"  class="checkboxClosedClasses" (change) = "onSaveLocaleStorage()"
                    [(ngModel)]="showClosedClasses" id="closedclas"/> 
                </p>
            </div> 
        </div> 


        <div *ngIf="!!reflections">

            <div class="tableCustom" *ngIf="reflections.length > 0; else noClasses">
                <div class="rowCustom header">
                    <div class="cell firstcolumn">{{ 'admin_shared.teachers' | translate}}</div>
                    <div class="cell">{{'shared.day' | translate}} 1</div>
                    <div class="cell">{{'shared.day' | translate}} 2</div>
                    <div class="cell">{{'shared.day' | translate}} 3</div>
                    <div class="cell">{{'shared.day' | translate}} 4</div>
                    <div class="cell">{{'shared.day' | translate}} 5</div>
                </div>
                <div class="rowCustom"  *ngFor="let item of reflections | adminfilterReflection: currentAgeGroup: currentClass: currentTeacher : showClosedClasses">
                    <div class="cell firstcolumn">
                        <div>
                            <div>{{item.firstname}} {{item.lastname}}</div>
                            <div>{{item.name}}</div>
                            <div>{{getAgeGroup(item.age_group) | translate}}</div>
                        </div> 
                    </div>
                    <div class="cell" 
                        *ngFor="let day of ['1','2','3','4','5']" 
                        (click)="select(item.idnumber, day, item.username)">

                        <span class="icon" *ngIf="item.days.includes(day)">
                            <div class="_svg _checkmark"></div>
                        </span>
                        <span class="icon" *ngIf="!item.days.includes(day)">
                            <div class="_svg _clipboard"></div>
                        </span>
                    </div>
                
                </div>
            </div>
        </div>
    
        <ng-template #noClasses>
            <p class="NoAssigned">{{'shared.no_class' | translate}}</p>
        </ng-template>
    </div>
</div>
<!-- Loader spinner-->
<div class="myloader" *ngIf="!loading">
    <loader></loader>
</div>
<!--end spinner -->