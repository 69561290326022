import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AppState, TeacherService, AssessmentService, ContentService } from 'src/app/services';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})

export class AssessmentComponent implements OnInit {

  public title: string = 'Assessment';

  public classes: any[] = null;
  public groups: any[] = null;

  public school: any;

  private age: string;
  
  constructor(private appState: AppState,
              private teacherService: TeacherService,
              private contentService: ContentService,
              private assessmentService: AssessmentService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private translateService: TranslateService) {

    this.school = this.appState.restore('school');
    
    this.age = this.appState.restore('age');
    if (!this.age) {
      this.appState.store('age', 'in', true);
      this.age = this.appState.get('age');
    }
    
    this.groups = this.assessmentService.getAssessmentGroups(this.age);
  }

  ngOnInit(): void {

    this.load();
  }

  public vmOpen(keyAssessment: string, classId: any) {

    this.router.navigate(['home', 'as', classId, keyAssessment, 'students'], { relativeTo: this.activatedRoute.parent });
  }

  public vmGetTemplateLink( age: string) {
    
    
    const language = this.translateService.currentLang;
    return this.contentService.getAssessmentTemplate(language, age);
  }  

  public openFile(key: any) {
    window.open(this.vmGetTemplateLink(key));
  }

  private load() {

    // get list of all classes
    // for each class check it's reflections for given week
    this.teacherService
        .getClasses()
        .then((classes) => {

          this.classes = classes.sort((a, b) => a.title.localeCompare(b.title));          
          this.classes = classes.filter( element => element.age_group == this.age)

        });
  }


}
