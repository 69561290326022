<!-- search component start -->
<div class="search-comp">
  <!-- app menu start -->
  <app-menu [submenu]="true" [school]="school"></app-menu>
  <!-- app menu end -->

  <!-- container lg start -->
  <div class="container-lg">
    <!-- row start -->
    <div class="row ml-0 mr-0">
      <!-- column start -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <!-- input wrapper start -->
        <div class="inputFilterWrapper">
          <label for="age">{{'shared.age_group' | translate}}:</label>
          <ng-select [(ngModel)]="currentAge" [clearable]="false" [clearOnBackspace]="false" [searchable]="false" labelForId="age">
            <ng-option *ngFor="let age of ageList" [value]="age.value">{{ age.title | translate }}</ng-option>
          </ng-select>
        </div>
        <!-- input wrapper end -->
      </div>
      <!-- column end -->
      <!-- column start -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <!-- input wrapper start -->
        <div class="inputFilterWrapper">
          <label for="topic">{{'shared.topic' | translate}}:</label>
          <ng-select [(ngModel)]="currentTopic" [clearable]="false" [clearOnBackspace]="false" [searchable]="false" labelForId="topic">
            <ng-option *ngFor="let topic of topicList" [value]="topic.value">{{ topic.title | translate }}</ng-option>
          </ng-select>
        </div>
        <!-- input wrapper end -->
      </div>
      <!-- column end -->
    </div>
    <!-- row end -->
    <!-- row start -->
    <div class="row ml-0 mr-0 justify-content-center">
      <!-- column start -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <!-- search input wrapper start -->
        <div class="inputSearchWrapper">
          <!-- search text input start -->
          <input
            type="text"
            name="searchText"
            id="searchText"
            class="searchTextInput"
            placeholder="{{'shared.enter_text' | translate}}"
            [(ngModel)]="searchText"
            (input)="vmOnInput($event)"
          />
          <!-- search text input end -->

          <!-- search button start -->
          <button type="button" class="searchBtn" (click)="vmSearch($event)" [disabled]="searchBtnDisabled">{{'shared.search' | translate}}</button>
          <!-- search button end -->
        </div>
        <!-- search input wrapper end -->
      </div>
      <!-- column end -->
    </div>
    <!-- row end -->
  </div>
  <!-- container lg end -->

  <!-- search results start -->
  <div class="container-lg" *ngIf="searchResults && !noSearchResults">
    <!-- search table start -->
    <div class="searchResultTable">
      <!-- table header start -->
      <div class="tableHeader">
        <!-- table row start -->
        <div class="tableRow">
          <div class="tableCell tableCell--age">{{'shared.age' | translate}}</div>
          <div class="tableCell tableCell--week">{{'shared.week' | translate}}</div>
          <div class="tableCell tableCell--day">{{'shared.day' | translate}}</div>
          <div class="tableCell tableCell--topic">{{'shared.topic' | translate}}</div>
          <div class="tableCell tableCell--name">{{'shared.name' | translate}}</div>
        </div>
        <!-- table row end -->
      </div>
      <!-- table header end -->

      <!-- table body start -->
      <div class="tableBody">
        <!-- table row  start -->
        <div class="tableRow" *ngFor="let result of searchResults" (click)="vmNavigate(result)">
          <div class="tableCell tableCell--age">{{ result.ageTitle | translate}}</div>
          <div class="tableCell tableCell--week">{{ result.week }}</div>
          <div class="tableCell tableCell--day">{{ result.day }}</div>
          <div class="tableCell tableCell--topic">
            <div [ngSwitch]="result.activity">
              <div *ngSwitchCase="'cg'">
                <img *ngIf="language == 'en'" class="topicImg" src="../../../assets/svg/daily-activities-tab-icons/cg-tab-02.svg" alt="Cognitive Topic" />
                <img *ngIf="language == 'es'" class="topicImg" src="../../../assets/svg/svg_es/daily-activities-es/cg_es.svg" alt="Cognitive Topic" />
              </div>
              <div *ngSwitchCase="'ir'">
                <img  *ngIf="language == 'en'" class="topicImg" src="../../../assets/svg/daily-activities-tab-icons/ir-tab.svg" alt="Interactive Reading Topic" />
                <img  *ngIf="language == 'es'" class="topicImg" src="../../../assets/svg/svg_es/daily-activities-es/ir_es.svg" alt="Interactive Reading Topic" />
              </div>
              <div *ngSwitchCase="'ll'">
                <img   *ngIf="language == 'en'" class="topicImg" src="../../../assets/svg/daily-activities-tab-icons/ll-tab.svg" alt="Language & Literacy Topic" />
                <img   *ngIf="language == 'es'" class="topicImg" src="../../../assets/svg/svg_es/daily-activities-es/ll_es.svg" alt="Language & Literacy Topic" />
              </div>
              <div *ngSwitchCase="'mm'">
                <img  *ngIf="language == 'en'"  class="topicImg" src="../../../assets/svg/daily-activities-tab-icons/mm-tab.svg" alt="Music & Movement Topic" />
                <img  *ngIf="language == 'es'"  class="topicImg" src="../../../assets/svg/svg_es/daily-activities-es/mm_es.svg" alt="Music & Movement Topic" />
              </div>
              <div *ngSwitchCase="'pd'">
                <img  *ngIf="language == 'en'"  class="topicImg" src="../../../assets/svg/daily-activities-tab-icons/pd-tab.svg" alt="Physical Development Topic" />
                <img  *ngIf="language == 'es'"  class="topicImg" src="../../../assets/svg/svg_es/daily-activities-es/pd_es.svg" alt="Physical Development Topic" />
              </div>
              <div *ngSwitchCase="'st'">
                <img  *ngIf="language == 'en'" class="topicImg" src="../../../assets/svg/daily-activities-tab-icons/st-tab.svg" alt="STEAM Topic" />
                <img  *ngIf="language == 'es'" class="topicImg" src="../../../assets/svg/svg_es/daily-activities-es/st_es.svg" alt="STEAM Topic" />
              </div>
            </div>
          </div>
          <div class="tableCell tableCell--name">{{ result.name }}</div>
        </div>
        <!-- table row  end -->
      </div>
      <!-- table body end -->
    </div>
    <!-- search table end -->
  </div>
  <!-- search results end -->

  <!-- search results start -->
  <div class="container-lg" *ngIf="noSearchResults">
    <!-- result item start -->
    <div class="row ml-0 mr-0">
      <div class="col">
        <!-- no search results start -->
        <div class="noResultsMessage">{{'shared.no_result' | translate}}</div>
        <!-- no search results end -->
      </div>
    </div>
    <!-- result item end -->
  </div>
  <!-- search results end -->

  <loader *ngIf="loader"></loader>
</div>
