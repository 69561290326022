import { Component, OnInit, SimpleChanges, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/services/app.service';
import { ContentService } from 'src/app/services/content.service';
import { AdminService } from '../../../services/index';
import { IClass } from 'src/app/interfaces/IClass';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'teacher-class-reflections',
  templateUrl: './class.reflection.component.html',
  styleUrls: ['./class.reflection.component.scss']
})
export class AdminClassReflectionComponent implements OnInit  {

  public unknownPage: string = 'admin_home.teacher_reflections';
  public classTitle: string = null;
  public currentSchool: any;

  public age: string = null;
  public week: string = null;  
  public day: string = null;

  public currentClass: IClass;

  public reflection: any[];

  private classId: string;
  private school: string;
  private version: string;
  
  public hasUnsavedChanges:boolean = false;
  public loader:boolean = false;

  constructor(private appState: AppState,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private contentService: ContentService,
              private adminService: AdminService,
              private _location: Location,
              private translateService: TranslateService) {

        this.week = this.appState.restore('week');
        this.day = this.appState.restore('day');

        // if week is empty set default value of 1
        if (!this.week) {
          this.appState.store('week', 1, true);
          this.week = this.appState.get('week');
        }

        // if day is empty set default value of 1
        if (!this.day) {
          this.appState.store('day', 1, true);
          this.day = this.appState.get('day');
        }
  }

  ngOnInit(): void {

    this.week = this.appState.restore('week');
    // get class title
    this.classId = this.activatedRoute.snapshot.params['idclass'];
    this.school = this.activatedRoute.snapshot.params['idschool'];
    this.currentSchool = this.appState.restore('school');

    let teacher = this.activatedRoute.snapshot.params['username']

    this.adminService.getClasses(this.school).then((classes: IClass[]) => {

        this.currentClass = classes.find((item) => item.idnumber === this.classId);

        this.load(this.currentClass.age_group, this.week, this.classId, teacher);

    });
    
  }

  public onBack() {

    this._location.back();
  }

  /**
   * Load reflections per day.
   * Before loading content check if reflection was already submitted
   * @param age age code
   * @param week week number
   * @param day day number
   * @param classId class identifier
   */
  private async load(age: string, week: string, classId: string, teacher: string) {
 
    const language = this.translateService.currentLang;

    this.loader = true;
    // load reflection content
    this.contentService.getReflections(language, age)
      .catch((err) => {
        if (language !== 'en') {
            return this.contentService.getReflections('en', age)
        } else { 
            throw err;
        }
      })
      .then((content) => {

            this.reflection = content.body;
            this.version = content.version; 
            
            // try loading previously saved reflection
            this.adminService.getReflection(this.school, week, this.day, classId, teacher).then((stored) => {

                  if (!!stored) {
                    
                    // if previously saved reflection found
                    // than restore previously selected answers
                    // and previously entered text
                    JSON.parse(stored)
                        .forEach((previous) => {
                          
                            // find matching question
                            const question = this.reflection.find((question) => question.id === previous.id);

                            // restore entered text
                            if (!!previous.text) {
                              question.value = previous.text;
                            }

                            // restore selected answers
                            if (!!previous.answers && !!question.answers) {
                              previous.answers.forEach((selected) => {
                                
                                const answer = question.answers.find((answer) => answer.id === selected);
                                answer.value = true
                              })
                            }
                        });
                  }
                  
                  this.loader = false;                  
                }) 
                .catch(() => {
                  this.loader = false;
              })
        });
  }
}
