import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { FilterBaseComponent, IOption } from '../base/filter-base.component';

@Component({
  selector: 'app-week-filter',
  templateUrl: '../base/filter-base.component.html',
  styleUrls: ['../base/filter-base.component.scss'],
  // tslint:disable-next-line: no-outputs-metadata-property
  outputs: ['onSelect', 'onTemplateClear'],
})
export class WeekFilterComponent extends FilterBaseComponent implements OnInit, OnChanges {
  public weekAmount: number = 52;
  title: string = 'Week';

  options: Array<IOption> = [];

  @Input()
  public reset: boolean = false;

  @Input()
  public set isTemplateChanged(value: boolean) {
    this.templateChanged = value;
  }

  constructor() {
    super();
  }

  public ngOnInit(): void {
    for (let index = 1; index <= this.weekAmount; index++) {
      this.options.push({ value: index, title: `${index}` });
    }
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.reset) {
      this.value = null;
      this.reset = false;
    }
  }
}
