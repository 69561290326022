import {Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-adminassessment',
    templateUrl: './assessment.component.html',
    styleUrls: ['./assessment.component.scss'],
  })

export class AdminAssesmentComponent implements OnInit {

    public unknownPage: string = 'assessment.title';
    public assessment: {key: string, title: string }[];
    public school: any;
    
    constructor(private adminService: AdminService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private translateService: TranslateService) {
       
    }

    public ngOnInit(): void {

      this.assessment = this.adminService.getAssessment();
    }

    public onAssessmentChoose(key) {
      this.router.navigate([key], { relativeTo: this.activatedRoute });
    }
    public onChangeSchool(school) {
      this.school = school;
    }
}