import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState, LoginService } from 'src/app/services';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [LoginService, AppState],
})
export class ResetPasswordComponent implements OnInit {

  public password: string = '';
  public confirmPassword: string = '';
  public resultMessage: boolean = false;
  public isFlagSave: boolean = false;

  constructor( private loginService: LoginService, public appState: AppState, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  public onPasswordEnter() {

    if(this.isFlagSave) {
      
      let passToken =  this.route.snapshot.paramMap.get('passToken');
      let username =  this.route.snapshot.paramMap.get('username');

      this.loginService.resetPassword( passToken, username, this.password)
      .then((response) => {
        console.log('password reset successfully')

        this.router.navigate(['/login']);

      }).catch((error) => {
        this.resultMessage = true;
        console.log('Error: ', error);
      });
    } 
  }

  public onFormChange(): void {
    
    if( this.password === this.confirmPassword && this.validatePassword(this.password) && this.validatePassword(this.confirmPassword)) {
       this.isFlagSave = true;
    } else {
      this.isFlagSave = false;
    }
  }

  private validatePassword( password: string) {
    return  password != '' && password.length >= 8 && !password.includes(' ') 
  }

}
