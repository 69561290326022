import {Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ISchool } from 'src/app/interfaces/ISchool';
import { AdminService, AppState, ContentService } from 'src/app/services';
import { IAssessmentAge } from 'src/app/interfaces/IAssessmentAge';
import { IAssessmentDetails } from 'src/app/interfaces/IAssessmentDetails';
import { ISection } from 'src/app/interfaces/ISection';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
    selector: 'app-edit-assessment',
    templateUrl: './edit.assessment.component.html',
    styleUrls: ['./edit.assessment.component.scss'],
  })

export class AdminEditAssesmentComponent implements OnInit {

    public unknownPage: string = 'admin_shared.view_assessment';

    public loading: boolean = false;
   
    public sections: any; 
    public assessmentDate = moment(new Date()).unix();
    public info;
    public child;
    public school: any;

    constructor(private adminService: AdminService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private appState: AppState,
        private _location: Location,
        private contentService: ContentService,
        private translateService: TranslateService) {
       
    }

    public ngOnInit(): void {

        let idAssessment = this.activatedRoute.snapshot.params['id'];
        let username = this.activatedRoute.snapshot.params['username'];
        let assessment = this.activatedRoute.snapshot.params['assessment'];

        this.school = this.appState.restore('school');

        this.Load(assessment, idAssessment, username);

        this.getNameUser(assessment, username);
      
    }

    private getNameUser(assessment, username) {

      this.adminService.getChildrenByAssessment(this.school.idnumber, assessment).then((response) => {
        
          this.child = response.find((x) => x.student === username);

      })
    }

    private Load(age, idAssessment, username) {

      const _list = { i1: 9, i2: 10, t1: 10, t2: 12, pk: 8, ps: 12 };
      const language = this.translateService.currentLang;
  
      const resultArray = Array.from({ length: _list[age] }, (_, index) => ({ _id: (index + 1).toString() }))
                          .map( item => {
  
                            const id = item._id.length < 2 ? '0000' + item._id : '000' + item._id
                         
                            if (language == 'en') {
                                return { key:  `LBP-${age.toUpperCase()}-AS-${id}` };
                            } else {
                                return { key: `LBP-${language.toUpperCase()}-${age.toUpperCase()}-AS-${id}` };
                            }
                          });

        // retrieve list of questions specific to age group
        const content: Promise<ISection>[] = resultArray.map((folder) => {

            return this.contentService.getAssessmentQuestions(folder.key)
                        .then<ISection>((content) => ({
                            key: folder.key,
                            title: content.title,
                            questions: content.body,
                            version: content.version
                        }));

        });    

        const details = this.adminService.getAssessmentDetail(username, idAssessment);

        // wait till both content and assessment details are loaded
        Promise.all([Promise.all(content), details]).then(([content, details]) => {        

            this.sections = content;

            // update assessment date
            this.assessmentDate = details.date;

            this.info = details;
            
            // for each saved assessment block
            // for each saved question
            // restore the selected answer
            details.assessment.forEach((assessment) => {

            const section = this.sections.find((x) => x.key.replace('-ES-', '-') === assessment.key);
            assessment.questions.forEach((answeredQuestion) => {
            
            const question = section.questions.find((question) => question.id === answeredQuestion.id);
            question.selected = answeredQuestion.answer;
            });

            })

        });
    }
    
    public onAnswerChange(question, answer) {
        question.selected = answer.id;
    }
    
    public back() {
      this._location.back();
    }

    public onChanged(date:any){
        if(date){
            this.assessmentDate = date;
        }
    }
}