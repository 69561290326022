import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.scss'],
})
export class FiltersPanelComponent implements OnInit {
  /**
   * Filter parameters.
   */
  public parameters: IParameters = {
    language: null,
    mode: null,
    age: null,
    week: null,
    day: null,
    activity: null,
  };

  public language: string = null;
  public age: string = null;

  public filtersToShow: IFilterVisibility = {
    mode: true,
    age: true,
    week: false,
    day: false,
    activity: false,
  };

  public reset: boolean = false;

  /**
   * Template version.
   */
  @Input()
  public version: string = null;

  /**
   * Flag to indicate that template was changed.
   */
  @Input()
  public templateChanged: boolean = false;

  @Output()
  public onLoad: EventEmitter<IParameters> = new EventEmitter();

  @Output()
  public onSave: EventEmitter<IParameters> = new EventEmitter();

  @Output()
  public onTemplateClear: EventEmitter<any> = new EventEmitter();

  constructor() {}

  public ngOnInit(): void {}

  public vmOnLoad(form: NgForm): void {
    switch (this.parameters.mode) {
      case 'da':
      case 'ds': {
        if (this.parameters.age && this.parameters.week && this.parameters.day && this.parameters.activity) {
          this.onLoad.emit(Object.assign({}, this.parameters));
        }

        break;
      }
      case 'lp':
      case 'mtweek': {
        if (this.parameters.age && this.parameters.week) {
          this.onLoad.emit(Object.assign({}, this.parameters));
        }
        break;
      }
      case 'hc': {
        if (this.parameters.age && this.parameters.week && this.parameters.day) {
          this.onLoad.emit(Object.assign({}, this.parameters));
        }
        break;
      }

      // Weekly
      case 'wda':
      case 'wds': {
        if (this.parameters.age && this.parameters.week && this.parameters.activity) {
          this.onLoad.emit(Object.assign({}, this.parameters));
        }

        break;
      }

      case 'wlp': {
        if (this.parameters.age && this.parameters.week) {
          this.onLoad.emit(Object.assign({}, this.parameters));
        }
        break;
      }

      case 'whc': {
        if (this.parameters.age && this.parameters.week) {
          this.onLoad.emit(Object.assign({}, this.parameters));
        }
        break;
      }

      default: {
        console.log('Unknown mode: ', this.parameters.mode);
        break;
      }
    }
  }

  public vmOnSave(form: NgForm): void {
    if (this.templateChanged) {
      this.onSave.emit(Object.assign({}, this.parameters));
    }
  }

  public vmOnLanguageSelect(language: string): void {
    this.language = language;
  }

  public vmOnAgeSelect(age: string): void {
    this.age = age;
    this.vmResetParameters('age');
    this.vmSwitchFilters(null);
  }

  public vmOnModeSelected(mode: string): void {
    this.vmResetParameters('mode');
    this.vmSwitchFilters(this.parameters.mode);
  }

  /**
   * Method to set `templateChanges` variable to `false` and create event to clear template when user agreed that his changes will be lost.
   */
  public vmOnTemplateClear(e: boolean): void {
    this.templateChanged = false;
    this.onTemplateClear.emit(true);
  }

  private vmResetParameters(by?: string): void {
    if (by === 'language' || by === 'age') {
      this.parameters = {
        language: this.parameters.language,
        age: this.parameters.age,
        mode: this.parameters.mode,
        week: this.parameters.week,
        day: this.parameters.day,
        activity: this.parameters.activity
      };
    } else if (by === 'age') {
      this.parameters = {
        language: this.parameters.language,
        age: this.parameters.age,
        mode: null,
        week: null,
        day: null,
        activity: null,
      };
    } else if (by === 'mode') {
      this.parameters = {
        language: this.parameters.language,
        mode: this.parameters.mode,
        age: this.parameters.age,
        week: null,
        day: null,
        activity: null,
      };
    } else {
      this.parameters = {
        language: this.parameters.language,
        mode: null,
        age: null,
        week: null,
        day: null,
        activity: null,
      };
    }
  }

  private vmSwitchFilters(mode: string): void {
    switch (mode) {
      case 'da':
      case 'ds': {
        this.filtersToShow = {
          mode: true,
          age: true,
          week: true,
          day: true,
          activity: true,
        };
        break;
      }
      case 'lp':
      case 'mtweek': {
        this.filtersToShow = {
          mode: true,
          age: true,
          week: true,
          day: false,
          activity: false,
        };
        break;
      }
      case 'hc': {
        this.filtersToShow = {
          mode: true,
          age: true,
          week: true,
          day: true,
          activity: false,
        };
        break;
      }

      // Weekly

      case 'wda':
      case 'wds': {
        this.filtersToShow.activity = true;
        this.filtersToShow.day = false;
        this.filtersToShow.week = true;
        break;
      }
      case 'wlp': {
        this.filtersToShow = {
          mode: true,
          age: true,
          week: true,
          day: false,
          activity: false,
        };
        break;
      }

      case 'whc': {
        this.filtersToShow.age = true;
        this.filtersToShow.activity = false;
        this.filtersToShow.day = false;
        this.filtersToShow.week = true;
        this.filtersToShow.mode = true;
        break;
      }

      default: {
        this.filtersToShow.age = true;
        this.filtersToShow.activity = false;
        this.filtersToShow.day = false;
        this.filtersToShow.week = false;
        this.filtersToShow.mode = false;
        break;
      }
    }
    this.reset = true;
  }
}

export interface IParameters {
  language: string;
  mode: string;
  age: string;
  week: string;
  day: string;
  activity: string;
}

interface IFilterVisibility {
  mode: boolean;
  age: boolean;
  week: boolean;
  day: boolean;
  activity: boolean;
}
