import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { TeacherService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-daily-standarts',
  templateUrl: './daily-standarts.component.html',
  styleUrls: ['./daily-standarts.component.scss','../../teacher/teacher.component.scss'],
})
export class DailyStandartsComponent implements OnInit, OnChanges {

  public tableData: any[] = null;  
  public language: string;

  /**
   * Pretty titles for activities.
   */
  private activitiesTitles: Array<{ key: string; title: string }> = null;

  constructor(private teacherService: TeacherService, private translateService: TranslateService) {
    this.activitiesTitles = this.teacherService.getStandards();
  }

  @Input()
  public xml: any = null;

  public ngOnInit(): void {
    this.language = this.translateService.currentLang;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!this.xml) {
      this.vmCreateTableData(this.xml);
    } else {
      this.tableData = null;
    }
  }

  private vmCreateTableData(data: any): void {
    this.tableData = data.elements.map(
      ( _activity: { type: string; 
                     name: string; 
                     attributes: { id: string }; 
                     elements: Array<{ type: string; text: string }> }) => {
        return {
          title: this.activitiesTitles.find((_title) => _title.key === _activity.attributes.id).title,
          text: !!_activity.elements ? _activity.elements[0].text : '',
          id: _activity.attributes.id
        };
      }
    );
  }
}
