<app-menu [home]="false" [pageAdmin]="true" [unknownPage]='unknownPage' [school]="school"></app-menu>
<app-filterbyschool-component (onChange)="onChangeSchool($event)" [back]='true'></app-filterbyschool-component>

<div class="wrapperPage container-lg">
    <div class="blockWrapperButtons">
        <div class="groupButtons">
           
            <input type="file"
                id="file"
                accept=".xlsx, .xls, .csv"
                (click)="reset()"
                (change)="handleFileInput($event.target.files)"
                class="inputFile"
                #myInput>
            <label for="file">
                <span class="btn">{{ 'admin_bm.choose_file' | translate }}</span>
                <span *ngIf="!fileToUpload">{{ 'admin_import.file_not_selected' | translate }}</span>
                <span *ngIf="fileToUpload">{{fileToUpload.name}}</span>
            </label>

            <button (click)="downloadTemplate()">{{ 'admin_import.download' | translate }}</button>
        </div>

        <div class="wrapperInputElement">
            <button (click)="addUsers()"
                    [disabled]="unActiveBtn">{{ 'admin_import.add_to_school' | translate }}</button>
        </div>
    </div>

    <div class="descriptionFile" *ngIf="!datas">
        <ul>
            <li><strong>{{ 'profile.username' | translate}}</strong> - {{ 'admin_import.login_info' | translate }}</li>
            <li><strong>{{ 'login.password' | translate}}</strong> - {{ 'admin_import.password_info' | translate }}</li>
            <li><strong>{{ 'profile.firstname' | translate }}</strong> - {{ 'admin_import.fn_info' | translate }}</li>
            <li><strong>{{ 'profile.lastname' | translate }}</strong> - {{ 'admin_import.ln_info' | translate }}</li>
        </ul>
    </div>

    <div class="tableCustom" *ngIf="datas">
        <div class="rowCustom header">
            <div class="cell">{{ 'profile.username' | translate}}</div>
            <div class="cell">{{ 'login.password' | translate}}</div>
            <div class="cell">{{ 'profile.firstname' | translate }}</div>
            <div class="cell">{{ 'profile.lastname' | translate }}</div>
            <div class="cell">{{ 'admin_shared.status' | translate}}</div>
        </div>
        <div class="rowCustom" *ngFor="let item of datas">
            <div class="cell">{{item.username}}</div>
            <div class="cell">{{item.password}}</div>
            <div class="cell">{{item.firstname}}</div>
            <div class="cell">{{item.lastname}}</div>
            <div class="cell">
                <div class="saved" *ngIf="item.status">
                    <p [ngClass]="{'successful': item.status === 'saved', 'error': item.status === 'error'}">
                        {{item.status === 'error' ? 'admin_import.error_saved' : 'admin_import.successful' | translate}}
                    </p>
                </div>
                <div class="errors" *ngIf="!item.status">
                    <p>{{item.errors.username | translate}}</p>
                    <p>{{item.errors.password | translate}}</p>
                    <p>{{item.errors.firstname | translate}}</p>
                    <p>{{item.errors.lastname | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Loader spinner-->
<div class="myloader" *ngIf="!loading">
    <loader></loader>
</div>
<!--end spinner -->