// src/app/email-verification.service.ts
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/services/app.service'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EmailVerificationService {
 // tarasenko.agt@gmail.com
  private apiRoot: string = environment.api;
  private apiKey: string = environment.apiKey;

  constructor(private http: HttpClient, private appState: AppState) { }

  public sendCode(email: string) {

    const session = this.appState.get('session');
    const headers = new HttpHeaders({ 'x-vms-session': session });
    const url = `${this.apiRoot}/user/emailcode`;
    const body = { "apikey": this.apiKey, "email": email };         
  
     return this.http.post(url, body, { headers }).toPromise();     
    }

    public verifyCode(code: string) {

      const session = this.appState.get('session');
      const headers = new HttpHeaders({ 'x-vms-session': session });
      const url = `${this.apiRoot}/user/emailverify`;
      const body = {
          "apikey": this.apiKey,
          "code": code
       };         
    
       return this.http.post(url, body, { headers }).toPromise();     
    }

}