<div class="wrapper">
    <div class="mb-3">
        <app-menu [mode]="title" [school]="school"></app-menu> 
        <app-filters [backBtnDisabled]='true' [showWeek]="true" [showDay]="daily" (weekChanged)="weekChanged($event)" (dayChanged)="dayChanged($event)"></app-filters>
    </div>
    
    <div class="container-lg">
        <app-home-connection *ngIf="template" [resources]="resources"></app-home-connection>
        <div *ngIf="missing">{{'shared.missing' | translate}}</div>
        <div *ngIf="noClassesAssigned" class="noClassesAssigned">{{'shared.no_class' | translate}}! {{'shared.contact_admin' | translate}}</div>
        <div class="loader" *ngIf="loader">
            <loader></loader>
        </div>
    </div>
</div>