import { Component, OnInit } from '@angular/core';
import { TeacherService } from 'src/app/services/teacher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/services';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  public title: string = '';
  public school: any;
  public sections  = [];
  private age: string;

  constructor( private router: Router, 
               private appState: AppState,
               private activatedRoute: ActivatedRoute, 
               private teacherService: TeacherService) { }

  ngOnInit(): void {
    this.school = this.appState.restore('school');
    this.age = this.appState.restore('age');
    this.sections = this.teacherService.getSections(this.age);
    if (this.age == 'in' || this.age == 't1' || this.age == 't2') {
      this.sections.find(section => section.key === 'ch').titleKey = "resources.tg.characteristics_of_infants_toddlers";
    }
  }

  public onSectionChoose( key:string ) {

    switch (key) {
      case 'teacher_guide': 
        let links = this.teacherService.getResourcesForPDF();
        let url = links.find((x) => x.key === key).resources[this.age];

        window.open(url, "_blank");
        
        break;
      case 'in':
      case 'ro':
      case 'cl': 
      case 'ch':
        this.router.navigate(['content', key ], { relativeTo: this.activatedRoute });
        break;
      default: 
        this.router.navigate(['tile', key ], { relativeTo: this.activatedRoute });
    } 
  }

}
