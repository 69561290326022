import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/services/app.service';
import { TeacherService } from 'src/app/services/teacher.service';

@Injectable()
export class AssessmentService {

    private contentRoot: string = environment.content;
    private apiRoot: string = environment.api;

    constructor(private http: HttpClient,
                private appState: AppState,
                private teacherService: TeacherService) {
        // empty        
    }
    
    /**
     * Get assessment gropus
     * @param age age group
     */
    public getAssessmentGroups(age: string) {

        const list = [
            { age: 'in', key: 'i1', title: 'Birth - 6 Months', titleKey: 'age_group.0_6' },
            { age: 'in', key: 'i2', title: '6 - 12 Months',  titleKey: 'age_group.6_12' },
            { age: 't1', key: 't1', title: '12 - 24 Months', titleKey: 'age_group.12_24'},
            { age: 't2', key: 't2', title: '24 - 36 Months', titleKey: 'age_group.24_36' },
            { age: 'ps', key: 'ps', title: '36 - 48 Months', titleKey: 'age_group.36_48' },
            { age: 'pk', key: 'pk', title: '48 - 60 Months', titleKey: 'age_group.48_60' }
        ]

        return list.filter((item) => item.age === age);
    }

    public getAssessment() {
        return [
            {key: 'i1', title: 'Birth - 6 Months', titleKey: 'age_group.0_6'},
            {key: 'i2', title: '6 - 12 Months',  titleKey: 'age_group.6_12'},
            {key: 't1', title: '12 - 24 Months', titleKey: 'age_group.12_24'},
            {key: 't2', title: '24 - 36 Months', titleKey: 'age_group.24_36'},
            {key: 'ps', title: '36 - 48 Months', titleKey: 'age_group.36_48'},
            {key: 'pk', title: '48 - 60 Months', titleKey: 'age_group.48_60'},
        ]
      }
}
