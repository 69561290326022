import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppState, TeacherService, ContentService } from 'src/app/services';

@Component({
    selector: 'app-plans',
    templateUrl: './lessonplans.component.html',
    styleUrls: ['./lessonplans.component.scss']
})
export class LessonPlansComponent implements OnInit {

    public school: any;

    public template: string = null;
    public age: string = null;
    public week: string = null;
    public missing: boolean = false;
    public loader: boolean = false;

    // lesson plans mode
    public mode: 'lp' | 'wlp';

    constructor(private appState: AppState,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private teacherService: TeacherService,
                private contentService: ContentService,
                private translateService: TranslateService) {

        this.age = this.appState.restore('age');
        this.week = this.appState.restore('week');
        
        if (!this.age) {
            this.appState.store('age', 'in', true);
            this.age = this.appState.get('age');
          }

        if (!this.week) {
            this.appState.store('week', 1, true);
            this.week = this.appState.get('week');
        }

        this.mode = this.teacherService.hasDailyView(this.age) ? 'lp' : 'wlp';
    }

    ngOnInit(): void {

        this.school = this.appState.restore('school');
        
        this.load(this.age, this.week);
    }    
  
    public print(){
        window.print();
    }
    
    public weekChanged(week) {

        this.week = week;
        this.load(this.age, this.week);
    }
    public _ageChanged(age) {
       
        this.age = age;
        this.mode = this.teacherService.hasDailyView(this.age) ? 'lp' : 'wlp';
        
        this.load(this.age, this.week);
    }

    public getAgeTitle(ageKey: string): string {

        const age = this.teacherService.getAges().find((a) => a.key == ageKey);
        return age.titleKey;
    }
    
    public selectedActivity(item) {

        this.appState.store('day', item.day, true);
        this.router.navigate(['../da', item.activity], { relativeTo: this.activatedRoute });
    }

    private load(age, week) {

        const language = this.translateService.currentLang;

        this.loader = true;
        this.contentService.getLessonPlans(language, age, week)
            .catch((err) => {
                if (language !== 'en') {
                    return this.contentService.getLessonPlans('en', age, week)
                } else { 
                    throw err;
                }
            })
            .then((data) => {

                this.missing = false;
                this.template = data.template;   
                this.loader = false;             
            })
            .catch((err) => {

                this.missing = true;
                this.template = null;
                this.loader = false;            
            })
    }
}
