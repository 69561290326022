import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState, TeacherService, ContentService, IResource } from 'src/app/services';

@Component({
  selector: 'teacher-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})

export class ConnectionsComponent implements OnInit {

  public title: string = '@Home Connections';
  public loader: boolean = false;
  public school: any;

  public template: string = null;
  public resources: IResource[];
  public missing: boolean = false;

  private age: string = null;
  private week: string = null;
  private day: string = null;
  private language: string = null;
  
  // is daily view avaialble
  public daily: boolean;

  constructor(private appState: AppState,
              private teacherService: TeacherService,
              private contentService: ContentService, 
              private translateService: TranslateService) {
  }

  ngOnInit(): void {

    this.school = this.appState.restore('school');
    
    this.age = this.appState.restore('age');
    this.daily = this.teacherService.hasDailyView(this.age);

    this.week = this.appState.restore('week');
    this.day = this.daily ? this.appState.restore('day') : null;
    
    this.language = this.translateService.currentLang;

    this.load(this.age, this.week, this.day, this.language);
  }

  public weekChanged(week) {

    this.week = week;
    this.load(this.age, this.week, this.day,  this.language);
  }

  public dayChanged(day) {

    this.day = day;
    this.load(this.age, this.week, this.day, this.language);
  }

  public _ageChanged(age) {
        
    this.age = age;
    
    this.daily = this.teacherService.hasDailyView(this.age);
    this.day = this.daily ? this.appState.get('day') : null;

    this.load(this.age, this.week, this.day, this.language);
    
}
  
  private load(age, week, day, lang) {
    
    this.loader = true;
    
    this.contentService
        .getHomeConnections(lang, age, week, day)
        .catch((err) => {
          if (lang !== 'en') {
              return this.contentService.getHomeConnections('en', age, week, day)
          } else { 
              throw err;
          }
        })
        .then((data) => {

          this.missing = false;
          this.loader = false;
          this.template = data.template;
          this.resources = data.files.map<IResource>((file) => ({
            title: file.filename,
            data: file.url,
            type: file.type
          }));                
        })
        .catch((err) => {

            this.missing = true;
            this.template = null;
            this.loader = false;
        })
  }
}
