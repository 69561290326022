import { Component, Input, OnInit } from '@angular/core';
import { AppState } from 'src/app/services';

@Component({
  selector: 'app-alignment',
  templateUrl: './alignment.component.html',
  styleUrls: ['./alignment.component.scss']
})
export class AlignmentComponent implements OnInit {

  public title: string = 'State Standards';
  public school: any;
  @Input() public  selectedState: number;

  public stateStandards = [
    { id: 1, key: 'Alabama',  resource: ''},
    { id: 2, key: 'Alaska',  resource: ''},
    { id: 3, key: 'Arizona', resource: ''},
    { id: 4, key: 'Arkansas',  resource: ''},
    { id: 5, key: 'California', resource: ''},
    { id: 6, key: 'Colorado',  resource: ''},
    { id: 7, key: 'Connecticut', resource: ''},
    { id: 8, key: 'Delaware',   resource: ''},
    { id: 9, key: 'District of Columbia', resource: ''},
    { id: 10, key: 'Florida',  resource: '.../../../../../../../assets/pdf/FL_and_LBP_Alignment _0_to_60_Months.pdf'},
    { id: 11, key: 'Georgia',  resource: '.../../../../../../../assets/pdf/GA_and_LBP_Alignment_0_to_60_Months.pdf'},
    { id: 12, key: 'Hawaii', resource: ''},
    { id: 13, key: 'Idaho',  resource: ''},
    { id: 14, key: 'Illinois',  resource: ''},
    { id: 15, key: 'Indiana', resource: ''},
    { id: 16, key: 'Iowa', resource: ''},
    { id: 17, key: 'Kansas',  resource: ''},
    { id: 18, key: 'Kentucky', resource: ''},
    { id: 19, key: 'Louisiana', resource: ''},
    { id: 20, key: 'Maine', resource: ''},
    { id: 21, key: 'Maryland', resource: ''},
    { id: 22, key: 'Massachusetts', resource: ''},
    { id: 23, key: 'Michigan', resource: ''},
    { id: 24, key: 'Minnesota',  resource: ''},
    { id: 25, key: 'Mississippi', resource: ''},
    { id: 26, key: 'Missouri', resource: ''},
    { id: 27, key: 'Montana',  resource: ''},
    { id: 28, key: 'Nebraska', resource: ''},
    { id: 29, key: 'Nevada', resource: ''},
    { id: 30, key: 'New Hampshire', resource: ''},
    { id: 31, key: 'New Jersey', resource: ''},
    { id: 32, key: 'New Mexico',  resource: ''},
    { id: 33, key: 'New York', resource: ''},
    { id: 34, key: 'North Carolina',  resource: ''},
    { id: 35, key: 'North Dakota',  resource: ''},
    { id: 36, key: 'Ohio',  resource: ''},
    { id: 37, key: 'Oklahoma',  resource: ''},
    { id: 38, key: 'Oregon',  resource: ''},
    { id: 39, key: 'Pennsylvania',  resource: ''},
    { id: 40, key: 'Rhode Island',  resource: ''},
    { id: 41, key: 'South Carolina', resource: ''},
    { id: 42, key: 'South Dakota', resource: ''},
    { id: 43, key: 'Tennessee',  resource: ''},
    { id: 44, key: 'Texas',  resource: ''},
    { id: 45, key: 'Utah', resource: ''},
    { id: 46, key: 'Vermont',  resource: ''},
    { id: 47, key: 'Virginia', resource: ''},
    { id: 48, key: 'Washington',  resource: ''},
    { id: 49, key: 'West Virginia', resource: ''},
    { id: 50, key: 'Wisconsin', resource: ''},
    { id: 51, key: 'Wyoming',  resource: ''},
    { id: 52, key: 'HEAD START',  resource: '.../../../../../../../assets/pdf/Head_Start_and_Learning_Beyond_Alignment _0_to_60_Months.pdf'}
  ]

  constructor(private appState: AppState) { }

  ngOnInit(): void {
    
    this.school = this.appState.restore('school');
    let _selectedState = this.appState.restore('selectedState');

    if(_selectedState){
      this.selectedState = _selectedState;
    }
  }

  onStateChange(){
    this.appState.store('selectedState', this.selectedState, true);
  }
  
  public back(){
    history.back();
  }

}
