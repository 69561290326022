import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, EMPTY, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services';

@Injectable()
export class Interceptor401 implements HttpInterceptor {

  constructor(private sessionService: SessionService,
              private router: Router) {
    /* empty */
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(req)
              .pipe(
                    tap((event: HttpEvent<any>) => {
                          if (event instanceof HttpResponse) {

                            // any call to API will reset session timeout
                            // need to apply same logic for client timeout
                            if (event.url.startsWith(environment.api) ) {

                              this.sessionService.resetSessionTimeout();
                            }
                          }
                        }
                    ),
                    catchError((response: HttpErrorResponse) => {
                      if (response instanceof HttpErrorResponse) {

                        const isLoginMethod = !!response.url ? response.url.endsWith('/login') : null;
                        const isUnauthorized = response.status === 401;

                        if (!isLoginMethod && isUnauthorized) {
                          var msg = 'Your session is expired. You will be redirected to login page.';                      
                          alert(msg);
                          
                          sessionStorage.clear();

                          this.router.navigate(['/login']);
                          // return empty observable to stop error propagation
                          return EMPTY;
                        }
                      }
                      
                      return throwError(response);
                    })               
              );
  }
}
