
  <div class="container-lg">
    <section class="daily-standarts-comp">
      <!--container fluid start-->
      <div class="container-fluid">
        <!--row start-->
        <div class="row mb-1 title-row">
          <p>{{'standards.title' | translate}}</p>  
        </div>
        <!--row end-->
    
        <!--row start-->
        <div class="row mb-1" *ngFor="let row of tableData">
          <!--column start-->
          <div class="col first">
            <div class="svg-wrapper" [ngClass]="{es_icons: language == 'es'}">
              <div class="svg" [ngClass]="{al: row.id == 'al',
                                           cg: row.id == 'cg',
                                           ir: row.id == 'ir',
                                           ll: row.id == 'll',
                                           mm: row.id == 'mm',
                                           pd: row.id == 'pd',
                                           st: row.id == 'st' }">
               </div>
             </div>
          </div>
          <!--column end-->
          <!--column start-->
          <div class="col second">{{ row.text }}</div>
          <!--column end-->
        </div>
        <!--row end-->
      </div>
      <!--container fluid end-->
    </section>
  </div>
  
