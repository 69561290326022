import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState, TeacherService } from 'src/app/services';

@Component({
  selector: 'app-sub-tile',
  templateUrl: './sub-tile.component.html',
  styleUrls: ['./sub-tile.component.scss']
})
export class SubTileComponent implements OnInit {
  public title: string = '';
  public school: any;
  public age: string;  
  public section: string; 
  public subTile: any;
  public ageGroupFolder: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private appState: AppState, private teacherService: TeacherService) { 
  }

  ngOnInit(): void {

    this.school = this.appState.restore('school');

    this.age = this.appState.restore('age');

    if ( this.age == 'in' ||  this.age == 't1' || this.age == 't2') {
     
      this.ageGroupFolder = 'Infant-Toddler';
    } else {
     
      this.ageGroupFolder = 'Preschool';
    }

    this.section = this.activatedRoute.snapshot.params['sub'];

    if (this.section) {
      
      this.getSubTile();
    }
  }

  public exit() {
    
    this.router.navigate(['home/tg'], { relativeTo: this.activatedRoute.parent });
  }

  public getSubTile(): void {

    const subTiles = this.teacherService.getSubTiles();
    this.subTile = subTiles.find((item) => this.section == item.key);
    // for teacher mode, 'Getting Started' dont show
    // but show in Admin mode
    if (this.section === 'ulb') {
      
      this.subTile.resources = this.subTile.resources.filter((x) => x.key != 'start');
    }
    
      // 	add additional resources for Teacher Interactions and remove Differentiated  Instruction;
    if( (this.age == 'in' || this.age == 't1' || this.age == 't2') && this.subTile.key == 'ti' ) {

      let additionalResources = [ 
        {
          title: 'Serve and Return',
          key: 'serve&return',
          src: 'Sub-Tile-Serve-and-Return.jpg',
          titleKey: 'resources.tg.ti.serve_return'
        },
        {
            title: 'Curriculum',
            key: 'curriculum',
            src: 'Sub-Tile-Curriculum.jpg',
            titleKey: 'resources.tg.ti.curriculum'
        }
      ]

      this.subTile.resources = this.subTile.resources.concat(additionalResources);
      this.subTile.resources =  this.subTile.resources.filter(item => item.key != "instruction");
    }
  }

  public onModeChoose(key: string){
    
      let sub = this.activatedRoute.snapshot.params['sub'];
      this.router.navigate(['home/tg/content', sub, key ], { relativeTo: this.activatedRoute.parent });
  }
}
