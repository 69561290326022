<div class="right" (click)="openAgeGroupPicker($event)">
    <div class="text"> {{ageTitle | translate}} </div>
    <div  class="icon smile">
       <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175 175">
         <defs>
           <style>
             .cls-111 {
               fill: #74d1e6;
             }
       
             .cls-222 {
               fill: #fefefe;
             }
           </style>
         </defs>
         <g>
           <path class="cls-111" d="M94.92,167.2c-24.51,1.39-44.89-5.66-61.87-21.72A76.1,76.1,0,0,1,8.81,96.25c-2.38-24.6,5-46.1,22.29-63.89A76,76,0,0,1,79.4,9c24-2.37,45.15,4.62,62.82,21.09a76.21,76.21,0,0,1,24.53,49c2.37,22.28-3.42,42.31-17.76,59.6S116,165.16,94.92,167.2ZM128.48,88H47.29c0,.22.06.4.1.56A79.84,79.84,0,0,0,56,109.79a52.43,52.43,0,0,0,12.91,15,31.71,31.71,0,0,0,17.56,6.87,26.37,26.37,0,0,0,6-.41,33.21,33.21,0,0,0,16.79-8.46,52.93,52.93,0,0,0,10-12.25A77.71,77.71,0,0,0,127,93.14C127.51,91.44,128,89.74,128.48,88ZM71.89,72a.9.9,0,0,0,.08-.25,43.89,43.89,0,0,0-.3-6A11.89,11.89,0,0,0,50.81,60.5a10.65,10.65,0,0,0-2.54,5.55c-.18,1.4-.12,2.83-.16,4.25,0,.57,0,1.14,0,1.74Zm55.74,0a43.09,43.09,0,0,0-.3-6.18,11.73,11.73,0,0,0-10.77-9.66,11.49,11.49,0,0,0-7.13,1.71A11.34,11.34,0,0,0,104,65.55a37.46,37.46,0,0,0-.2,6.37s.06.07.09.11Z"/>
           <path class="cls-222" d="M128.48,88c-.5,1.75-1,3.45-1.49,5.15a77.71,77.71,0,0,1-7.75,17.43,52.93,52.93,0,0,1-10,12.25,33.21,33.21,0,0,1-16.79,8.46,26.37,26.37,0,0,1-6,.41,31.71,31.71,0,0,1-17.56-6.87,52.43,52.43,0,0,1-12.91-15,79.84,79.84,0,0,1-8.58-21.24c0-.16-.06-.34-.1-.56Z"/>
           <path class="cls-222" d="M71.89,72H48.11c0-.6,0-1.17,0-1.74,0-1.42,0-2.85.16-4.25a10.65,10.65,0,0,1,2.54-5.55,11.89,11.89,0,0,1,20.86,5.32,43.89,43.89,0,0,1,.3,6A.9.9,0,0,1,71.89,72Z"/>
           <path class="cls-222" d="M127.63,72H103.88s-.09-.07-.09-.11a37.46,37.46,0,0,1,.2-6.37,11.34,11.34,0,0,1,5.44-7.65,11.49,11.49,0,0,1,7.13-1.71,11.73,11.73,0,0,1,10.77,9.66A43.09,43.09,0,0,1,127.63,72Z"/>
         </g>
       </svg>
    </div>
  </div>

  <div *ngIf="ageGroupPickerState" class="agePicker _bunner">
    <div class="ageGroup" (clickOutside)="onClickedOutside($event)">
      <div class="container">
        <div class="title">{{'age_group.select_age' | translate}}</div>
        <div class="row mt-3">
          <div class="col-sm-6 col-md-4 col-lg-4" *ngFor="let age of ages">
            <div class="card mb-3" (click)="onAgeChoose(age.key)" [ngClass]="{'active': age.key == this.ageKey }" >
              <img class="card-img" src="/assets/img/ages/{{age.key}}.jpg" alt="{{age.title}}">
              <div class="card-title-top bg-warning d-flex justify-content-center p-2 font-weight-bold text-uppercase">
                {{ age.titleKey | translate }}
              </div>
              <div class="card-title-bottom bg-success d-flex justify-content-center text-light p-2 font-weight-bold">
                {{ age.subtitleKey | translate }}
              </div>
            </div>
          </div>     
        </div>
      </div>    
    </div>
  </div>