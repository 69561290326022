<div class="wrapper" *ngIf="!loader">
        
        <app-daily-activity *ngIf="template" 
                            [age]="age" 
                            [activity]="activity" 
                            [xml]="template" 
                            [resources]="resources"></app-daily-activity>
        <div *ngIf="missing">{{'shared.missing' | translate}}</div>
        
        <button class="btn btn-link green" (click)="openConenctions()">{{'daily_activities.home_connections' | translate}}</button>
        <button class="btn btn-link blue" (click)="openReflections()">{{'daily_activities.teacher_reflections' | translate}}</button>
</div>

<div class="wrapper loader" *ngIf="loader">
        <loader></loader>
</div>


