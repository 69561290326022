import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { FilterBaseComponent, IOption } from '../base/filter-base.component';

@Component({
  selector: 'app-day-filter',
  templateUrl: '../base/filter-base.component.html',
  styleUrls: ['../base/filter-base.component.scss'],
  // tslint:disable-next-line: no-outputs-metadata-property
  outputs: ['onSelect', 'onTemplateClear'],
})
export class DayFilterComponent extends FilterBaseComponent implements OnInit, OnChanges {
  title = 'Day';

  options: Array<IOption> = [
    { value: 1, title: 'Mon' },
    { value: 2, title: 'Tue' },
    { value: 3, title: 'Wed' },
    { value: 4, title: 'Thu' },
    { value: 5, title: 'Fri' },
  ];

  @Input()
  public reset: boolean = false;

  @Input()
  public set isTemplateChanged(value: boolean) {
    this.templateChanged = value;
  }

  constructor() {
    super();
  }

  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.reset) {
      this.value = null;
      this.reset = false;
    }
  }
}
