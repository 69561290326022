import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  private _resources: IResource[] = [];
  constructor() {}

  /**
   * Method to get resources array.
   */
  public get getResources(): IResource[] {
    return this._clone();
  }

  /**
   * Method to store resource.
   */
  public store(resource: IResource): void {
    this._resources.push(resource);
  }

    /**
   * Method to replace  resource.
   */
    public replace (resource: IResource): void {
      const index = this._resources.findIndex(_resource => _resource.title.split('.')[0] == resource.title.split('.')[0]);
      if (index !== -1) {
        this._resources[index] = { ...resource };
      } else {
        console.error('Resource not found, cannot update.');
      }
    }

  /**
   * Method to `Clear List of Resources.`
   * Removes all resources stored in service.
   */
  public clear(): void {
    this._resources = [];
  }

  /**
   * Method to remove resource from list.
   * @param resource resource
   */
  public remove(resource: IResource): void {
    this._resources = this._resources.filter((_resource: IResource) => _resource.title !== resource.title);
    this.rename();
  }

  public sortbyTitle(a: IResource, b: IResource){
    if (a.title.match(/\d+/) > b.title.match(/\d+/)) return 1;
    if (a.title.match(/\d+/) < b.title.match(/\d+/)) return -1;
    return 0;
  }

  private _clone(): IResource[] {
    return this._resources.map((_x) => Object.assign({}, _x)).sort(this.sortbyTitle);
  }

  /**
   * Method to rename resources in the list after remove.
   */
  private rename(): void {
    this._resources.forEach((_resource: IResource, index: number) => {
      const ext = _resource.title.slice(_resource.title.lastIndexOf('.'));
      _resource.title = `resource${index + 1}${ext}`;
    });
  }
}

export interface IResource {
  title: string;
  data: string;
  type: string;
}
