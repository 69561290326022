import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Pipe({
  name: 'search',
  pure: false
})
@Injectable()
export class SearchPipe implements PipeTransform {

  transform(items: any[], studentName: string, args: string[]): any {
    if (!studentName || !items) return items;
    return SearchPipe.filter(items, studentName, args);
  }

  static filter(items: any[], studentName: string, args: string[]) {
    const toCompare = studentName.toLowerCase();

    function checkInside(item: any, studentName: string) {
      return args.some((_property) => {
        return item[_property].toString().toLowerCase().includes(toCompare);
      });
    }

    return items.filter(function (item) {
      return checkInside(item, studentName);
    });
  }
}