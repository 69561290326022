import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';
import { TeacherService } from 'src/app/services/teacher.service';

@Component({
  selector: 'app-age-filter',
  templateUrl: '../base/filter-base.component.html',
  styleUrls: ['../base/filter-base.component.scss'],
  // tslint:disable-next-line: no-outputs-metadata-property
  outputs: ['onSelect', 'onTemplateClear'],
})
export class AgeFilterComponent extends FilterBaseComponent implements OnInit, OnChanges {
  title = 'Age';

  @Input()
  public reset: boolean = false;

  @Input()
  public set isTemplateChanged(value: boolean) {
    this.templateChanged = value;
  }

  constructor(private teacherService: TeacherService) {
    super();
  }

  ngOnInit(): void {
    this.options = this.teacherService.getAges().map(({ key, title }) => ({ title, value: key }));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.reset) {
      this.value = null;
      this.reset = false;
    }
  }
}
