import { Component, OnInit } from '@angular/core';
import { AppState, LoginService } from 'src/app/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  providers: [LoginService, AppState],
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

      public email: string;
      public validEmail: boolean = false;
      public message: any = { status: '', text: ''};

      constructor( private loginService: LoginService ,  public appState: AppState ) { }

      ngOnInit(): void {
      }

      public onEmailEnter() {

        if ( this.validateEmail(this.email) ) {

          this.validEmail = true;

          this.loginService.resetEmails(this.email)
          .then(() => {

            this.message.status = 'SUCCSESS';
            this.message.text = 'forgot_pass.email_sent';
            this.validEmail = false;
          }, 
          (error) => {

            this.message.status =  error.error.code;
            if ( error.error.code == 'USER_NOT_FOUND' )    this.message.text = 'forgot_pass.email_not_found';
            if ( error.error.code == 'USER_HAS_NO_ROLE' )  this.message.text = 'forgot_pass.user_has_no_role';
          });
        } else{

          this.validEmail = false;
        } 
      }

      public validateEmail(email: string) {
      
        this.message.text = '';
        this.message.status =  '';

        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)){
          
            this.validEmail = true;
            return true;
        } else {
          
            this.validEmail = false;
            return false;
        }
      }
  }

