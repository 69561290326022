import { Injectable } from '@angular/core';
import { AppState } from 'src/app/services/app.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class MessagesService {

    private apiRoot: string = environment.api;

    constructor(
        private http: HttpClient, 
        private translateService: TranslateService,
        private appState: AppState) {
    // empty        
    }

    public getMsgUsername(username) {

        if (username && (/\s/g.test(username.trim()) || !(/^[ A-Za-z0-9_@.-]*$/.test(username)) || /\s$/.test(username))){
            // has space
            return "messages.username_should";

        } else {
            return null;
        }
 
    }

    public getExistUsername(username) {

        return this.Exists(environment.apiKey, username);
    }

    public getMsgPassword(password) {

        if (password && password.length < 8){

            return 'messages.use_for_pass';

        } else if (password && (/\s/g.test(password))){

            return "messages.pass_space";
        } else {
            return null;
        }
    }

    public getMsgDate(date) {

        if (Object.prototype.toString.call(date) === "[object Date]") {
            // it is a date
            if (isNaN(date.getTime())) {  // d.valueOf() could also work
              // date is not valid
              return "messages.date_no_valid";
            } else {
              // date is valid
              return null;
            }
          } else {
            // not a date
            return "messages.date_no_valid";
          }
    }

    public getMsgEmail(email){

        if (email && !this.validateEmail(email)) {
            return email + this.translateService.instant('messages.not_valid');
        } else {
            return null;
        }

    }
    private validateEmail(email) {

        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        return re.test(email);
    }

    private Exists(apiKey: string, username: string) {

        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        // call API to get list of classes
        const url = `${this.apiRoot}/user/exists`;
        let params = new HttpParams();

        params = params.append('apikey', apiKey);
        params = params.append('login_email', username);

        return this.http.get(url, {params, headers})
                        .toPromise()
                        .then((exists) =>{

                            return exists;
                        });      

    }
    public ExistsArray(usernames: Array<string>) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({
            'x-vms-session': session,  
            'Content-Type': 'application/json'});
        // call API to get list of classes
        const url = `${this.apiRoot}/admin/user/exists`;

        const body = usernames;
       
        return this.http.post(url, body, { headers })
                        .toPromise()
                        .then((exists: IExists[]) =>{

                            return exists;
                        });      

    }
}

interface IExists{
    username: string,
    exists: boolean
}