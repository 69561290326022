import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AppState, MessagesService, TeacherService, EmailVerificationService, LoginService} from 'src/app/services';

@Component({
    selector: 'form-user',
    templateUrl: './formuser.component.html',
    styleUrls: ['./formuser.component.scss'],
    providers: [ LoginService],
  })

export class FormUserComponent implements OnInit {

    @Input() user: {username: string, password?: string, lastname: string, firstname: string, email: string, birthdate?: any, language: string, verified: boolean };
    @Input() page: string;  
    @Output() newUser = new EventEmitter<any>();
    @Output() flagSave = new EventEmitter<any>();
    @Input() editPage: boolean;
    @Input() dataSaved: boolean =  false;
    
    public stringBirthdate: string;
    public userHasEditAccess: boolean;
    public errorPassword: string = null;
    public errorUsername: string = null;
    public errorEmail: string = null;

    public show: boolean = false;
    public typePassword: string = 'password';
    
    public validCode: boolean = false;
    public code: string;
    public verify_now_popup = { state: false, error_state: false }
    public profile;
    public lastSavedEmail: string;
    public school: any;
    

    constructor(  
        private getMsgError: MessagesService, 
        private appState: AppState,     
        private teacherService: TeacherService,
        private loginService: LoginService,
        private emailVerificationService: EmailVerificationService) {       
    }

    public ngOnInit(): void {

      this.profile = this.appState.restore('profile');
      this.userHasEditAccess = this.profile.roles.includes('schooladmin') || this.profile.roles.includes('teacher') ;
      this.lastSavedEmail = this.profile.email;
      if (this.user && !this.user.birthdate) {
        this.user.birthdate = moment(new Date()).unix();
      }
      
      this.school = this.appState.restore('school');
    }

    public onChangeSave() {
        this.newUser.emit(this.user);
        
        this.flagSave.emit(this.checkBtnSave()); 
    }

    public getDateFrom(date) {
      this.user.birthdate = date;

      this.onChangeSave();
    }

    public onChangeUsername() {

      this.errorUsername = this.getMsgError.getMsgUsername(this.user.username);

      this.onChangeSave();
    }

    public onChangePassword() {

      this.errorPassword = this.getMsgError.getMsgPassword(this.user.password);

      this.onChangeSave();
    }
    
    public onChangeEmail() {
      
      this.errorEmail = this.getMsgError.getMsgEmail(this.user.email);

      this.onChangeSave();
    }
 
    public onChangeLanguage() {
      this.onChangeSave();
    }

    public onExistsUsername() {
  
      this.getMsgError.getExistUsername(this.user.username).then((response: { exists: boolean}) => {
        if (response.exists) {
           // error           
           this.errorUsername = 'profile.errorUsername';

        } else {
          this.errorUsername = null;
          this.onChangeUsername();
        }
        this.onChangeSave();
      })
    }

    private emailConst() {
   
      if (this.page !== 'userprofile') {
        this.user.email = 'true';
      }
    }

    private checkBtnSave(){
    
      if (!!this.editPage) { 

        // if fill all fields and have not errors
        if ((this.user.firstname && this.user.lastname && this.user.email) &&
            (!this.errorUsername && !this.errorPassword && !this.errorEmail)) {

          return false;
        } else {

          return true;
        } 

      } else {
        // email for teacher and student is always constanta
        // value email is in file admin.service
        this.emailConst();
        
        // if fill all fields
        // and have not errors
        if ((this.user.username && this.user.password && 
          this.user.firstname && this.user.lastname && this.user.email) &&
          (!this.errorUsername && !this.errorPassword && !this.errorEmail)) {

          return false;
        } else {

          return true;
        } 
      }
    }

    public toggleShow(e){

        this.show = !this.show;
        if (this.show) {
          this.typePassword = 'text';
        } else {
          this.typePassword = 'password';
        }
    }

    public emailVerification (){
      
      this.verify_now_popup.state = true;
      this.emailVerificationService.sendCode(this.user.email).then(
        (response) => {
          //
        },
        (error) => {
          console.error('Error sending verification code', error);
        }
      ); 
    }

    public confirm(){
      this.emailVerificationService.verifyCode(this.code).then(
        (response) => {
          this.verify_now_popup.state = false;
          this.code = ''; 
          this.updateUser();
          this.flagSave.emit(true); 
        },
        (error) => {
          console.error('Verification code error', error);   
          this.code = '';
          this.updateUser();  
          this.verify_now_popup.error_state = true;
          this.validCode = false;
        }
      ); 
    }

    public close(){
      this.verify_now_popup.state = false;
      this.verify_now_popup.error_state = false;
      this.code = '';
    }

    public validateCode(code: string): boolean {
      
      this.verify_now_popup.error_state = false;
      this.validCode = code.length == 4;
      return this.validCode;
    }

    public sendVerificationCode(): void {
    
      this.emailVerificationService.sendCode(this.user.email).then(
        (response) => {
        //  console.log('Verification code sent', response);
        },
        (error) => {
          console.error('Error sending verification code', error);
        }
      ); 
    }

    private updateUser(){
     // console.log(this.profile)
      this.teacherService.updateUser(this.user)
      .then((response) => {
        this.loginService.profile().subscribe((_profile) => {
          this.profile = _profile
          this.lastSavedEmail = this.profile.email;
        })
       })
      .catch((error) => {
        console.log('Error:', error);
      });
    }

}