import { Injectable } from '@angular/core';

export type InternalStateType = {
  [key: string]: any
};

@Injectable()
export class AppState {

  public _state: InternalStateType = {};

  // already return a clone of the current state
  public get state() {
    return this._state = this._clone(this._state);
  }

  // never allow mutation
  public set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }

  public get(prop?: any) {
    // use our state getter for the clone
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : null;
  }

  public set(prop: string, value: any) {
    // internally mutate our state
    return this._state[prop] = value;
  }

  public remove(key: string){
    if (this._state.hasOwnProperty(key))
      delete this._state[key];
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  } 
  public store(key: string, value: any, permanent: boolean = false) {

    let item = { value };

    if (permanent) {
      localStorage.setItem(key, JSON.stringify(item));
    } else {
      sessionStorage.setItem(key, JSON.stringify(item));
    }

    this.set(key, value);
  }

  public restore(key: string) {
    const value = sessionStorage.getItem(key) || localStorage.getItem(key);
    if (!!value) {

      let item = JSON.parse(value);
      this.set(key, item.value);
      return item.value;

    } else {

      return;
    }
  }

  private _clone(object: InternalStateType) {
    // simple object clone
    return JSON.parse(JSON.stringify(object));
  }
}
