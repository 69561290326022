import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppState, TeacherService, ContentService, Question, ToastService, AssessmentService } from 'src/app/services';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-assessment',
  templateUrl: './edit.assessment.component.html',
  styleUrls: ['./edit.assessment.component.scss']
})

export class EditAssessmentComponent implements OnInit {

  public title: string = 'Assessment';
  public school: any;
    
  public clas: any;
  public student: any;

  public sections: any[] = null;
  public assessmentDate: any = moment(new Date()).unix();

  private classId: string;
  private studentId: string;
  private assessmentId: string;
  private keyAssessment: string;
  
  public ageLimits: string;
  public hasUnsavedChanges: boolean = false;

  constructor(private appState: AppState,
              private activatedRoute: ActivatedRoute,
              private teacherService: TeacherService,
              private contentService: ContentService,
              private toastService: ToastService,
              private assessmentService: AssessmentService,
              private translateService: TranslateService) {


    this.classId = this.activatedRoute.snapshot.params['class'];
    this.studentId = this.activatedRoute.snapshot.params['student'];
    this.assessmentId = this.activatedRoute.snapshot.params['assessment'];
    this.keyAssessment = this.activatedRoute.snapshot.params['keyAssessment'];


    this.teacherService
        .getClasses()
        .then((classes) => {

          this.clas = classes.find((x) => x.idnumber === this.classId);
        });

    this.teacherService
        .getStudents(this.classId)
        .then((studnets) => {

          this.student = studnets.find((x) => x.username === this.studentId);
        })

  }

  ngOnInit(): void {

    this.school = this.appState.restore('school');
    
    this.vmLoad(this.keyAssessment);

    this.ageLimits = this.assessmentService.getAssessment().find((x) => x.key === this.keyAssessment).titleKey;

  }
  
  public onAnswerChange(question, answer) {
    question.selected = answer.id;
    this.hasUnsavedChanges = true;
  }

  public exit() {

    this.hasUnsavedChanges = false;
    history.back();
  }

  public back() {

    if (this.hasUnsavedChanges) {
      const confirm_question = this.translateService.instant('shared.changes_will_be_lost')
      const exitConfirm = confirm(confirm_question)
      if (exitConfirm) {
        this.exit();
      } else {
        return;
      }
    } else {
      this.exit();
    }
  }

  public onChanged(date:any){
    if(date){
      this.assessmentDate = date;
    }
  }

  public submit() {

    if (this.assessmentDate) {
       
      // convert assessments data
      const assessments = this.sections
                            .map((section) => ({
                              key: section.key.replace('-ES-', '-'),
                              questions: section.questions.filter((x) => x.selected).map((x) => ({ id: x.id, answer: x.selected }))
                            }))
                            .filter((section) => section.questions.length > 0);

      this.teacherService.saveAssessment(this.assessmentId, this.classId, this.studentId, this.keyAssessment, this.assessmentDate, assessments).then(() => {

          // exit'
          this.exit();
        },
        (error) => {
          const info = this.translateService.instant('shared.save_failed')
          this.toastService.showDanger(info);
        })

      } else {

        const info = this.translateService.instant('shared.need_to_fill_date')
        alert(info);
      }
  }

  private vmLoad(age: string) {

    const _list = { i1: 9, i2: 10, t1: 10, t2: 12, pk: 8, ps: 12 };
    const language = this.translateService.currentLang;

    const resultArray = Array.from({ length: _list[age] }, (_, index) => ({ _id: (index + 1).toString() }))
                        .map( item => {

                          const id = item._id.length < 2 ? '0000' + item._id : '000' + item._id
                       
                          if (language == 'en') {
                              return { key:  `LBP-${age.toUpperCase()}-AS-${id}` };
                          } else {
                              return { key: `LBP-${language.toUpperCase()}-${age.toUpperCase()}-AS-${id}` };
                          }
                        });


    // retrieve list of questions specific to age group
    const content: Promise<Section>[]  = resultArray.map((folder) => {

                                        return this.contentService.getAssessmentQuestions(folder.key)
                                                  .then<Section>((content) => ({
                                                    key: folder.key,
                                                    title: content.title,
                                                    questions: content.body,
                                                    version: content.version
                                                  }));
                                    });    
    
    // if assessment id is provided
    // read saved assessment details
    const details = !!this.assessmentId ? this.teacherService.getAssessmentDetails(this.classId, this.studentId, this.assessmentId) : null;
    
    // wait till both content and assessment details are loaded
    Promise.all([Promise.all(content), details])
      .then(([content, details]) => {        
        
        this.sections = content;

        // if were are in edit mode
        // restore previously saved results
        if (!!details) {

          // update assessment date
          this.assessmentDate = details.date;
          
          // for each saved assessment block
          // for each saved question
          // restore the selected answer
          details.assessment.forEach((assessment) => {

            const section = this.sections.find((x) => x.key.replace('-ES-', '-') === assessment.key);

            assessment.questions.forEach((answeredQuestion) => {
              const question = section.questions.find((question) => question.id === answeredQuestion.id);
              question.selected = answeredQuestion.answer;
            });

          })
        }
      });
  }
}

interface Section {
  key: string,
  title: string,
  questions: Question[],
  version: string
}