import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeacherGuard } from './guards/teacher.guard';
import { EditorGuard } from './guards/editor.guard';
import {AdminGuard } from './guards/admin.guard';
import { LoginComponent } from './auth/login/login.component';
import { SamlInitComponent } from './auth/saml/init/saml-init.component';
import * as teacher from './teacher';
import * as editor from './editor';
import * as admin from './admin';
import { DeactivateGuard } from './guards/deactivate.guard'; 
import { AssessmentDeactivateGuard } from './guards/assessment-deactivate.guard';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { EmailVerificationComponent } from './auth/email-verification/email-verification.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { UserProfileComponent } from './auth/user-profile/user-profile.component';
import { ParentComponent } from './parent/parent.component';
import { CleverLoginComponent } from './auth/clever/clever.component';
import { AfterLoginComponent } from './auth/after/after-login.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-pass', component: ForgotPasswordComponent },
  { path: 'email-verification', component: EmailVerificationComponent },
  { path: 'clever', component: CleverLoginComponent },
  { path: 'after-login', component: AfterLoginComponent },
  { path: 'parent', component: ParentComponent },
  { path: 'profile', component: UserProfileComponent },
  { path: 'reset-pass/:username/:passToken', component: ResetPasswordComponent },
  { path: 'editor', component: editor.EditorComponent, canActivate: [EditorGuard] },
  { 
    path: 'admin', 
    component: admin.AdminComponent,
    canActivate: [AdminGuard],
    children: [
      { path: 'home', component: admin.AdminModeComponent },
      { path: 'home/cl', component: admin.ClassManagementComponent },
      { path: 'home/cl/:idschool/:idclass', component: admin.EditClassComponent },
      { path: 'home/cl/:idschool', component: admin.AddClassComponent },
      { path: 'home/cl/:idschool/:idclass/:title/user', component: admin.UserComponent },
      { path: 'home/cl/:idschool/:idclass/:title/:user', component: admin.EditUserComponent },
      { path: 'home/cl/:idschool/:idclass/:title', component: admin.AssignedToClassroomComponent },

      { path: 'home/um', component: admin.UserManagementComponent },
      { path: 'home/um/:mode', component: admin.ListComponent },
      { path: 'home/um/:mode/:idschool/user', component: admin.UserNewManagementComponent },
      { path: 'home/um/:mode/:idschool/:user', component: admin.EditUserManagementComponent },

      { path: 'home/um/:mode/:idschool/import/teacher', component: admin.ImportTeacherComponent },
      { path: 'home/um/:mode/:idschool/import/child', component: admin.ImportChildComponent },
      { path: 'home/cl/:idschool/:idclass/import/cl/child', component: admin.ImportChildComponent },
      { path: 'home/cl/:idschool/:idclass/:title/user/cl/:where', component: admin.UserComponent },

      { path: 'home/tr', component: admin.AdminReflectionComponent },
      { path: 'home/tr/:idschool/:idclass/:username', component: admin.AdminClassReflectionComponent },
      { path: 'home/rep', component: admin.AdminAssesmentComponent },
      { path: 'home/rep/:assessment', component: admin.AdminAssesmentStudentsComponent },
      { path: 'home/rep/:assessment/:id/:username', component: admin.AdminEditAssesmentComponent },
      { path: 'home/um/:mode/:user', component: admin.AdminAssesmentStudentComponent},
      { path: 'home/brand', component: admin.AdminBrandComponent },
      { path: 'home/cl/:idschool/:idclass/as/child/:user', component: admin.AdminAssesmentStudentComponent},
      { path: 'home/introduce/:sub/:content', component: teacher.ContentComponent},
    ]
  },
  { 
      path: 'teacher', 
      component: teacher.TeacherRootComponent, 
      canActivate: [TeacherGuard],
      children: [
        { path: 'home', component: teacher.ModeComponent },
        { path: 'home/da/:activity', component: teacher.DailyActivitiesComponent },
        { path: 'home/ds/:activity',  component: teacher.DailyStandardsComponent },
        { path: 'home/as', component: teacher.AssessmentComponent },
        { path: 'home/as/:class/:keyAssessment/students', component: teacher.StudentsAssessmentComponent },
        { path: 'home/as/:class/:keyAssessment/:student', component: teacher.EditAssessmentComponent, canDeactivate: [AssessmentDeactivateGuard] },
        { path: 'home/as/:class/:keyAssessment/:student/:assessment', component: teacher.EditAssessmentComponent, canDeactivate: [AssessmentDeactivateGuard] },
        { path: 'home/hc', component: teacher.ConnectionsComponent },

        { path: 'home/tg', component: teacher.GuideComponent },
        { path: 'home/tg/tile/:sub', component: teacher.SubTileComponent},
        { path: 'home/tg/content/:sub', component: teacher.ContentComponent},
        { path: 'home/tg/content/:sub/:content', component: teacher.ContentComponent},

        { path: 'home/rs', component: teacher.ResourcesTeacherComponent },

        { path: 'home/mt', component: teacher.MaterialComponent },
        { path: 'home/mt/:datas', component: teacher.MaterialsWeeklyComponent },
        { path: 'home/st', component: teacher.StandardsComponent },
        { path: 'home/st/stateStandards', component: teacher.StateStandardsComponent },
        { path: 'home/st/alignment', component: teacher.AlignmentComponent },
        { path: 'home/lp', component: teacher.LessonPlansComponent },
        { path: 'home/tr', component: teacher.ReflectionsComponent },
        { path: 'home/tr/:class', component: teacher.ClassReflectionsComponent, canDeactivate: [DeactivateGuard] },
        { path: 'home/search', component: teacher.SearchComponent }
      ]
  },
  { path: 'saml/:client/init', component: SamlInitComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
