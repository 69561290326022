<div class="wrapper">
    <div class="mb-3">
        <app-menu  [mode]="'home_connections.title'" [details]="''" [school]="school"></app-menu> 
        <app-filters [showWeek]="true" [showDay]="daily" [showAge]="true"
                    (weekChanged)="weekChanged($event)" (dayChanged)="dayChanged($event)"
                    (_ageChanged)="_ageChanged($event)"></app-filters>
    </div>
    
    <div class="container-lg">
        <app-home-connection *ngIf="template" [resources]="resources"></app-home-connection>
        <div *ngIf="missing">{{'shared.missing' | translate}}</div>
        <div class="loader" *ngIf="loader">
            <loader></loader>
        </div>
    </div>
</div>