
<app-menu [submenu]="true" [newFirstName]="firstName" [UPpage]="true" [details]="'profile.title'" [school]="school"></app-menu>

<div class="wrapperPage container-lg">
    <div class="_row">
      <button (click)="save()" [disabled]="isFlagSave" [ngClass]="{'disableBtn': isFlagSave }">
        {{'profile.save' | translate}}
        </button>

      <div *ngIf="saveResult.flag" class="saveResult"  [ngClass]="{ 'error': saveResult.status == 'error', 'success': saveResult.status == 'success' }">
          {{saveResult.text | translate}}
      </div>
    </div>
    <form-user [user]="user" 
               (newUser)="onChangeUser($event)" 
               (flagSave)="onFlagSave($event)"  
               [page]="'userprofile'"
               [dataSaved]="!isFlagSave"
               [editPage]="true"></form-user>


</div>
<!-- Loader spinner-->
<div class="myloader" *ngIf="!loading">
    <loader></loader>
  </div>
  <!--end spinner -->