import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState, AssessmentService, TeacherService } from 'src/app/services';

@Component({
  selector: 'app-students-assessment',
  templateUrl: './students.assessment.component.html',
  styleUrls: ['./students.assessment.component.scss']
})

export class StudentsAssessmentComponent implements OnInit {

  public title: string = 'Assessment';
  public school: any;

  public clas: any;
  public students: any[] = null;
  public assessmentTitle: string;
  public currentStudent: any;
  
  private age: string;
  private classId: string;
  private keyAssessment: string;

  /** Student name from input */
  public searchText: string = '';

  constructor(private appState: AppState,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private teacherService: TeacherService,
              private assessmentService: AssessmentService) {

    this.classId = this.activatedRoute.snapshot.params['class'];

    this.keyAssessment = this.activatedRoute.snapshot.params['keyAssessment'];

    this.assessmentTitle = this.assessmentService.getAssessment().find((x) => x.key === this.keyAssessment).titleKey;

    this.teacherService
      .getClasses()
      .then((classes) => {
        this.clas = classes.find((x) => x.idnumber === this.classId);
      });
  }

  ngOnInit(): void {

    this.school = this.appState.restore('school');

    this.load();

  }

  public new(username: string) {

    this.router.navigate([ 'home/as', this.classId, this.keyAssessment, username], { relativeTo: this.activatedRoute.parent });
  }

  public edit(username: string, id: string) {

    this.router.navigate(['home/as', this.classId, this.keyAssessment, username, id], { relativeTo: this.activatedRoute.parent });
  }
  
  public delete(username: string, id: string) {

    if (confirm('Are you sure you want to delete assessment result ?')) {
      this.teacherService
          .deleteAssessment(this.classId, username, id)
          .then(() => this.load());
    }
  }

  public exit() {
    history.back();
  }

  private load() {

    this.teacherService.getStudents(this.classId).then((students) => {
            
            students.forEach((student) => student.assessments = []);
            students.sort((a, b) => a.firstname.localeCompare(b.firstname));

            this.teacherService.getAssessments(this.classId, this.keyAssessment).then((assessments) => {

                    assessments.sort((a, b) => -a.date.toString().localeCompare(b.date.toString()))
                        .forEach((assessment) => {
                    
                            const student = students.find((x) => x.username === assessment.student);
                            
                            const date = new Date( assessment.date * 1000);

                            student.assessments.push({
                                id: assessment.id,
                                date: date.toLocaleDateString()
                            });                    
                        });

                    this.students = students;
              })
        });
  }
}
