import {Component, OnInit } from '@angular/core';
import { AppState, SessionService } from '../services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
  })

export class AdminComponent implements OnInit {

    constructor(
        private appState: AppState,
        private sessionService: SessionService,
        private router: Router
      ) {
      
        this.appState.store('role', 'schooladmin', true);

        if (this.router.routerState.snapshot.url == '/admin') {
          this.router.navigate(['/admin/home']);
        }
        
       
      }

      public ngOnInit(): void {
    //    this.schools = this.activatedRoute.snapshot.data['schools'];
      }
}