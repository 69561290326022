import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { TeacherService } from 'src/app/services/teacher.service';
import { IWeeklyTableData, IWeeklyTableActivity } from '../../lesson-plans.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-weekly-lesson-table',
  templateUrl: './weekly-lesson-table.component.html',
  styleUrls: ['./weekly-lesson-table.component.scss','../../../../teacher/teacher.component.scss'],
})
export class WeeklyLessonTableComponent implements OnInit {
  
  
  public language: string;
  public ageTitle: string;

  /**
 * Input `age parameter` from filters.
 */
  @Input()
  public age: string = null;

  /**
   * Input `week parameter` from filters.
   */
  @Input()
  public week: string = null;

  @Input()
  public tableData: IWeeklyTableData = {
    activities: [
      { id: 'st', sortBy: 1, title: null, items: [] },
      { id: 'pd', sortBy: 2, title: null, items: [] },
      { id: 'll', sortBy: 3, title: null, items: [] },
      { id: 'mm', sortBy: 4, title: null, items: [] },
      { id: 'ir', sortBy: 5, title: null, items: [] },
      { id: 'cg', sortBy: 6, title: null, items: [] }
    ],
  };

  @Output()
  public onSelect: EventEmitter<{ code: string }> = new EventEmitter();
  constructor(private teacherService: TeacherService, private translateService: TranslateService) {}

  ngOnInit(): void {
    
    this.getAgeTitle(this.age);
    this.language = this.translateService.currentLang;
  }

  public vmOnSelected(code: string) {
    this.onSelect.emit({ code });
  }

  public getAgeTitle(ageKey: string) {

    const age = this.teacherService.getAges().find((a) => a.key == ageKey);
    this.ageTitle = age.titleKey;
  }
}
