import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TeacherService, AppState } from 'src/app/services';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  public language;
  public ageList: { title: string; value: string }[] = null;
  public topicList: { title: string; value: string }[] = null;

  public currentAge: string = 'all';
  public currentTopic: string = 'all';
  public searchText: string = null;
  public searchResults: ISearchResult[] = null;
  public noSearchResults: boolean = false;
  public searchBtnDisabled: boolean = true;

  public loader: boolean = false;

  public school: any;

  private ageSortOrders: string[] = ['in', 't1', 't2', 'ps', 'pk'];

  private topicSortOrders: { [key: string]: number } = {
    st: 1,
    ll: 2,
    pd: 3,
    mm: 4,
    ir: 5,
    cg: 6,
    ss: 7,
  };

  constructor(private teacherService: TeacherService, private router: Router, private activatedRoute: ActivatedRoute, private appState: AppState, private translateService: TranslateService) {
    // empty
  }

  public ngOnInit(): void {

    this.school = this.appState.restore('school');
    this.ageList = this.teacherService.getAges().map((_item) => ({ title: 'age_group.' + _item.key, value: _item.key }));
    this.ageList = [{ title: 'shared.all', value: 'all' }, ...this.ageList];
    this.topicList = this.teacherService.getActivities().map((_item) => ({ title: 'topics.' + _item.key, value: _item.key }));
    this.topicList = [{ title: 'shared.all', value: 'all' }, ...this.topicList]; 
    this.language = this.translateService.currentLang;
    this.vmGetLastSearch();
  }

  public vmSearch(e: Event): void {
    if (!this.searchText || this.searchText.length < 3) {
      return null;
    }

    this.searchResults = null;
    this.loader = true;

    const language: string = this.translateService.currentLang;

    this.teacherService.searchText(this.searchText, language, this.currentAge, this.currentTopic ).subscribe(
      (data) => {
        this.loader = false;
        this.noSearchResults = false;

        this.searchResults = data.map((_item: ISearchResult) => {
          _item.ageTitle = this.ageList.find((_age) => _age.value === _item.age).title;
          return _item;
        });

        this.vmSortResults();

        this.vmSaveLastSearch();
      },
      (error) => {
        this.loader = false;
        if (error.status === 404) {
          this.noSearchResults = true;
        }
        this.searchResults = null;
      }
    );
  }

  public vmNavigate(item: ISearchResult) {
    this.appState.store('age', item.age, true);
    this.appState.store('day', item.day, true);
    this.appState.store('week', item.week, true);

    if (item.type === 'da') {
      this.router.navigate(['../da', item.activity], { relativeTo: this.activatedRoute });
    }
  }

  public vmOnInput(e: Event) {
    if (this.searchText.length >= 3) {
      this.searchBtnDisabled = false;
    } else {
      this.searchBtnDisabled = true;
    }
  }

  private vmSortResults(): void {
    this.searchResults = this.ageSortOrders
      .map((_sortAge) => {
        let ageGroupe = this.searchResults.filter((_searchResult) => _searchResult.age === _sortAge);

        ageGroupe.forEach((_item) => {
          _item.topicOrder = this.topicSortOrders[_item.activity];

          _item.order = _item.day ? +(_item.week + _item.day + _item.topicOrder) : +(_item.week + _item.topicOrder);
        });

        ageGroupe = ageGroupe.sort((a, b) => {
          return a.order < b.order ? -1 : 1;
        });

        return ageGroupe;
      })
      .reduce((acc, next) => {
        acc = [...acc, ...next];
        return acc;
      }, []);
  }

  private vmSaveLastSearch(): void {
    const params = {
      text: this.searchText,
      age: this.currentAge,
      topic: this.currentTopic,
      results: this.searchResults,  
      language: this.language
    };

    window.sessionStorage.setItem('lastSearch', JSON.stringify(params));
  }

  private vmGetLastSearch(): void {
    this.loader = true;
    const lastSearch = JSON.parse(window.sessionStorage.getItem('lastSearch')) || null;

    if (!!lastSearch && this.language == lastSearch.language ) {
      this.currentAge = lastSearch.age;
      this.currentTopic = lastSearch.topic;
      this.searchText = lastSearch.text;
      this.searchResults = lastSearch.results;
      this.searchBtnDisabled = false;
    }

    this.loader = false;
  }
}

interface ISearchResult {
  id: string;
  age: string;
  ageTitle?: string;
  activity: string;
  type: string;
  day: string;
  week: string;
  name: string;
  topic: string;
  language: string;
  order?: number;
  topicOrder?: number;
}
