
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState, TeacherService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  public age: string;
  public subKey: string;
  public contentKey: string;
  public resources: any;
  public modeTitle: string = '';
  public sectionTitle: string = '';
  public bunner: any = { state: false, src: '' };
  public school: any;
  private cdnRoot: string = environment.cdn;
 
  constructor( private activatedRoute: ActivatedRoute, private appState: AppState, private teacherService: TeacherService ) {}

  ngOnInit(): void {

    this.school = this.appState.restore('school');
    this.resources = { resource_1m: this.cdnRoot + "/video/Infant-Toddlers.Classroom-Structure.mp4#t=4" };
    this.age = this.appState.restore('age');
    this.subKey = this.activatedRoute.snapshot.params['sub'];
    this.contentKey =  this.activatedRoute.snapshot.params['content'];

    if ( this.subKey != 'in' && this.subKey != 'ro' && this.subKey != 'cl' && this.subKey != 'ch' ) {

      const subTiles = this.teacherService.getSubTiles();
      this.sectionTitle = subTiles.find(item => item.key == this.subKey).titleKey;
      this.getResources(this.contentKey)
    } else {

      this.contentKey = this.subKey;
      this.getResources(this.subKey)
    }
  }

  public exit() {

    history.back()
  }

  public openBunner(e: Event, url:string, type: string) : void {
  
    e.stopPropagation();
    e.preventDefault();

    this.bunner.state = true;
    this.bunner.src = url;
    this.bunner.type = type;

    if (type == 'video') {   

      this.bunner.src = url.substring(0, url.indexOf('#'));
    } else{
       
      this.bunner.src = url;
    }
  }

  public onClickedOutside(e: Event) {

    if (this.bunner.state) {

      this.bunner.state = false;
    }
  }

  public getResources( key: string){
 
    const all_resources = this.teacherService.getResources();
    const content = all_resources.find(item => item.key == key);
    this.modeTitle = content.titleKey;

    if (this.age == 'ps' || this.age == 'pk') {

      this.resources = content.resources_ps_pk;
    }
    if (this.age == 'in' || this.age == 't1' || this.age == 't2') {
      
      this.resources = content.resources_in_t1_t2 ;
      this.modeTitle = 'resources.tg.characteristics_of_infants_toddlers'
    }

  }
}
