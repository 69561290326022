
import { Component, OnInit } from '@angular/core';
import { AppState, TeacherService, ContentService, IResource, SessionService } from 'src/app/services';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})

export class ParentComponent implements OnInit {

  public title: string = '@Home Connections';
  public loader: boolean = false;

  public template: string = null;
  public resources: IResource[];
  public missing: boolean = false;
  public noClassesAssigned: boolean = false;

  private age: string = null;
  private week: string = null;
  private day: string = null;

  public school: any;
  
  // is daily view avaialble
  public daily: boolean;

  constructor(private appState: AppState,
              private teacherService: TeacherService,
              private sessionService: SessionService,
              private contentService: ContentService) {
                
  
  }

  ngOnInit(): void {
    this.school = this.appState.restore('school');
    this.firstLoad();
  }

  public weekChanged(week) {

    this.week = week;
    this.load(this.age, this.week, this.day);
  }

  public dayChanged(day) {

    this.day = day;
    this.load(this.age, this.week, this.day);
  }
  
  private load(age, week, day) {
  
    this.loader = true;
    const language = this.appState.get('language');
    this.contentService
        .getHomeConnections(language, age, week, day)
        .catch((err) => {
          if (language !== 'en') {
              return this.contentService.getHomeConnections('en', age, week, day)
          } else { 
              throw err;
          }
        })
        .then((data) => {

          this.missing = false;
          this.loader = false;
          this.template = data.template;

          this.resources = data.files.map<IResource>((file) => ({
            title: file.filename,
            data: file.url,
            type: file.type
          }));                
        })
        .catch((err) => {

            this.missing = true;
            this.template = null;
            this.loader = false;
        })
  }

  public firstLoad(){

    this.week = this.appState.restore('week');
    this.day = this.appState.restore('day');

    return this.teacherService.getAgeGroups().then((ageGroups: Array<any>) => {
 
       if(ageGroups.length != 0) {

          this.age = ageGroups[0].age_group

          this.daily = this.teacherService.hasDailyView(this.age);
          this.day = this.daily ? this.appState.restore('day') : null;
          this.load(this.age, this.week, this.day); 
         
       } else {
        
          this.age = '';
          this.noClassesAssigned = true;
       }

    }).catch((error) => {

        console.log('Error: ', error);
    });;
  }


}


