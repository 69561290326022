// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  content: 'https://lmsqa-cdn.learningbeyondpaper.com/Library',
  cdn: 'https://lmsqa-cdn.learningbeyondpaper.com',
  api: 'https://lmsqa-api.learningbeyondpaper.com/api-v1',
  apiKey: '56a2948bb69b419bbfd19ff59241e8ef'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
