import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, ElementRef,ViewChild } from '@angular/core';
import { ResourcesService, IResource } from '../../services/resources.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit, OnChanges {
  public resourcesList: IResource[] = [];

  @ViewChild('fileInput') fileInput: ElementRef;
  public resourseTitle: string = '';

  @Input()
  public clearResources: boolean = false;

  @Output()
  public resourcesChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(private resourcesService: ResourcesService) {}

  public ngOnInit(): void {
    this.vmLoadResources();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.clearResources) {
      this.vmLoadResources();
      this.clearResources = false;
    }
  }
  
  public vmFileUpload(e: Event): void {
    const filesInput: HTMLInputElement = document.querySelector('#inputGroupFile01');

    for (let index = 0; index < filesInput.files.length; index++) {
      const file: File = filesInput.files.item(index);

      const reader: FileReader = new FileReader();
      reader.addEventListener('load', (event: ProgressEvent) => {
        const length: number = this.resourcesList.length;
        const target: any = event.target;
        const ext = file.name.slice(file.name.lastIndexOf('.'));

        const resource: IResource = {
          title: `resource${length + 1}${ext}`,
          data: target.result,
          type: file.type,
        };

        this.resourcesList.push(resource);
        this.resourcesService.store(resource);
      });

      reader.readAsDataURL(file);
    }

    this.resourcesChanged.emit(true);
  }

  public vmRemove(resource: IResource): void {
    // Remove selected resource
    this.resourcesService.remove(resource);

    // get new list of resources.
    this.resourcesList = this.resourcesService.getResources;

    this.resourcesChanged.emit(true);
  }
  
  public replace(event: any): void{

    const filesInput: HTMLInputElement = document.querySelector('#uploadFile01');
    const file =  filesInput.files.item(0);
    const reader: FileReader = new FileReader();

    reader.addEventListener('load', (event: ProgressEvent) => {
      const title = this.resourseTitle.split('.')[0] + file.name.slice(file.name.lastIndexOf('.'));
      const target: any = event.target;

      const resource: IResource = {
        title: title.toLowerCase(),
        data: target.result,
        type: file.type,
      };
      
      this.resourcesService.replace(resource)
      this.resourcesList = this.resourcesService.getResources;
    });
    
    reader.readAsDataURL(file);
    this.resourcesChanged.emit(true);
  }

  public vmReplaceFile(resource: IResource): void {
    this.resourseTitle = resource.title;
    this.fileInput.nativeElement.click();
  }

  private vmLoadResources(): void {
    this.resourcesList = this.resourcesService.getResources;
  }
}
