import {Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { ISchool } from 'src/app/interfaces/ISchool';
import {  AppState } from 'src/app/services';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-lists',
    templateUrl: './lists.component.html',
    styleUrls: ['./lists.component.scss'],
  })

export class ListComponent implements OnInit {

    // unknownPage is value what to show us where we are
    // we dont go to link in menu
    // this is to show users where we are
    public unknownPage: string = 'admin_shared.list_teacher'; // child
    public titlePage: string;

    public searchText: string = '';

    public idclass: string;

    public loading: boolean = false;
    public list: ITeacher[];
    public listSchools: ISchool[];
    public currentSchool: ISchool;
    public mode: string;

    constructor(private router: Router,
        private adminService: AdminService,
        private route: ActivatedRoute,
        private appState: AppState,
        private _location: Location,
        private translateService: TranslateService) {

    }

    public ngOnInit(): void {      

        this.mode = this.route.snapshot.params['mode'];
        this.unknownPage = this.mode === 'assignedchild' ? 'admin_um.child_management' : 'admin_um.teacher_management';
        this.currentSchool = this.appState.restore('school');    
    }

    public load() {

        if (this.mode === 'assignedchild'){

            this.adminService.resetChildrenCache();

            this.adminService.getChildren(this.currentSchool.idnumber).then((list: ITeacher[]) => {
                this.list = list;
                this.loading = true;
            })
        } else {

            this.adminService.resetTeachersCache();

            this.adminService.getTeachers(this.currentSchool.idnumber).then((list: ITeacher[]) => {
                this.list = list;
                this.loading = true;
            })
        }
    }

    public onChangeSchool(school) {

        this.currentSchool = school;

        this.loading = false;

        this.load();
    }

    public onCreate() {
        this.router.navigate([this.currentSchool.idnumber, 'user'], { relativeTo: this.route });
    }

    public onImportTeacher() {
        this.router.navigate([this.currentSchool.idnumber, 'import', 'teacher'], { relativeTo: this.route });
    }

    public onImportChild() {
        this.router.navigate([this.currentSchool.idnumber, 'import', 'child'], { relativeTo: this.route });
    }

    public onBack() {
        this._location.back();
    }

    public getLocaleDate(date) {
        
        let _date = new Date(date * 1000);

        return  _date.toLocaleDateString();
    }

    public onEdit(username) {
        this.router.navigate([this.currentSchool.idnumber, username], { relativeTo: this.route });
    }

    public onDeleteChild(username){

        if (confirm(this.translateService.instant('admin_import.are_you_sure'))) {

            // delete user and delete from array
            // for view on page
            this.adminService.removeChild(username).then(() => {

                this.list = this.list.filter((x) => x.username != username);

            }).catch((error) => {
                console.log(this.translateService.instant('admin_import.error_delete_class'), error);
            });

        }       
    }

    public onDeleteTeacher(username){
        
        if (confirm(this.translateService.instant('admin_import.are_you_sure'))) {

            // delete user and delete from array
            // for view on page
            this.adminService.removeTeacher(username).then(() => {

                this.list = this.list.filter((x) => x.username != username);

            }).catch((error) => {
                console.log(this.translateService.instant('admin_import.error_delete_class'), error);
            });

        }   
    }
    
    public onAssessment(username) {
        this.router.navigate([ username], { relativeTo: this.route });
    }
}