import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppState } from '../../services/app.service';
import { SessionService } from 'src/app/services'
import { LoginService } from '../../services/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login',
  providers: [LoginService],
  styleUrls: ['./clever.component.scss'],
  templateUrl: './clever.component.html',
})
export class CleverLoginComponent implements OnInit {
  
    public language: string = '';
    public incorrectDataError: string;
    public loading: boolean = false;

    constructor(private router: Router,  
                public appState: AppState,
                private translateService: TranslateService,
                private activetedRoute: ActivatedRoute,
                private sessionService: SessionService,
                private loginService: LoginService) {
    
        const code = this.activetedRoute.snapshot.queryParams['code'];
        this.loginService.clever(code)
            .subscribe((response: any) => {

              this.appState.store('session', response.session);
              sessionStorage.setItem('usrActiv_' + response.session,'0');

              this.sessionService.restoreSessionTimeout();
              this.sessionService.setCheckSession(response.session);
              
               this.router.navigate(['/after-login']);
            },
            (error) => {
                console.log(error)
                            
                this.loading = true;
                if (error && error.error.message.code === 'USER_NOT_FOUND_IN_LBP') {
                  this.incorrectDataError = 'clever.not_exist_in_LBP';
                } else if (error && error.error.message.code === 'USER_NOT_ASSIGNED_TO_SCHOOL') {
                  this.incorrectDataError = 'clever.no_assigned';
                } else if (error && error.error.message.code === 'USER_HAS_NO_ACTIVE_SCHOOL') {
                  this.incorrectDataError = 'clever.no_active_school';
                } else if (error) {
                  this.incorrectDataError = 'clever.smth_went_wrong';
                }
            }
          );
    }

    public ngOnInit() {
    
        if (this.appState.restore('language')) {
          this.language = this.appState.restore('language');
        } else {
          this.language = 'en';
          this.appState.store('language','en', true);
        }
    
        this.translateService.use(this.language);
    }
    
}
