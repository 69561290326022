<div class="wrapper">
    <app-menu [home]="false" [pageAdmin]="true" [unknownPage]="unknownPage" [school]="school"></app-menu>
    <app-filterbyschool-component (onChange)="onChangeSchool($event)" [back]='true'></app-filterbyschool-component>

    <div class="container-lg">
        <div class="row mt-3">
            <div class="col-sm-6 col-md-4 col-lg-4" *ngFor='let mode of assessment'>
              <div class="card mb-3" (click)="onAssessmentChoose(mode.key)">
                <div class="assessment">
                    <div class="icon"></div>
                </div>
                <div class="card-title-bottom bg-primary d-flex justify-content-center text-light p-2 font-weight-bold">
                  {{mode.titleKey | translate}} 
                </div>
              </div>
            </div>                              
        </div>
      </div>
      
</div>