<div class="wrapper">
    <app-menu [home]="false" [pageAdmin]="true" [unknownPage]='unknownPage' [school]="school"></app-menu>
    <app-filterbyschool-component (onChange)="onChangeSchool($event)" [back]="true"></app-filterbyschool-component>
    
    <div class="container-lg">
        <div class="row mt-3">
            <div class="col-sm-6 col-md-4 col-lg-3" *ngFor='let mode of modes'>
            <div class="card mb-3" (click)="onModeChoose(mode.key)">
                <img class="card-img" src="/assets/img/modes/{{mode.key}}.jpg" alt="{{mode.title}}">
                <div class="card-title-bottom bg-primary d-flex justify-content-center text-light p-2 font-weight-bold">
                {{mode.titleKey | translate}} 
                </div>
            </div>
            </div>                              
        </div>
    </div>
</div>
