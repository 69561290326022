import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';

@Component({
  selector: 'app-mode-filter',
  templateUrl: '../base/filter-base.component.html',
  styleUrls: ['../base/filter-base.component.scss'],
  // tslint:disable-next-line: no-outputs-metadata-property
  outputs: ['onSelect', 'onTemplateClear'],
})
export class ModeFilterComponent extends FilterBaseComponent implements OnInit, OnChanges {
  title = 'Mode';

  constructor() {
    super();
  }

  @Input()
  public set isTemplateChanged(value: boolean) {
    this.templateChanged = value;
  }

  @Input()
  public age: string = null;

  public ngOnInit(): void {
    // empty
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.age) {
      this.value = null;
      if (this.age === 'in' || this.age === 't1') {
        this.options = [
          { title: 'Weekly Activity', value: 'wda' },
          { title: 'Weekly Standards', value: 'wds' },
          { title: 'Weekly Lesson Plans', value: 'wlp' },
          { title: 'Weekly Materials', value: 'mtweek' },
          { title: 'Weekly Home Connection', value: 'whc' },
        ];
      } else {
        this.options = [
          { title: 'Daily Activity', value: 'da' },
          { title: 'Daily Standards', value: 'ds' },
          { title: 'Daily Lesson Plans', value: 'lp' },
          { title: 'Weekly Materials', value: 'mtweek' },
          { title: 'Daily Home Connection', value: 'hc' },
        ];
      }
    }
  }
}
