import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState, LoginService, TeacherService } from 'src/app/services';
       
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',  
  providers: [LoginService, AppState, TeacherService],
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  public user: {  username: string, password?: string, lastname: string, firstname: string, email: string, language: string, verified: boolean } = {
    username: '',
    firstname:  '',
    lastname:  '',
    email: '',
    password: '',
    language: '',
    verified: false
  };

  public incorectEmail: boolean = false;
  public incorectPass: boolean = false;
  public loading: boolean = true;

  public school: any;

  public isFlagSave: boolean = true;
  public editPage: string;
  public firstName: string;
  public saveResult: {flag: boolean, text: string, status: string} = { flag: false, text: '', status: ''};

  constructor( private teacherService: TeacherService, 
               private loginService: LoginService,
               private appState: AppState,
               private translateService: TranslateService) {
        // empty
  }

  ngOnInit(): void {

    this.getUser();
    this.school = this.appState.restore('school');
  }

  private getUser(): any{

      this.loading = false;
      return this.teacherService.getUser().then((user) => {

        // save user language to local storage
        // it would on login page to get correct localization
        this.appState.set('language', user.lang);

        this.user = {
            username:  user.username,
            firstname:  user.firstname,
            lastname:  user.lastname,
            email: user.email,
            password: '',
            language: user.lang,
            verified: user.verified
        };
        this.loading = true;
      }).catch((error) => {

        console.log('Error: ', error);
      });;
  }


  public save():void {

    this.loading = false;

    this.saveResult = { flag: false, text: '', status: ''};

    let _user = this.user;

    if ( _user.password == '') {
      delete _user.password;
    }

    this.teacherService.updateUser(_user).then((response) => {

      this.loginService.profile().subscribe((profile) => {
        
        let _profile = profile
        _profile.firstname = _user.firstname;
        _profile.lastname = _user.lastname;
        _profile.email = _user.email;

        this.appState.store('profile', _profile);
        this.firstName = _user.firstname;
        this.loading = true;

        // save user language to local storage
        // it would on login page to get correct localization
        this.appState.store('language', _user.language, true);
        this.appState.store('lang_change_event', true, true);
        this.translateService.use(_user.language);
      });

      this.saveResult =  { flag: true, text: 'profile.status_success', status: 'success'};
      this.isFlagSave =  true;

    }).catch((error) => {
      
      this.saveResult =  { flag: true, text: 'profile.status_error', status: 'error'};
      console.log("Error:", error)
      this.loading = true;
    });

  }

  public onChangeUser(user: any) {
    this.user = user;
  }
  public onFlagSave(flag) {
    this.isFlagSave = flag;
    this.saveResult =  { flag: false, text: '', status: ''};
  }
  
}
