<div class="wrapper">
    <app-menu   [submenu]='true' [details]="title" [school]="school"></app-menu>
  
    <div class="container-lg">
      <div class="row mt-3">
          <div class="col-sm-6 col-md-4 col-lg-3" *ngFor='let material of materials'>
            <div class="card mb-3" (click)="onMaterialChoose(material.key)">
              <img *ngIf="material.key == 'week' || material.key == 'day'" class="card-img" src="/assets/img/modes/mt.jpg" alt="{{material.title}}">
              <img *ngIf="material.key == 'cards'" class="card-img" src="/assets/img/modes/letter_cards.png" alt="{{material.title}}">
              <div class="card-title-bottom bg-primary d-flex justify-content-center text-light p-2 font-weight-bold">
                {{material.titleKey | translate }}
              </div>
            </div>
          </div>                              
      </div>
    </div>
    
  </div>