import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState, TeacherService, EmailVerificationService, LoginService} from 'src/app/services';
//tarasenko.agt@gmail.com 
@Component({
  selector: 'email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  providers: [TeacherService, LoginService],
})
export class EmailVerificationComponent implements OnInit {
  public email: string;
  public code: string;
  public validEmail: boolean = false;
  public validCode: boolean = false;
  public verify_now_popup = { state: false, error_state: false };
  public user;
  public profile;

  constructor(
    private teacherService: TeacherService,
    private appState: AppState,
    private loginService: LoginService,
    private router: Router,
    private emailVerificationService: EmailVerificationService
  ) {}

  ngOnInit(): void {
    this.profile = this.appState.restore('profile');
    this.getUser();
  }

  public validateEmail(email: string) {
    if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      this.validEmail = true;
      return true;
    } else {
      this.validEmail = false;
      return false;
    }
  }

  public validateCode(code: string) {
    this.verify_now_popup.error_state = false;

    if (code.length == 4) {
      this.validCode = true;
      return true;
    } else {
      this.validCode = false;
      return false;
    }
  }

  public openPopap() {
    this.verify_now_popup.state = true;
    this.user.email = this.email;
    this.updateUser();
    this.sendVerificationCode();
  }

  public closePopap() {

    this.verify_now_popup.state = false;
    this.code = '';
    this.verify_now_popup.error_state = false;
  }


  public confirm() {

    this.emailVerificationService.verifyCode(this.code).then(
      (response) => {
        console.log('Code is verified', response);
        this.verify_now_popup.state = false;
        this.code = '';
        this.updateUser();
        this.goToHomePage();
      },
      (error) => {
        console.error('Verification code error', error);   
        this.code = '';
        this.validCode = false;
        this.verify_now_popup.error_state = true;
      }
    ); 
  }

  public sendVerificationCode(): void {

      this.emailVerificationService.sendCode(this.email).then(
        (response) => {
          console.log('Verification code sent', response);
        },
        (error) => {
          console.error('Error sending verification code', error);
        }
      ); 
  }

  public verifyLater() {
    this.user.email = this.email;
    this.updateUser();
    this.goToHomePage();
  }

  private goToHomePage() {
    
    if (this.profile.roles.includes('schooladmin')) {
      this.router.navigate(['/admin']);
    } else {
      this.router.navigate(['/teacher']);
    }
  }

  private getUser() {
    this.teacherService.getUser()
      .then((_user) => {
        this.user = _user;
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  }

  private updateUser(){
    this.teacherService.updateUser(this.user)
    .then((response) => {
      this.loginService.profile().subscribe((_profile) => {
        this.profile = _profile
      })
     })
    .catch((error) => {
      console.log('Error:', error);
    });
  }
}
