import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/services/app.service'

@Injectable()
export class SessionService {

    private apiRoot: string = environment.api;
    // session timeout id
    // updated on API usage
    private timeoutId;
    private defaultTimeout = 10 * 60 * 60 * 1000;
    private checkInterval = 20000;
    private IdcheckSession;

    constructor(private appState: AppState,
                private router: Router,
                private http: HttpClient) {
        // empty
    }

    /** Reset session timeout to default value */
    public resetSessionTimeout() {

        this.setSessionTimeout(this.defaultTimeout)
    }

    /**
     * Restore session timeout.
     * Do nothing if timeout already started.
     * Else read previous timeout from local storage.
     */
    public restoreSessionTimeout() {

        // read saved value for session timeout
        // if value is not set or it is less than current time 
        // than we have expired session and redirect to login is required
        // else we should have active session
        // and session timeout should be recalculated based on current time
        const timeout = this.appState.restore('timeout');
        const remainingTime = timeout - Date.now();
        if (!timeout || remainingTime <= 0) {
            this.router.navigate(['/login']);
        } else {
            this.setSessionTimeout(remainingTime)
        };
    }

    /**
     * Set session timeout
     * @param time time interval
     */
    private setSessionTimeout(time) {

        // clear existing session timeout
        // reset session timeout        
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {

            // if timeout happens than
            // 1. clear session
            // 2. clear timeout
            // 3. redirect to login
            this.appState.store('session', null);
            this.appState.store('timeout', null);
            this.router.navigate(['/login']);
        
        }, time);

        // store active timeout value in local storage
        // it should be restored on page refresh
        this.appState.store('timeout', Date.now() + time);
    }

    public getSessionCheck(){
     
        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        // call API
        const url = `${this.apiRoot}/session/check`;
        let params = new HttpParams();
        return this.http.get(url, {params, headers})
                        .toPromise()
                        .then((response) =>{

                            return response;
                        })
        
    }
    public putSessionProlong(){
     
        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        // call API
        const url = `${this.apiRoot}/session/prolong`;

        return this.http.put(url, {}, { headers }).toPromise();    
        
    }
    public checkSessionAfter(interval, session) {

        if (!session) { clearTimeout(this.IdcheckSession); return; };

        this.IdcheckSession = setTimeout(() => {
    
          this.getSessionCheck().then((response: any) => {
    
            let userActive = sessionStorage.getItem('usrActiv_' + session);
    
           // console.log('checkSession(): TTL= ' + response.TTL + ' active=' + userActive);
            // if session is expiring
            if (response.TTL <= this.checkInterval) { //20sec
                if (userActive) {
                  // if session is almost expired and user active - prolong session
                  this.prolongSession(session);
                } else {
                    // if user not active - check after session is expired to show warning
                    this.checkSessionAfter(parseInt(response.TTL) + 1000 /* 1 sec*/, session);
                }
            } else {
                // check user activity before session is expired
                this.checkSessionAfter(this.checkInterval, session); // should return 401 and redirect to /logout
            }
          })
        }, interval);
      }
    
    public prolongSession(session) {

        sessionStorage.removeItem('usrActiv_' + session);

        this.putSessionProlong().then(() => {

            this.checkSessionAfter(this.checkInterval, session);

        }).catch(function (error) {
            console.error(error);
        });
    }

    public onBodyClick() {
        const session = this.appState.restore('session');

        let test = sessionStorage.getItem('usrActiv_' + session);

        let userActive = test ? parseInt(test) : 0;

        userActive++;

        sessionStorage.setItem('usrActiv_' + session, userActive.toString());
    }

    public setCheckSession(session) {
        
        document.addEventListener('click', () =>  { this.onBodyClick(); });
        this.checkSessionAfter(this.checkInterval, session);
    }
    public restoreCheckSession() {

        if (!this.IdcheckSession) {
            
            const session = this.appState.restore('session');

            this.setCheckSession(session);
        }

    }
}
