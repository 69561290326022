<div class="wrapper">

    <div class="container-lg">
        <button class="btn btn-link" (click)="back()">
              <span class="arrow"> <img src="../../../assets/img/lb-back-arrow.png" alt=""></span>
               <span class="name">{{'shared.back' | translate}}</span> 
        </button>
        
        <div class="missing" *ngIf="missing">{{'shared.missing' | translate}}</div>
    </div>

    <app-daily-standarts *ngIf="template" [xml]="template"></app-daily-standarts>

</div>