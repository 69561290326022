import { Injectable } from '@angular/core';

/**
 * Service with default xml templates.
 */

@Injectable({
  providedIn: 'root',
})
export class XmlTemplatesService {
  constructor() {}

  public getTemplate(type: string, age: string) {
    let template: string = null;

    switch (type) {
      case 'da':
      case 'wda': {
        template = `<daily>
  <name>Enter text here</name>
  <topic>Enter text here</topic>
  <objectives>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
  </objectives>
  <materials>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
  </materials>
  <procedures>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
  </procedures>
  <support>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
  </support>
  <social>
    <item>Enter text here</item>
    <item>Enter text here</item>
    <item>Enter text here</item>
  </social>
  <resources>
    <resource src="resource1.jpg" />
    <resource src="resource2.jpg" />
    <resource src="resource3.jpg" />
  </resources>
</daily>`;
        break;
      }
      case 'lp': {
        if (age === 'in' || age === 't1' || age === 't2') {
          template = `<lp>
  <day id="1">
    <activity id="st">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="mm">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
  </day>
  <day id="2">
    <activity id="st">
      <name>[ default text day2 ]</name>
      <objectives>
        <item>[ default text day2 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day2 ]</name>
      <objectives>
        <item>[ default text day2 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day2 ]</name>
      <objectives>
        <item>[ default text day2 ]</item>
      </objectives>
    </activity>
    <activity id="mm">
      <name>[ default text day2 ]</name>
      <objectives>
        <item>[ default text day2 ]</item>
      </objectives>
    </activity>
  </day>
  <day id="3">
    <activity id="st">
      <name>[ default text day3 ]</name>
      <objectives>
        <item>[ default text day3 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day3 ]</name>
      <objectives>
        <item>[ default text day3 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day3 ]</name>
      <objectives>
        <item>[ default text day3 ]</item>
      </objectives>
    </activity>
    <activity id="mm">
      <name>[ default text day3 ]</name>
      <objectives>
        <item>[ default text day3 ]</item>
      </objectives>
    </activity>
  </day>
  <day id="4">
    <activity id="st">
      <name>[ default text day4 ]</name>
      <objectives>
        <item>[ default text day4 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day4 ]</name>
      <objectives>
        <item>[ default text day4 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day4 ]</name>
      <objectives>
        <item>[ default text day4 ]</item>
      </objectives>
    </activity>
    <activity id="mm">
      <name>[ default text day4 ]</name>
      <objectives>
        <item>[ default text day4 ]</item>
      </objectives>
    </activity>
  </day>
  <day id="5">
    <activity id="st">
      <name>[ default text day5 ]</name>
      <objectives>
        <item>[ default text day5 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day5 ]</name>
      <objectives>
        <item>[ default text day5 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day5 ]</name>
      <objectives>
        <item>[ default text day5 ]</item>
      </objectives>
    </activity>
    <activity id="mm">
      <name>[ default text day5 ]</name>
      <objectives>
        <item>[ default text day5 ]</item>
      </objectives>
    </activity>
  </day>
</lp>`;
        } else {
          template = `<lp>
  <day id="1">
    <activity id="st">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ir">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="cg">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
  </day>
  <day id="2">
    <activity id="st">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ir">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="cg">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
  </day>
  <day id="3">
    <activity id="st">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ir">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="cg">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
  </day>
  <day id="4">
    <activity id="st">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ir">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="cg">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
  </day>
  <day id="5">
    <activity id="st">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ll">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="pd">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="ir">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
    <activity id="cg">
      <name>[ default text day1 ]</name>
      <objectives>
        <item>[ default text day1 ]</item>
      </objectives>
    </activity>
  </day>
</lp>`;
        }
        break;
      }
      case 'wlp': {
        if (age === 'in' || age === 't1' || age === 't2') {
          template = `<lp>
          <activity id="st">
            <name>[ default text day1 ]</name>
            <objectives>
              <item>[ default text day1 ]</item>
            </objectives>
          </activity>
          <activity id="ll">
            <name>[ default text day1 ]</name>
            <objectives>
              <item>[ default text day1 ]</item>
            </objectives>
          </activity>
          <activity id="pd">
            <name>[ default text day1 ]</name>
            <objectives>
              <item>[ default text day1 ]</item>
            </objectives>
          </activity>
          <activity id="mm">
            <name>[ default text day1 ]</name>
            <objectives>
              <item>[ default text day1 ]</item>
            </objectives>
          </activity>
      </lp>`;
        } else {
          template = `<lp>
          <activity id="st">
            <name>[ default text day1 ]</name>
            <objectives>
              <item>[ default text day1 ]</item>
            </objectives>
          </activity>
          <activity id="ll">
            <name>[ default text day1 ]</name>
            <objectives>
              <item>[ default text day1 ]</item>
            </objectives>
          </activity>
          <activity id="pd">
            <name>[ default text day1 ]</name>
            <objectives>
              <item>[ default text day1 ]</item>
            </objectives>
          </activity>
          <activity id="ir">
            <name>[ default text day1 ]</name>
            <objectives>
              <item>[ default text day1 ]</item>
            </objectives>
          </activity>
          <activity id="cg">
            <name>[ default text day1 ]</name>
            <objectives>
              <item>[ default text day1 ]</item>
            </objectives>
          </activity>
      </lp>`;
        }

        break;
      }
      case 'ds':
      case 'wds': {
        template = `
<dailystandards>
  <activity id="st">[ default text day1 ]</activity>
  <activity id="ll">[ default text day1 ]</activity>
  <activity id="pd">[ default text day1 ]</activity>
  <activity id="al">[ default text day1 ]</activity>
  <activity id="cg">[ default text day1 ]</activity>
</dailystandards>
        `;
        break;
      }

      case 'mtweek':{
        if (age === 'in' || age === 't1' || age === 't2') {
          template = `
      <materials>
          <activity id="st">
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
          </activity>
          <activity id="ll">
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
          </activity>
          <activity id="pd">
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
          </activity>
          <activity id="mm">
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
          </activity>
      </materials>`;
        } else {
          template = `
      <materials>
          <activity id="st">
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
          </activity>
          <activity id="ll">
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
          </activity>
          <activity id="pd">
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
          </activity>
          <activity id="ir">
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
          </activity>
          <activity id="cg">
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
            <item>[ default text day1 ]</item>
          </activity>
      </materials>`;
        }

        break;
      }
      case 'hc':
      case 'whc': {
        template = `
<hc>
  <resources>
    <resource src="resource1.pdf" />
  </resources>
</hc>`;
        break;
      }

      default: {
        break;
      }
    }
    return template;
  }
}
