import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AppState, SessionService, TeacherService } from 'src/app/services';

@Component({
  selector: 'app-teacher',
  template: '<div class="main"><router-outlet></router-outlet></div>',
  styleUrls: ['./teacher.component.scss']
})

export class TeacherRootComponent {

  public ages = [];

  constructor(private appState: AppState,
              private sessionService: SessionService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private translateService: TranslateService) {
    
  
    this.appState.store('role', 'teacher', true);

    // read stored week, day and age values
    const week = this.appState.restore('week');
    const day = this.appState.restore('day');
    const age = this.appState.restore('age');

    // if week is empty set default value of 1
    if (!week) {
      this.appState.set('week', 1);
    }

    // if week is empty set default value of 1
    if (!day) {
      this.appState.set('day', 1);
    }

    // if age is specified than check if component was opened directly
    // if yes than use autonavigation to open corresponding mode page
    // if age not specified go to age selection page
    if (!!age) {

      let hasChildren = this.activatedRoute.children.length > 0;
      if (!hasChildren && !!age) {
  
        this.router.navigate(['home'], { relativeTo: this.activatedRoute });
      }

    } else {

      this.router.navigate(['/teacher/home']);
    } 
  }
}
