<form class="form-inline">
    <div class="form-group">
      <div class="input-group">
        <input class="input" 
               name="dp" 
               autocomplete="off"
               [(ngModel)]="model" 
               ngbDatepicker
               (dateSelect)="onDateSelect(model)"
               #d="ngbDatepicker">

        <div   class="_form-control">{{date}}</div>

        <div class="input-group-append">
          <div class="icon" (click)="d.toggle()">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 792 611.999999" version="1.2">
            <defs>
            <clipPath id="clip1">
              <path d="M 135.472656 86 L 656.722656 86 L 656.722656 566.621094 L 135.472656 566.621094 Z M 135.472656 86 "/>
            </clipPath>
            </defs>
            <g id="surface1">
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 158.765625 203.0625 L 632.25 203.0625 L 632.25 540.332031 L 158.765625 540.332031 Z M 158.765625 203.0625 "/>
            <g clip-path="url(#clip1)" clip-rule="nonzero">
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,29.019165%,70.199585%);fill-opacity:1;" d="M 175.128906 227.464844 L 617.0625 227.464844 L 617.0625 502.25 C 617.0625 515.992188 605.910156 527.144531 592.167969 527.144531 L 200.027344 527.144531 C 186.28125 527.144531 175.128906 515.992188 175.128906 502.25 Z M 185.308594 86.230469 C 157.777344 86.230469 135.472656 108.535156 135.472656 136.070312 L 135.472656 516.78125 C 135.472656 544.316406 157.777344 566.621094 185.308594 566.621094 L 606.882812 566.621094 C 634.417969 566.621094 656.722656 544.316406 656.722656 516.78125 L 656.722656 136.070312 C 656.722656 108.535156 634.417969 86.230469 606.882812 86.230469 L 558.847656 86.230469 L 558.847656 126.445312 C 558.664062 149.8125 539.550781 168.648438 516.183594 168.417969 C 493.042969 168.230469 474.347656 149.535156 474.164062 126.445312 L 474.164062 86.230469 L 318.027344 86.230469 L 318.027344 126.445312 C 318.027344 149.8125 299.054688 168.785156 275.6875 168.785156 C 252.269531 168.785156 233.34375 149.8125 233.296875 126.445312 L 233.296875 86.230469 L 185.308594 86.230469 "/>
            </g>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,29.019165%,70.199585%);fill-opacity:1;" d="M 535.988281 125.890625 L 535.988281 65.5 C 535.988281 54.71875 527.242188 46.015625 516.460938 46.015625 C 505.722656 46.0625 497.070312 54.761719 497.023438 65.5 L 497.023438 125.890625 C 497.070312 136.671875 505.816406 145.371094 516.550781 145.324219 C 527.289062 145.277344 535.988281 136.625 535.988281 125.890625 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,29.019165%,70.199585%);fill-opacity:1;" d="M 295.167969 65.5 C 295.121094 54.71875 286.375 46.015625 275.640625 46.0625 C 264.90625 46.0625 256.203125 54.761719 256.203125 65.5 L 256.203125 125.890625 C 256.203125 136.671875 264.949219 145.371094 275.734375 145.324219 C 286.46875 145.277344 295.121094 136.625 295.167969 125.890625 L 295.167969 65.5 "/>
            </g>
            </svg>
            
          </div>
        </div>

      </div>
    </div>
  </form>
