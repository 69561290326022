import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ClassReflectionsComponent } from '../teacher';

@Injectable({
  providedIn: 'root'
})
export class DeactivateGuard implements CanDeactivate <ClassReflectionsComponent> {
  canDeactivate(
    component: ClassReflectionsComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !component.hasUnsavedChanges || confirm('Your changes will be lost, continue ?');
  }
  
}
