import {Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminFilterReflection} from './AdminFilterReflection';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import { AdminService, TeacherService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/services/app.service';
import { RouterExtService } from '../../services/RouterExtService';


@Component({
    selector: 'app-adminreflection',
    templateUrl: './reflection.component.html',
    styleUrls: ['./reflection.component.scss'],
  })


export class AdminReflectionComponent implements OnInit {

    public unknownPage: string = 'teacher_reflections.title';
    public day: string = null;
    public week: string = null; 
    public school: ISchool; 

    public reflections: any[];

    // filters
    public listAgeGroups: {key: string, title: string}[];
    public listClasses: IClass[];
    public listTeachers: ITeacher[];

    public currentTeacher: ITeacher;
    public currentAgeGroup: {key: string, title: string};
    public currentClass: IClass;

    public showClosedClasses: boolean = false;
    public loading: boolean = false;
    private previousUrl: string;

    private tr_filter: { age: {key: string, title: string}, class: IClass, teacher: ITeacher, closed: boolean};
    

    constructor(private appState: AppState,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private teacherService: TeacherService,
      private adminService: AdminService,
      private routerService: RouterExtService,
      private translateService: TranslateService ) {

        this.week = this.appState.restore('week');
        this.day = this.appState.restore('day');
        
        // if week is empty set default value of 1
        if (!this.week) {
          this.appState.store('week', 1, true);
          //this.appState.set('week', 1);
          this.week = this.appState.get('week');
        }

        // if day is empty set default value of 1
        if (!this.day) {
          this.appState.store('day', 1, true);
          //this.appState.set('day', 1);
          this.day = this.appState.get('day');
        }

        // filters
        this.tr_filter = {
          age: this.appState.restore('tr_age'),
          class: this.appState.restore('tr_class'),
          teacher: this.appState.restore('tr_teacher'),
          closed: this.appState.restore('tr_closed')
        }
    }

    public ngOnInit(): void {

      this.previousUrl = this.routerService.getPreviousUrl();

      this.listAgeGroups = this.adminService.getAgeGroup();
      this.listAgeGroups.unshift({key: null, title: 'shared.all'});
     
    }
    public onSaveLocaleStorage() {
        // save filters
        this.appState.store('tr_age', this.currentAgeGroup, true);
        this.appState.store('tr_class', this.currentClass, true);
        this.appState.store('tr_teacher', this.currentTeacher, true);
        this.appState.store('tr_closed', this.showClosedClasses, true);
    }

    private getTeachers(school): void {

      this.adminService.getTeachers(school).then((teachers: ITeacher[]) => {

        this.listTeachers = Object.assign([], teachers.sort((a, b) => (a.firstname + a.lastname < b.firstname + b.lastname ? -1 : 1)));

        this.listTeachers.unshift({
            birthdate: null,
            email: null,
            firstname: this.translateService.instant('admin_shared.all_teachers'),
            lastname: null,
            username: null,
          });

        if (this.currentTeacher === undefined) {
          this.currentTeacher = this.tr_filter.teacher ? this.listTeachers.find((x) => x.username === this.tr_filter.teacher.username) :  this.listTeachers[0];
         // this.currentTeacher = this.listTeachers[0];
        }

      }).catch((error) => {
        console.log('Error get teacher: ', error);
      });
      
    }


    public weekChanged(week) {

      this.loading = false;

      this.week = week;


      this.load(this.week, this.school.idnumber);
    }

    public select(clas: string, day: string, username: string) {


      this.appState.store('tr_filter_save', true, false);

      this.appState.store('day', day, true);
      this.router.navigate([this.school.idnumber, clas, username], { relativeTo: this.activatedRoute });
    }

    public getAgeGroup(age_group: string) {
 
      let age = this.adminService.getAgeGroup().find((x) => x.key === age_group);

      return age ? age.title : null;
    }

    private getDayReflection(clas: string, reflections, teacher) {

      return reflections.filter((x) => x.class === clas && x.teacher === teacher).map((y) => y.day);
    }

    private load(week: string, school) {

      this.showClosedClasses = this.tr_filter.closed ? this.tr_filter.closed : false;

      if (this.currentAgeGroup === undefined) {

        this.currentAgeGroup = this.tr_filter.age ? this.listAgeGroups.find((x) => x.key === this.tr_filter.age.key) :  this.listAgeGroups[0];
        
      }
      let allTeachers = [];

      Promise.all([this.adminService.getClasses(school), 
                   this.adminService.getReflections(school, week)]).then(([classes, reflections]) => {

                    let promiseArr : Array<Promise<ITeacher[]>> = [];

                    classes.forEach((clas, i) => {

                     promiseArr.push(this.adminService.getTeachersByClass(clas.idnumber).then((teachers) => {
                      let tempTeachers = teachers.map((x) => {
                               return {
                                 username: x.username,
                                 lastname: x.lastname,
                                 firstname: x.firstname,
                                 idnumber: clas.idnumber,
                                 name: clas.name,
                                 age_group: clas.age_group,
                                 date_begin: clas.date_begin,
                                 date_end: clas.date_end,
                                 days: this.getDayReflection(clas.idnumber, reflections, x.username)
                               }
                         })
                        allTeachers.push(...tempTeachers);
                        return teachers;
                     }));
                    })

                    Promise.all(promiseArr).then((result: any) => {
                      this.reflections = allTeachers
                                            .sort((a, b) => 
                                                  (a.firstname.toLowerCase() + 
                                                  a.lastname.toLowerCase() + 
                                                  a.name.toLowerCase() < b.firstname.toLowerCase() + 
                                                                         b.lastname.toLowerCase() + 
                                                                         b.name.toLowerCase() ? -1 : 1))

                      this.loading = true;
                    });

                                        
                    this.listClasses = Object.assign([], classes.sort((a, b) => (a.name < b.name ? -1 : 1)));
  
                        this.listClasses.unshift({
                            date_begin: null,
                            date_end: null,
                            idnumber: null,
                            main_tchr_first: null,
                            main_tchr_last: null,
                            main_tchr_username: null,
                            name: this.translateService.instant('admin_shared.all_classes'),
                            age_group: null
                          });

                          
                          if (this.currentClass === undefined) {
                            this.currentClass = this.tr_filter.class ? this.listClasses.find((x) => x.idnumber === this.tr_filter.class.idnumber) :  this.listClasses[0];
                            // this.currentClass = this.listClasses[0];
                          }
                                                
              })
    }

    public onChangeSchool(school: ISchool) {

      this.loading = false;

      this.currentTeacher = undefined;
      this.currentClass = undefined;
      this.currentAgeGroup = undefined;
      this.showClosedClasses = false;

    
      if (!this.previousUrl.includes('tr/' + school.idnumber + '/')) {

        this.appState.remove('tr_age');
        this.appState.remove('tr_class');
        this.appState.remove('tr_teacher');
        this.appState.remove('tr_closed');

        this.tr_filter.age = undefined;
        this.tr_filter.class = undefined;
        this.tr_filter.closed = false;
        this.tr_filter.teacher = undefined;
      }
      this.previousUrl = '';
 
      this.school = school;
      
      this.reflections = undefined;

 
      this.getTeachers(school.idnumber);

      this.load(this.week, school.idnumber);

    }
    
}