<section class="resources-comp">
  <!--container start-->
  <div class="container-fluid">
    <!--row start-->
    <div class="row">
      <!--col start-->
      <div class="col">
        <!--upload file input start-->
        <div class="input-group">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              (input)="vmFileUpload($event)"
              multiple
            />
            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
          </div>
        </div>
        <!--upload file input end-->
      </div>
      <!--col end-->
    </div>
    <!--row end-->

    <!--row start-->
    <div class="row">
      <!--col start-->
      <div class="col">
        <!--resources container start-->
        <div class="resources-container">
          <!--resource start-->
          <div class="resource" *ngFor="let resource of resourcesList">
            <!--video wrapper start-->
            <div class="resource__asset-wrapper" [ngSwitch]="resource.type">
              <!--video start-->
              <video src="{{ resource.data }}" controls *ngSwitchCase="'video/mp4'"></video>
              <!--video end-->

              <!--image start-->
              <img src="{{ resource.data }}" controls *ngSwitchCase="'image/jpeg'" />
              <!--image end-->

              <!--image start-->
              <img src="{{ resource.data }}" controls *ngSwitchCase="'image/png'" />
              <!--image end-->

              <!--pdf start-->
              <img src="../../../assets/icons/pdf-icon.png" controls *ngSwitchCase="'application/pdf'" />
              <!--pdf end-->
            </div>
            <!--video wrapper end-->

            <!--title start-->
            <p class="resource__title">{{ resource.title }}</p>
            <!--title end-->

            <!--button remove start-->
            <button class="btn btn-upload" (click)="vmReplaceFile(resource)">Upload New</button>
            <!--button remove end-->
            <input type="file" #fileInput id="uploadFile01" (change)="replace($event)"  style="display: none;" />
            <!--button remove start-->
            <button class="btn btn-danger" (click)="vmRemove(resource)">Remove</button>
            <!--button remove end-->
          </div>
          <!--resource end-->
        </div>
        <!--resources container end-->
      </div>
      <!--col end-->
    </div>
    <!--row end-->
  </div>
  <!--container end-->
</section>
