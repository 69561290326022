import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { FilterBaseComponent, IOption } from '../base/filter-base.component';

@Component({
  selector: 'app-type-filter',
  templateUrl: '../base/filter-base.component.html',
  styleUrls: ['../base/filter-base.component.scss'],
  // tslint:disable-next-line: no-outputs-metadata-property
  outputs: ['onSelect', 'onTemplateClear'],
})
export class TypeFilterComponent extends FilterBaseComponent implements OnInit, OnChanges {
  title = 'Type';

  options: Array<IOption> = [{ value: 'lessonplans', title: 'Lesson Plans' }];

  @Input()
  public reset: boolean = false;

  constructor() {
    super();
  }

  @Input()
  public set isTemplateChanged(value: boolean) {
    this.templateChanged = value;
  }

  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.reset) {
      this.value = null;
      this.reset = false;
    }
  }
}
