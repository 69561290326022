
<app-menu [home]="false" [pageAdmin]="true" [unknownPage]='unknownPage' [school]="currentSchool"></app-menu>
<app-filterbyschool-component (onChange)="onChangeSchool($event)" [back]='true'></app-filterbyschool-component>

<div class="wrapperPage container-lg">
    <div class="blockWrapperButtons">
        <div class="groupButtons" *ngIf="currentSchool.school_type != 'Clever'">
            <button (click)="onCreate()" *ngIf="mode === 'assignedteacher'">{{'admin_shared.create_teacher' | translate}}</button>
            <button (click)="onCreate()" *ngIf="mode === 'assignedchild'">{{'admin_shared.create_new_child' | translate}}</button>
            <button (click)="onImportTeacher()" *ngIf="mode === 'assignedteacher'">{{'admin_shared.import' | translate}}</button>
            <button (click)="onImportChild()" *ngIf="mode === 'assignedchild'">{{'admin_shared.import' | translate}}</button>
        </div>
        <div></div>

        <div class="wrapperInputElement">
            <p>{{'shared.search' | translate}}: <input type="text" placeholder="{{'admin_shared.placeholder' | translate}}" 
                            [(ngModel)]="searchText" /></p>

        </div>
    </div>
  

    <div [ngSwitch]="mode">
        <div *ngSwitchCase="'assignedteacher'">
            <div class="tableCustom">
                <div class="rowCustom header">
                    <div class="cell name">{{ 'admin_shared.teacher_name' | translate}}</div>
                    <div class="cell usernameTeacher">{{'profile.username' | translate}}</div>
                    <div class="cell action">{{ 'admin_shared.action' | translate}}</div>
                </div>
                <div class="rowCustom" *ngFor="let item of list | search: searchText:['lastname', 'firstname', 'username']">
                    <div class="cell left name">{{item.firstname}} {{item.lastname}}</div>
                    <div class="cell usernameTeacher">{{item.username}}</div>
                    <div class="cell action">
                        <div class="icon edit" (click)="onEdit(item.username)"></div>
                        <div class="icon delete" *ngIf="currentSchool.school_type != 'Clever'" (click)="onDeleteTeacher(item.username)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'assignedchild'">
            <div class="tableCustom children">
                <div class="rowCustom header">
                    <div class="cell">{{'assessment.child_name'  | translate}}</div>
                    <div class="cell date">{{'profile.date_of_birth' | translate}}</div>
                    <div class="cell username">{{'profile.username' | translate}}</div>
                    <div class="cell action">{{ 'admin_shared.action' | translate}}</div>
                </div>
                <div class="rowCustom" *ngFor="let item of list | search: searchText:['lastname', 'firstname', 'username']">
                    <div class="cell left">{{item.firstname}} {{item.lastname}}</div>
                    <div class="cell date">{{getLocaleDate(item.birthdate)}}</div>
                    <div class="cell username">{{item.username}}</div>
                    <div class="cell action">
                        <div class="icon assessment" (click)="onAssessment(item.username)"></div>
                        <div class="icon edit" (click)="onEdit(item.username)"></div>
                        <div class="icon delete" *ngIf="currentSchool.school_type != 'Clever'" (click)="onDeleteChild(item.username)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<!-- Loader spinner-->
<div class="myloader" *ngIf="!loading">
    <loader></loader>
</div>
<!--end spinner -->