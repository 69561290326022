import {Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import {Location} from '@angular/common';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import * as moment from 'moment';
import { AppState } from 'src/app/services/app.service';

@Component({
    selector: 'app-editclass',
    templateUrl: './editclass.component.html',
    styleUrls: ['./editclass.component.scss'],
  })

export class EditClassComponent implements OnInit {

    // unknownPage is value what to show us where we are
    // we dont go to link in menu
    // this is to show users where we are
    public unknownPage: string = 'admin_shared.edit_class';

    // flag for switch tabs
    public flagList: string = 'children';

    // values for showing under table
    public teachersByClass: ITeacher[];
    public childrenByClass: ITeacher[];

    // values for editing class
    public age_groups;
    public currentClass: IClass;
    public classPeriodFrom: string;
    public classPeriodTo: string;

    public school: any;

    // username current teacher who is assigned on this class
    public listTeachers: ITeacher[];
    public currentTeacher: string;
    public currentSchool: ISchool;

    // get id school from url 
    private idschool: string;
    private idclass: string

    // button 'Save' is disable 
    // if not all fields are filled
    // if true - disables
    public isFlagSave: boolean = true;

    public loading: boolean = false;
    //if end date is earliear the start date
    public errorDate: string;

    constructor(private router: Router,
                private adminService: AdminService,
                private route: ActivatedRoute,
                private _location: Location,
                private appState: AppState) {

      this.flagList = this.appState.restore('list');

      if (!this.flagList) {
        this.flagList = 'children';
      }

    }

    public ngOnInit(): void {

      this.currentSchool = this.appState.restore('school');  
      this.age_groups = this.adminService.getAgeGroup();

      // get id of class and id of school from url
      this.idclass = this.route.snapshot.params['idclass'];
      this.idschool = this.route.snapshot.params['idschool'];

      this.onEditingClass();

      this.onGetChildTeacher();
    }

    public onEditingClass() {
    
      this.adminService.getClasses(this.idschool).then((listClasses: IClass[]) => {// else call method api with current school
          let _class = listClasses.find((x) => x.idnumber === this.idclass);
          this.currentClass = Object.assign({}, _class);
        

        // copy without link because user can change something but not save
        this.currentTeacher = this.currentClass.main_tchr_username;
   
      }).catch((error) => {
        console.log('Error get class: ', error);
      });

      // get list of teachers for field
      this.adminService.getTeachers(this.idschool).then((teachers: ITeacher[]) => {

        this.listTeachers = teachers;

      }).catch((error) => {
        console.log('Error get teacher: ', error);
      });

    }

    public onGetChildTeacher() {

      this.adminService.getTeachersByClass(this.idclass).then((teachersByClass: ITeacher[]) => {
        this.teachersByClass = teachersByClass;
        this.loading = true;
      }).catch((error) => {
        console.log('Error get teacher by class: ', error);
      });
      
      this.adminService.getChildrenByClass(this.idclass).then((childrenByClass: ITeacher[]) => {
        this.childrenByClass = childrenByClass;
        this.loading = true;
      }).catch((error) => {
        console.log('Error get children by class: ', error);
      });

    }

    public onBack() {

     this._location.back();
    }

    // check all fields
    // all fields should be filled
    public onChangeSave() {

      let flagDates = this.onCheckDates(this.currentClass.date_begin, this.currentClass.date_end);

      if (this.currentClass && this.currentClass.name && 
          this.currentClass.age_group && this.currentClass.date_begin &&
          this.currentClass.date_end && this.currentTeacher && !flagDates) {
            this.isFlagSave = false;
      } else {
        this.isFlagSave = true;
      }  
    }
    // start date must be less than end date
    private onCheckDates(beginDate, endDate): boolean {

      if (!endDate || !beginDate) return true;

      if (beginDate < endDate) {
        this.errorDate = null;
        return false;
      } else {
        this.errorDate = 'admin_shared.errorDate_text';
        return true;
      }
    }

    public Save(clas: IClass,  teacher: string) {

      this.adminService.editClasses(clas, this.idschool, teacher).then((response) => {
       
        this.adminService.resetClassesCache();
        this.adminService.resetTeacherByClassCache();

        this.onGetChildTeacher();

        this.isFlagSave = true;

      }).catch((error) => {
        console.log('Error Edit Class: ', error);
      });
    }
    public onAssignedToClassroom(title: string) {
      this.router.navigate([title], { relativeTo: this.route });
    }
    public onGoToEdit(page, username) {
      this.router.navigate([page, username], { relativeTo: this.route });
    }

    public getDateFrom(date) {
      this.currentClass.date_begin = date;
      // check all field
      // all field must be filled
      // start date must be less than end date
      this.onChangeSave();
    }
    public getDateTo(date) {
      this.currentClass.date_end = date;
      this.onChangeSave();
    }
    public deleteTeacherFromClass(username) {

      this.adminService.removeTeacherFromClass(username, this.idclass).then((respnse) => {

        this.teachersByClass = this.teachersByClass.filter((x) => x.username != username);

        this.adminService.resetTeacherByClassCache();
      }).catch((error) => {
        console.log('removeTeacherFromClass: ', error);
      });
    }
    public deleteChildFromClass(username) {
 
      this.adminService.removeChildFromClass(username, this.idclass).then((respnse) => {

        this.childrenByClass = this.childrenByClass.filter((x) => x.username != username);

        this.adminService.resetChildrenByClassCache();

      }).catch((error) => {
        console.log('removeChildFromClass: ', error);
      });
      
    }
    public getLocaleDate(date) {
        
      let _date = new Date(date * 1000);

      return  _date.toLocaleDateString();
    }
    public onAssessment(username) {
      this.router.navigate(['as', 'child', username], { relativeTo: this.route });
    }

    public onOpenList(value) {

      this.appState.store('list', value, true);
      
      this.flagList = this.appState.get('list');
    }

    public onChangeSchool(school) {
      this.school = school;
    }

    public onCreateChild() {
      let where = 'toSchool';
      let title = 'assignedchild';
      this.router.navigate([title, 'user', 'cl', where], { relativeTo: this.route });
    }
    public onImport() {
       this.router.navigate(['import', 'cl', 'child'], { relativeTo: this.route });
    }
}