import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { element } from 'protractor';

import { AppState } from 'src/app/services/app.service';
import { TeacherService } from 'src/app/services/teacher.service';

@Component({
  selector: 'teacher-reflections',
  templateUrl: './reflections.component.html',
  styleUrls: ['./reflections.component.scss']
})
export class ReflectionsComponent implements OnInit {

  public title: string = 'Teacher Reflections';
  public school: any;
  public age: string = null;
  public week: string = null;  

  public reflections: any[] = null;
  
  constructor(private appState: AppState,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private teacherService: TeacherService) {

    this.age = this.appState.restore('age');
    this.week = this.appState.restore('week');

    if (!this.age) {
      this.appState.store('age', 'in', true);
      this.age = this.appState.get('age');
    }

  if (!this.week) {
      this.appState.store('week', 1, true);
      this.week = this.appState.get('week');
  }
  }

  ngOnInit(): void {
    this.school = this.appState.restore('school');
    
    this.load(this.week);
  }

  public weekChanged(week) {

    this.week = week;
    this.load(this.week);
  }
  public _ageChanged(age) {
        
    this.age = age;
    this.load(this.week);

}

  public select(classId: string, day: string) {

    this.appState.store('day', day, true);
    this.router.navigate(['home/tr', classId], { relativeTo: this.activatedRoute.parent });
  }

  private load(week: string) {

    // get list of all classes
    // for each class check it's reflections for given week
    this.teacherService.getClasses().then((classes) => {

          const reflections = classes.map((item) => this.getReflection(item.idnumber, item.title, week, item.age_group));
          
          Promise.all(reflections).then((reflections) => {
            
            this.reflections = reflections.filter( element => element.age_group == this.age);
          });
        });
  }

  private getReflection(classId: string, title: string, week: string, age_group: string) {

    // get reflections per class and week
    // convert into required format
    return this.teacherService
              .getReflections(classId, week)
              .then((reflections) => ({
                id: classId,
                title: title,
                age_group: age_group,
                days: reflections.map((r) => r.day)
              }));
  }
}
