import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})

export class DatepickerComponent implements OnInit {

  @Input()  assessmentDate: any;
  @Output() onChange = new EventEmitter()

  public model: NgbDateStruct;
  public today = this.calendar.getToday();
  public date: string;

  constructor(private calendar: NgbCalendar) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
     if( changes.assessmentDate ) {
       if(!!this.assessmentDate){
        let _date = new Date(this.assessmentDate * 1000);
        this.date = _date.toLocaleDateString()
       } else{
        let todey = new Date();
        this.date = todey.toLocaleDateString()
        let unixAssessmentDate = moment(todey).unix();
        this.onChange.emit(unixAssessmentDate);
       }
     } 
  }
  
  public onDateSelect(date:any) {
    let unixAssessmentDate = moment( new Date(date.year, date.month-1, date.day), 'YYYY-MM-DD').unix();
    this.onChange.emit(unixAssessmentDate);
  }

}
