import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AppState, PreviousRouteService } from 'src/app/services';
import { TeacherService } from 'src/app/services/teacher.service';
import { Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router  } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [ TeacherService, AppState]
})

export class MenuComponent implements OnInit, OnChanges  {
  
  public ages = [];
  public logo: any;
  public ageTitle: string;
  public ageKey: string;
  public firstname: string;
  public previousUrl: string;
  // available modules
  public editor: boolean = false;
  public teacher: boolean = false;
  public admin: boolean = false;
  public parent: boolean = false;
  public student: boolean = false;
  public role: string;
  public submenuState: boolean = false;
  public backBtnState: boolean = true;
  public ageGroupControlState: boolean = false;
  public ageGroupPickerState:  boolean = false;
  public searchBtnState: boolean = false;
  private defaultColor: string = '#2cc84d';
  // toggler for collapse / expand menu
  public isMenuCollapsed = true;
  public dropdownMenuState  = false;
  public isTeacherSection: boolean = false;
  public isEditorPage: boolean = false;
  public unknownPageTitle: string;
  
  @Input() home: boolean = true;
  @Input() pageAdmin: boolean = false;
  // User Profile Page
  @Input() UPpage: boolean = false;
  @Input() mode: string;
  @Input() details: string;
  @Input() adminmode: string;
  @Input() submenu: boolean;
  @Input() backBtnDisabled: boolean;
  @Input() ageGroupControlDisabled: boolean;
  @Input() newFirstName: string;
  @Input() searchBtn: boolean;
  @Input() school: any;

  // unknownPage is value what to show us where we are
  // we dont go to link in menu
  // this is to show users where we are
  @Input() unknownPage: string;
  
  constructor ( private appState: AppState,
                private teacherService : TeacherService,
                private adminService: AdminService,
                private _location: Location,
                private route: ActivatedRoute,
                private router: Router,
                private http: HttpClient,
                private translateService: TranslateService,
                private previousRouteService: PreviousRouteService) {

    // read profile information and current role        
    const profile = this.appState.restore('profile');
    this.role = this.appState.restore('role');

    // get first     
    this.firstname = profile.firstname;
    // check if teacher / editor options should be enabled
    this.editor = profile.roles.includes('coursecreator');
    // if user has role 'schooladmin' - he can go to teacher page
    this.teacher = (profile.roles.includes('teacher') &&  this.role === 'teacher') || (profile.roles.includes('schooladmin') && this.role === 'schooladmin') || (profile.roles.includes('teacher') && profile.roles.includes('coursecreator') &&  this.role == 'coursecreator' );
    this.admin = profile.roles.includes('schooladmin'); 
    this.student =  profile.roles.includes('student');
    this.parent =  profile.roles.includes('parent');
  }

  ngOnInit(): void {

    this.ages = this.teacherService.getAges();
   
    if (this.submenu) this.submenuState = true;
    if (this.backBtnDisabled) this.backBtnState = false;
    if (this.ageGroupControlDisabled) this.ageGroupControlState = true;
    if (this.searchBtn) this.searchBtnState = true;
    
    this.ageKey = this.appState.restore('age');

    if (this.ageKey) {
        this.ageTitle = this.getAgeTitle(this.ageKey);
    } else {
      this.ageTitle = this.getAgeTitle('in');
      this.appState.store('age', 'in', true);
    }

    let _isLogo = this.adminService.vmGetLogo();   
    if (_isLogo) {
      this.logo = _isLogo;
    }

   this.isTeacherSection =  this.route.snapshot['_routerState'].url.includes('teacher');
   this.isEditorPage =  this.route.snapshot['_routerState'].url.includes('editor');
   this.previousUrl= this.previousRouteService.getPreviousUrl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.UPpage){
      //for quickly change username on User Profile Page
      if(this.newFirstName){
        this.firstname = this.newFirstName;
      }
    }

    if(changes && changes.school && changes.school.currentValue) {

      this.vmSetSettingsSchool(changes.school.currentValue);
    }
  }

  private vmSetLogo(school: any) {

    let _isLogo = this.adminService.vmGetLogo(); 

    if (!_isLogo){
      if(school && school.logo) { this.vmSetLogoHeader(school); }
      if(school && !school.logo) { this.vmSetDefaultLogoHeader(); }    
    }
  }

  private vmSetSettingsSchool(school){

    if(school) { this.vmSetColorHeader(school.color); }
    this.vmSetLogo(school);
  }

  private vmSetColorHeader(color: string) {

    const hex = document.getElementById('header');
    const hex2 = document.getElementById('header2');

    if (hex != null && hex2 != null) {
      hex.style.backgroundColor = color ? color : this.defaultColor;
      hex2.style.backgroundColor = color ? color : this.defaultColor;
    }
  }

  private async vmSetDefaultLogoHeader() {
    this.logo = await this.adminService.getResourceBlobImage("/assets/img/default_logo.png");
  }
  
  private async vmSetLogoHeader(school) {
      let res = await this.adminService.getResourceLogo(school.idnumber);
      let files = [res];
		  let images : any = await Promise.all(files.map(f=>{return this.readAsDataURL(f)}));
      this.logo = images[0].data;
      this.adminService.vmSetLogo(this.logo);
  }

  private readAsDataURL(file) {
		return new Promise((resolve, reject)=>{
			let fileReader = new FileReader();
			fileReader.onload = function(){
				return resolve({data:fileReader.result, name:file.name, size: file.size, type: file.type});
			}
			fileReader.readAsDataURL(file);
		})
  }

  public onAgeChoose( age:string ): void {
    this.ageKey = age;
    this.appState.store('age', age, true);

    this.ageGroupPickerState = false;
    this.ageTitle = this.getAgeTitle(age);
  }

  public getAgeTitle(ageKey: string): string {

      const age = this.teacherService.getAges().find((a) => a.key == ageKey);
      return age.titleKey
  }

  public changeState():void{
 
    this.dropdownMenuState = !this.dropdownMenuState;
  }

  public onClickedOutside(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    
    if (this.ageGroupPickerState) {
      this.ageGroupPickerState = false;
    }
  }

  public closeMenu() {
    this.dropdownMenuState = false;
  }
  
  public onAdminClick(){
    this.adminService.resetAllCache()
    this.dropdownMenuState = false;
  }

  public back() {
    if(this.UPpage) {
      this.router.navigateByUrl(this.previousUrl);
    }
    this._location.back();
  }

  public openAgeGroupPicker(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.ageGroupPickerState = true;
  }

  public ageChanged(age) {

    console.log(age);
  }

  public createUnknownPageTitle(titleKey:string){

    if(titleKey.includes(':')){
      var parts = titleKey.split(":");
      var beforeColon = parts[0];
      var afterColon = parts[1];
      return  this.translateService.instant(beforeColon) + " : " + this.translateService.instant(afterColon)
     }
     else {
      return  this.translateService.instant(titleKey) 
     } 
  }
}

